import React from "react";
import { DebtAnalysisUIProvider } from "./DebtAnalysisContext";
import { DebtAnalysisStat } from "./DebtAnalysisStat";
export function DebtAnalysisMain({ history }) {

  return (
    <DebtAnalysisUIProvider>
      <DebtAnalysisStat />
    </DebtAnalysisUIProvider>
  );
}