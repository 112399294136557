import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { customersSlice } from "../app/modules/Contacts/_redux/customersSlice";
import { importSlice } from "../app/modules/Import/_redux/importSlice";
import { questionsSlice } from "../app/modules/QuestionAnalysis/_redux/questionsSlice";
import { domainsSlice } from "../app/modules/DomainAnalysis/_redux/domainsSlice";
import { domainListSlice } from "../app/modules/Domains/_redux/domainsSlice";
import { cpvTrackingSlice } from "../app/modules/CpvTracking/_redux/cpvTrackingSlice";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { leadAnalysisSlice } from "../app/modules/LeadAnalysis/_redux/leadAnalysisSlice";
import { leadStatusAnalysisSlice } from "../app/modules/LeadStatusAnalysis/_redux/leadStatusAnalysisSlice";
import { leadCompareAnalysisSlice } from "../app/modules/LeadCompareAnalysis/_redux/leadCompareAnalysisSlice";
import { timezoneReducer } from "./timezone/timezoneReducer";
import { fileDownloader } from "./fileDownloader/fileDownloaderReducer";
import { adminLogsListSlice } from "../app/modules/AdminLogs/_redux/adminLogsSlice";
import { staffManagementListSlice } from "../app/modules/StaffManagement/_redux/staffManagementSlice";
import { adSpentListSlice } from "../app/modules/AdsSpent/_redux/AdsSpentSlice";
import { CPASlice } from "../app/modules/CostPerAcquisition/_redux/cpaSlice";
import { liveChatSlice } from "../app/modules/LiveChatAnalysis/_redux/liveChatSlice";
import { conversationSlice } from "../app/modules/LiveChatConversation/_redux/conversationSlice";
import { liveChatConversationSlice } from "../app/modules/LiveChatConversationAnalysis/_redux/liveChatSlice";
import { landingPagesSlice } from "../app/modules/LandingPagesManagement/_redux/landingPageSlice";
import { questionsManageSlice } from "../app/modules/QuestionManagement/_redux/questionManagementSlice";
import { landingPagesTemplatesSlice } from "../app/modules/LandingPagesCSSTemplates/_redux/templatesSlice";
import { fpSlice } from "../app/modules/FunnelPerformance/_redux/fpSlice";
import { dbLogsListSlice } from "../app/modules/DB-BackupListing/_redux/dbBackupSlice";
import { debtAnalysisSlice } from "../app/modules/DebtAnalysis/_redux/debtAnalysisSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  questionAnalysis: questionsSlice.reducer,
  domainAnalysis: domainsSlice.reducer,
  cpvTracking: cpvTrackingSlice.reducer,
  import: importSlice.reducer,
  domains: domainListSlice.reducer,
  leadAnalysis: leadAnalysisSlice.reducer,
  leadStatusAnalysis: leadStatusAnalysisSlice.reducer,
  leadCompareAnalysis: leadCompareAnalysisSlice.reducer,
  timezoneBy: timezoneReducer.reducer, // master / domain
  adminLogs: adminLogsListSlice.reducer,
  staffManagement: staffManagementListSlice.reducer,
  adSpent: adSpentListSlice.reducer,
  cpa: CPASlice.reducer,
  liveChat: liveChatSlice.reducer,
  liveChatConversation:conversationSlice.reducer,
  liveChatConversationAnalysis:liveChatConversationSlice.reducer,
  landingPages:landingPagesSlice.reducer,
  question_management:questionsManageSlice.reducer,
  cssTemplates:landingPagesTemplatesSlice.reducer,
  funnelPerformance:fpSlice.reducer,
  dbLogs:dbLogsListSlice.reducer,
  fileDownloader:fileDownloader.reducer,
  debtAnalysis: debtAnalysisSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
