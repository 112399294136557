import { createSlice } from "@reduxjs/toolkit";

const initialAdSpentState = {
  listLoading: false,
  actionsLoading: false,
  entities: {},
  error: null,
  accounts: [],
  allLeadSources:[]
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const adSpentListSlice = createSlice({
  name: "ad_spent",
  initialState: initialAdSpentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Ad Spent Fetched
    AdSpentFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    // Ad Spent Fetched
    accountFetched: (state, action) => {
      const { accounts } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.accounts = accounts;
    },
    //Account created and push new one to listing
    accountCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.accounts = action.payload.accountDetails;
    },
    // delete Account
    accountDeleted: (state, action) => {
      console.log(action.payload.details.leadSource);
      state.error = null;
      state.actionsLoading = false;
      state.accounts = state.accounts.filter(
        (el) => el != action.payload.details.leadSource
      );
    },
    //Spent Updated created and push new one to listing
    spentUpdated: (state, action) => {
      const { day, leadSource, difference } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.entities[leadSource][0] =
        state.entities[leadSource][0] + parseFloat(difference);
      state.entities["Total"][day] = state.entities["Total"][day] + parseFloat(difference);
      state.entities["Total"][0] = state.entities["Total"][0] + parseFloat(difference);
      // state.accounts = action.payload.accountDetails;
    },
    // Ad Spent Fetched
    leadSourcesFetched: (state, action) => {
      const { leadSources } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allLeadSources = leadSources;
    },
  },
});
