import React from "react";
import { LandingPagesLoadingDialog } from "./landing-pages-loading-dialog/LandingPagesLoadingDialog";
import { LandingPagesUIProvider } from "./LandingPagesUIContext";
import { LandingPagesCard } from "./LandingPagesCard";

export function LandingPagesPage(props) {
  return (
    <LandingPagesUIProvider>
      <LandingPagesLoadingDialog />
      <LandingPagesCard {...props} />
    </LandingPagesUIProvider>
  );
}
