import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { CpvTrackingMain } from "../modules/CpvTracking/pages/CpvTrackingMain";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function CpvTrackingPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("cpv_track_analysis")
  ) {
    return <CpvTrackingMain />;
  } else {
    return <UnauthorizedAccess />;
  }
}
