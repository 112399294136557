import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CPAFilters } from "./CPAFilters";
import { ChartComponent } from "./ChartComponent";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/cpaActions";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

export function CPACards() {
  const history = useHistory();
  const { currentState, userType, permissions } = useSelector(
    (state) => ({
      currentState: state.cpa,
      userType: state.auth.user.userType,
      permissions: state.auth.user.permissions,
    }),
    shallowEqual
  );
  const { chartData, listLoading } = currentState;
  return (
    <Card>
      {/* Cost Per Acquisition */}
      <CardHeader title="">
        <CardHeaderToolbar>
          {(userType.toLowerCase() === "admin" ||
            permissions["cpa"].includes("leadStatus")) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push("/cpa/add-sale-status");
              }}
            >
              Add Lead Sale Status
            </Button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CPAFilters />
        {listLoading ? (
          <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ) : (
          <div className="graph-container mt-2">
            <ChartComponent
              id={"cpa_chart"}
              labels={chartData.labels}
              values={chartData.series}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
}
