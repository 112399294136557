import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import FileSaver from "file-saver";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { Button, FormHelperText, InputBase, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { debounce } from "lodash";
import * as actions from "../../../_redux/customersActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { AWSFileDownload } from "../../../../../../_metronic/_helpers/AWSFileUpload";
import momentTZ from "moment-timezone";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/styles";

const API_URL = process.env.REACT_APP_API_URL;
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 13,
      padding: "10px 26px 10px 12px",
      width: "100%",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);


const prepareFilter = (queryParams, values) => {
  const {
    name,
    email,
    phone,
    domain,
    email_valid,
    lead_type,
    searchText,
    startDate,
    endDate,
    lead_status,
    isduplicate,
    hideduplicate
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by name
  filter.name = name !== "" ? name : undefined;
  // Filter by email
  filter.email = email !== "" ? email : undefined;
  // Filter by email_valid
  filter.email_valid = email_valid !== "" ? email_valid : undefined;
  // Filter by phone
  filter.phone = phone !== "" ? phone : undefined;
  // Filter by domain
  filter.domain = domain !== "" ? domain : undefined;
  // Filter by lead_type
  filter.lead_type = lead_type !== "" ? lead_type : undefined;
  // Filter by lead_status
  filter.lead_status = lead_status !== "" ? lead_status : undefined;
  // Filter by isduplicate
  filter.isduplicate = isduplicate !== "" ? isduplicate:undefined;
  // Filter by Hide Duplicate
  filter.hideduplicate = hideduplicate;
  delete newQueryParams.operator;
  if (searchText && searchText.length > 0) {
    filter.name = searchText;
    filter.email = searchText;
    filter.phone = searchText;
    filter.domain = searchText;
    filter.ipAddress = searchText;
    filter.sid = searchText;
    newQueryParams.operator = "OR";
  }
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  newQueryParams.from = 1;
  newQueryParams.filter = filter;
  return newQueryParams;
};
const leadStatusOptions = [{ label: "All", value: "All" }];
const staticFilters = [
  // {
  //   label: "Search By Name",
  //   type: "text",
  //   key: "name",
  // },
  // {
  //   label: "Search By Email",
  //   type: "text",
  //   key: "email",
  // },
  {
    label: "Is email Valid or not?",
    type: "dropdown",
    key: "email_valid",
    options: [
      {
        label: "All",
        value: "All",
      },
      {
        label: "VALID",  
        value: "VALID",
      },
      {
        label: "INVALID",
        value: "INVALID",
      },
    ],
  },
  // {
  //   label: "Search By Phone",
  //   type: "text",
  //   key: "phone",
  // },
  // {
  //   label: "Search By Domain",
  //   type: "text",
  //   key: "domain",
  // },
  {
    label: "Lead Type",
    type: "dropdown",
    key: "lead_type",
    options: [
      {
        label: "All",
        value: "All",
      },
      {
        label: "Good",
        value: "VALID",
      },
      {
        label: "Bad",
        value: "BK",
      },
    ],
  },
  {
    label: "Lead Status",
    type: "dropdown",
    key: "lead_status",
    options: leadStatusOptions,
  },
  {
    label: "AB Duplicate",
    type: "dropdown",
    key: "isduplicate",
    options: [
      {
        label: "All",
        value: "All",
      },
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
];
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));
export function CustomersFilter({ listLoading, userInfo }) {
  const { permissions, userType } = userInfo;

  // let selectInputRef = useRef();
  let selectInputRef = {};
  let selectInputRefemail_valid = useRef();
  let selectInputReflead_type = useRef();
  /* staticFilters.map((selectedFilter,i) => {
    if(selectedFilter.type == 'dropdown'){
      selectInputRef[selectedFilter.key] = React.createRef()
    }
  }) */

  const dispatch = useDispatch();
  const [notificationOpen, setNotificationOpen] = useState({
    open: false,
    message: "",
  });

  const [hideduplicate, setHideDuplicate] = React.useState(false);

  // Customers UI Context
  const classes = useStyles();
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
  }

  const { currentState, userId } = useSelector((state) => ({
    currentState: state.customers,
    userId: state.auth.user.id,
  }));

  const { exportId, downloadUrl } = currentState;

  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: { ...customersUIContext.queryParams, userId },
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const startExport = () => {
    setNotificationOpen({
      open: true,
      message:
        "Exporting Started.Notification will be displayed once file is ready to download.",
    });
    dispatch(actions.startExport(customersUIProps.queryParams));
  };

  // console.log('exportId', exportId + ' - ', exportStatus);

  var timer = 0;

  const setIntervalForExportStatus = () => {
    timer = setInterval(() => {
      if (exportId && exportId.length > 0) {
        fetchExportStatus(exportId);
      }
    }, 3000);
  };

  const fetchExportStatus = (ids) => {
    if (exportId && exportId.length > 0) {
      dispatch(actions.getExport(ids));
    } else {
      clearInterval(timer);
    }
  };

  function getLeadStatus() {
    return axios.get(`${API_URL}/contacts/getLeadStatus`);
  }

  const loadData = () => {
    getLeadStatus().then((res) => {
      if (res.data && res.data.respType === "Success") {
        res.data.data.leadStatus.forEach((leadStatus) => {
          leadStatusOptions.push({
            label: leadStatus.dataField,
            value: leadStatus.text,
          });
        });
      }
    });
  };

  useEffect(loadData, []);

  useEffect(() => {
    clearInterval(timer);
    setIntervalForExportStatus();
    return () => clearInterval(timer);
  }, [exportId]);
  const makeSnackBar = () => {
    let btnActions = [];
    downloadUrl.forEach((url, index) => {
      btnActions.push(
        <div key={url.id}>
          <Button
            key="Download"
            color="secondary"
            size="small"
            onClick={() => {
              setNotificationOpen({
                open: false,
                message: "",
              });
              console.log(url);
              AWSFileDownload(url)
                .then((response) => {
                  if (response) {
                    setNotificationOpen({
                      open: true,
                      message: "File Downloaded Successfully!!",
                    });
                    dispatch(actions.donwloadDone(url.id));
                  }
                })
                .catch((error) => {
                  console.warn(error);
                });
            }}
          >
            DOWNLOAD {index + 1}
          </Button>
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={() => {
              downloadUrl.forEach((url) => {
                dispatch(actions.donwloadDone(url.id));
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      );
    });
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={downloadUrl.length > 0}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">Download File ({downloadUrl.length})</span>
        }
        action={btnActions}
      />
    );
  };
  useCallback(debounce(applyFilter, 1000), []);
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          email_valid: "All",
          phone: "",
          domain: "",
          lead_type: "All",
          lead_status: "All",
          isduplicate:"All",
          searchText: "",
          hideduplicate: false,
          startDate: customersUIProps.queryParams.startDate,
          endDate: customersUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          let dataToSend = {...values};
          dataToSend.email_valid = dataToSend.email_valid === "All" ?  "":dataToSend.email_valid;
          dataToSend.lead_type = dataToSend.lead_type === "All" ?  "":dataToSend.lead_type;
          dataToSend.lead_status = dataToSend.lead_status === "All" ?  "":dataToSend.lead_status;
          dataToSend.isduplicate = dataToSend.isduplicate === "All"? "":dataToSend.isduplicate;
          dataToSend.hideduplicate =  dataToSend.hideduplicate;

          applyFilter(dataToSend);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            {makeSnackBar()}
            <Snackbar
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={notificationOpen.open}
              onClose={() => {
                setNotificationOpen(false);
              }}
              ContentProps={{
                "aria-describedby": "notification",
              }}
              message={
                <span id="notification">{notificationOpen.message}</span>
              }
            />
            <div className="form-group row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-group shadow-sm">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                      setFieldValue("startDate", startDate);
                      setFieldValue("endDate", endDate);

                      handleSubmit();
                    }}
                    initialSettings={{
                      startDate: moment(customersUIProps.queryParams.startDate),
                      endDate: moment(customersUIProps.queryParams.endDate),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years"),
                          moment.utc().utcOffset(offSet),
                        ],
                        Today: [
                          moment.utc().utcOffset(offSet),
                          moment.utc().utcOffset(offSet),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek'),
                          moment.utc().utcOffset(offSet).endOf('isoweek'),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                          moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month"),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12 contact-filter-btn">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mr-3 contact-btn-p-7"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Update List
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="mr-3 contact-btn-p-7"
                  onClick={() => {
                    staticFilters.forEach((fil) => {
                      setFieldValue(fil.key, "");
                    });
                    setFieldValue("searchText", "");
                    // setFieldValue("email_valid", "");
                    selectInputReflead_type.current.value = "";
                    selectInputRefemail_valid.current.value = "";
                    console.log("selectInputRef", selectInputRef);
                    handleSubmit();
                  }}
                >
                  Clear List
                </Button>
                {(userType.toLowerCase() === "admin" ||
                  permissions["contacts"].includes("export")) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="contact-btn-p-7"
                    onClick={startExport}
                  >
                    Export
                  </Button>
                )}
              </div>
            </div>
            <div className="form-group row contact_filter_input">
              <div className="col-lg-2 col-md-12 col-sm-12 contact-filter-input">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              {staticFilters.map((selectedFilter, i) => {
                return (
                  <React.Fragment key={selectedFilter.key}>
                    {selectedFilter.type ? (
                      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 contact-filter-input">
                        {selectedFilter.type === "text" ? (
                          <input
                            type="text"
                            className="form-control shadow-sm"
                            name={selectedFilter.key}
                            placeholder={selectedFilter.label}
                            value={values[selectedFilter.key]}
                            onChange={(e) => {
                              setFieldValue(selectedFilter.key, e.target.value);
                              handleSubmit();
                            }}
                          />
                        ) : selectedFilter.type === "dropdown" ? (
                          <>
                            <FormControl className={"w-100"}>
                              <InputLabel htmlFor={selectedFilter.key}></InputLabel>
                              <Select
                                id={selectedFilter.key}
                                className="w-100 shadow-sm"
                                name={selectedFilter.key}
                                placeholder={selectedFilter.label}

                                onChange={(e) => {
                                  console.log(selectedFilter.options);
                                  setFieldValue(
                                    selectedFilter.key,
                                    e.target.value
                                  );
                                  handleSubmit();
                                }}
                                onBlur={handleBlur}
                                defaultValue={"All"}
                                ref={
                                  selectedFilter.key == "email_valid"
                                    ? selectInputRefemail_valid
                                    : selectInputReflead_type
                                }
                                input={<BootstrapInput className="w-100" />}
                              >
                                <MenuItem value="" disabled>

                              {selectedFilter.label}
                                </MenuItem>
                                {selectedFilter.options.map((option,i) => {
                                return (
                                    <MenuItem value={option.value} key={i}>{option.label}</MenuItem>
                                );
                              })}
                              </Select>
                              <FormHelperText>
                              {selectedFilter.label}
                              </FormHelperText>
                            </FormControl>

                          </>
                        ) : (
                          "Invalid option"
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}
              <div className=" col-xl-2 col-lg-1 col-md-2 col-sm-3 col-4" style={{ paddingTop: "10px"}}>
                <div className="input-group" style={{height:"35px"}}>
                  <FormControl className="m-0">
                    <input
                        className="ts-cb m-0 ml-5"
                        name="hideduplicate"
                        type="checkbox"
                        checked={hideduplicate}
                        onChange={(event, val) => {
                          setFieldValue("hideduplicate", !hideduplicate);
                          setHideDuplicate(!hideduplicate);
                          handleSubmit();
                        }
                    }
                    />
                  </FormControl>
                  <small className="form-text m-0 ml-1">Hide Duplicate</small>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
