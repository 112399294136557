import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useStaffManagementUIContext } from "../StaffManagementUIContext";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  delete newQueryParams.operator;
  if (searchText && searchText.length > 0) {
    filter.search = searchText;
  }
  newQueryParams.from = 1;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function AddUsersFilter({ listLoading }) {
  const usersUIContext = useStaffManagementUIContext();
  const usersListUIProps = useMemo(() => {
    return {
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
    };
  }, [usersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(usersListUIProps.queryParams, values);
    if (!isEqual(newQueryParams, usersListUIProps.queryParams)) {
      // update list by queryParams
      usersListUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
          startDate: usersListUIProps.queryParams.startDate,
          endDate: usersListUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3 col-md-12 col-sm-12 contact-filter-input">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
