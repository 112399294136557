import * as requestFromServer from "./liveChatCrud";
import { liveChatConversationSlice, callTypes } from "./liveChatSlice";

const { actions } = liveChatConversationSlice;

export const fetchAllDomainNames = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDomainNames()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allDomainNamesFetched({ domains: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allDomainNamesFetched({ domains: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getLiveChatChartData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLiveChatChartData(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        let originalData = { ...response.data.data },
          labels = originalData.labels,
          conversionData = originalData.conversionData,
          totalChats = originalData.totalChats,
          totalConversions = originalData.totalConversions;

        delete originalData.labels;
        delete originalData.conversionData;
        delete originalData.totalChats;
        delete originalData.totalConversions;

        let series = [],
          domainCharts = [],
          tableData = [];

        if (queryParams.domains) {
          //----------------For Particular Domain Charts----------------------
          domainCharts = Object.keys(originalData).map((i) => {
            let series = Object.keys(conversionData).reduce((prev, curr) => {
              if (conversionData[curr][i]) {
                return prev.concat({
                  type: "bar",
                  text: curr,
                  scales: "scale-x,scale-y-2",
                  values: conversionData[curr][i],
                  stacked: true,
                  stack: 1,
                  // "bar-width": "80%",
                });
              } else {
                return prev;
              }
            }, []);
            return {
              domain_name: i,
              series: [
                ...series,
                {
                  type: "line",
                  scales: "scale-x,scale-y",
                  text: "Percentage",
                  "line-color": "black",
                  values: originalData[i],
                  marker: {
                    size: 3,
                  },
                },
              ],
            };
          });

          //For Total Perc Top Graph
          series = Object.keys(originalData).map((i) => {
            return { name: i, data: originalData[i] };
          });
           tableData = Object.keys(originalData).map((i) => {
            let totalOfChats = totalChats[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalConversionsCount = totalConversions[i].reduce(
              (prev, curr) => {
                return prev + curr;
              },
              0
            );
            let totalPercentage = totalConversionsCount
              ? Math.abs(
                  ((totalConversionsCount / totalOfChats) * 100).toFixed(2)
                )
              : 0;
            let dataForTable = {
                domain_name: i,
              },
              seriesNested = [];

            for (const [key, value] of Object.entries(conversionData)) {
              if (value[i]) {
                //For table view data
                let totalCount = value[i].reduce((a, b) => a + b, 0),
                  cr = Math.abs(((totalCount / totalOfChats) * 100).toFixed(2));
                dataForTable[key] = [totalCount, cr, ...value[i]];

                seriesNested.push({
                  type: "bar",
                  text: key,
                  scales: "scale-x,scale-y-2",
                  values: value[i],
                  stacked: true,
                  stack: 1,
                  // "bar-width": "80%",
                });
              }
            }
            return {
              "Total Chats": [totalOfChats, "-"].concat(totalChats[i]),
              ...dataForTable,
              Total: [
                totalConversionsCount,
                totalOfChats ? Math.abs(
                  ((totalConversionsCount / totalOfChats) * 100).toFixed(2)
                ):0,
                ...totalConversions[i],
              ],

              Percentage: [totalPercentage, "-"].concat(originalData[i]),
            };
          });
        } else {
          for (let i of Object.keys(originalData)) {
            let totalOfChats = totalChats[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalConversionsCount = totalConversions[i].reduce(
              (prev, curr) => {
                return prev + curr;
              },
              0
            );
            let totalPercentage = totalConversionsCount
              ? Math.abs(
                  ((totalConversionsCount / totalOfChats) * 100).toFixed(2)
                )
              : 0;
            if (totalOfChats > 0) {
              let dataForTable = {
                  domain_name: i,
                },
                seriesNested = [];

              for (const [key, value] of Object.entries(conversionData)) {
                if (value[i]) {
                  //For table view data
                  let totalCount = value[i].reduce((a, b) => a + b, 0),
                    cr = Math.abs(
                      ((totalCount / totalOfChats) * 100).toFixed(2)
                    );
                  dataForTable[key] = [totalCount, cr, ...value[i]];

                  seriesNested.push({
                    type: "bar",
                    text: key,
                    scales: "scale-x,scale-y-2",
                    values: value[i],
                    stacked: true,
                    stack: 1,
                    // "bar-width": "80%",
                  });
                }
              }
              tableData.push({
                "Total Chats": [totalOfChats, "-"].concat(totalChats[i]),
                ...dataForTable,
                Total: [
                  totalConversionsCount,
                  totalOfChats? Math.abs(
                    ((totalConversionsCount / totalOfChats) * 100).toFixed(2)
                  ):0,
                  ...totalConversions[i],
                ],

                Percentage: [totalPercentage, "-"].concat(originalData[i]),
              });

              domainCharts.push({
                domain_name: i,
                series: [
                  ...seriesNested,
                  {
                    type: "line",
                    scales: "scale-x,scale-y",
                    text: "Percentage",
                    "line-color": "black",
                    values: originalData[i],
                    marker: {
                      size: 3,
                    },
                  },
                ],
              });
              series.push({ name: i, data: originalData[i] });
            }
          }
        }

        dispatch(
          actions.chartDetailsFetched({
            allConversions: series,
            domainCharts: domainCharts,
            labels: labels,
            tableData: tableData,
          })
        );
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
