/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TextField from "@material-ui/core/TextField";

import { ChartComponent } from "./ChartComponent";
import momentTZ from "moment-timezone";
import FilterPopover from "../../../../_metronic/layout/components/filterDropdown/FilterPopoverTrends";
import { withStyles } from "@material-ui/styles";
import { FormHelperText, InputBase, InputLabel } from "@material-ui/core";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../DebtAnalysis/_redux/debtAnalysisActions";

import { updateDomainFilter } from "../../../../redux/timezone/timezoneActions";
import FilterPopoverCh from "../../../../_metronic/layout/components/filterDropdown/FilterPopoverCh";
// import './trendstat.css';

const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 13,
      padding: "10px 26px 10px 12px",
      width: 120,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems:"center"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
var activeLeadStatus = '';
const API_URL = process.env.REACT_APP_API_URL;
function getLeadTrends(data) {
  const {
    startDate,
    endDate,
    selectedDurationFilter,
    selectedLeadStatus,
    livechat,
    selectedLeadTypeStatus,
    selectedDomains,
    showTotalDomains,
    hideTotalLeads
  } = data;

  (showTotalDomains === false && hideTotalLeads === false) ? activeLeadStatus = '' : activeLeadStatus = 's-sold';

  if (!livechat) {
    let selectedLeadStatusArray = selectedLeadStatus.map(
      (status) => status.dataField
    );

    var autoSelectedStatus = '';
    if (!selectedLeadStatusArray.length) {
      autoSelectedStatus += activeLeadStatus;
    }
    
    return axios.get(
      `${API_URL}/contacts/getLeadTrends?startDate=` +
        startDate +
        "&endDate=" +
        endDate +
        "&selectedDurationFilter=" +
        selectedDurationFilter +
        `&selectedLeadStatus=${autoSelectedStatus}` +
        selectedLeadStatusArray.join(",") +
        "&selectedLeadTypeStatus=" +
        selectedLeadTypeStatus +
        "&selectedDomains=" +
        selectedDomains +
        "&showTotalDomains=" +
        showTotalDomains +
        "&hideTotalLeads=" +
        hideTotalLeads
    );
  } else {
    return axios.get(
      `${API_URL}/contacts/getLeadTrends?startDate=` +
        startDate +
        "&endDate=" +
        endDate +
        "&selectedDurationFilter=" +
        selectedDurationFilter +
        "&livechattrend=" +
        livechat +
        "&selectedDomains=" +
        selectedDomains
    );
  }
}

function getLeadStatus() {
  return axios.get(`${API_URL}/contacts/getLeadStatus`);
}
export function TrnendStat({ className }) {
  const classes = useStyles();
  let selectInputReflead_type = useRef();

  const [startDate, setStartDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59"
  );
  const [durationFilter, setDurationFilter] = React.useState(false);
  const [selectedDurationFilter, setSelectedDurationFilter] = React.useState(
    "byTime"
  );
  const [chartData, setChartData] = React.useState({ series: [], labels: [] });
  const [leadStatus, setLeadStatus] = React.useState([]);
  const [leadStatusFilter, setLeadStatusFilter] = React.useState(false);
  const [checkedAllcolumns, setCheckedAllColumns] = React.useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = React.useState([]);
  const [livechat, setLiveChat] = React.useState(false);
  const [selectedLeadTypeStatus, setSelectedLeadTypeStatus] = React.useState('');
  const [selectedDomains, setSelectedDomains] = React.useState('');
  const [hideTotalLeads, setHideTotalLeads] = React.useState(false);
  const [showTotalDomains, setShowTotalDomains] = React.useState(true);
  const [activeTotalDomains, setActiveTotalDomains] = React.useState([]);
  const [activeDomains, setActiveDomains] = React.useState(0);

  const dispatch = useDispatch();
  const { currentState,defaultDomains } = useSelector(  
    (state) => ({ currentState: state.debtAnalysis,defaultDomains:state.timezoneBy }),
    shallowEqual
  );
  const { allDomainNames, listLoading } = currentState;
  const domainsState= defaultDomains.selectedDomain;

  useEffect(() => {
    dispatch(actions.fetchAllDomainNames());
  }, []);



  const getStat = () => {
    const data = {
      startDate,
      endDate,
      selectedDurationFilter,
      selectedLeadStatus,
      livechat,
      selectedLeadTypeStatus,
      selectedDomains,
      showTotalDomains,
      hideTotalLeads
    };

    getLeadTrends(data).then((res) => {
      if (res.data && res.data.respType === "Success") {
        const originalData = res.data.data;
        let series, dataValue;
        var trackData = res.data.data.domainWiseData;
        var trackDataVal = [];
        
        if (hideTotalLeads || showTotalDomains || !showTotalDomains) { 
          hideTotalLeads ? dataValue = res.data.data.data : dataValue = res.data.data.leadStatusTotal;
          trackDataVal.push(
            {
              name: selectedLeadTypeStatus != 'All' && selectedLeadTypeStatus != ''  ? (selectedLeadTypeStatus === "VALID" ? `Good Leads` : `Bad Leads` ) : (hideTotalLeads ?  "Total Leads" : "Total Leads Status"),
              data: dataValue,
            }
          );
        }
        
        const example = [];
          for (const key1 in trackData) {
            var value1 = trackData[key1];
            trackDataVal.push({name : value1.domain , data : value1.data, values : 'domainsData'});
          }

        if (!livechat) {
          series = trackDataVal;
        } else {
          series = [
            {
              name: "Total Valid Leads",
              data: res.data.data.data,
            },
          ];
        }
        if (!livechat) {
          let selectedStatus = [];
          if (!(showTotalDomains === false && hideTotalLeads === false)) {
            (selectedLeadStatus.length < 1 ? selectedStatus = [{"text": "S-Sold","dataField": "s-sold"}] : selectedStatus = selectedLeadStatus);
          }
          else {
            selectedStatus = selectedLeadStatus;
          }          
          selectedStatus.forEach((status) => {
            if (originalData[status.dataField]) {
              const obj = {
                name: leadStatus.find((s) => s.dataField === status.dataField) ? 
                      leadStatus.find((s) => s.dataField === status.dataField).text :
                      (status.dataField === "s-sold" ?  "S-Sold" : ''),
                data: originalData[status.dataField]
                  ? originalData[status.dataField]
                  : 0,
                  statusValues: 'leadStatusData'  
              };
                series.push(obj);
            }
          });
        } else {
          const obj = {
            name: "Livechat Status",
            data: res.data.data.livechat_status,
          };
          series.push(obj);
        }
        setChartData({ series, labels: res.data.data.labels });

        if (hideTotalLeads && !showTotalDomains) {
          setActiveTotalDomains(series.filter(x => x.values === 'domainsData'));
        }
        else if (hideTotalLeads && showTotalDomains) {
          setActiveTotalDomains(series.filter(x => !x.values && !x.statusValues ));
        }
        else if (showTotalDomains && !hideTotalLeads) {
          setActiveTotalDomains(series.filter(x => x.statusValues === 'leadStatusData'));
        }
        else {
          let activeLeads = series.filter(x => x.statusValues === 'leadStatusData')
          selectedDomains.length === 1 && activeLeads.length != 0 ?  
          setActiveTotalDomains(activeLeads) :
          selectedDomains.length === 0 && activeLeads.length != 0 ? 
          setActiveTotalDomains(activeLeads) :
          setActiveTotalDomains(series.filter(x => x.values === 'domainsData'));
        }
        let filterData = series;
        let filterDomain = filterData.filter(x => x.values)
        let domainName = []; 
        let domainFinalData = [];
        for (const domainDataSel of filterDomain) {
          let domainData;
          domainDataSel.name.split("www.").length > 1 ? domainData = domainDataSel.name : domainData = "www."+domainDataSel.name;
          domainName.push(domainData);
        }
        let uniqueDomains = domainName.filter(function(item, pos) {return domainName.indexOf(item) == pos; });
        let filteredDomains = allDomainNames.filter(function(n) { return uniqueDomains.indexOf(n.dataField) !== -1; });
        for (const domain of filteredDomains) {
          domainFinalData.push(domain.dataField);
        }
        setActiveDomains(domainFinalData);
      }
    });
  };

  const applyDomainFilter = val=>{
    var datastring = val;
    if(val != '') {
      setSelectedDomains(datastring);
    }
    else {
      setSelectedDomains([]);
    }
  }

  const loadData = () => {
    getLeadStatus().then((res) => {
      if (res.data && res.data.respType === "Success") {
        let leadStatuses = [];
        leadStatuses = res.data.data.leadStatus;
        
        setLeadStatus(leadStatuses);
      }
    });
    getStat();
    setSelectedLeadTypeStatus("All"); 
  };


  
  useEffect(loadData, []);
  useEffect(getStat, [
    startDate,
    endDate,
    selectedDurationFilter,
    selectedLeadStatus,
    selectedLeadTypeStatus,
    selectedDomains
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const staticFilters = [
    {
      label: "Lead Type",
      type: "dropdown",
      key: "lead_type",
      options: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Good",
          value: "VALID",
        },
        {
          label: "Bad",
          value: "BK",
        },
      ],
    }
  ];



  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        {/* <div className="card-header align-items-center border-0 py-5 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              Trends
            </span>
          </h3>
        </div> */}
        {/* Body */}
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0 row" >
          <form
            className={classes.container}
            noValidate
            style={{ width: "1100px" }}
          >
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3 filterDesignQuery">
              <div className="input-group shadow-sm">
                <DateRangePicker
                  onCallback={(start, end, label) => {
                    let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                    let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  initialSettings={{
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                    ranges: {
                      "Life Time": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(10, "years"),
                        moment.utc().utcOffset(offSet),
                      ],
                      Today: [
                        moment.utc().utcOffset(offSet),
                        moment.utc().utcOffset(offSet),
                      ],
                      Yesterday: [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "days"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "days"),
                      ],
                      "Last 7 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(6, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "Last 14 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(13, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "Last 30 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(29, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "This week": [
                        moment.utc().utcOffset(offSet).startOf('isoweek'),
                        moment.utc().utcOffset(offSet).endOf('isoweek'),
                      ],
                      "Last week": [
                        moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                        moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                      ],
                      "This Month": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .startOf("month"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .endOf("month"),
                      ],
                      "Last Month": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "month")
                          .startOf("month"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                    },
                    locale: {
                      format: "MMMM DD, YYYY",
                    },
                    alwaysShowCalendars: true,
                  }}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-calendar-check"></i>
                  </span>
                </div>
              </div>
              <small className="form-text text-muted">
                <b>Filter</b> by Date
              </small>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4 filterDesignQuery">
              <FormControl className="w-100">
              <FilterPopover
                  querySelectedDomains={selectedDomains}
                  selectedDomains={activeDomains.length}
                  showAllDomainCheck={showTotalDomains}
                  disableBtn={hideTotalLeads}
                  value={selectedLeadStatus.map(e=>e.dataField)}
                  fieldName={"dataField"}
                  key={Math.random()}
                  options={leadStatus}
                  placeholder="Select Lead Status"
                  maxListShow={3}
                  activeLeadStatusData={activeLeadStatus}
                  onApply={(e) => {
                    let leads = leadStatus.filter((l) =>
                      e.includes(l.dataField)
                    );
                    setSelectedLeadStatus(leads);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Select</b> Lead Status
                </small>
              </FormControl>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-sm-6 col-6 filterDesignQuery">
          <FormControl >
                  <Select
                    open={durationFilter}
              className="shadow-sm"
              onOpen={() => {
                      setDurationFilter(true);
                    }}
                    onClose={() => {
                      setDurationFilter(false);
                    }}
                    value={selectedDurationFilter}
                    onChange={(event) => {
                      let val = event.target.value;
                      setSelectedDurationFilter(val);
                    }}
                    input={<BootstrapInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "Select Filter";
                      }
                      return selectedDurationFilter === "byTime"
                        ? "By Time"
                        : "By Day of Week";
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={"byTime"} value={"byTime"}>
                      <Checkbox checked={selectedDurationFilter === "byTime"} />
                      <ListItemText primary={"By Time"} />
                    </MenuItem>
                    <MenuItem key={"byDayOfWeek"} value={"byDayOfWeek"}>
                      <Checkbox
                        checked={selectedDurationFilter === "byDayOfWeek"}
                      />
                      <ListItemText primary={"By Day of Week"} />
                    </MenuItem>
                  </Select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Duration
                  </small>
                  </FormControl>
            </div>



            {staticFilters.map((selectedFilter, i) => {
                return (
                  <React.Fragment key={selectedFilter.key}>
                    {selectedFilter.type ? (
                      <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 contact-filter-input filterDesignQuery">
                            <FormControl>
                              <InputLabel htmlFor={selectedFilter.key}></InputLabel>
                              <Select
                                onChange={(event) => {
                                  let val = event.target.value;
                                  setSelectedLeadTypeStatus(val);
                                }}
                                id={selectedFilter.key}
                                className="w-100 shadow-sm"
                                name={selectedFilter.key}
                                placeholder={selectedFilter.label}
                                defaultValue={"All"}
                                ref={selectInputReflead_type}
                                input={<BootstrapInput className="w-100" />}
                              >
                                <MenuItem value="" disabled>
                                  {selectedFilter.label}
                                </MenuItem>
                                {selectedFilter.options.map((option,i) => {
                                return (
                                    <MenuItem value={option.value} key={i}>{option.label}</MenuItem>
                                );
                              })}
                              </Select>
                              <FormHelperText>
                              {selectedFilter.label}
                              </FormHelperText>
                            </FormControl>
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}

          <div className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12 contact-filter-input">
            <FilterPopoverCh
              selectedLeadStatusData={selectedLeadStatus}  
              showAllStatus={hideTotalLeads}
              disableBtn={showTotalDomains}
              value={chartData.series}
              activeDomains={activeDomains}
              options={allDomainNames} onApply={applyDomainFilter}
            />
            <small className="form-text text-muted">
              <b>Filter</b> by Domain
            </small>
          </div>

          <div className=" col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 filterDesignQuery">
              <div className="input-group" style={{height:"35px"}}>
                <FormControl className="m-0">
                  <input
                    className="ts-cb m-0 ml-5"
                    name="showAllDomains"
                    type="checkbox"
                    checked={showTotalDomains}
                    onChange={() => {  
                      setShowTotalDomains(!showTotalDomains);
                      setSelectedDomains([]);
                    }}
                  />
                </FormControl>
                <small className="form-text m-0 ml-1"><b>Show</b> All Domains</small>
              </div>
          </div>

          <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 filterDesignQuery">
              <div className="input-group" style={{height:"35px"}}>
                <FormControl className="m-0">
                  <input
                    className="ts-cb m-0 ml-5"
                    name="hideTotalLeads"
                    type="checkbox"
                    checked={hideTotalLeads}
                    onChange={() => {
                      setHideTotalLeads(!hideTotalLeads);
                      setSelectedLeadStatus([]);
                      setSelectedDomains([]);
                    }}
                  />
                </FormControl>
                <small className="form-text m-0 ml-1"><b>Show</b> Total Leads</small>
              </div>
          </div>


          </form>
        </div>
        <div className="graph-container mt-2 apexchartsCustom">
          <div className="row m-0 justify-content-center">
          <ChartComponent
              id={"trend_chart"}
              labels={chartData.labels}
              values={activeTotalDomains}
            />
          </div>
        </div>
      </div>
    </>
  );
}
    