import React from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";

import { StaffUsersTable } from "./staff-users-table/StaffUsersTable";
import { AddUsersFilter } from "./users-listing-filter/AddUsersFilter";
// import { AdminLogsFilter } from "./admin-logs-filter/AdminLogsFilter";

export function StaffMnagementCards({userInfo}) {
  const history = useHistory();
  const { permissions, userType } = userInfo;

  return (
    <Card>
      {/* Staff Management */}
      <CardHeader title="">
        <CardHeaderToolbar>
          {(userType.toLowerCase() === "admin" ||
            permissions["staff_manage"].includes(
              "create"
            )) && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  history.push("/staff-management/add-new");
                }}
              >
                Add New Staff
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push("/roles");
                }}
              >
                Add New Role
              </Button>
            </>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <AddUsersFilter />
        <StaffUsersTable userInfo={userInfo}/>
      </CardBody>
    </Card>
  );
}
