// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }
        
        const options = props.type && props.type === 'pie' ? getPieChartOptions(props) : {};

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px" }}
            ></div>
        </>
    );
}
function getPieChartOptions(props) {
    const colors = {
        2:['#187de4', '#1bc5bd'],
        5:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
    };
    var options = {
        series: props.values,
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: props.labels,
        legend: {
            position: 'right',
            markers: {
                width: 40,
                radius: 0
            },
            width: 180,
            inverseOrder: true,
            formatter: function (val, opt) { 
                let total = 0;
                val = val === "VALID" ? "Good" : "Bad";
                for(let i=0;i<opt.w.config.series.length;i++){
                    total += opt.w.config.series[i];
                }
                let percent = ((opt.w.config.series[opt.seriesIndex] * 100) / total).toFixed(2);
                return "&nbsp;" + val + "&nbsp;&nbsp;" + percent +"%"
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300,
                    height: 300,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        },{
            breakpoint: 769,
            options: {
                chart: {
                    width: 350,
                    height: 220,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        },{
            breakpoint: 1025,
            options: {
                chart: {
                    width: 300,
                    height: 300,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        }],
        // colors: colors[props.labels.length]?colors[ props.labels.length]:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
        colors: props.labels.length == 1 ? (props.labels[0] == 'VALID' ? ['#1bc5bd']:  ['#187de4']) : colors[props.labels.length]?colors[ props.labels.length]:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
        plotOptions: {
            pie: {
              dataLabels: {
                  offset: -20,
                  minAngleToShowLabel: 0
              }, 
            }
        },
        tooltip: {
            fillSeriesColor: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let sum = 0, value = series[seriesIndex],color = w.config.colors[seriesIndex] ? w.config.colors[seriesIndex] : '#187de4';
                let label = w.config.labels[seriesIndex] === "VALID" ? "Good Leads" : "Bad Leads"
                // w.config.labels[seriesIndex] ? w.config.labels[seriesIndex] : 'NA';
                let dataArr = w.config.series ? w.config.series : [];
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(1)+"%";
                return '<div class="arrow_box" style="background:'+color+';padding:10px;">' +
                    '<span>' + label + '</span></br>' +
                    '<span> Count = ' + value + '</span></br>' +
                    '<span>' + percentage +  '</span>' +
                    '</div>'
              }
          }
    };
    return options;
}