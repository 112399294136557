import * as requestFromServer from "./cpaCrud";
import { CPASlice, callTypes } from "./cpaSlice";

const { actions } = CPASlice;

export const fetchAccountsList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAccountList()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.accountsFetched({ accountsList: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(actions.accountsFetched({ accountsList: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getChartsData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getChartData(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        let originalData = { ...response.data.data };
        delete originalData.labels;
        let series = Object.keys(originalData).map((dt) => {
          if (dt === "data") {
            return { name: "Total CPA", data: response.data.data[dt] };
          } else {
            return { name: dt, data: response.data.data[dt] };
          }
        });
        dispatch(
          actions.chartDetailsFetched({
            chartData: { series, labels: response.data.data.labels },
          })
        );
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};



export const addNewSaleStatus = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addSaleStatus(details)
    .then((response) => {
      let statusDetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.saleStatusCreated({ statusDetails }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const deleteStatus = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteStatus(id)
    .then((response) => {
      dispatch(actions.statusDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllLeadStatus = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSaleStatus()
    .then((response) => {
      let statusDetails = response.data.data;

      if (response.data.respType === "Success") {
        dispatch(actions.statusFetched({ statusDetails }));
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};