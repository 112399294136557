import React from "react";
import { DomainsLoadingDialog } from "./domains-loading-dialog/DomainsLoadingDialog";
import { DomainsUIProvider } from "./DomainsUIContext";
import { DomainsCard } from "./DomainsCard";
export function DomainsPage({ history }) {
  const customersUIEvents = {
    openDeleteCustomerDialog: (id) => {
      history.push(`/contacts/${id}/delete`);
    },
    openDeleteDomainsDialog: () => {
      history.push(`/e-commerce/customers/deleteDomains`);
    },
  }

  return (
    <DomainsUIProvider customersUIEvents={customersUIEvents}>
      <DomainsLoadingDialog />
      <DomainsCard />
    </DomainsUIProvider>
  );
}
