// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ChartComponentDemo} from './ChartComponentDemo';
import {DebtAnalysisFilters} from './DebtAnalysisFilters';
import { xor } from "lodash";
export function DebtAnalysisStat({className}) {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.debtAnalysis,timezoneBy:state.timezoneBy.timezone }),
    shallowEqual
  );
  const {
    labels,
    averageDebt,
    listLoading,
  } = currentState;

  // useEffect(()=>{
  //   domainUiProps.setQueryParams((prev) => ({ ...prev,filterByDomainTZ:timezoneBy}));
  // },[timezoneBy]);

  return (
    <>
      <div className={`card card-custom live-chat ${className}`}>
        {/* Header */}
        {/* <div className="card-header align-items-center border-0 py-5 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              Debt Analysis
            </span>
          </h3>
        </div> */}
        {/* Body */}
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0 row">
          <DebtAnalysisFilters valuesdebt={averageDebt} />
        </div>
        {listLoading ? (
          <div className="d-flex align-items-center mx-5 my-8">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ) :  <>
        <div className="graph-container mt-2">
          <div className="text-center kt_graph_analysis">
            <ChartComponentDemo
              id={"debt_analysis_chart"}
              type={"total"}
              labels={labels}
              values={averageDebt}
            />
          </div>
        </div>
      </>}
      </div>
    </>
  );
}  