// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import * as moment from 'moment-timezone';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const DomainEditSchema = Yup.object().shape({
  domain_name: Yup.string()
    .required("Domain name is required"),
  cpv_lab_api_key: Yup.mixed()
    .required("API Key is required"),
  cpv_lab_url: Yup.mixed()
    .required("CPV Lab URL is required")
});

export function DomainEditForm({
  saveDomain,
  domain,
  id,
  actionsLoading,
  onHide,
}) {

  const timezone = moment.tz.names();
  var timezoneList = [];
  timezone.forEach(function(tz, index){
    timezoneList.push({
      title: tz,
      value: tz
    })
  })

  const [value, setValue] = React.useState('');

  useEffect(() => {
    setValue({title:domain.timezone, value:domain.timezone});
  }, [domain.timezone])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={domain}
        validationSchema={DomainEditSchema}
        onSubmit={(values) => {
          saveDomain(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body 
            // className="overlay overlay-block"
            >
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Domain Name */}
                  <div className="col-lg-6">
                    <Field
                      name="domain_name"
                      component={Input}
                      placeholder="Domain Name"
                      label="Domain Name"
                      validate="void"
                      disabled={id==0?'':"disabled"}
                    />
                  </div>
                  {/* Lead Source */}
                  <div className="col-lg-6">
                    <Field
                      name="leadsource"
                      component={Input}
                      placeholder="Lead Source"
                      label="Lead Source"
                    />
                  </div>
                  {/* App Key */}
                  <div className="col-lg-6 pt-9">
                    <Field
                      name="cpv_lab_api_key"
                      component={Input}
                      placeholder="App Key"
                      label="App Key"
                    />
                  </div>
                  {/* CPV LAB Url */}
                  <div className="col-lg-6 pt-9">
                    <Field
                      name="cpv_lab_url"
                      component={Input}
                      placeholder="CPV Lab Url"
                      label="CPV Lab Url"
                    />
                  </div>
                  {/* Timezone */}
                  <div className="col-lg-6 pt-9">
                    {/* <Field
                      name="timezone"
                      component={Select}
                      placeholder="Timezone"
                      label="Timezone"
                    /> */}
                    
                    <label>Select Timezone</label>
                    <Autocomplete
                      id="timezone_1"
                      value={value}
                      onChange={(event, newValue) => {
                        if(newValue != '' && newValue != null){
                          setFieldValue('timezone', newValue.value);
                          setValue({title:newValue.value, value:newValue.value});
                        }
                      }}
                      defaultValue={value}
                      options={timezoneList}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <TextField {...params} label="Select Timezone" variant="filled" />}
                    />

                    {/* <select
                      className="form-control"
                      name="timezone"
                      placeholder="Filter by Campaign"
                    >
                      <option value="" key={'-'}>Select Timezone</option>
                      {
                        timezone.map((tz, index) => {
                          if(tz === domain.timezone){
                            return <option key={index} value={tz} selected={true}>{tz}</option>
                          } else {
                            return <option key={index} value={tz}>{tz}</option>
                          }
                          
                        })
                      } 
                    </select> */}
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
