import * as requestFromServer from "./landingPageCrud";
import { landingPagesSlice, callTypes } from "./landingPageSlice";
import * as utils from "../../../../_metronic/_helpers/AWSFileUpload";
import { toast } from "react-toastify";

const { actions } = landingPagesSlice;

export const addNewLandingPage = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewLandingPage(data)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Added", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find imports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return false;
    });
};

export const updateLandingPage = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLandingPage(data)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Updated", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find imports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return false;
    });
};


export const getAllLandingPages = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllLandingPages(params)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        dispatch(actions.pagesList({ entities: [],tenant:"" ,totalCount:0}));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.pagesList({
            entities: response.data.data.data,
            tenant: response.data.data.tenant,
            totalCount:response.data.data.totalCount
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find pages";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getLandingPage = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLandingPage(id)
    .then((response) => {
      if (response && response.data && response.data.respType) {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return {
          entities: response.data.data.data,
          tenant: response.data.data.tenant,
        };
      } else {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return null;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find page";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return null;
    });
};

export const getQuestionsByCategory = (category) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getQuestionsByCategory(category)
    .then((response) => {
      if (response && response.data && response.data.respType) {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return {
          entities: response.data.data,
        };
      } else {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return null;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find pages";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return null;
    });
};

export const fetchAllLeadSources = () => (dispatch) => {
  return requestFromServer
    .findLeadSources()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.leadSourcesFetched({ leadSources: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.leadSourcesFetched({  leadSources: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Lead Sources";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
  
}