import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function AddUpdateTemplateDialogHeader({ id }) {
  // Staff Redux state
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.cssTemplates.actionsLoading,
    }),
    shallowEqual
  );
  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{id ? `Update Template (id: ${id})`:"Add New Template"}</Modal.Title>
      </Modal.Header>
    </>
  );
}
