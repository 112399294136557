import * as moment from 'moment';
export const DomainStatusCssClasses = ["danger", "success", "info", ""];
export const DomainStatusTitles = ["Suspended", "Active", "Pending", ""];
export const DomainTypeCssClasses = ["success", "primary", ""];
export const DomainTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 }
];
export const initialFilter = {
  sortOrder: "asc", // asc||desc
  sortBy: "id",
  from: 1,
  rows: 25
};
