import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function AddOrEditRoleDialogHeader({ id }) {

  // Role Redux state
  const { roleForEdit,actionsLoading } = useSelector(
    (state) => ({
      roleForEdit: state.staffManagement.roleForEdit,
      actionsLoading: state.staffManagement.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");

  // Title couting
  useEffect(() => {
    var _title = id ? "" : "Add New Role";
    if (roleForEdit && id) {
      _title = `Edit Role ${roleForEdit.role_name}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [roleForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {title}
        </Modal.Title>
      </Modal.Header>
    </>
  );
}
