import * as requestFromServer from "./dbbackupCrud";
import {dbLogsListSlice, callTypes} from "./dbBackupSlice";

const {actions} = dbLogsListSlice;

export const fetchDBListing = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDBBackupLogs(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.DBLogsFetched({ totalCount: 0, entities: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.DBLogsFetched({ totalCount: response.data.data.count, entities: response.data.data.records}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Admin Logs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
