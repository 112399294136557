import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./CpvTrackingUIHelpers";

const CpvTrackingUIContext = createContext();

export function useCpvTrackingUIContext() {
  return useContext(CpvTrackingUIContext);
}

export const CPVUIConsumer = CpvTrackingUIContext.Consumer;

export function CpvTrackingUIProvider({customersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <CpvTrackingUIContext.Provider value={value}>{children}</CpvTrackingUIContext.Provider>;
}