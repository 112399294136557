import React from "react";
import { Route } from "react-router";
import { RoleLoadingDialog } from "./role-loading-dialog/RoleLoadingDialog";
import { RoleUIProvider } from "./RoleUIContext";
import { RoleCards } from "./RoleCards";
import { AddOrEditRoleDialog } from "./add-update-role-dialog/AddOrEditRoleDialog";


export function RolePage({ history }) {
  const roleUIEvents = {
    
  }

  return (
    <RoleUIProvider roleUIEvents={roleUIEvents}>
      <RoleLoadingDialog />
      <RoleCards />
      <Route path="/roles/add">
        {({ history, match }) => (
          <AddOrEditRoleDialog
            show={match != null}
            onHide={() => {
              history.push("/roles");
            }}
          />
        )}
      </Route>
      <Route path="/roles/edit/:id">
        {({ history, match }) => (
          <AddOrEditRoleDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/roles");
            }}
          />
        )}
      </Route>
    </RoleUIProvider>
  );
}