import { createSlice } from "@reduxjs/toolkit";

const initialStaffManagementState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  rolePermissions: [],
  rolesListing: [],
  roleForEdit: undefined,
  lastError: null,
  error:null
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const staffManagementListSlice = createSlice({
  name: "staff_management",
  initialState: initialStaffManagementState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Permissions Fetched
    permissionsFetched: (state, action) => {
      const { permissions } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.rolePermissions = permissions;
    },
    // Roles Fetched
    rolesFetched: (state, action) => {
      const { roles } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.rolesListing = roles;
    },
    //Role created and push new one to listing
    roleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rolesListing.push(action.payload.roledetails);
    },
    // update Role
    roleUpdate: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.rolesListing = state.rolesListing.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
    },
    // get role fetched by id
    roleFetched: (state, action) => {
      state.actionsLoading = false;
      state.roleForEdit = action.payload.roleForEdit;
      state.error = null;
    },
    
    //Role created and push new one to listing
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.totalCount = state.totalCount +1;
      state.entities.unshift(action.payload.userdetails);
    },
    // Users Fetched
    usersFetched: (state, action) => {
      const { users,totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = users;
      state.totalCount = totalCount;
    },
    // delete User
    userDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => parseInt(el.id) !== parseInt(action.payload.id));
    },
    // update User
    userUpdate: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
    },
    
  },
});
