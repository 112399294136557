import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


export function addOption(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/live-chat-conversation/options`,details, header);
}

export function findOptions(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params:details};
  return axios.get(`${API_URL}/live-chat-conversation/options`, header);
}

export function deleteOption(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.delete(`${API_URL}/live-chat-conversation/options/${details.id}`, header);
}


export function addText(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/live-chat-conversation`,details, header);
}
export function updateText(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.put(`${API_URL}/live-chat-conversation/${details.id}`,details.data, header);
}

export function findTexts(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params:details};
  return axios.get(`${API_URL}/live-chat-conversation`, header);
}

export function deleteText(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.delete(`${API_URL}/live-chat-conversation/${details.id}`, header);
}
