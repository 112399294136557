import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls"
import { ImportFileUpload } from "./import-file-upload/ImportFileUpload"

export function ImportFileUploadCard() {
  return (
    <Card>
      <CardHeader title="File Upload">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ImportFileUpload/>
      </CardBody>
    </Card>
  )
};