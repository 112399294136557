var permissions = {
  dashboard: {
    totalLeadsWeek: {
      name: "Total Leads This Week",
    },
    totalLeadBetweenTimeFrame: {
      name: "Total Leads",
    },
    totalGoodLeads: {
      name: "Total Good Leads",
    },
    totalBadLeads: {
      name: "Total Bad Leads",
    },
    todayLeadCompareToYesterday: {
      name: "Today's Lead Compared To yesterday",
    },
    todayLeadCompareToLastWeek: {
      name: "Today's Lead Compared To Last Week",
    },
    hourlyLeads: {
      name: "Hourly Leads",
    },
  },
  domain_manage: {
    view: {
      name: "View",
    },
    create: {
      name: "Create",
    },
    edit: {
      name: "Edit",
    },
    delete: {
      name: "Delete",
    },
  },
  contacts: {
    view: {
      name: "View",
    },
    export: {
      name: "Export",
    },
    delete: {
      name: "Delete",
    },
  },
  question_analysis: {
    view: {
      name: "View",
    },
  },
  domain_analysis: {
    view: {
      name: "View",
    },
  },

  cpv_track_analysis: {
    view: {
      name: "View",
    },
  },
  lead_analysis: {
    view: {
      name: "View",
    },
  },
  lead_status_analysis: {
    view: {
      name: "View",
    },
  },
  live_chat: {
    view: {
      name: "View",
    },
  },
  lead_compare_analysis: {
    view: {
      name: "View",
    },
  },
  trends: {
    view: {
      name: "View",
    },
  },
  imports: {
    view: {
      name: "Import Data",
    },
  },
  admin_logs: {
    view: {
      name: "View",
    },
  },
  staff_manage: {
    view: {
      name: "View",
    },
    create: {
      name: "Create",
    },
    edit: {
      name: "Edit",
    },
    delete: {
      name: "Delete",
    },
  },
  ad_spent: {
    view: {
      name: "View",
    },
    update: {
      name: "update",
    },
    account: {
      name: "Add Lead Source",
    },
  },
  cpa: {
    view: {
      name: "View",
    },
    leadStatus: {
      name: "Add Lead Sale Status",
    },
  },
  conversation_manage: {
    view: {
      name: "View",
    },
  },
  landing_pages: {
    view: {
      name: "View",
    },
    add: {
      name: "Add",
    },
    update: {
      name: "Update",
    },
  },
  question_manage: {
    view: {
      name: "View",
    },
    limit: {
      name: "Question Limit",
    },
    add: {
      name: "Add",
    },
    update: {
      name: "Update",
    },
  },
  templates: {
    view: {
      name: "View",
    },
    add: {
      name: "Add",
    },
    update: {
      name: "Update",
    },
  },
  funnel_performance: {
    view: {
      name: "View",
    }
  },
};

export default permissions;
