import React, { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cpaActions";

export function SaleLeadStatusDialogTable({ listLoading,saleStatuses }) {
  const [id, setId] = useState("");
  const dispatch = useDispatch();

  const confirmation = (statusId) => {
    setId(statusId);
  };
  const removeAccount = (statusId) => {
    dispatch(actions.deleteStatus( statusId));
    setId("");

  };
  const renderAccountLise = (e) => {
    return (
      <>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="w-50">Sale Status</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {saleStatuses.map((status, i) => {
              return (
                <tr key={i}>
                  <td className="w-50">{status.status}</td>
                  <td>
                    {id === status.id ? (
                      <>
                        <Button
                          variant="danger"
                          className="mr-3 p-2"
                          onClick={(e) => removeAccount(status.id)}
                        >
                          Confirm
                        </Button>
                        <Button className="p-2" onClick={(e) => setId("")}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        title="Delete Account"
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={(e) => confirmation(status.id)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger fa fa-trash"></span>
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <>
      {listLoading ? (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      ) : (
        renderAccountLise()
      )}
    </>
  );
}
