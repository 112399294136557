// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';
import Axios from "axios";
import JSZipUtils from "jszip-utils";
import { saveAs } from 'file-saver';
const API_URL = process.env.REACT_APP_API_URL;

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {tenant}
) {

  const downloadHtmlFile = e =>{
    const zip = require('jszip')();
    Axios.post(`${API_URL}/landing-pages/get-landing-page-file-data`,{tenant,id:row.id}).then(async res=>{
      const data = res.data.data;
    const file = new Blob([data], {type: 'text/html'});
    zip.file("index.html", file)
    let reviewData = await JSZipUtils.getBinaryContent("https://ourdatametrics.com/script-files/Reviews.json")
    
    zip.file("Reviews.json", reviewData, {binary:true})
    let data2 = await JSZipUtils.getBinaryContent("https://ourdatametrics.com/script-files/qualified.html")
    zip.file("qualified.html", data2, {binary:true})
    zip.generateAsync({type: "blob"}).then(content => {
      saveAs(content, row.name.toLowerCase().split(" ").join("_") + ".zip");
    });
    // element.href = URL.createObjectURL(file);
    // element.download = "index.html";
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();
    })
  }
  return (
    <>
    <Link
        id={"download" + row.id}
        title="Download index file"
        className="btn btn-icon btn-light btn-hover-primary btn-sm m-2"
        onClick={downloadHtmlFile}
      >
        <GetAppIcon />
      </Link>
      <Link
        id={"edit" + row.id}
        title="Edit Page"
        className="btn btn-icon btn-light btn-hover-primary btn-sm m-2"
        to={"/landing-pages/forms/edit/" + row.id}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary fa fa-pencil-alt"></span>

      </Link>
    </>
  );
}
