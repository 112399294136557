import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RoleUIHelpers";

const RoleUIContext = createContext();

export function useRoleUIContext() {
  return useContext(RoleUIContext);
}

export const RoleUIConsumer = RoleUIContext.Consumer;

export function RoleUIProvider({roleUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initRole = {
    role_name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRole
  };

  return <RoleUIContext.Provider value={value}>{children}</RoleUIContext.Provider>;
}