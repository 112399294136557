import { createSlice } from "@reduxjs/toolkit";

const initialDomainsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const questionsManageSlice = createSlice({
  name: "question-management",
  initialState: initialDomainsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false; // When click on something
      } 
    },
    questionsFetched: (state, action) => {
      const { entities,totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
     state.totalCount =totalCount;
    },
    newQuestionAdded: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.concat([data]);
    },
    questionUpdated: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.concat([data]);
    },
     // deleteCustomers
     questionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => action.payload.id != el.q_id
      );
    },
  },
});
