// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }

    const options =
      props.type && props.type === "bar" ? getPieChartOptions(props) : {};

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "200px" }}
      ></div>
    </>
  );
}

function getPieChartOptions(props) {
  let options = {
    series: props.values,
    colors:['#1BC5BD', '#F64E60'],
    chart: {
      type: "bar",
      height: 450,
      stacked: true,
      // stackType: "100%",
    },
    dataLabels: {
      formatter: function(val, { seriesIndex, dataPointIndex, w }) {
        let total = parseInt(w.config.series[0]["data"][dataPointIndex])+ parseInt(w.config.series[1]["data"][dataPointIndex]);
        return parseInt(parseInt(val)/total *100)  + "%";
      },
      style: {
        fontSize: "10.5px",
        colors: ["#000"],
      },
      orientation: "horizontal",
      enabled: true,
      enabledOnSeries: undefined,
      textAnchor: "middle",
      distributed: false,
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 2,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.6,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
          xaxis: {
            categories: props.labels,
            hideOverlappingLabels: true,
            labels: {
              style: {
                cssClass: "live-chat-x-axis",
                fontSize: "8px",
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
      },
    }
    ],
    plotOptions: {
        bar: {
          horizontal: true,
        },
      },
    xaxis: {
      categories: props.labels,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      offsetX: 0,
      offsetY: 50,
    },
  };
  return options;
}
