import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function getImportList() {
  return axios.get(`${API_URL}/imports/`);
}

export function getStatusOfImport() {
  return axios.get(`${API_URL}/imports/active/`);
}

export function startImport(originalFileName, cloudFileName, headerMapObject) {
  return axios.post(`${API_URL}/imports/`, {
    originalFileName: originalFileName,
    cloudFileName: cloudFileName,
    headerMapObject: JSON.stringify(headerMapObject)
  });
}
