import React, { useEffect, useMemo } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { months, year, years } from "../AdsSpentUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/AdsSpentActions";
import { useAdsSpentUIContext } from "../AdsSpentUIContext";
import { Button, Col, Form, In, Row } from "react-bootstrap";
import { useHistory } from "react-router";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: "100px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const AdsSpentFilter = ({ userInfo,updateState }) => {
  const { permissions, userType } = userInfo;

  const history = useHistory();
  const classes = useStyles();

  const adSpentUIContext = useAdsSpentUIContext();
  const adSpentUIProps = useMemo(() => {
    return {
      queryParams: adSpentUIContext.queryParams,
      setQueryParams: adSpentUIContext.setQueryParams,
    };
  }, [adSpentUIContext]);

  // Getting curret state of Ad spent list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.adSpent }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchAdSpentListing(adSpentUIProps.queryParams));
  }, [adSpentUIProps.queryParams,updateState]);

  const { month, year } = adSpentUIProps.queryParams;
  return (
    <div className="mb-9">
      <Row className="align-items-center">
        <Col md="6">
          <FormControl className={classes.margin}>
            <InputLabel id="demo-customized-select-label">Year</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className="shadow-sm"
              value={year}
              onChange={(e) => {
                adSpentUIProps.setQueryParams((prev) => {
                  return { ...prev, year: e.target.value };
                });
              }}
              input={<BootstrapInput />}
            >
              {years.map((y, i) => {
                return (
                  <MenuItem value={y} key={i}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.margin}>
            <InputLabel id="demo-customized-select-label">Month</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className="shadow-sm"
              value={month}
              onChange={(e) => {
                adSpentUIProps.setQueryParams((prev) => {
                  return {
                    ...prev,
                    month: e.target.value,
                    days: months[e.target.value],
                  };
                });
              }}
              input={<BootstrapInput />}
            >
              {Object.keys(months).map((m, i) => {
                return (
                  <MenuItem value={m} key={i}>
                    {m}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
        <Col md="6" className="text-right">
          {(userType.toLowerCase() === "admin" ||
            permissions["ad_spent"].includes("account")) && (
            <Button
              variant="primary"
              onClick={(e) => {
                history.push("/ads-spent/accounts");
              }}
            >
              Add Lead Source
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
