import * as requestFromServer from "./domainsCrud";
import { domainsSlice, callTypes } from "./domainsSlice";

const { actions } = domainsSlice;

export const fetchDomainsAnalysis = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainAnalysis(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.domainsFetched({ entities: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(actions.domainsFetched({ entities: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomainsList = (startDate, endDate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainList(startDate, endDate)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.domainsList({ domainList: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(actions.domainsList({ domainList: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllDomainNames = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDomainNames()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allDomainNamesFetched({ domains: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allDomainNamesFetched({ domains: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getBarChartData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBarChartData(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        let originalData = { ...response.data.data };
        dispatch(
          actions.chartDetailsFetched({
            chartData: originalData,
          })
        );
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
