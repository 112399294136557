/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { FunnelPerformanceFilters } from "./FunnelPerformanceFilters";
import { ChartComponent } from "./ChartComponent";
import { Col, Row } from "react-bootstrap";
import { FunnelPerformanceNumbersView } from "./FunnelPerformanceNumbersView";

export function FunnelPerformanceStat({ className }) {
  const [numberView, setNumberView] = React.useState(false);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.funnelPerformance }),
    shallowEqual
  );
  const {
    labels,
    series,
    listLoading,
  } = currentState;

  return (
    <>
      <div className={`card card-custom live-chat ${className}`}>
        {/* Header */}
        {/* <div className="card-header align-items-center border-0 py-5 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              Funnels Performance 
            </span>
          </h3>
        </div> */}
        {/* Body */}  
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0 row">
          <FunnelPerformanceFilters
            setShowTableView={setNumberView}
            showTableView={numberView}
          />
        </div>
        {listLoading ? (
          <div className="d-flex align-items-center mx-5 my-8">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ): (
          <>
            <div className="graph-container mt-2">
              <div className="text-center">
                <ChartComponent
                  id={"coversion_chart"}
                  type={"total"}
                  labels={labels}
                  series={series}
                />
              </div>
            </div>
           </>
        )}
      </div>
    </>
  );
}
