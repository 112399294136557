import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function findPermissions() {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` } };
  return axios.get(`${API_URL}/users/get-permission`, header);
}

export function createRole(roleData) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` } };
  return axios.post(`${API_URL}/users/roles`, roleData, header);
}

export function findAllRoles(params) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params };
  return axios.get(`${API_URL}/users/roles`, header);
}

export function updateRole(role) {
  let id = role.id;
  delete role.id;

  return axios.put(`${API_URL}/users/roles/` + id, { ...role });
}

export function saveStaffUser(user) {
  const formData = new FormData();
  Object.keys(user).forEach((key) => {
    if (key === "profileImage") {
      formData.append(key, user[key]);
    } else {
      formData.append(key, JSON.stringify(user[key]));
    }
  });
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` } };
  return axios.post(`${API_URL}/users/add-staff`, formData, header);
}

export function findAllUsers(params) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params };
  return axios.get(`${API_URL}/users`, header);
}

export function deleteUser(userId) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` } };
  return axios.delete(`${API_URL}/users/${userId}`, header);
}

export function updateUser(user) {
  let id = user.id;
  delete user.id;

  let formData = new FormData();
  Object.keys(user).forEach((key) => {
    if (key === "profileImage") {
      formData.append(key, user[key]);
    } else {
      formData.append(key, JSON.stringify(user[key]));
    }
  });
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` } };
  return axios.put(`${API_URL}/users/` + id, formData);
}
