import React, { useEffect, useMemo, useState } from "react";
import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  FormControl,
  MenuItem,
  InputBase,
  Select,
  makeStyles,
} from "@material-ui/core";

import { withStyles } from "@material-ui/styles";
import * as actions from "../../../_redux/questionsActions";

import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useQuestionsUIContext } from "../QuestionsUIContext";
import { Button } from "react-bootstrap";
import { QuestionsContent } from "./QuestionsContent";

const useStyles = makeStyles((theme) => ({
  colMargin: {
    borderRight: "5px solid grey",
    borderRadius: "4px",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const getChartDataFromApi = () => {};

export function QuestionsMain({ category, ind,refresh }) {
  const classes = useStyles();
  const [firstDomain, setFirstDomain] = useState("");
  const [secondDomain, setSecondDomain] = useState("");
  const [firstQuestion, setFirstQuestion] = useState({});
  const [secondQuestion, setSecondQuestion] = useState({});
  const [firstQuestionList, setFirstQuestionList] = useState([]);
  const [secondQuestionList, setSecondQuestionList] = useState([]);
  const [chartsData, setChartsData] = useState({});
  const [loadingContent, setLoadingContent] = useState(false);

  const questionsUIContext = useQuestionsUIContext();
  const questionsUIProps = useMemo(() => {
    return {
      queryParams: questionsUIContext.queryParams,
      setQueryParams: questionsUIContext.setQueryParams,
    };
  }, [questionsUIContext]);

  const { currentState,timezoneBy } = useSelector(
    (state) => ({
      currentState: state.questionAnalysis,
      chartsData: state.questionAnalysis["chartsData_" + category.id],
      questionsList: state.questionAnalysis["questionsList_" + category.id],
      timezoneBy:state.timezoneBy.timezone
    }),
    shallowEqual
  );
  const { domainList } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstDomain) {
      async function fetchQuestionsData() {
        let firstQuestionList = await actions.fetchQuestionsList(
          firstDomain,
          "firstQuestionList",
          category.id,
          questionsUIProps.queryParams.startDate,
          questionsUIProps.queryParams.endDate
        );
        setFirstQuestionList(firstQuestionList.questionsList);
      }
      fetchQuestionsData();
    }
  }, [firstDomain]);

  useEffect(() => {
    if (secondDomain) {
      async function fetchQuestionsData() {
        let secondQuestionList = await actions.fetchQuestionsList(
          secondDomain,
          "secondQuestionList",
          category.id,
          questionsUIProps.queryParams.startDate,
          questionsUIProps.queryParams.endDate
        );
        setSecondQuestionList(secondQuestionList.questionsList);
      }
      fetchQuestionsData();
    }
  }, [secondDomain]);

  useEffect(() => {
    if (validOptions(firstQuestion, secondQuestion)) {
      getChartData(firstQuestion, secondQuestion);
    }
  },[timezoneBy,refresh])

  const getChartData = async (question1, question2) => {
    setLoadingContent(true);
    let chartsDataT = await actions.fetchChartData(
      firstDomain,
      secondDomain,
      question1.q_id,
      question2.q_id,
      category.id,
      questionsUIProps.queryParams.startDate,
      questionsUIProps.queryParams.endDate,
      timezoneBy
    );
    setLoadingContent(false);
    setChartsData(chartsDataT ? chartsDataT.chartsData : null);
  };

  const validOptions = (first, second) => {
    return firstDomain && secondDomain && first.question && second.question;
  };
  return (
    <div>
      <div className={`card card-custom`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 py-2 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              {ind + 1}. {category.category_name}
            </span>
          </h3>
        </div>
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0" style={{ height: "50px" }} />
        <div className="card-spacer mt-n25 p-3">
          <Row>
            <Col className={classes.colMargin}>
              <div className="text-center">
                <h4>For Question 1</h4>
              </div>
              <FormControl className="w-100 mb-3">
                <InputLabel htmlFor="domain"></InputLabel>
                <Select
                  id="domain"
                  value={firstDomain}
                  onChange={(e) => {
                    setFirstDomain(e.target.value);
                    setFirstQuestion({});
                  }}
                  input={<BootstrapInput i />}
                >
                  {domainList.length ? (
                    <MenuItem value="" disabled>
                      Select Domain
                    </MenuItem>
                  ) : (
                    <MenuItem value="" disabled>
                      Not Found!
                    </MenuItem>
                  )}
                  {domainList.map((d, i) => {
                    return (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Select Domain</FormHelperText>
              </FormControl>
              <FormControl className="w-100 mb-3">
                <InputLabel htmlFor="question1"></InputLabel>
                <Select
                  id="question1"
                  value={firstQuestion}
                  onChange={(e) => {
                    setFirstQuestion(e.target.value);
                    if (validOptions(e.target.value, secondQuestion)) {
                      getChartData(e.target.value, secondQuestion);
                    }
                  }}
                  disabled={firstDomain === ""}
                  input={<BootstrapInput disabled={firstDomain === ""} />}
                >
                  <MenuItem value="" disabled>
                    Select Question
                  </MenuItem>

                  {(firstDomain === secondDomain) &&  secondQuestion.q_id? (
                      firstQuestionList.filter(q=>q.q_id != firstQuestion.q_id).map((q, i) => {
                        return (
                          <MenuItem value={q} key={i}>
                            {q.question}
                          </MenuItem>
                        );
                      })
                  ):(
                    firstQuestionList.map((q, i) => {
                        return (
                          <MenuItem value={q} key={i}>
                            {q.question}
                          </MenuItem>
                        );
                      })
                  )}
                </Select>
                <FormHelperText>Select Question</FormHelperText>
              </FormControl>
            </Col>
            <Col>
              <div className="text-center">
                <h4>For Question 2</h4>
              </div>
              <FormControl className="w-100 mb-3">
                <InputLabel htmlFor="domain"></InputLabel>
                <Select
                  id="domain"
                  value={secondDomain}
                  onChange={(e) => {
                    setSecondDomain(e.target.value);
                    setSecondQuestion({});
                  }}
                  input={<BootstrapInput i />}
                >
                  <MenuItem value="" disabled>
                    Select Domain
                  </MenuItem>
                  {domainList.map((d, i) => {
                    return (
                      <MenuItem value={d} key={i}>
                        {d}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Select domain</FormHelperText>
              </FormControl>
              <FormControl className="w-100 mb-3">
                <InputLabel htmlFor="domain"></InputLabel>
                <Select
                  id="domain"
                  value={secondQuestion}
                  onChange={(e) => {
                    setSecondQuestion(e.target.value);
                    if (validOptions(firstQuestion, e.target.value)) {
                      getChartData(firstQuestion, e.target.value);
                    }
                  }}
                  input={<BootstrapInput i />}
                >
                  <MenuItem value="" disabled>
                    Select Question
                  </MenuItem>
                  {(firstDomain === secondDomain) && firstQuestion.q_id ? (
                      secondQuestionList.filter(q=>q.q_id != firstQuestion.q_id).map((q, i) => {
                        return (
                          <MenuItem value={q} key={i}>
                            {q.question}
                          </MenuItem>
                        );
                      })
                  ):(
                    secondQuestionList.map((q, i) => {
                        return (
                          <MenuItem value={q} key={i}>
                            {q.question}
                          </MenuItem>
                        );
                      })
                  )}
                </Select>
                <FormHelperText>Select Question</FormHelperText>
              </FormControl>
            </Col>
          </Row>
          {/* <div className="text-center mt-5">
            <Button
              disabled={
                firstDomain === "" ||
                secondDomain === "" ||
                !firstQuestion.question ||
                !secondQuestion.question
              }
              onClick={getChartData}
            >
              Get Stats
            </Button>
          </div> */}
          {validOptions(firstQuestion, secondQuestion) &&
            (loadingContent ? (
              <div className="d-flex align-items-center justify-content-center" style={{height:"100px"}}>
                <div className="mr-2 text-muted">Loading...</div>
                <div className="spinner spinner-primary mr-10"></div>
              </div>
            ) : (
              chartsData.q1 &&
              chartsData.q1 && (
                <QuestionsContent
                  category={category}
                  firstQuestion={firstQuestion}
                  secondQuestion={secondQuestion}
                  domain={{ firstDomain, secondDomain }}
                  chartsData={chartsData}
                />
              )
            ))}
        </div>
      </div>
    </div>
  );
}
