import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/domainsActions";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import Input from "@material-ui/core/Input";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router";
import CachedIcon from "@material-ui/icons/Cached";
import Button from "@material-ui/core/Button";
import { updateDomainFilter } from "../../../../../redux/timezone/timezoneActions";
import FilterPopover from "../../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export function BarChartFilter() {
  const history = useHistory();
  const classes = useStyles();
  const [barChart, setBarChart] = React.useState(true);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const [durationFilter, setDurationFilter] = React.useState("15m");
  const [selectedDomains, setSelectedDomains] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { currentState, timezoneBy, defaultDomains } = useSelector(
    (state) => ({
      currentState: state.domainAnalysis,
      timezoneBy: state.timezoneBy.timezone,
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );
  const { allDomainNames } = currentState;
  let domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    domainsState = [];
  }, [location]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAllDomainNames());
  }, []);

  useEffect(() => {
    dispatch(
      actions.getBarChartData({
        domains: domainsState.join(","),
        durationFilter,
        timezoneBy,
      })
    );
  }, [durationFilter, timezoneBy, domainsState]);

  const refreshData = () => {
    dispatch(
      actions.getBarChartData({
        domains: selectedDomains.join(","),
        durationFilter,
        timezoneBy,
      })
    );
  };

  return (
    <>
      <Row className="align-items-center w-100 mb-3">
        <Col lg="12" md="12" sm="12">
          <FilterPopover
            fieldName={"dataField"}
            value={domainsState}
            options={allDomainNames}
            onApply={(val) => {
              dispatch(updateDomainFilter(val));
            }}
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Domain
          </small>
        </Col>
      </Row>
      <Row>
      <Col xl="3" lg="4" md="4" sm="5">
          <FormControlLabel
            control={
              <Switch
                checked={barChart}
                onChange={(e) => {
                  setBarChart(true);
                  history.push("/domain-analysis");
                }}
              />
            }
            label="BarCharts Per Minute"
          />
        </Col>
        <Col md="4" sm="4" lg="3" xs="8">
          <FormControl className={"w-100"}>
            <InputLabel htmlFor="lead/account"></InputLabel>
            <Select
              id="lead/account"
              className="w-100 shadow-sm"
              value={durationFilter}
              onChange={(e) => {
                setDurationFilter(e.target.value);
              }}
              input={<BootstrapInput className="w-100" />}
            >
              <MenuItem value="" disabled>
                Select Time Duration
              </MenuItem>
              <MenuItem value="1m">Last 1 min</MenuItem>
              <MenuItem value="2m">Last 2 min</MenuItem>
              <MenuItem value="5m">Last 5 min</MenuItem>
              <MenuItem value="10m">Last 10 min</MenuItem>
              <MenuItem value="15m">Last 15 min</MenuItem>
              <MenuItem value="30m">Last 30 min</MenuItem>
              <MenuItem value="45m">Last 45 min</MenuItem>
              <MenuItem value="60m">Last 60 min</MenuItem>
            </Select>
            <FormHelperText>Select Duration Filter</FormHelperText>
          </FormControl>
        </Col>
        <Col lg="1" md="1" sm="3" xs="4">
          <Button
            variant="outlined"
            color="secondary"
            className="shadow-sm"
            onClick={refreshData}
            title="Refresh"
          >
            <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
          </Button>
        </Col>
      </Row>
    </>
  );
}
