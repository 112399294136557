import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls"
import { ImportTable } from "./import-table/ImportTable"

export function ImportTableCard({props}) {
  return (
    <Card>
      <CardHeader title="Import list">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ImportTable />
      </CardBody>
    </Card>
  );
}
