// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
// import { useDomainsUIContext } from "../DomainsUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Grid,
  DragDropProvider,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid'
import Pagination from "react-js-pagination";
import axios from 'axios';
import { useCpvTrackingUIContext } from "../CpvTrackingUIContext";
import Axios from "axios";

export function CpvTrackingStateTableComponent(props) {

  const columns = [{
    name: "index",
    title: "#",
    dataField: "index",
    text: "Index"
  },
  {
    name: "state_name",
    title: "State",
    dataField: "state_name",
    text: "State"
  },
  {
    name: "count",
    title: "Total Count",
    dataField: "count",
    text: "Total Count"
  }]

  let entities = props.entities

  const [activePage, setActivePage] = useState(1);
  const [activeRecords, setActiveRecords] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'count', direction: 'desc' }]);
  const [lastQuery, setLastQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  // const [stateChartData, setStateChartData] = useState(null);
  const perPageLoading = 10
  const API_URL = process.env.REACT_APP_API_URL;

  const cpvTrackingUIContext = useCpvTrackingUIContext();
  const cpvUIProps = useMemo(() => {
    return {
      queryParams: cpvTrackingUIContext.queryParams,
      setQueryParams: cpvTrackingUIContext.setQueryParams,
    };
  }, [cpvTrackingUIContext]);


  function getStateReportTotal(){
    return axios.get(`${API_URL}/cpv-tracking/getStateWiseReportTotal`,{params:cpvUIProps.queryParams});
  }

  const getStateTotalCount = () => {
    getStateReportTotal().then(res=>{
      if( res.data && res.data.respType === "Success" ) {
        setTotalItemsCount(res.data.data.totalRecords);
      }
    });
  }

  useEffect(() => {
    // getStateWiseReportChart();
    // dispatch(actions.getStateWiseReportChart());
    if(totalItemsCount == 0){
      getStateTotalCount();
    }
  }, []);

  const tableDetailColumnExtensions = [
    { columnName: 'index', width: 10 },
    { columnName: 'state_name', width: 210 },
    { columnName: 'count', width: 115 },
  ];

  // const handlePageChange = (pageNumber) => {
  //   setActivePage(pageNumber);

  //   let activeRec = []
  //   let tempIndex = 1;
  //   if(pageNumber == 1){
  //     tempIndex = 1
  //   } else {
  //     tempIndex = perPageLoading * (pageNumber-1)
  //   }
  //   for(let i = tempIndex; i<(tempIndex+perPageLoading); i++){
  //     if(entities[i]){
  //       let localObj = {}
  //       localObj.index = (tempIndex == 1 ? i : i+1)
  //       localObj.state_name = entities[i].state_name
  //       localObj.count = entities[i].count
  //       activeRec.push(localObj)
  //     }
  //   }
  //   loadData()
  //   setActiveRecords(activeRec)
  // }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let activeRec = []
      let tempIndex = 1;
      if(pageNumber == 1){
        tempIndex = 1
      } else {
        tempIndex = perPageLoading * (pageNumber-1)
      }
      for(let i = tempIndex; i<(tempIndex+perPageLoading); i++){
        if(entities[i]){
          let localObj = {}
          localObj.index = (tempIndex == 1 ? i : i+1)
          localObj.state_name = entities[i].state_name
          localObj.count = entities[i].count
          activeRec.push(localObj)
        }
      }
      setActiveRecords(activeRec)
      loadData(pageNumber)
}

  const SortingIcon = ({ direction }) => (
    <span
      className={`fa fa-arrow-${direction === 'asc' ? 'up' : 'down'}`}
      style={{ fontSize: '12px', paddingLeft: '5px' }}
    />
  );

  const SortLabel = ({ onSort, children, direction }) => (
    <button
      type="button"
      className="btn btn-sm"
      onClick={onSort}
    >
      <b>{children}</b>
      {(direction && <SortingIcon direction={direction} />)}
    </button>
  );

  const loadData = (activePage) => {
    const queryString = `${API_URL}/cpv-tracking/getStateWiseReport`;
      setLoading(true);
      Axios.get(queryString,{params:{...cpvUIProps.queryParams,activePage,sortCol:sorting[0].columnName,sortDir:sorting[0].direction}})
        .then(({ data }) => {
          let res = []
          let index = activePage != 1 ? (perPageLoading * (activePage - 1) + 1) : 1
          
          data.data.forEach(el => {
            res.push({
              index: index++,
              state_name: el.state_name,
              count: el.count
            })
          })
          setActiveRecords(res);
          setLoading(false);
        })
        .catch(() => setLoading(false));
      setLastQuery(queryString);
  };
useEffect(()=>{
loadData(1);
},[sorting])
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12 m-h-505px">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Grid
            rows={activeRecords}
            columns={columns}
          >
            <SortingState
              sorting={sorting}
              onSortingChange={setSorting}
            />
            <Table
              columnExtensions={tableDetailColumnExtensions}
            />
            <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />
          </Grid>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPageLoading}
            // totalItemsCount={entities.length}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={perPageLoading}
            onChange={handlePageChange}
            itemClass={"btn btn-icon btn-sm border-0 btn-light  mr-2 my-1"}
            activeClass={"btn-hover-primary active"}
            activeLinkClass={"btn-hover-primary text-white"}
            linkClass={"custom-pagination-a"}
            prevPageText={<i className="ki ki-bold-arrow-back icon-xs"></i>}
            nextPageText={<i className="ki ki-bold-arrow-next icon-xs"></i>}
            firstPageText={<i className="ki ki-bold-double-arrow-back icon-xs"></i>}
            lastPageText={<i className="ki ki-bold-double-arrow-next icon-xs"></i>}
          />
        </div>
      </div>
    </>
);
}