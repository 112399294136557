import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useLeadAnalysisUIContext } from "../LeadAnalysisUIContext";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import * as actions from "../../../_redux/leadAnalysisActions";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import CachedIcon from "@material-ui/icons/Cached";
import { updateDomainFilter } from "../../../../../../redux/timezone/timezoneActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Col, Row } from "react-bootstrap";
import FilterPopover from "../../../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import momentTZ from "moment-timezone";
import { useLocation } from 'react-router-dom';
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const prepareFilter = (queryParams, values) => {
  const { domain, campaign, startDate, endDate, columns } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by domain
  filter.domain = domain !== "" ? domain : undefined;
  filter.campaign = campaign !== "" ? campaign : undefined;
  filter.columns = columns !== "" ? columns : undefined;
  delete newQueryParams.operator;
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  newQueryParams.from = 1;
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function LeadAnalysisFilter({ listLoading, getColumns }) {
  const dispatch = useDispatch();
  // leadAnalysis UI Context
  const leadAnalysisUIContext = useLeadAnalysisUIContext();
  const leadAnalysisUIProps = useMemo(() => {
    return {
      queryParams: leadAnalysisUIContext.queryParams,
      setQueryParams: leadAnalysisUIContext.setQueryParams,
    };
  }, [leadAnalysisUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      leadAnalysisUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, leadAnalysisUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      leadAnalysisUIProps.setQueryParams(newQueryParams);
    }
  };

  const { currentState, defaultDomains } = useSelector((state) => ({
    currentState: state.leadAnalysis,
    defaultDomains: state.timezoneBy,
  }));
  const { domainList, campaignList, columns } = currentState;
  const domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    sessionStorage.setItem("urlChange", 1);
    setInterval(() => {
      sessionStorage.setItem("urlChange", 0);
    }, 100);
  }, [location]);

  const [selectedCampaign, setSelectedCampaign] = React.useState([]);
  const [checkedAllCampaign, setCheckedAllCampaign] = React.useState(false);
  const [openCampaign, setOpenCampaign] = React.useState(false);
  const [openColumnFilter, setOpenColumnFilter] = React.useState(false);
  const [checkedAllcolumns, setCheckedAllColumns] = React.useState(true);
  const [campaignMenuList, setCampaignMenuList] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const refreshData = () => {
    dispatch(actions.fetchLeadAnalysis(leadAnalysisUIProps.queryParams));
  };

  const handleOpenCampaign = () => {
    setOpenCampaign(true);
  };

  const handleCloseCampaign = () => {
    setOpenCampaign(false);
  };

  const handleOpenColumnFilter = () => {
    setOpenColumnFilter(true);
  };

  const handleCloseColumnFilter = () => {
    setOpenColumnFilter(false);
  };

  useEffect(() => {
    dispatch(actions.fetchDomainsList());
    dispatch(actions.fetchCampaignList(null));
    dispatch(actions.fetchColumns());
  }, [null, dispatch]);

  // let CampaignMenuList = [];

  ////----------------OLD
  // if (campaignList) {
  //   // CampaignMenuList = campaignList.map((campaign, index) => {
  //   //   return <option key={index} value={campaign.campaign_name}>{campaign.campaign_name}</option>
  //   // });
  //   if (campaignList.length > 0) {
  //     // CampaignMenuList.push({campaign_name:'Select All', domain: ''});
  //     campaignList.forEach((element) => {
  //       CampaignMenuList.push({
  //         campaign_name: element.campaign_name,
  //         domain: element.domain,
  //       });
  //     });

  //     let newMenuList = [];
  //     // newMenuList = CampaignMenuList.sort((a, b) => -b.campaign_name.localeCompare(a.campaign_name))
  //     newMenuList = CampaignMenuList.sort(
  //       (a, b) => -b.domain.localeCompare(a.domain)
  //     );
  //     newMenuList = newMenuList;
  //     CampaignMenuList = newMenuList;
  //   }
  // }

  useEffect(()=>{
    getColumns(selectedColumns);
  }, [selectedColumns]);  

  useEffect(() => {
    console.log("asfsdfsadf", campaignList);
    if (campaignList) {
      let tempArr = [];
      let filteredArr = campaignList.filter((c) => {
        if (tempArr.includes(c.campaign_name)) {
          return false;
        } else {
          tempArr.push(c.campaign_name);
          return true;
        }
      });
      setCampaignMenuList(filteredArr);
    }
  }, [campaignList]);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          domain: "",
          campaign: "",
          startDate: leadAnalysisUIProps.queryParams.startDate,
          endDate: leadAnalysisUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <Row className="align-items-center w-100 mb-3">
              <Col lg="8" md="8" sm="12">
                <FilterPopover
                  fieldName={"dataField"}
                  value={domainsState}
                  options={domainList}
                  onApply={(val) => {
                    setFieldValue("domain", val.join(","));
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Domain
                </small>
              </Col>
              <Col
                lg="4"
                md="4"
                sm="12"
                className="analysis-page lead-analysis"
              >
                <FormControl>
                  {/* <InputLabel id="demo-mutiple-checkbox-label" shrink={true}>Select Domain</InputLabel> */}
                  {/* <Autocomplete
                    multiple
                    textFieldProps={{
                      label: "Label",
                      InputLabelProps: { shrink: false },
                    }}
                    limitTags={3}
                    id="tags-filled"
                    variant="outlined"
                    options={CampaignMenuList}
                    groupBy={(option) => String(option.domain)}
                    value={selectedCampaign}
                    open={openCampaign}
                    onOpen={handleOpenCampaign}
                    // onClose={handleCloseCampaign}
                    onBlur={(e, val) => {
                      setOpenCampaign(false);
                      handleSubmit();
                    }}
                    onChange={(e, val) => {
                      let isAllSelected = false;

                      val.forEach((innerVal) => {
                        if (
                          innerVal.campaign_name == "Select All" ||
                          innerVal.campaign_name == "Unselect All"
                        ) {
                          isAllSelected = true;
                        }
                      });

                      // if(checkedAllCampaign){
                      //   isAllSelected = true
                      // }

                      const index = CampaignMenuList.indexOf("Select All");
                      if (index > -1) {
                        // CampaignMenuList.splice(index, 1);
                      }

                      let selectedCampaignList = [];
                      if (isAllSelected) {
                        if (
                          selectedCampaign.length == CampaignMenuList.length
                        ) {
                          setSelectedCampaign([]);
                          setCheckedAllCampaign(false);
                          selectedCampaignList = ["-"];
                        } else {
                          setSelectedCampaign(CampaignMenuList);
                          setCheckedAllCampaign(true);
                          selectedCampaignList = CampaignMenuList.map(
                            (CampaignMenuList) => CampaignMenuList.campaign_name
                          );
                        }
                        setOpenCampaign(false);
                      } else {
                        setSelectedCampaign(val);
                        selectedCampaignList = val.map(
                          (val) => val.campaign_name
                        );

                        setCheckedAllCampaign(false);
                        setOpenCampaign(true);
                      }

                      setFieldValue("campaign", selectedCampaignList.join());
                      // handleSubmit();
                    }}
                    getOptionLabel={(option) => option.campaign_name}

                    renderOption={(option, state) => {
                      const selectIndex = selectedCampaign.findIndex(
                        (campaign) => campaign === option.campaign_name
                      );

                      state.selected = false;
                      if (selectIndex > -1) {
                        state.selected = true;
                      }
                      if (option.campaign_name == "Select All") {
                        return (
                          <React.Fragment>
                            <Checkbox checked={checkedAllCampaign} />
                            <ListItemText
                              primary={
                                checkedAllCampaign
                                  ? "Unselect All"
                                  : "Select All"
                              }
                            />
                          </React.Fragment>
                        );
                      } else {
                        let selectedCampaignArr = selectedCampaign.map(
                          (selectedCampaign) => selectedCampaign.campaign_name
                        );

                        return (
                          <React.Fragment>
                            <Checkbox
                              checked={
                                selectedCampaignArr.indexOf(
                                  option.campaign_name
                                ) > -1
                              }
                            />
                            <ListItemText primary={option.campaign_name} />
                          </React.Fragment>
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Select Campaign"
                      />
                    )}
                  ></Autocomplete> */}
                  <FilterPopover
                    value={selectedCampaign}
                    options={campaignMenuList}
                    fieldName={"campaign_name"}
                    placeholder="Select Compaign"
                    maxListShow={3}
                    onApply={(e) => {
                      setSelectedCampaign(e)
                      setFieldValue("campaign", e.join());
                      handleSubmit();
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Filter</b> by Campaign
                  </small>
                </FormControl>
              </Col>
            </Row>
            <Row className="align-items-center w-100 mb-3">
              <Col lg="5" md="5" sm="12">
                <div>
                  <div className="input-group shadow-sm">
                    <DateRangePicker
                      onCallback={(start, end, label) => {
                        let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                        let endDate = end.format("YYYY-MM-DD HH:mm:ss");

                        setFieldValue("startDate", startDate);
                        setFieldValue("endDate", endDate);

                        handleSubmit();
                      }}
                      initialSettings={{
                        startDate: moment(
                          leadAnalysisUIProps.queryParams.startDate
                        ),
                        endDate: moment(
                          leadAnalysisUIProps.queryParams.endDate
                        ),
                        ranges: {
                          "Life Time": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(10, "years"),
                            moment.utc().utcOffset(offSet),
                          ],
                          Today: [
                            moment.utc().utcOffset(offSet),
                            moment.utc().utcOffset(offSet),
                          ],
                          Yesterday: [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(1, "days"),
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(1, "days"),
                          ],
                          "Last 7 Days": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(6, "days"),
                            moment.utc().utcOffset(offSet),
                          ],
                          "Last 14 Days": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(13, "days"),
                            moment.utc().utcOffset(offSet),
                          ],
                          "Last 30 Days": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(29, "days"),
                            moment.utc().utcOffset(offSet),
                          ],
                          "This week": [
                            moment.utc().utcOffset(offSet).startOf('isoweek'),
                            moment.utc().utcOffset(offSet).endOf('isoweek'),
                          ],
                          "Last week": [
                            moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                            moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                          ],
                          "This Month": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .startOf("month"),
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .endOf("month"),
                          ],
                          "Last Month": [
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(1, "month")
                              .startOf("month"),
                            moment
                              .utc()
                              .utcOffset(offSet)
                              .subtract(1, "month")
                              .endOf("month"),
                          ],
                        },
                        locale: {
                          format: "MMMM DD, YYYY",
                        },
                        alwaysShowCalendars: true,
                      }}
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        <i className="fa fa-calendar-check"></i>
                      </span>
                    </div>
                  </div>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Date
                  </small>
                </div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <Row>
                  <Col
                    lg="10"
                    md="10"
                    sm="12"
                    className="contact-filter-input analysis-page"
                  >
                    <FormControl>
                      {/* <Autocomplete
                        multiple
                        limitTags={3}
                        textFieldProps={{
                          label: "input",
                          InputLabelProps: { shrink: false },
                        }}
                        id="column-checkbox"
                        variant="outlined"
                        options={[
                          { dataField: "Select All", text: "SELECT_ALL" },
                          ...columns,
                        ]}
                        value={columns.filter((column) => column.selected)}
                        open={openColumnFilter}
                        onOpen={handleOpenColumnFilter}
                        // onClose={handleCloseColumnFilter}
                        onBlur={(e, val) => {
                          setOpenColumnFilter(false);
                          handleSubmit();
                        }}
                        onChange={(event, val) => {
                          //let val = event.target.value;
                          let isAllSelected = false;
                          let isAllUnselected = false;
                          if (event.currentTarget.innerText == "Select All")
                            isAllSelected = true;
                          else if (
                            event.currentTarget.innerText == "Unselect All"
                          )
                            isAllUnselected = true;

                          let selectedColumnList = [];
                          if (isAllSelected) {
                            setCheckedAllColumns(true);
                            selectedColumnList = columns.map(
                              (column) => column.dataField
                            );
                            dispatch(actions.updateColumns(selectedColumnList));

                            setOpenColumnFilter(false);
                          } else if (isAllUnselected) {
                            setCheckedAllColumns(false);
                            selectedColumnList = [];
                            dispatch(actions.updateColumns(selectedColumnList));
                            setOpenColumnFilter(false);
                          } else {
                            selectedColumnList = val;
                            selectedColumnList = selectedColumnList.map(
                              (column) => column.dataField
                            );
                            dispatch(actions.updateColumns(selectedColumnList));
                            setCheckedAllColumns(false);
                            setOpenColumnFilter(true);
                          }
                          setFieldValue(
                            "columns",
                            selectedColumnList
                              .map((column) => column.dataField)
                              .join()
                          );
                          // handleSubmit();
                        }}
                        getOptionLabel={(option) => option.dataField}
                        renderOption={(option, state) => {
                          if (option.text == "SELECT_ALL") {
                            return (
                              <React.Fragment>
                                <Checkbox checked={checkedAllcolumns} />
                                <ListItemText
                                  primary={
                                    checkedAllcolumns
                                      ? "Unselect All"
                                      : "Select All"
                                  }
                                />
                              </React.Fragment>
                            );
                          } else {

                            return (
                              <React.Fragment>
                                <Checkbox checked={option.selected} />
                                <ListItemText primary={option.dataField} />
                              </React.Fragment>
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Select Columns"
                          />
                        )}
                      ></Autocomplete> */}
                      <FilterPopover
                        value={selectedColumns}
                        options={columns}
                        placeholder="Select Lead Status"
                        maxListShow={7}
                        fieldName={"text"}
                        onApply={(e) => {
                          setSelectedColumns(e);
                          dispatch(actions.updateColumns(e));
                          setFieldValue("columns", e.join());
                          handleSubmit();
                        }}
                      />
                      <small className="form-text text-muted">
                        <b>Select</b> Columns
                      </small>
                    </FormControl>
                  </Col>
                  <Col lg="2" md="2" sm="12" className="mt-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="shadow-sm"
                      onClick={refreshData}
                      title="Refresh"
                    >
                      <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}
