import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function ConversationAddEditDialogHeader({ id }) {
  const [title, setTitle] = useState("");
  useEffect(() => {
    let _title = id ? "Edit Conversation Text" : "Add New Conversation Text";
    setTitle(_title);
  }, []);

  return (
    <>
      {/* {actionsLoading && <ModalProgressBar />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
