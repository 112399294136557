import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const fileDownloader = createSlice({
  name: "fileDownloader",
  initialState: initialState,
  reducers: {
    addOrUpdatePrgressState: (state, action) => {
      const { data } = action.payload;
      if (state.files.find((file) => file.name === data.name)) {
        state.files = state.files.map((f) => {
          if (f.name === data.name) {
            return data;
          } else {
            return f;
          }
        });
      } else {
        state.files = [data, ...state.files];
      }
    },
    resetPrgressState: (state, action) => {
      const { data } = action.payload;

      state.files = data;
    },
  },
});
