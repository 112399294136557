import {createSlice} from "@reduxjs/toolkit";

const initialLeadAnalysissState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities:null,
  leadAnalysisForEdit: undefined,
  lastError: null,
  domainList: [],
  campaignList: null,
  columns: [],
  defaultColumns: [],
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadAnalysisSlice = createSlice({
  name: "leadAnalysis",
  initialState: initialLeadAnalysissState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findLeadAnalysiss
    leadAnalysisFetched: (state, action) => {
      const { entities } = action.payload;
      const { analysis, totalCount } = entities;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = analysis || [];
    },
    // get domain list
    domainsList: (state, action) => {
      const { domainList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.domainList = domainList;
    },
    // get campaign List
    campaignList: (state, action) => {
      const { campaignList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignList = campaignList;
    },
    columnsReceived: (state, action) => {
      const { columns, defaultColumns } = action.payload;
      state.listLoading = false;
      state.error = null;
      const columnList = [];
      columns.forEach(column => {
        columnList.push(column.dataField);
        column.selected = true;
      })
      state.columns = columns;
      state.defaultColumns = columnList
    },
    updateColumns: (state, action) => {
      const values = action.payload;
      const copy = [...state.columns];
      copy.forEach(col => {
        const findFromValues = values.find(value => value === col.dataField);
        if(findFromValues){
          col.selected = true;
        } else{
          col.selected = false;
        } 
      });
      state.columns = copy;
    }
  }
});
