import { makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  blue: {
    color: "blue",
    backgroundColor: "darkgrey",
  },
  greyBack: {
    backgroundColor: "lightgrey",
    fontWeight: 800,
  },
  table: {
    marginBottom: "4em",
    cursor: "pointer",
  },
}));

export function FunnelPerformanceNumbersView({ data, labels }) {
  const classes = useStyles();
  const [rowHighlightData, setRowHighlightData] = useState({
    row: null,
    col: null,
    domain: null,
  });
  return (
    <>
      {data.map((numberData, i) => {
        return (
          <div>
            <div className="text-center">
              <div
                className="pb-4 text-center"
                style={{ borderBottom: "1px solid #e2e2e2" }}
              >
                <a
                  href="#"
                  className="text-primary font-weight-bold font-size-h4"
                >
                  {numberData.domain_name}
                </a>
              </div>
            </div>
            <Table className={classes.table} key={i} bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th
                    className={classnames({
                      [classes.blue]:
                        rowHighlightData.col === 0 &&
                        i === rowHighlightData.domain,
                      [classes.greyBack]: true,
                    })}
                  >
                    Total
                  </th>
                  {labels.map((label, k) => {
                    return (
                      <th
                        key={k + 1}
                        className={
                          rowHighlightData.col === k + 1 &&
                          i === rowHighlightData.domain &&
                          classes.blue
                        }
                      >
                        {label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr
                  className={
                    rowHighlightData.row === 0 &&
                    i === rowHighlightData.domain &&
                    classes.blue
                  }
                >
                  <th>Total Valid Leads</th>
                  {numberData.totalValids.map((valids, k) => {
                    return (
                      <td
                        className={classnames({
                          [classes.blue]:
                            rowHighlightData.col === k &&
                            i === rowHighlightData.domain,
                          [classes.greyBack]: k === 0,
                        })}
                        key={k}
                        onClick={(e) => {
                          setRowHighlightData({
                            row: 0,
                            col: k,
                            domain: i,
                          });
                        }}
                      >
                        {valids}
                      </td>
                    );
                  })}
                </tr>

                <tr
                  className={
                    rowHighlightData.row === 1 &&
                    i === rowHighlightData.domain &&
                    classes.blue
                  }
                >
                  <th>Total Live Chats</th>
                  {numberData.totalChats.map((chat, k) => {
                    return (
                      <td
                      className={classnames({
                        [classes.blue]:
                          rowHighlightData.col === k &&
                          i === rowHighlightData.domain,
                        [classes.greyBack]: k === 0,
                      })}
                        key={k}
                        onClick={(e) => {
                          setRowHighlightData({
                            row: 1,
                            col: k,
                            domain: i,
                          });
                        }}
                      >
                        {chat}
                      </td>
                    );
                  })}
                </tr>
                <tr
                  className={
                    rowHighlightData.row === 2 &&
                    i === rowHighlightData.domain &&
                    classes.blue
                  }
                >
                  <th>Total Percentage</th>
                  {numberData.percentage.map((perc, k) => {
                    return (
                      <td
                      className={classnames({
                        [classes.blue]:
                          rowHighlightData.col === k &&
                          i === rowHighlightData.domain,
                        [classes.greyBack]: k === 0,
                      })}
                        key={k}
                        onClick={(e) => {
                          setRowHighlightData({
                            row: 2,
                            col: k,
                            domain: i,
                          });
                        }}
                      >
                        {perc}%
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
}
