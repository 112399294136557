import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./AdminLogsUIHelpers";

const AdminLogsUIContext = createContext();

export function useAdminLogUIContext() {
  return useContext(AdminLogsUIContext);
}

export const AdminLogsUIConsumer = AdminLogsUIContext.Consumer;

export function AdminLogsUIProvider({adminLogsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams
  };

  return <AdminLogsUIContext.Provider value={value}>{children}</AdminLogsUIContext.Provider>;
}