import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";
import { QuestionAnalysisMain } from "../modules/QuestionAnalysis/pages/QuestionAnalysisMain";

export function QuestionAnalysisPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("question_analysis")
  ) {
    return <QuestionAnalysisMain />;
  } else {
    return <UnauthorizedAccess />;
  }
}
