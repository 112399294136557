// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { number } from "prop-types";
export function ChartComponent(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }
    const options =
      props.type === "total"
        ? getAllCRChartOptions(props)
        : getParticularDomainChart(props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "200px" }}
      ></div>
    </>
  );
}
function getAllCRChartOptions(props) {
  let options = {
    series: props.values,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      stackType: "100%",
      height: "400",
    },
    colors: [
      "#2cc5db",
      "#06b109",
      "#3f11b7",
      "#b9c8a2",
      "#626d13",
      "#bddc14",
      "#003399",
      "#945da1",
      "#7e68da",
      "#4f2c47",
      "#2a1e69",
      "#ae0167",
      "#b3b8fa",
      "#3d2d27",
      "#00FF00",
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: [3, 3],
    },
    title: {
      text: "Live Chat Analysis (Total CR)",
      align: "left",
    },
    xaxis: {
      categories: props.labels,
      hideOverlappingLabels: true,
      labels: {
        style: {
          cssClass: "live-chat-x-axis",
          fontSize: "8px",
        },
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      forceNiceScale: false,
      tickAmount: 5,
      labels: {
        formatter: function(value) {
          return value + "%";
        },
      },
    },
  };
  return options;
}

function getParticularDomainChart(props) {
  let options = {
    series: props.values,
    chart: {
      height: 250,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#003399", "#f64e60"],
    stroke: {
      width: [3, 3],
      dashArray: [0, 2],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props.labels,

      labels: {
        style: {
          cssClass: "live-chat-x-axis",
          fontSize: "8px",
        },
        // formatter: function(val) {
        //   let number = val ? parseInt(val.split(" ")[0]):null;
        //   let skip = false
        //   if(number){
        //     skip = number % 2 !== 0;
        //   }

        //   // let total = parseInt(w.config.series[0]["data"][])+ parseInt(w.config.series[1]["data"][dataPointIndex]);
        //   return skip ? "" : val;
        // },
        hideOverlappingLabels: false,
      },
    },
    yaxis: [
      {
        max: 100,
        min: 0,
        tickAmount: 5,
        forceNiceScale: false,
        title: {
          text: "Percentage",
        },
        labels: {
          formatter: function(value) {
            return value + "%";
          },
        },
      },
      {
        opposite: true,
        tickAmount: 5,
        min: 0,
        title: {
          text: "Total Valid Leads",
        },
        labels: {
          formatter: function(value) {
            return parseInt(value);
          },
        },
      },
    ],
  };
  return options;
}
