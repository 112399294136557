import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function getCpvTracking(data) {
  let params = '';
  // Object.keys(queryParams).forEach(key=>{
  //   if( params.length > 0 ) params += '&';
  //   params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : queryParams[key]}`;
  // });

  let endPoint = `${API_URL}/cpv-tracking/getCampaignAndCountryWiseReport`
  if(data.type == 'campaign')
    endPoint = `${API_URL}/cpv-tracking/getCampaignNameWiseReport`
  else if(data.type == 'country')
    endPoint = `${API_URL}/cpv-tracking/getCountryWiseReport`
  else if(data.type == 'state')
    endPoint = `${API_URL}/cpv-tracking/getStateWiseReportChart`

  return axios.get(endPoint,{params:data});
}

export function getDomainList() {
  return axios.get(`${API_URL}/domain-report/getStats?startDate=2020-10-27&endDate=2020-11-28`);
}