import React, { useEffect, useMemo, useState } from "react";
import * as actions from "../../../_redux/templatesActions";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useTemplatesUIContext } from "../TemplatesUIContext";
import * as uiHelpers from "../TemplatesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { makeStyles } from "@material-ui/core/styles";
import { RenderPreviewInModal } from "../RenderPreviewInModal/RenderPreviewInModal";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const API_URL = process.env.REACT_APP_API_URL;

export function TemplatesTable({ userInfo }) {
  const { permissions, userType } = userInfo;
  const [url, setUrl] = useState("");

  // Pages UI Context
  const TemplatesUIContext = useTemplatesUIContext();
  const TemplatesUIProps = useMemo(() => {
    return {
      ids: TemplatesUIContext.ids,
      setIds: TemplatesUIContext.setIds,
      queryParams: TemplatesUIContext.queryParams,
      setQueryParams: TemplatesUIContext.setQueryParams,
    };
  }, [TemplatesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.cssTemplates }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getAllTemplates(TemplatesUIProps.queryParams));
  }, [TemplatesUIProps.queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "preview",
      text: "Preview",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
          href="#"
            title="Preview template"
            onClick={(e) => {
              setUrl(API_URL + "/landing-pages/preview/" + row.id);
            }}
          >
            Preview
          </a>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["templates"].includes("update")
          ? false
          : true,

      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TemplatesUIProps.queryParams.rows,
    page: TemplatesUIProps.queryParams.from,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  TemplatesUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      {url.length > 0 && (
        <RenderPreviewInModal
          show={true}
          url={url}
          onHide={() => {
            setUrl("");
          }}
        />
      )}
    </>
  );
}
