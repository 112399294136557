import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AccountsDialogHeader } from "./AccountsDialogHeader";
import { useAdsSpentUIContext } from "../AdsSpentUIContext";
import { AcountsListTable } from "./AcountsListTable";
import * as actions from "../../../_redux/AdsSpentActions";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText, makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: "300px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export function AccountsDialog({ show, onHide, match }) {
  const [newAccountName, setNewAccountName] = useState("");
  const classes = useStyles();

  const adSpentUIContext = useAdsSpentUIContext();
  const adSpentUIProps = useMemo(() => {
    return {
      queryParams: adSpentUIContext.queryParams,
    };
  }, [adSpentUIContext]);

  // Getting curret state of Ad Spent list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.adSpent }),
    shallowEqual
  );
  const {
    listLoading,
    accounts,
    actionsLoading,
    allLeadSources,
  } = currentState;

  // Staff Redux state
  const dispatch = useDispatch();

  const { month, year } = adSpentUIProps.queryParams;
  useEffect(() => {
    if (match) {
      dispatch(actions.fetchAccountsListing({ month, year }));
      dispatch(actions.fetchAllLeadSources());
    }
  }, [month, year, match]);

  const saveAccount = (e) => {
    dispatch(
      actions.addNewAccount({ month, year, leadSource: newAccountName })
    );
    setNewAccountName("");
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={(e) => onHide()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AccountsDialogHeader month={month} year={year} />
      <Modal.Body className="w-75">
        <form className="d-flex mb-4 align-items-baseline">
          <FormControl className={classes.margin}>
            <InputLabel htmlFor="lead/account"></InputLabel>
            <Select
              id="lead/account"
              value={newAccountName}
              onChange={(e) => {
                setNewAccountName(e.target.value);
              }}
              input={<BootstrapInput i />}
            >
              <MenuItem value="" disabled>
                Select Lead Source
              </MenuItem>
              {allLeadSources.map((m, i) => {
                return (
                  <MenuItem value={m} key={i}>
                    {m}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Select Lead Source</FormHelperText>
          </FormControl>

          {actionsLoading ? (
            <Button variant="primary ml-3" disabled>
              Loading <i className="fas fa-spinner fa-spin" />
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={saveAccount}
              disabled={newAccountName.length <= 0}
            >
              Add
            </Button>
          )}
        </form>

        <AcountsListTable
          month={month}
          year={year}
          listLoading={listLoading}
          accounts={accounts}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary ml-3" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
