import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { DomainsFilter } from "./domains-filter/DomainsFilter";
import { DomainsTable } from './domains-table/DomainsTable';
export function DomainsCard() {

  return (
    <Card>
      {/* <CardHeader title="Domain Analysis">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <DomainsFilter />
        <DomainsTable/>
      </CardBody>
    </Card>
  );
}
