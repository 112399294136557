// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function BarChartComponent(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }

    const options = getOptions(props);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "150px" }}
      ></div>
    </>
  );
}



const getOptions = (props) => {
  var options = {
    series: props.data,
    colors:['#1BC5BD', '#F64E60'],
    chart: {
      type: "bar",
      stacked: true,
      height:"150px",
      toolbar: {
        show: false,}
      // stackType: "100%",
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
  },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      formatter: function(val, { seriesIndex, dataPointIndex, w }) {
        let total = parseInt(w.config.series[0]["data"][dataPointIndex])+ parseInt(w.config.series[1]["data"][dataPointIndex]);
        return val + "  (" +parseInt(parseInt(val)/total *100)  + "%)";
      },
      style: {
        fontSize: "10.5px",
        colors: ["#000"],
      },
      orientation: "horizontal",
      enabled: true,
      enabledOnSeries: undefined,
      textAnchor: "middle",
      distributed: false,
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 2,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.6,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    xaxis: {
      categories: [""],
      tickAmount: 5,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };
  return options;
};
