import React from "react";
import { LeadCompareAnalysisLoadingDialog } from "./lead-compare-analysis-loading-dialog/LeadCompareAnalysisLoadingDialog";
import { LeadCompareAnalysisUIProvider } from "./LeadCompareAnalysisUIContext";
import { LeadCompareAnalysisCard } from "./LeadCompareAnalysisCard";
export function LeadCompareAnalysisPage({ history }) {

  return (
    <LeadCompareAnalysisUIProvider>
      <LeadCompareAnalysisLoadingDialog />
      <LeadCompareAnalysisCard />
    </LeadCompareAnalysisUIProvider>
  );
}
