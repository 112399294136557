import {timezoneReducer} from "./timezoneReducer";

const {actions} = timezoneReducer;

export const updateTimezoneBy = timezone => dispatch => {
    dispatch(actions.updateTimezoneBy(timezone));
};

export const updateDomainFilter = selectedDomain => dispatch => {
    dispatch(actions.updateSelectedDomains({selectedDomain}));
};