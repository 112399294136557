import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LeadStatusAnalysisFilter } from "./lead-status-analysis-filter/LeadStatusAnalysisFilter";
import { LeadStatusAnalysisTable } from './lead-status-analysis-table/LeadStatusAnalysisTable';
export function LeadStatusAnalysisCard() {

  return (
    <Card>
      {/* <CardHeader title="Lead Status Analysis">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <LeadStatusAnalysisFilter />
        <LeadStatusAnalysisTable/>
      </CardBody>
    </Card>
  );
}
