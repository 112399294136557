// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }
        
        const options = getPieChartOptions(props);

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props.values]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px" }}
            ></div>
        </>
    );
}
function getPieChartOptions(props) {
    const copy = [...props.values];
    let maxIndex = -1,maxTotal = 0, totalLeadCount = 0;
    copy.forEach((obj,index) => {
        if(obj.name !== 'Total Leads'){
            const maxNumber = Math.max(...obj.data);
            if ( maxTotal < maxNumber ) {
                maxTotal = maxNumber;
                maxIndex = index;
            }
        } else{ 
            totalLeadCount = Math.max(...obj.data);
        }
    });
    const finalData = [];
    copy.forEach((obj, index) => {
        let show = false;
        if ( obj.name === 'Total Leads' ) {
            show = true;
        }
        if ( maxIndex > -1 && copy[maxIndex].name === obj.name) {
            show = true;
        }
        const defaultOptions = {
            seriesName: obj.name === 'Total Leads' ? 'Total Leads' : maxIndex > -1 ?  copy[maxIndex].name : 'Total Leads',
            show,
            opposite: obj.name === 'Total Leads',
            max: obj.name === 'Total Leads' ? (totalLeadCount + 5 ) : (maxTotal + 3),
            labels: {
                formatter: function(val) {
                    return val.toFixed(0);
                }
            },
            tickAmount: 4,
        };
        finalData.push(defaultOptions);
    });
    var options = {
        series: props.values,
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            stackType: "100%",
            height:"500px"
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Cost Per Acquisition',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: props.labels,
            labels: {
                style: {
                  cssClass: "live-chat-x-axis",
                  fontSize: "8px",
                },
              },
        },
        yaxis: finalData
      };
    return options;
}