// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Form, InputGroup, FormControl } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL;

export function ScriptsCopyColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { anchorEl, setAnchorEl, classes, popoverId, setPopoverId, tenant }
) {
  const open = row.id === popoverId;
  const id = open ? "script-popover" + row.id : undefined;
  const script = `<script src="${API_URL}/landing-pages/render-form/${tenant}/${row.id}" type="text/javascript"></script>`;

  const copyContent = (id) => {
    if (document.selection) {
      // IE
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(id));
      range.select();
      document.execCommand("copy");
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(document.getElementById(id));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }
  }
  return (
    <div key={rowIndex}>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setPopoverId(row.id);
        }}
      >
        Show Scripts
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => {
          setAnchorEl(null);
          setPopoverId(null);
        }}
        anchorOrigin={{
          vertical: "left",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography} style={{ width: "400px" }}>
          <Form.Label htmlFor={"script"+rowIndex}>Embed Script</Form.Label>
          <InputGroup className="mb-2">
            <FormControl
              id={"script"+rowIndex}
              placeholder="Script"
              value={script}
              disabled
            />
            <InputGroup.Append>
              <InputGroup.Text style={{cursor:"pointer"}} title="Copy to clipboard" onClick={(e) => copyContent("script"+rowIndex)}>
                <i className="fas fa-copy" style={{color:"black"}}></i>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Typography>
      </Popover>
    </div>
  );
}
