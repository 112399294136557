import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SaleLeadStatusDialogHeader } from "./SaleLeadStatusDialogHeader";
import { SaleLeadStatusDialogTable } from "./SaleLeadStatusDialogTable";
import * as actions from "../../../_redux/cpaActions";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText, makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: "300px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export function SaleLeadStatusDialog({ show, onHide, match }) {
  const [saleStatus, setSaleStatus] = useState("");
  const [allLeadSaleStatuses, setAllLeadSaleStatuses] = useState([]);
  const classes = useStyles();

  // Getting curret state of Ad Spent list from store (Redux)
  const { currentState,permissions,userType } = useSelector(
    (state) => ({ currentState: state.cpa,
      userType: state.auth.user.userType,
      permissions: state.auth.user.permissions }),
    shallowEqual
  );
  const { listLoading, actionsLoading, saleStatuses } = currentState;

  // Staff Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    if (match) {
      if (
        !["Admin", "admin"].includes(userType) &&
        (permissions["cpa"] && !permissions["cpa"].includes("leadStatus"))
      ) {
        onHide();
      }
      else{
        dispatch(actions.getAllLeadStatus());
        Axios.get(`${API_URL}/logs/getListOfAllLeadStatuses`).then((res) => {
          setAllLeadSaleStatuses(res.data.data);
        });
      }
     
    }
  }, [match]);

  const saveLeadStatus = (e) => {
    dispatch(actions.addNewSaleStatus({ status: saleStatus }));
    setSaleStatus("");
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={(e) => onHide()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SaleLeadStatusDialogHeader />
      <Modal.Body className="w-75">
        <form className="d-flex mb-4 align-items-baseline ">
          <FormControl className={classes.margin}>
            <InputLabel htmlFor="lead/account"></InputLabel>
            <Select
              id="lead/account"
              value={saleStatus}
              onChange={(e) => {
                setSaleStatus(e.target.value);
              }}
              input={<BootstrapInput i />}
            >
              <MenuItem value="" disabled>
                Select Sale Lead Status
              </MenuItem>
              {allLeadSaleStatuses.map((m, i) => {
                return (
                  <MenuItem value={m} key={i}>
                    {m}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Select Sale Lead Status</FormHelperText>
          </FormControl>
          {/* <Form.Group controlId="form-input" className="w-100">
            <Form.Control type="text" placeholder="Enter Sale Lead Status" value={saleStatus} onChange={e => setSaleStatus(e.target.value)} />
          </Form.Group> */}

          {actionsLoading ? (
            <Button variant="primary ml-3" disabled>
              Loading <i className="fas fa-spinner fa-spin" />
            </Button>
          ) : (
            <Button
              variant="primary ml-3"
              onClick={saveLeadStatus}
              disabled={saleStatus.length <= 0}
            >
              Add
            </Button>
          )}
        </form>

        <SaleLeadStatusDialogTable
          listLoading={listLoading}
          saleStatuses={saleStatuses}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary ml-3" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
