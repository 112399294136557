import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { DomainsFilter } from "./domains-filter/DomainsFilter";
import { DomainsTable } from "./domains-table/DomainsTable";
import { DomainsGrouping } from "./domains-grouping/DomainsGrouping";
import { useDomainsUIContext } from "./DomainsUIContext";

export function DomainsCard({ userInfo }) {
  const { permissions, userType } = userInfo;

  const history = useHistory();

  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      ids: domainsUIContext.ids,
      newDomainsButtonClick: domainsUIContext.newDomainsButtonClick,
    };
  }, [domainsUIContext]);

  return (
    <Card>
      {/* Domain Leadsource Details */}
      <CardHeader title="">
        <CardHeaderToolbar>
          {(userType.toLowerCase() === "admin" ||
            permissions["domain_manage"].includes("create")) && (
            <Button
              id="add-domain"
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push("/domains/edit/0");
              }}
            >
              Add New Domain
            </Button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <DomainsFilter /> */}
        {domainsUIProps.ids.length > 0 && <DomainsGrouping />}
        <DomainsTable userInfo={userInfo} />
      </CardBody>
    </Card>
  );
}
