// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import * as moment from "moment-timezone";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import Select from "@material-ui/core/Select";
import { InputLabel, MenuItem } from "@material-ui/core";
import { Select } from "formik-material-ui";
import * as actions from "../../../../_redux/staffManagementActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import subPermissions from "../../../permissionsObj";
import {
  Input,
  Checkbox,
} from "../../../../../../../_metronic/_partials/controls";
// Validation schema

export function AddPermissionForm({
  saveStaffUser,
  profileInfo,
  actionsLoading,
  allPermissions,
  setTabKey,
  error,
}) {
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.staffManagement }),
    shallowEqual
  );
  const { rolesListing } = currentState;
  useEffect(() => {
    if (rolesListing.length <= 0) {
      dispatch(actions.fetchAllRole());
    }
  }, []);

  const renderSubCategoryPermissions = (slug) => {
    return Object.keys(subPermissions[slug]).map((subPerm, i) => {
      return (
        <Col lg="6" key={i}>
          <label>
            <Field type="checkbox" name={slug} value={subPerm} />
            {"  "}
            {subPermissions[slug][subPerm].name}
          </label>
        </Col>
      );
    });
  };

  const renderPermissions = (values, setFieldValue) => {
    return allPermissions.map((perm, i) => {
      return (
        <tr key={i}>
          <td style={{ width: "220px" }}>
            <div className="d-flex align-items-center">
              <Field
                component={Checkbox}
                withFeedbackLabel={false}
                isSelected={
                  values[perm.slug] &&
                  values[perm.slug].length > 0 &&
                  values[perm.slug].length ===
                    Object.keys(subPermissions[perm.slug]).length
                }
                onChange={(e) => {
                  // console.log(perm.slug,subPermissions[]);
                  if (e.target.checked) {
                    // setFieldValue
                    setFieldValue(
                      perm.slug,
                      Object.keys(subPermissions[perm.slug])
                    );
                  } else {
                    setFieldValue(perm.slug, []);
                  }
                }}
              ></Field>
              <strong className="ml-1">
                &nbsp;&nbsp;&nbsp;&nbsp;{perm.name}
              </strong>
            </div>
          </td>
          <td>
            <Row>{renderSubCategoryPermissions(perm.slug)}</Row>
            {values[perm.slug] && values[perm.slug].includes("export") && (
              <div>
                <h5>Exports Limits</h5>
                <p className="text-danger">
                  (*if limit not added it will count as infinite).
                </p>
                <Row>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_day"
                      component={Input}
                      placeholder="Export Limit(Day)"
                      label="Day Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_week"
                      component={Input}
                      placeholder="Export Limit(Week)"
                      label="Week Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_month"
                      component={Input}
                      placeholder="Export Limit(Month)"
                      label="Month Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={profileInfo}
        onSubmit={(values) => {
          let permissions = { ...values },
            limits = {
              export_limit_day: values.export_limit_day
                ? values.export_limit_day
                : null,
              export_limit_week: values.export_limit_week
                ? values.export_limit_week
                : null,
              export_limit_month: values.export_limit_month
                ? values.export_limit_month
                : null,
            };
          delete permissions.profileInfo;
          delete permissions.roleId;
          delete permissions.export_limit_month;
          delete permissions.export_limit_week;
          delete permissions.export_limit_day;
          let data = {
            ...values.profileInfo,
            roleId: values.roleId.id ? values.roleId.id : "",
            permissions,
            limits,
          };
          saveStaffUser(data);
        }}
      >
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Role Name */}
                  <div className="col-12">
                    <InputLabel id="demo-simple-select-helper-label">
                      Please Select Role
                    </InputLabel>
                    <Field
                      component={Select}
                      name="roleId"
                      inputProps={{
                        id: "roleId",
                        onChange: (e) => {
                          resetForm();
                          let permissions = e.target.value.permissions,
                            limits = e.target.value.limits;
                          Object.keys(permissions).forEach((key) => {
                            setFieldValue(key, permissions[key]);
                          });
                          Object.keys(limits).forEach((key) => {
                            setFieldValue(key, limits[key]);
                          });
                        },
                      }}
                      className="form-control"
                      placeholder="Select Role"
                    >
                      {rolesListing.map((list, i) => {
                        return (
                          <MenuItem value={list} key={i}>
                            {list.name}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <div>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>Capabilities</th>
                      </tr>
                    </thead>
                    <tbody>{renderPermissions(values, setFieldValue)}</tbody>
                  </Table>
                </div>
                {error && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{error}</div>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={(e) => setTabKey("profile")}
                className="btn btn-warning btn-elevate"
              >
                <i className="fas fa-angle-double-left"></i>
                Back
              </button>
              <> </>
              {actionsLoading ? (
                <button
                  type="button"
                  className="btn btn-primary btn-elevate"
                  disabled
                >
                  Saving <i className="fas fa-spinner fa-spin" />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
