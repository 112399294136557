import React from "react";
import {
    LiveChatConversationStat
} from "./LiveChatConversationStat";
export function LiveChatConversationMain() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <LiveChatConversationStat className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
