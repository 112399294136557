import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import * as actions from "../../../_redux/landingPageActions";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText, InputLabel, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import { Route, useHistory } from "react-router";
import { AddUpdateQuestionsDialog } from "../../../../QuestionManagement/pages/question-management/add-update-questions-dialog/AddUpdateQuestionsDialog";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "200px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

function AddQuestionRow({ submitHandler, items, setItems, defaultVal,id }) {
  const classes = useStyles();
  const [allQuestions, setAllQuestions] = useState([]);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getQuestionsByCategory()).then((success) => {
      if (success) {
        setAllQuestions(success.entities);
      }
    });
  }, []);

  const addRowBelow = (i) => {
    let newItems = [...items];
    newItems.splice(i + 1, 0, defaultVal);
    setItems(newItems);
  };
  const removeRow = (i) => {
    if (items.length > 1) {
      let newItems = [...items];
      newItems.splice(i, 1);
      setItems(newItems);
    } else {
      toast.error("*Can't remove, Atleast 1 Question required", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <div className="text-right">
        <Button
          id="add-question"
          color="primary"
          onClick={() => {
            if(id){
              history.push(`/landing-pages/edit/${id}/add-question`);
            }
            else{
              history.push("/landing-pages/add-new/question");
            }
          }}
        >
          Add New Question
        </Button>
      </div>
      <Table className="mt-5" bordered responsive>
        <tbody>
          <tr>
            <th>Category</th>
            <th>Question</th>
            <th>Answers</th>
            <th style={{ minWidth: "180px" }}>Actions</th>
          </tr>
          {items.map((it, i) => {
            return (
              <tr key={i} id={"row" + i}>
                <td>{i + 1}</td>
                <td>
                  <FormControl className={classes.margin}>
                    <InputLabel htmlFor={"question" + i + 1}></InputLabel>
                    <Select
                      id={"question" + i + 1}
                      value={it.question}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let selected = e.target.value;
                        let questions = {
                          question: selected.question,
                          q_id: selected.q_id,
                          multiselect: selected.multiselect,
                        };
                        let answers = selected.QACollections.map((ans) => {
                          return {
                            ...ans.Answer,
                            qualified: ans.qualified ? true : false,
                          };
                        });
                        let newItems = [...items];
                        newItems[i].question = questions;
                        newItems[i].answers = answers;
                        setItems([...newItems]);
                      }}
                      input={<BootstrapInput />}
                      renderValue={(selected) => {
                        return selected.question;
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Question
                      </MenuItem>
                      {allQuestions
                        .filter((q) => q.category === i + 1)
                        .map((question, j) => {
                          return (
                            <MenuItem value={question} key={j}>
                              {question.question}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>Select question</FormHelperText>
                  </FormControl>
                  <br />
                  {it.question.multiselect &&(<b>(Multiselect type)</b>)}
                </td>
                <td>
                  <ul>
                    {it.answers.map((ans, j) => {
                      return (
                        <li key={j}>
                          {" "}
                          {ans.answer} {(ans.qualified && it.question.multiselect == false) && "(Qualified)"}
                        </li>
                      );
                    })}
                  </ul>
                </td>
                <td>
                  {i + 1 === items.length && (
                    <>
                      <Button
                        id={"add-item" + i}
                        className="add-btn"
                        color="primary"
                        onClick={(e) => {
                          if (it.question.q_id) {
                            addRowBelow(i);
                          } else {
                            toast.error(
                              "Please Select a Question First to add more!",
                              { position: toast.POSITION.TOP_CENTER }
                            );
                          }
                        }}
                      >
                        + Add
                      </Button>

                      <Button
                        id={"remove-item" + i}
                        className="add-btn ml-2"
                        variant="danger"
                        onClick={(e) => removeRow(i)}
                      >
                        - Remove
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Route path="/landing-pages/add-new/question">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateQuestionsDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  dispatch(actions.getQuestionsByCategory()).then((success) => {
                    if (success) {
                      setAllQuestions(success.entities);
                    }
                  });
                  history.push("/landing-pages/add-new");
                }}
              />
            );
          }
        }}
      </Route>
      <Route path="/landing-pages/edit/:id/add-question">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateQuestionsDialog
                show={match != null}
                renderId={match && match.params.id}
                onHide={(id) => {
                  dispatch(actions.getQuestionsByCategory()).then((success) => {
                    if (success) {
                      setAllQuestions(success.entities);
                    }
                  });
                  history.push("/landing-pages/edit/"+id);
                }}
              />
            );
          }
        }}
      </Route>
    </>
  );
}

export default AddQuestionRow;
