import React from "react";
import { CPALoadingDialog } from "./cpa-loading-dialog/CPALoadingDialog";
import { CPAUIProvider } from "./CPAUIContext";
import { CPACards } from "./CPACards";
import { SaleLeadStatusDialog } from "./add-sale-lead-status-dialog/SaleLeadStatusDialog";
import { Route } from "react-router";

export function CPAPage( ) {
  return (
    <CPAUIProvider >
      <CPALoadingDialog />
      <Route path="/cpa/add-sale-status">
        {({ history, match }) => (
          <SaleLeadStatusDialog
            show={match != null}
            match={match}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cpa");
            }}
          />
        )}
      </Route>
      <CPACards />
    </CPAUIProvider>
  );
}
