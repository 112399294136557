import React from "react";
import { DomainsLoadingDialog } from "./domains-loading-dialog/DomainsLoadingDialog";
import { DomainsUIProvider } from "./DomainsUIContext";
import { DomainsCard } from "./DomainsCard";
import { Route } from "react-router-dom";
import { DomainEditDialog } from "./domain-edit-dialog/DomainEditDialog";
import { DomainDeleteDialog } from "./domain-delete-dialog/DomainDeleteDialog";

export function DomainsPage(props) {
  const domainsUIEvents = {
    
  }
  return (
    <DomainsUIProvider domainsUIEvents={domainsUIEvents}>
      <DomainsLoadingDialog />
      <Route path="/domains/edit/:id">
        {({ history, match }) => (
          <DomainEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/domains/");
            }}
          />
        )}
      </Route>
      <Route path="/domains/delete/:id">
        {({ history, match }) => (
          <DomainDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/domains/");
            }}
          />
        )}
      </Route>
      <DomainsCard {...props}/>
    </DomainsUIProvider>
  );
}
