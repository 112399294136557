import React from 'react'
import {ImportUIProvider} from './ImportUIContext'
import { ImportTableCard } from './ImportTableCard'
import { ImportFileUploadCard } from './ImportFileUploadCard'

export function ImportPage() {
  return (
    <ImportUIProvider>
      <ImportFileUploadCard />
      <ImportTableCard />
    </ImportUIProvider>
  );
}
