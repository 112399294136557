import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StatusOptionDialogHeader } from "./StatusOptionDialogHeader";
import { OptionsListTable } from "./OptionsListTable";
import * as actions from "../../../_redux/conversationActions";

export function StatusOptionDialog({ show, onHide, match }) {
  const [newOptionName, setNewOptionName] = useState("");

  // Getting curret state of Options list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.liveChatConversation }),
    shallowEqual
  );
  const {
    listLoading,
    options,
    actionsLoading,
  } = currentState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (match) {
      dispatch(actions.fetchOptionsListing());
    }
  }, [match]);

  const saveOption = (e) => {
    dispatch(
      actions.addNewOption({ name:newOptionName })
    );
    setNewOptionName("");
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={(e) => onHide()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <StatusOptionDialogHeader />
      <Modal.Body className="w-75">
        <Form>
          <Form.Group controlId="optionName" className="d-flex">
            <Form.Control type="text" placeholder="Enter Option Name" value={newOptionName}onChange={e=>setNewOptionName(e.target.value)}/>

          {actionsLoading ? (
            <Button variant="primary" className="ml-2" disabled>
              Loading <i className="fas fa-spinner fa-spin" />
            </Button>
          ) : (
            <Button
            className="ml-2"
              variant="primary"
              onClick={saveOption}
              disabled={newOptionName.length <= 0}
            >
              Add
            </Button>
          )}
          </Form.Group>

        </Form>

        <OptionsListTable
          listLoading={listLoading}
          options={options}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary ml-3" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
