import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function getAllDomainNames() {
  return axios.get(`${API_URL}/cpa/account`);
}



export function getLiveChatChartData(details) {
  let token = localStorage.getItem("token");
  if (details.domains && Number(sessionStorage.getItem("urlChange")) === 1) details.domains = '';
  let header = { params:details};
  return axios.get(`${API_URL}/live-chat-conversation/getStats`, header);
}