import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Button as BootButton } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as actions from "../../../_redux/landingPageActions";
import { ReactFormBuilder, Registry } from "react-form-builder2";
import {
  MyInput,
  registerInputs,
} from "../FormBuilderCustomList/FormBuilderCustomList";
import "react-form-builder2/dist/app.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import AddQuestionRow from "../commonForms/AddQuestionRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/styles";
import Axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { RenderPreviewInModal } from "../RenderPreviewInModal/RenderPreviewInModal";

const API_URL = process.env.REACT_APP_API_URL;

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

if (!Registry.get("First_Name")) {
  registerInputs();
}
export function UpdatePage({ match }) {
  const items = [
    {
      key: "First_Name",
      element: "CustomElement",
      component: MyInput,
      type: "custom",
      placeholder: "First Name",
      field_name: "firstName",
      name: "First Name",
      label: "First Name",
      props: {
        placeholder: "Enter First Name",
        name: "firstName",
        type: "text",
      },
    },
    {
      key: "Last_Name",
      element: "CustomElement",
      component: MyInput,
      type: "custom",
      props: { placeholder: "Enter Last Name", name: "lastName", type: "text" },
      field_name: "lastName",
      name: "Last Name",
      label: "Last Name",
      static: true,
    },
    {
      key: "Email",
      element: "CustomElement",
      component: MyInput,
      type: "custom",
      props: { placeholder: "Enter Email", name: "email", type: "email" },
      field_name: "email",
      name: "Email",
      label: "Email",
      static: true,
    },
    {
      key: "Phone",
      element: "CustomElement",
      component: MyInput,
      type: "custom",
      props: {
        placeholder: "Enter Phone Number",
        name: "phone",
        type: "number",
      },
      field_name: "phone",
      name: "Phone Number",
      label: "Phone Number",
      static: true,
    },
    { key: "TwoColumnRow" },
  ];

  const LandingPageSchema = Yup.object().shape({
    name: Yup.string().required(),
    good_render: Yup.string().required(),
    bad_render: Yup.string().required(),
  });

  const defaultVal = {
    question: {},
    answers: [],
  };

  const history = useHistory();
  const [formJson, setFormJSON] = useState([]);
  const [loading, setLoading] = useState(true);
  const [questionItems, setQuestionItems] = useState([defaultVal]);
  const [cssTemplates, setCssTemplates] = useState([]);
  const [preview, setPreview] = useState(false);

  const dispatch = useDispatch();
  let initialValues = {
    name: "",
    title: "",
    sub_title: "",
    submit_name: "",
    site_logo: "",
    good_render: "",
    bad_render: "",
    tracking_code: "",
    instantRenderBadURL: false,
    renderThankYou: false,
    renderReviews:false,
    accountNo: "",
    cssTemplatePath: {},
  };

  // Getting curret state of LP list from store (Redux)
  const { currentState,permissions,userType } = useSelector(
    (state) => ({ currentState: state.landingPages,
      permissions: state.auth.user.permissions,
      userType: state.auth.user.userType, }),
    shallowEqual
  );
  const { allLeadSources } = currentState;


  useEffect(() => {

    // ------------check update permission when directly hit from url---------------
    if (
      !["Admin", "admin"].includes(userType) &&
      (permissions["landing_pages"] && !permissions["landing_pages"].includes("update"))
    ) {
      console.log("Sdfdsfds");
      history.push("/landing-pages/forms");
    }

    let id = match.params.id;
    dispatch(actions.fetchAllLeadSources());

    dispatch(actions.getLandingPage(id)).then((data) => {
      if (data) {
        initialValues.name = data.entities.name;
        initialValues.title = data.entities.title;
        initialValues.sub_title = data.entities.sub_title;
        initialValues.submit_name = data.entities.submit_name;
        initialValues.site_logo = data.entities.site_logo;
        initialValues.good_render = data.entities.good_render;
        initialValues.bad_render = data.entities.bad_render;
        initialValues.tracking_code = data.entities.tracking_code;
        initialValues.instantRenderBadURL = data.entities.instantRenderBadURL;
        initialValues.accountNo = data.entities.account_no;
        initialValues.renderThankYou = data.entities.renderThankYou;
        initialValues.renderReviews = data.entities.renderReviews;
        
        Axios.get(`${API_URL}/landing-pages/get-all-templates`).then((css) => {
          let dataTemp = css.data;
          if (dataTemp) {
            setCssTemplates(dataTemp.data);
            let found = dataTemp.data.find(
              (e) => e.filePath == data.entities.cssTemplatePath
            );
            initialValues.cssTemplatePath = found ? found : {};
          }
        });
        setFormJSON(data.entities.form_builder_json);
        setQuestionItems(
          data.entities.questions.length > 0
            ? data.entities.questions
            : [defaultVal]
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  }, []);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  

  const formik = useFormik({
    initialValues,
    validationSchema: LandingPageSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      if (formJson.length === 0) {
        toast.error("*Please add Some thing in Form Builder.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let id = match.params.id;
        dispatch(
          actions.updateLandingPage({
            ...values,
            cssTemplatePath: values.cssTemplatePath.filePath
              ? values.cssTemplatePath.filePath
              : "",
            id,
            form_builder_json: formJson,
            questions: questionItems,
          })
        ).then((success) => {
          if (success) {
            history.push("/landing-pages/forms");
          }
        });
      }
    },
  });

  return (
    <Card>
      <CardHeader title="Update Page">
        <CardHeaderToolbar>
          {/* {(userType.toLowerCase() === "admin" ||
            permissions["domain_manage"].includes("create")) && ( */}
          <Button
            id="add-domain"
            variant="contained"
            color="secondary"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
          {/* )} */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100px" }}
          >
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ) : (
          <>
            <div className="text-right">
              <BootButton
                variant="warning"
                onClick={(e) => {
                  history.push("/landing-pages/forms/add-new", {
                    values: { ...formik.values },
                    formJson: JSON.stringify(formJson),
                    questionItems: questionItems,
                  });
                }}
              >
                Copy Content in New Page
              </BootButton>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Site Name:</label>
                      <input
                        placeholder="Site Name"
                        id="name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "name"
                        )}`}
                        {...formik.getFieldProps("name")}
                        name="name"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">*required field</div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Account Number:</label>
                      <FormControl className="w-100">
                        <Select
                          id="lead/account"
                          value={formik.values.accountNo}
                          onChange={(e) => {
                            formik.setFieldValue("accountNo", e.target.value);
                          }}
                          input={<BootstrapInput i />}
                        >
                          <MenuItem value="" disabled>
                            Select Account Number
                          </MenuItem>
                          {allLeadSources.map((m, i) => {
                            return (
                              <MenuItem value={m} key={i}>
                                {m}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {formik.touched.accountNo && formik.errors.accountNo ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">*required field</div>
                          </div>
                        ) : null}
                      </FormControl>
                    </div>
                    {/* <div className="form-group ">
                      <label className="mb-0">Account Number:</label>
                      <input
                        placeholder="Account Number"
                        id="accountNo"
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "accountNo"
                        )}`}
                        {...formik.getFieldProps("accountNo")}
                        name="accountNo"
                      />
                      {formik.touched.accountNo && formik.errors.accountNo ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">*required field</div>
                        </div>
                      ) : null}
                    </div> */}
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Title:</label>
                      <input
                        placeholder="Title"
                        id="title"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        {...formik.getFieldProps("title")}
                        name="title"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Sub Title:</label>
                      <input
                        placeholder="Sub Title"
                        id="sub_title"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        {...formik.getFieldProps("sub_title")}
                        name="sub_title"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Submit Button Name:</label>
                      <input
                        placeholder="Submit Button Name"
                        id="submit_name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        {...formik.getFieldProps("submit_name")}
                        name="submit_name"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Site Logo:</label>
                      <input
                        placeholder="Site Logo"
                        id="site_logo"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        {...formik.getFieldProps("site_logo")}
                        name="site_logo"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Good Render Url:</label>
                      <input
                        placeholder="Good Render Url"
                        id="good_render"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "good_render"
                        )}`}
                        {...formik.getFieldProps("good_render")}
                        name="good_render"
                      />
                      {formik.touched.good_render &&
                      formik.errors.good_render ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">*required field</div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Bad Render Url:</label>
                      <input
                        placeholder="Bad Render Url:"
                        id="bad_render"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "bad_render"
                        )}`}
                        {...formik.getFieldProps("bad_render")}
                        name="bad_render"
                      />
                      {formik.touched.bad_render && formik.errors.bad_render ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">*required field</div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group ">
                      <label className="mb-0">Tracking Code:</label>
                      <textarea
                        placeholder="Tracking Code"
                        id="tracking_code"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        {...formik.getFieldProps("tracking_code")}
                        name="tracking_code"
                        rows="4"
                        style={{ resize: "none" }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <FormControlLabel
                      className="mb-4"
                      control={
                        <Switch
                          checked={formik.values.renderThankYou}
                          name="renderThankYou"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "renderThankYou",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Render Thank You First"
                    />
                     <FormControlLabel
                    className="mb-4"
                    control={
                      <Switch
                        checked={formik.values.renderReviews}
                        name="renderReviews"
                        onChange={(e) => {
                          formik.setFieldValue(
                            "renderReviews",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label="Render Reviews"
                  />
                    <FormControlLabel
                      className="mb-4"
                      control={
                        <Switch
                          checked={formik.values.instantRenderBadURL}
                          name="instantRenderBadURL"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "instantRenderBadURL",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Render Bad Url on Invalid Answer Click"
                    />
                  </Col>
                  <Col md="6">
                    <FormControl className="w-100">
                      <InputLabel htmlFor="css-template"></InputLabel>
                      <div className="d-flex">
                        <Select
                          id="css-template"
                          className="w-75 align-items-center"
                          value={formik.values.cssTemplatePath}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "cssTemplatePath",
                              e.target.value
                            );
                          }}
                          input={<BootstrapInput i />}
                          renderValue={(selected) => {
                            if (!selected.name) {
                              return "";
                            } else {
                              return selected.name;
                            }
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select CSS Template
                          </MenuItem>
                          {cssTemplates.map((it, i) => {
                            return (
                              <MenuItem value={it} key={i}>
                                {it.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {formik.values.cssTemplatePath.id && (
                          <>
                            <Tooltip title="Preview of selected">
                              <IconButton
                                color="secondary"
                                aria-label="Preview of selected"
                                component="span"
                                onClick={(e) => {
                                  setPreview(true);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <RenderPreviewInModal
                              show={preview}
                              url={
                                API_URL +
                                "/landing-pages/preview/" +
                                formik.values.cssTemplatePath.id
                              }
                              onHide={() => {
                                setPreview(false);
                              }}
                            />
                          </>
                        )}
                      </div>
                      <FormHelperText>Select CSS Template</FormHelperText>
                    </FormControl>
                  </Col>
                </Row>
              </div>

              <div style={{ minHeight: "200px", marginBottom: "100px" }}>
                <div className="text-center mb-0">
                  <h3>Questions</h3>
                </div>
                <hr />
                {/* <div className="text-right">
                <Button
                  id="add-question"
                  variant="contained"
                  color="secondary"
                  className="mb-2"
                  onClick={() => {
                    // history.goBack();
                  }}
                >
                  Add New Question
                </Button>
              </div> */}

                <AddQuestionRow
                  defaultVal={defaultVal}
                  items={questionItems}
                  setItems={setQuestionItems}
                  id={match.params.id}
                />
              </div>
              <div className="text-center mb-0">
                <h3>Details Form</h3>
              </div>
              <hr />
              <div>
                <ReactFormBuilder
                  data={formJson}
                  onPost={(e) => {
                    setFormJSON(e.task_data);
                  }}
                  toolbarItems={items}
                />
              </div>
              <div className="mt-3 text-center">
                <Button variant="contained" color="secondary" type="submit">
                  Save
                </Button>
              </div>
            </form>
          </>
        )}
      </CardBody>
    </Card>
  );
}
