import * as moment from "moment";

import momentTZ from "moment-timezone";
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");
export const defaultSorted = [{ dataField: "id", order: "desc" }];

export const sizePerPageList = [{ text: "25", value: 25 }];
export const initialFilter = {
  startDate:
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00",
  endDate:
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59",
  filter: {
    search: "",
    type: "all",
  },
  sortOrder: "desc", // asc||desc
  sortBy: "id",
  from: 1,
  rows: 25,
};
