import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ChartComponent } from "./ChartComponent";
import { BarChartFilter } from "./BarChartFilter";
import { shallowEqual, useSelector } from "react-redux";

export function BarChartsPerMinuteCard({ userInfo }) {
  // const { permissions, userType } = userInfo;

  const history = useHistory();
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.domainAnalysis,
    }),
    shallowEqual
  );
  const { chartData, listLoading } = currentState;
  return (
    <Card>
      <CardHeader title="Domain Analysis (Bar Charts)">
        <CardHeaderToolbar>
          {/* {(userType.toLowerCase() === "admin" || permissions["domain_manage"].includes("create")) && ( */}
          {/* )} */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <BarChartFilter />
        {listLoading ? (
          <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ) : (
          <ChartComponent
            type="bar"
            id={"bar_charts_domain_Analysis"}
            labels={chartData.labels}
            values={chartData.series}
          />
        )}
      </CardBody>
    </Card>
  );
}
