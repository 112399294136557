import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./DebtAnalysisHelper";
import { shallowEqual, useSelector } from "react-redux";

const DebtAnalysisUIContext = createContext();

export function useDebtAnalysisUIContext() {
  return useContext(DebtAnalysisUIContext);
}

export const DebtAnalysisUIConsumer = DebtAnalysisUIContext.Consumer;

export function DebtAnalysisUIProvider({ customersUIEvents, children }) {
  const { defaultDebtAnalysis } = useSelector(
    (state) => ({
      defaultDebtAnalysis: state.timezoneBy,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    filterByDomainTZ: defaultDebtAnalysis.timezone,
    domain: defaultDebtAnalysis.selectedDomain.join(","),
  });
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return (
    <DebtAnalysisUIContext.Provider value={value}>
      {children}
    </DebtAnalysisUIContext.Provider>
  );
}
