// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/staffManagementActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../StaffManagementUIHelpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useStaffManagementUIContext } from "../StaffManagementUIContext";
import * as columnFormatters from "./column-formatters";
import * as moment from "moment";
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")

export function StaffUsersTable({ userInfo }) {
  const { permissions, userType } = userInfo;

  // User UI Context
  const staffUsersUIContext = useStaffManagementUIContext();
  const staffUsersUIProps = useMemo(() => {
    return {
      ids: staffUsersUIContext.ids,
      setIds: staffUsersUIContext.setIds,
      queryParams: staffUsersUIContext.queryParams,
      setQueryParams: staffUsersUIContext.setQueryParams,
    };
  }, [staffUsersUIContext]);
  // Getting curret state of Users list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.staffManagement }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  // USer Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    staffUsersUIProps.setIds([]);

    // server call by queryParams
    dispatch(actions.fetchAllUsers(staffUsersUIProps.queryParams));
  }, [staffUsersUIProps.queryParams, dispatch]);

  const [expanded, setExpanded] = React.useState([]);

  const events = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      const isRowExpanded = expanded.includes(row.id);
      if (isRowExpanded) {
        setExpanded(expanded.filter((x) => x !== row.id));
      } else {
        setExpanded([...expanded, row.id]);
      }
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Full Name",
      sort: true,
      formatter: columnFormatters.FullNameColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "email",
      text: "User Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "role.name",
      text: "User Role",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return cellContent ? cellContent:"NA"
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "createdAt",
      text: "Date and Time",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return moment
          .utc(cellContent)
          .utcOffset(offSet)
          .format("YYYY-MM-DD hh:mm:ss");
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["staff_manage"].includes("edit")
          ? false
          : true,
      formatter: columnFormatters.EditColumnFormatter,
    },
    {
      dataField: "delete",
      text: "Delete",
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["staff_manage"].includes("delete")
          ? false
          : true,
      formatter: columnFormatters.DeleteColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: staffUsersUIProps.queryParams.pageSize,
    page: staffUsersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  staffUsersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
