export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 }
];
export const initialFilter = {
  sortOrder: "desc", // asc||desc
  sortBy: "q_id",
  filterBy:"All",
  from: 1,
  rows: 25
};
