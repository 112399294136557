import DashboardIcon from "@material-ui/icons/Dashboard";
import DnsIcon from "@material-ui/icons/Dns";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AirplayIcon from "@material-ui/icons/Airplay";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BackupIcon from "@material-ui/icons/Backup";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ChatIcon from "@material-ui/icons/Chat";
import PieChartIcon from "@material-ui/icons/PieChart";
import BarChartIcon from "@material-ui/icons/BarChart";
import TableChartIcon from "@material-ui/icons/TableChart";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import ListIcon from "@material-ui/icons/List";
var routes = [
  {
    to: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    accessCode: "dashboard",
  },
  {
    to: "/domains",
    name: "Domain Management",
    icon: DnsIcon,
    accessCode: "domain_manage",
  },
  {
    to: "/contacts",
    name: "Contacts",
    icon: PeopleAltIcon,
    accessCode: "contacts",
  },
  {
    submenu: true,
    name: "Leads",
    accessCode: [
      "lead_status_analysis",
      "lead_compare_analysis",
      "lead_analysis",
    ],
    icon: AirplayIcon,
    to: "/leads",
    subComponents: [
      {
        to: "/leads/analysis",
        name: "Lead Analysis",
        icon: TableChartIcon,
        accessCode: "lead_analysis",
      },
      {
        to: "/leads/status-analysis",
        name: "Lead Status Analysis",
        icon: PieChartIcon,
        accessCode: "lead_status_analysis",
      },
      {
        to: "/leads/compare-analysis",
        name: "Lead Compare Analysis",
        icon: BarChartIcon,
        accessCode: "lead_compare_analysis",
      },
    ],
  },
  {
    submenu: true,
    name: "Live Chats",
    accessCode: ["live_chat", "conversation_manage"],
    icon: ChatIcon,
    to: "/live-chat",
    subComponents: [
      {
        to: "/live-chat/analysis",
        name: "Live Chat Analysis",
        icon: MultilineChartIcon,
        accessCode: "live_chat",
      },
      {
        to: "/live-chat/conversation",
        name: "LC conversation Analysis",
        icon: BarChartIcon,
        accessCode: "conversation_manage",
      },
    ],
  },
  {
    to: "/funnels-performance",
    name: "Funnel Performance ",
    icon: TrendingUpIcon,
    accessCode: "cpv_track_analysis",
  },
  {
    to: "/questions-analysis",
    name: "Question Analysis",
    icon: QuestionAnswerIcon,
    accessCode: "question_analysis",
  },
  {
    to: "/domain-analysis",
    name: "Domain's Analysis",
    icon: DnsIcon,
    accessCode: "domain_analysis",
  },
  {
    to: "/cpv-tracking",
    name: "CPV Tracking Analysis",
    icon: TrendingUpIcon,
    accessCode: "cpv_track_analysis",
  },
  {
    to: "/debt-analysis",
    name: "Debt Analysis",
    icon: TrendingUpIcon,
    accessCode: "cpv_track_analysis",
  },
  {
    to: "/trends",
    name: "Trends",
    icon: AccessTimeIcon,
    accessCode: "trends",
  },
  {
    to: "/import",
    name: "Import",
    icon: BackupIcon,
    accessCode: "imports",
  },
  {
    to: "/admin-logs",
    name: "Admin Logs",
    icon: AirplayIcon,
    accessCode: "admin_logs",
  },
  {
    to: "/staff-management",
    name: "Staff Management",
    icon: GroupAddIcon,
    accessCode: "staff_manage",
  },
  {
    to: "/ads-spent",
    name: "AD Spent",
    icon: LocalAtmIcon,
    accessCode: "ad_spent",
  },
  {
    to: "/cpa",
    name: "Cost Per Acquisition",
    icon: AttachMoneyIcon,
    accessCode: "cpa",
  },
  {
    submenu: true,
    name: "Landing Pages",
    accessCode: ["landing_pages", "templates"],
    icon: ListIcon,
    to: "/landing-pages",
    subComponents: [
      {
        to: "/landing-pages/forms",
        name: "Forms",
        icon: ListIcon,
        accessCode: "landing_pages",
      },
      {
        to: "/landing-pages/templates",
        name: "CSS Templates",
        icon: ListIcon,
        accessCode: "templates",
      },
    ],
  },
  {
    to: "/question-management",
    name: "Question Management",
    icon: ListIcon,
    accessCode: "question_manage",
  },
  {
    to: "/db-backup",
    name: "DB Backup",
    icon: BackupIcon,
    accessCode: "admin_only",
  },
];

export default routes;
