// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { number } from "prop-types";
export function ChartComponent(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }
    const options = getAllCRChartOptions(props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "200px" }}
      ></div>
    </>
  );
}
function getAllCRChartOptions(props) {
//   let options = {
//     series: props.series,
//     chart: {
//       height: "400",
//       type: 'line',
//       stacked: true,
//       zoom: {
//         enabled: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         columnWidth:(props.series.length && props.series[0].data.length) > 4 ? "40%":"20%",
//       }
// },
//     stroke: {
//       width: [4, 2],
//       curve: 'smooth'
//     },
//     fill: {
//       opacity: [0.85, 0.25, 1],
//       gradient: {
//         inverseColors: false,
//         shade: 'light',
//         type: "vertical",
//         opacityFrom: 0.85,
//         opacityTo: 0.55,
//         stops: [0, 100, 100, 100]
//       }
//     },
//     labels: props.labels,
//     markers: {
//       size: 0
//     },
//     yaxis: {
//       min: 0,
//       tickAmount: 10
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       y: {
//         formatter: function (y) {
//           if (typeof y !== "undefined") {
//             return parseInt(y);
//           }
//           return y;
    
//         }
//       }
//     }
//   };
//   return options;
let options = {
  series: props.series,
  chart: {
    height: 500,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  colors: ["#003399", "#f64e60"],
  stroke: {
    width: props.labels.length >1 ?[3, 3]:[15,15],
    dashArray: [0, 2],
  },
  dataLabels: {
    enabledOnSeries:[0],
    formatter: function(val, { seriesIndex, dataPointIndex, w }) {
      let total = w.config.series[0]["data"][dataPointIndex];
      return isFinite(total) ? parseInt(total)  + "%" :"0%";
    },
    style: {
      fontSize: "10.5px",
      colors: ["#000"],
    },
    orientation: "horizontal",
    enabled: true,
    textAnchor: "middle",
    distributed: false,
    background: {
      enabled: true,
      foreColor: "#fff",
      padding: 2,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#fff",
      opacity: 0.6,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: "#000",
      opacity: 0.45,
    },
  },
  xaxis: {
    categories: props.labels,

    labels: {
      style: {
        cssClass: "live-chat-x-axis",
        fontSize: "8px",
      },
      // formatter: function(val) {
      //   let number = val ? parseInt(val.split(" ")[0]):null;
      //   let skip = false
      //   if(number){
      //     skip = number % 2 !== 0;
      //   }

      //   // let total = parseInt(w.config.series[0]["data"][])+ parseInt(w.config.series[1]["data"][dataPointIndex]);
      //   return skip ? "" : val;
      // },
      hideOverlappingLabels: false,
    },
  },
  yaxis: [
    {
      max: 100,
      min: 0,
      tickAmount: 5,
      forceNiceScale: false,
      title: {
        text: "Percentage",
      },
      labels: {
        formatter: function(value) {
          return value + "%";
        },
      },
    },
    {
      opposite: true,
      tickAmount: 5,
      min: 0,
      title: {
        text: "Total Valid Leads",
      },
      labels: {
        formatter: function(value) {
          return parseInt(value);
        },
      },
    },
  ],
};
return options;
}



