import * as requestFromServer from "./staffManagementCrud";
import { staffManagementListSlice, callTypes } from "./staffManagementSlice";

const { actions } = staffManagementListSlice;

export const fetchRolePermissions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPermissions(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.permissionsFetched({ permissions: [] }));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.permissionsFetched({ permissions: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find permissions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllRole = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllRoles(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.rolesFetched({ roles: [] }));
      } else if (response && response.data && response.data.respType) {
        dispatch(actions.rolesFetched({ roles: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find permissions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addNewRole = (roleDetails) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRole(roleDetails)
    .then((response) => {
      const roledetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.roleCreated({ roledetails }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRole = (role) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRole(role)
    .then((response) => {
      const roledetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.roleUpdate(roledetails));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRole = (role) => (dispatch) => {
  if (!role) {
    return dispatch(actions.roleFetched({ roleForEdit: undefined }));
  }

  dispatch(actions.roleFetched({ roleForEdit: role.roleForEdit }));
};

export const saveStaffUser = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .saveStaffUser(user)
    .then((response) => {
      const userdetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.userCreated({ userdetails }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAllUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllUsers(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.usersFetched({ totalCount: 0, users: [] }));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.usersFetched({
            totalCount: response.data.data.count,
            users: response.data.data.allUsers,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find permissions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUser(id)
    .then((response) => {
      dispatch(actions.userDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateUser = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(user)
    .then((response) => {
      const userDetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.userUpdate(userDetails));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
