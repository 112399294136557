import { makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  blue: {
    color: "blue",
    backgroundColor: "darkgrey",
  },
  greyBack: {
    backgroundColor: "lightgrey",
    fontWeight: 800,
  },
  table: {
    marginBottom: "4em",
    cursor: "pointer",
  },
  lightGreen: {
    backgroundColor: "lightgreen",
    fontWeight: 800,
  },
}));

export function LiveChatConversationNumbersView({ data, labels }) {
  const classes = useStyles();
  const [rowHighlightData, setRowHighlightData] = useState({
    row: null,
    col: null,
    domain: null,
  });
  return (
    <>
      {data.map((numberData, i) => {
        return (
          <div>
            <div className="text-center">
              <div
                className="pb-4 text-center"
                style={{ borderBottom: "1px solid #e2e2e2" }}
              >
                <a
                  href="#"
                  className="text-primary font-weight-bold font-size-h4"
                >
                  {numberData.domain_name}
                </a>
              </div>
            </div>
            <Table className={classes.table} key={i} bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th
                    className={classnames({
                      [classes.greyBack]: true,
                    })}
                  >
                    Total
                  </th>
                  <th
                    className={classnames({
                      [classes.lightGreen]: true,
                    })}
                  >
                    CR(%)
                  </th>
                  {labels.map((label, k) => {
                    return (
                      <th
                        key={k + 1}
                        className={
                          rowHighlightData.col === k + 2 &&
                          i === rowHighlightData.domain &&
                          classes.blue
                        }
                      >
                        {label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(numberData).map((key, nm) => {
                  return (
                    key !== "domain_name" && (
                      <tr
                        className={
                          rowHighlightData.row === nm &&
                          i === rowHighlightData.domain &&
                          classes.blue
                        }
                      >
                        <th key={nm}> {key}</th>
                        {numberData[key].map((td, ind) => {
                          return (
                            <td
                              key={ind}
                              className={classnames({
                                [classes.greyBack]: ind === 0,
                                [classes.lightGreen]: ind === 1,
                                [classes.blue]:
                                  rowHighlightData.col === ind &&
                                  i === rowHighlightData.domain,
                              })}
                              onClick={(e) => {
                                setRowHighlightData({
                                  row: nm,
                                  col: ind,
                                  domain: i,
                                });
                              }}
                            >
                              {td}
                              {ind === 1 &&
                                nm !== 0 &&
                                nm !== Object.keys(numberData).length - 1 &&
                                "%"}
                              {key === "Percentage" && ind !== 1 && "%"}
                            </td>
                          );
                        })}
                      </tr>
                    )
                  );
                })}
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
}
