// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/conversationActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../ConversationUIHelpers";
import { useConversationUIContext } from "../ConversationUIContext";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";

export function ConversationDataTable({ userInfo }) {
  const { permissions, userType } = userInfo;
 // conversation UI Context
 const conversationUIContext = useConversationUIContext();
 const conversationUIProps = useMemo(() => {
   return {
     queryParams: conversationUIContext.queryParams,
     setQueryParams: conversationUIContext.setQueryParams,
   };
 }, [conversationUIContext]);

  // Getting curret state of data list from store (Redux)
  const { currentState} = useSelector(
    (state) => ({ currentState: state.liveChatConversation }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  // Data Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchConversationTextListing(conversationUIProps.queryParams));
  }, [conversationUIProps.queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "text",
      text: "Text",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "edit",
      text: "Edit",
      formatter: columnFormatters.EditColumnFormatter,
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: columnFormatters.DeleteColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: conversationUIProps.queryParams.pageSize,
    page: conversationUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  conversationUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
