import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {DashboardPage} from "./pages/DashboardPage";
import {ContactsPage} from "./pages/ContactsPage";
import {QuestionAnalysisPage} from './pages/QuestionsAnalysis';
import {DomainsAnalysisPage} from './pages/DomainsAnalysis';
import {DomainsAnalysisBarCharts} from './pages/DomainsAnalysisBarCharts';
import {CpvTrackingPage} from './pages/CpvTracking';
import {ImportPage} from './pages/ImportPage';
import {DomainsPage} from './pages/DomainsPage';
import {LeadAnalysisPage} from './pages/LeadAnalysis';
import {LeadStatusAnalysisPage} from './pages/LeadStatusAnalysis';
import {LeadCompareAnalysisPage} from './pages/LeadCompareAnalysis';
import {TrendAnalysisPage} from './pages/Trends';
import {AdminLogsPage} from './pages/AdminLogsPage';
import {StaffManagementPage} from './pages/StaffManagementPage';
import {RolesPage} from './pages/RolesPage';
import {AdsSpentPage} from './pages/AdsSpentPage';
import {CostPerAcquisitionPage} from './pages/CostPerAcquisitionPage';
import {LiveChatAnalysisPage} from './pages/LiveChatAnalysisPage';
import { LiveChatConversationPage } from "./pages/LiveChatConversationPage";
import { LiveChatConversationAnalysisPage } from "./pages/LiveChatConversationAnalysisPage";
import { LandingPagesManagementPage } from "./pages/LandingPagesManagementPage";
import { AddNewPage } from "./modules/LandingPagesManagement/pages/landingPages/AddNewPage/AddNewPage";
import { UpdatePage } from "./modules/LandingPagesManagement/pages/landingPages/UpdatePage/UpdatePage";
import { QuestionManagementPage } from "./pages/QuestionManagementPage";
import { LandingPageCSSTemplatesPage } from "./pages/LandingPageCSSTemplatesPage";
import { FunnelPerformancePage } from "./pages/FunnelPerformancePage";
import { DBBackupListingPage } from "./pages/DBBackupListingPage";
import { DebtAnalysisPage } from "./pages/DebtAnalysisPage";

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
      <ToastContainer />

            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/contacts" component={ContactsPage}/>
                <ContentRoute path="/questions-analysis" component={QuestionAnalysisPage}/>
                <ContentRoute path="/domain-analysis" exact={true} component={DomainsAnalysisPage}/>
                <ContentRoute path="/domain-analysis/bar-charts" component={DomainsAnalysisBarCharts}/>
                <ContentRoute path="/cpv-tracking" component={CpvTrackingPage}/>
                <ContentRoute path="/import" component={ImportPage}/>
                <ContentRoute path="/domains" component={DomainsPage}/>
                <ContentRoute path="/leads/analysis" component={LeadAnalysisPage}/>
                <ContentRoute path="/leads/status-analysis" component={LeadStatusAnalysisPage}/>
                <ContentRoute path="/leads/compare-analysis" component={LeadCompareAnalysisPage}/>
                <ContentRoute path="/trends" component={TrendAnalysisPage}/>
                <ContentRoute path="/admin-logs" component={AdminLogsPage}/>
                <ContentRoute path="/staff-management" component={StaffManagementPage}/>
                <ContentRoute path="/roles" component={RolesPage}/>
                <ContentRoute path="/ads-spent" component={AdsSpentPage}/>
                <ContentRoute path="/cpa" component={CostPerAcquisitionPage}/>
                <ContentRoute path="/live-chat/analysis" component={LiveChatAnalysisPage}/>
                <ContentRoute path="/live-chat/conversation" exact = {true} component={LiveChatConversationAnalysisPage}/>
                <ContentRoute path="/live-chat/conversation/text-management" component={LiveChatConversationPage}/>
                <ContentRoute path="/debt-analysis" component={DebtAnalysisPage}/>

                {/* Landing page Routes */}
                <ContentRoute path="/landing-pages/forms" exact={true} component={LandingPagesManagementPage}/>
                <ContentRoute path="/landing-pages/forms/add-new" component={AddNewPage}/>
                <ContentRoute path="/landing-pages/forms/edit/:id" component={UpdatePage}/>
                <ContentRoute path="/question-management" component={QuestionManagementPage}/>
                <ContentRoute path="/landing-pages/templates" component={LandingPageCSSTemplatesPage}/>
                <ContentRoute path="/funnels-performance" component={FunnelPerformancePage}/>
                <ContentRoute path="/db-backup" component={DBBackupListingPage}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
