import * as requestFromServer from "./leadAnalysisCrud";
import {leadAnalysisSlice, callTypes} from "./leadAnalysisSlice";

const {actions} = leadAnalysisSlice;

export const removeLeadAnalysisState = () => dispatch => {
  dispatch(actions.leadAnalysisFetched({ totalCount: 0, entities: []}));
  // dispatch(actions.columnsReceived({ columns: [], defaultColumns: []}));
};

export const fetchLeadAnalysis = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLeadAnalysis(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.leadAnalysisFetched({ totalCount: 0, entities: []}));
      }else if ( response && response.respType === 'Failure' ){
        dispatch(actions.leadAnalysisFetched({ totalCount: 0, entities: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.leadAnalysisFetched({ totalCount: 0, entities: response.data.data}));
      }
    })
    .catch(error => {
      console.log(error);
      error.clientMessage = "Can't find leadAnalysis";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomainsList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainList()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.domainsList({ domainList: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.domainsList({domainList: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignList = (domainList) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignList(domainList)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.campaignList({ campaignList: null}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.campaignList({campaignList: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchColumns = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getColumns()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.columnsReceived({ columns: null, defaultColumns: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.columnsReceived({columns: response.data.data.columns, defaultColumns: response.data.data.columns}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateColumns = (selectedColumns) => dispatch => {
  dispatch(actions.updateColumns(selectedColumns));
}