// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  AWSFileDownloadRequest,
  _S3,
} from "../../../../../../../_metronic/_helpers/AWSFileUpload";
import axios from "axios";
import { toast } from "react-toastify";
import {saveAs} from "file-saver";
const API_URL = process.env.REACT_APP_API_URL;


export function DownloadColumnFormatter(cellContent, row, rowIndex) {
  const downloadDump =  (e) => {
    axios
      .post(`${API_URL}/admin-logs/save-db-log`)
      .then(async (res) => {
        let Url = await AWSFileDownloadRequest({
          downloadUrl: cellContent,
          fileName: row.name,
        });
        saveAs(Url,row.name)
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Link
        id={"download" + row.id}
        title="Download index file"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
        onClick={downloadDump}
      >
        <GetAppIcon />
      </Link>
    </>
  );
}
