import * as moment from 'moment';
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")
export const CustomerStatusCssClasses = ["danger", "success", "info", ""];
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 200 },
];
export const initialFilter = {
  startDate:moment.utc().utcOffset(offSet).format('YYYY-MM-DD') + ' 00:00:00',
  endDate:moment.utc().utcOffset(offSet).format('YYYY-MM-DD') + ' 23:59:59',
  filter: {
    firstname:"",
    lastname:"",
    email: "",
    ipAddress: "",
    email_valid: "",
    phone: "",
    domain: "",
    lead_type: "",
  },
  sortOrder: "asc", // asc||desc
  sortBy: "id",
  from: 1,
  rows: 25,
};
