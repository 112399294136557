import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Alert, AlertTitle } from '@material-ui/lab';
import * as actions from "../../_redux/importActions"
import { ImportCardSpinner } from '../import-card-spinner/ImportCardSpinner'
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { ProgressBar, Button, Spinner } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const IMPORT_STATUS = "INQUEUE";

const DragHandle = sortableHandle(() => <div className="Showcase__style__handle">::</div>);
const SortableItem = sortableElement(({value}) => (
  <div className="Showcase__style__stylizedItem">
    <DragHandle />
    {value}
  </div>
));
const SortableContainer = sortableContainer(({children}) => {
  return <div className="">{children}</div>;
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function ImportFileUpload() {

  const [base64Data, imgUpload] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileHeaders, setFileHeaders] = useState("");
  const [importKeywords, setImportKeywords] = useState([
  // {
  //   systemHeader:'Phone',
  //   systemHeaderKey: 'phone',
  //   fileHeader:''
  // },
    {
    systemHeader:'Subid',
    systemHeaderKey: 'sid',
    fileHeader:''
  },
  {
    systemHeader:'Lead Status',
    systemHeaderKey: 'lead_status',
    fileHeader:''
  },
  // {
  //   systemHeader:'Email',
  //   systemHeaderKey: 'email',
  //   fileHeader:''
  // }
  ]);
  const inputEl = useRef(React.createRef());
  const dispatch = useDispatch();

  const classes = useStyles();
  const [timer, setTimer] = useState(0);
  const [isValid, setIsValid] = useState({flag: true, msg: ''});
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const getBase64 = (e) => {
    var file = e.target.files[0];

    readHeaders(file);

    imgUpload(file);
    setFileName(file.name);
  }

  const uploadFileToAWS = () => {
    const systemHeadersSelection = [], fileHeadersSelection = [];
    importKeywords.forEach(word => {
      if ( word.fileHeader === '' ){
        setIsValid({flag:false, msg: `Please Select Header for ${word.systemHeader}`});
      }else {
        systemHeadersSelection.push(word.systemHeaderKey);
        fileHeadersSelection.push(word.fileHeader);
      }
    });
    if ( base64Data === '' || fileName === ''){
      setIsValid({flag:false, msg: `Please select file`});
    }else if(fileHeadersSelection.length === importKeywords.length) {
      const headerMapObject = {
        'systemHeaders': systemHeadersSelection,
        'fileHeaders': fileHeadersSelection
      };
      dispatch(actions.startImport(base64Data, fileName, headerMapObject, fileUploadProgressHandler));
    }
  }

  const readHeaders = (file) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);

    let headers = [];
    reader.onloadend = function (evt) {
      var data = evt.target.result;
      var byteLength = data.byteLength;
      var ui8a = new Uint8Array(data, 0);

      var headerString = '';

      for (var i = 0; i < byteLength; i++) {
        var char = String.fromCharCode(ui8a[i]);

        if (char.match(/[^\r\n]+/g) !== null) {
          headerString += char;
        } else {
          break;
        }
      }

      // Split our header string into an array
      headers = headerString.split(',');
      headers = headers.map(header => {
        return header.replace(/["']/g, "")
      })
      setFileHeaders(headers);
    };
  }

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.import }),
    shallowEqual
  );

  const { actionsLoading, fetching, importStatus, percentCompletion, isImportingInProgress } = currentState;

  const fetchImportStatus = () => {
    dispatch(actions.getImportStatus());
  }

  const setIntervalForImportStatus = () => {
    const newTimer = setInterval(() => {
      fetchImportStatus();
    }, 2000);
    setTimer(newTimer);
  }

  const removeInterval = () => {
    if(importStatus === "NO_ACTIVE_QUEUE")
      clearInterval(timer);
  }

  useEffect(() => {
    if(importStatus !== "NO_ACTIVE_QUEUE")
    {
      fetchImportStatus();
      setIntervalForImportStatus();
    }
  }, [importStatus]);
  useEffect(()=>{
    removeInterval();
  }, [percentCompletion, importStatus])
  useEffect(()=>{
    if(!isImportingInProgress)
    {
      imgUpload("");
      setFileName("");
      setFileHeaders("");
      inputEl.current.value = '';
    }
  },[isImportingInProgress])
  const onSortEnd = ({oldIndex, newIndex}) => {
    setFileHeaders(arrayMove(fileHeaders, oldIndex, newIndex));
  };
  const fileUploadProgressHandler = (progress)=>{
    let progressPercentage = Math.round(progress.loaded / progress.total * 100);
         console.log("progress: "+progressPercentage);
         setFileUploadProgress(progressPercentage);
   }
  const cancelUpload = ()=>
  {
    dispatch(actions.cancelImportFileProcess());
    imgUpload("");
    setFileName("");
    setFileHeaders("");
    inputEl.current.value = '';
  }
  const showMessage = () => {
    removeInterval();
    return (
      <Alert severity="info" className={classes.root}>
        <div>
          <AlertTitle>Status</AlertTitle>
          Import is inprogress
          <br />
          <LinearProgressWithLabel value={percentCompletion} />
        </div>
      </Alert>
    )
  }

  return (
    <>
      <div className="form-group row file-upload-buttons-div">
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!isValid.flag}
        onClose={()=>{
          setIsValid({flag: true, msg: ''});
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{isValid.msg}</span>}
        action={[
          <div key='btn-action'>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => {
                setIsValid({flag: true, msg: ''});
              }}
            >
          <CloseIcon />
        </IconButton>
      </div>
        ]}
      />
        {fetching ? <ImportCardSpinner /> :
          <>
            <input id="import-file" ref={inputEl} type="file" className="input-file" name="imgUpload" accept='.csv' onChange={getBase64} hidden />

              <div className="col-lg-11 upload-file-left">
                <label htmlFor="import-file">{fileName||"Choose a file"}</label>
              </div>
              <div className="col-lg-1 upload-file-right">
                <label htmlFor="import-file">Browse</label>
              </div>
          </>
        }
      </div>
      {!actionsLoading && base64Data && fileHeaders ?
        <>
          <div className="form-group row">
            <div className="col-lg-3">
              <div>
                <button
                  id="kt_login_signin_submit"
                  type="button"
                  className={`btn btn-primary`}
                  onClick={uploadFileToAWS}
                  disabled={actionsLoading}
                >
                <span>Upload To Server</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-lg-12 pt-3" style={{'border': '1px solid #d2d2d2'}}>
              <div className="col-lg-12" style={{'borderBottom': '1px solid #d2d2d2'}}>
                <div className="col-lg-12 d-inline-block">
                  <h6>System Keyword</h6>
                </div>
                {/* <div className="col-lg-6 d-inline-block">
                  <h6>Uploaded file Keyword</h6>
                </div> */}
              </div>

              <div className="col-lg-12 form-group">
                <div className="col-lg-12 d-inline-block">
                  <div className="">
                    {importKeywords.map((word, importIndex) => (
                      <div className="Showcase__style__stylizedItem" key={`item-${importIndex}`}>
                        <div className='system-header'>
                          <div className="Showcase__style__handle">::</div>
                          {word.systemHeader}
                        </div>
                        <div>
                          <select onChange={(e)=>{
                            const copy = [...importKeywords];
                            copy[importIndex].fileHeader = e.target.value;
                            setImportKeywords(copy);
                          }}>
                          {fileHeaders.map((value, index) => (
                            <option key={`item-${index}`} defaultValue={word.fileHeader}>{value}</option>
                          ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="col-lg-6 d-inline-block">
                  <SortableContainer onSortEnd={onSortEnd}>
                    {fileHeaders.map((value, index) => (
                      <SortableItem key={`item-${value}`} index={index} value={value} />
                    ))}
                  </SortableContainer>
                </div> */}
              </div>

              <div className="col-lg-12 pb-3">
                <em>*Please map Uploaded file keywords according to System Keywords</em>
              </div>
            </div>
          </div>
        </> : ""
      }
      { isImportingInProgress ?
      <>
        <div className="form-group row file-uploading-options-div">
          <div className="col-lg-2">
            {/* <button className="uploading">Uploading...</button> */}
            <Button className="uploading" variant="primary">
              <Spinner className="uploading-spinner" size="sm" animation="border" variant="light" />
              Uploading...
            </Button>
          </div>
          <div className="col-lg-2">
            {/* <button className="cancel">Cancel upload</button> */}
            <Button className="cancel" variant="secondary" onClick={cancelUpload}>Cancel upload</Button>
          </div>
        </div>
        <div className="form-group">
          <label>{fileUploadProgress}% uploaded</label>
          <ProgressBar now={fileUploadProgress} />
        </div>
      </> : "" }
    </>
  )
};
