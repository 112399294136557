// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/adminLogsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../AdminLogsUIHelpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useAdminLogUIContext } from "../AdminLogsUIContext";
import * as columnFormatters from "./column-formatters";
import * as moment from "moment";
import momentTZ from "moment-timezone";
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

export function AdminLogsTable() {
  // Admin Logs UI Context
  const adminLogsUIContext = useAdminLogUIContext();
  const adminLogsUIProps = useMemo(() => {
    return {
      ids: adminLogsUIContext.ids,
      setIds: adminLogsUIContext.setIds,
      queryParams: adminLogsUIContext.queryParams,
      setQueryParams: adminLogsUIContext.setQueryParams,
    };
  }, [adminLogsUIContext]);
  // Getting curret state of admin logs list from store (Redux)
  const { currentState, allState } = useSelector(
    (state) => ({ currentState: state.adminLogs, allState: state }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  // Admin Logs Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    adminLogsUIProps.setIds([]);

    // server call by queryParams
    dispatch(actions.fetchAdminLogs(adminLogsUIProps.queryParams));
  }, [adminLogsUIProps.queryParams, dispatch]);

  const [expanded, setExpanded] = React.useState([]);

  const events = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      const isRowExpanded = expanded.includes(row.id);
      if (isRowExpanded) {
        setExpanded(expanded.filter((x) => x !== row.id));
      } else {
        setExpanded([...expanded, row.id]);
      }
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cellContent) => {
        return (
          <span className="text-capitalize">
            {cellContent.split("_").join(" ")}
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "User Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "createdAt",
      text: "Logs",
      sort: true,
      formatter: columnFormatters.LogsColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "ip",
      text: "User IP",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "hostname",
      text: "User hostname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "city",
      text: "User location",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return row.city && row.country ? row.city + ", " + row.country : "NA";
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: adminLogsUIProps.queryParams.pageSize,
    page: adminLogsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  adminLogsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
