import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function addNewTemplates(data) {
  const formData = new FormData();
  formData.append("cssFile",data.cssFile);
  formData.append("name",data.templateName);
  return axios.post(`${API_URL}/landing-pages/add-new-template`,formData);
}

export function getAllTemplates(params) {
  return axios.get(`${API_URL}/landing-pages/get-templates-pagination`,{params:params});
}

export function deleteTemplate(id) {
  return axios.delete(`${API_URL}/landing-pages/delete-template/${id}`);
}

export function updateTemplate(data) {
  const formData = new FormData();
  formData.append("cssFile",data.cssFile);
  formData.append("name",data.templateName);
  return axios.put(`${API_URL}/landing-pages/update-template/${data.id}`,formData);
}

export function getQuestionsByCategory(category) {
  let params = category ? {categoryId:category}:{};
  return axios.get(`${API_URL}/landing-pages/questions`,{params:params});
}


