import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  selectedDomain:[],
  timezone: 'master' // master / domain
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const timezoneReducer = createSlice({
  name: "timezoneReducer",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // update timezoneBy
    updateTimezoneBy: (state, action) => {
        const { timezone } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.timezone = timezone;
    },
    // update domainFilter
    updateSelectedDomains: (state, action) => {
      const { selectedDomain } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.selectedDomain = selectedDomain;
  },
  }
});
