// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as moment from "moment";
import momentTZ from "moment-timezone";
import {InputBase, MenuItem, Select, Tooltip} from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import FilterPopover from "../../../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import './CustomersTable.css';

const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

export function CustomersTable({ userInfo }) {
  const { permissions, userType } = userInfo;

  // Customers UI Context
  const customersUIContext = useCustomersUIContext();

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      openDeleteCustomersDialog: customersUIContext.openDeleteCustomersDialog,
      openLeadStatusDialog: customersUIContext.openLeadStatusDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const [currentDate, setCurrentDate] = React.useState(0);

  // Customers Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    dispatch(actions.fetchCustomerCount(customersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, currentDate, dispatch]);

  // Table columns
  const checkDateAndLoadData = () => {
    let updatedCurrentDate = moment
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD HH:mm");
    if (currentDate != updatedCurrentDate) {
      setCurrentDate(updatedCurrentDate);
    }
  };

  useEffect(() => {
    let updatedCurrentDate = moment
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD HH:mm");
    setCurrentDate(updatedCurrentDate);
    window.addEventListener("focus", checkDateAndLoadData);
    return () => {
      window.removeEventListener("focus", checkDateAndLoadData);
    };
  }, []);

  const [expanded, setExpanded] = React.useState([]);
  const [open, setOpen] = useState(false);

  function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(function() {
    /* clipboard successfully set */
  }, function() {
    console.error("Clipboard no accessible on http");
  });
}

  const expandRow = {
    renderer: (row, rowIndex) => {
      const chatContainer = React.createRef();
      const scrollToMyRef = () => {
        const scroll =
          this.chatContainer.current.scrollHeight -
          this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
      };

      const ques = [];

      const messages = [];

      ques.push(
        <div style={{ textAlign: "center", margin: 10, display:'flex', marginX:1, justifyContent:'center', alignItems:'center' }}>
          <Tooltip title="Sub-ID" style={{marginRight:2}}>
            <Chip
              avatar={<Avatar>SID</Avatar>}
              color="secondary"
              variant="outlined"
              label={row.sid ? row.sid : ""}
            />
          </Tooltip>

        <div className="position-relative">
          <IconButton size='small' onClick={
            () => {
                navigator.clipboard.writeText(row.sid ? row.sid : "");
                setOpen(true);
                setTimeout(()=> {
                  setOpen(false);
                },2000)
              }
            }
          >
          <FileCopyIcon/>
          </IconButton>
          {open ? <div className="textcopiedtooltip fadeincopytooltip">Copied</div> : <div className="fadeincopytooltip"></div>} 
        </div>

        </div>
      );

      if (
        (row && row.question_answer && row.question_answer.length > 0) ||
        (row && row.chat_data && row.chat_data.length > 0) ||
        row.sid
      ) {
        let resolveChatData;
        let chat;
        let chat_email;
        if (row && row.chat_data && row.chat_data.length > 0) {
          resolveChatData = row.chat_data.replace(/\s\s+/g, " ");
          resolveChatData = resolveChatData.replace(/\r?\n|\r/g, "");
          resolveChatData = resolveChatData.replace(/\\/g, "");
          chat = JSON.parse(resolveChatData);

          if (chat["events"] != undefined) {
            chat_email = chat["custom_variables"].map((e) => {
              return e["key"].toLowerCase() === "email" ? e["value"] : "";
            });

            var agent = chat["user_ids"][0];

            chat["events"].map((msg, index) => {
              if (msg["author_id"] === agent) {  
                messages.push(
                  <div key={index} className="agent">
                    <div className="agent-inner">
                      <p className="messageHeader">{msg["author_id"]}</p>
                      <p className="messageText">{msg["text"]}</p>
                    </div>
                  </div>
                );
              } else {
                messages.push(
                  <div key={index} className="customer">
                    <div className="customer-inner">
                      <p className="messageHeader">{row.name}</p>
                      <p className="messageText">{msg["text"]}</p>
                    </div>
                  </div>
                );
              }
            });
          }
        }

        row.question_answer.map((question, index) => {
          ques.push(
            <div key={index}>
              <p>{question.question ? question.question : "-"}</p>
              <p>{question.answer ? question.answer.join(", ") : "-"}</p>
            </div>
          );
        });

        var questions =
          ques.length > 0 ? (
            ques.map((q, index) => (
              <React.Fragment key={index}>{q}</React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <p className="messageHeader">NO QUESTION AVAILABLE</p>
            </React.Fragment>
          );

        var msg;

        if (row.sid) {
          msg =
            messages.length > 0 ? (
              messages.map((m, index) => (
                <React.Fragment key={index}>{m}</React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <div style={{ padding: 10, textAlign: "center" }}>
                  <Chip label="Chat Not AVAILABLE" disabled color="secondary" />
                </div>
              </React.Fragment>
            );
        } else if (row.email == chat_email) {
          msg =
            messages.length > 0 ? (
              messages.map((m, index) => (
                <React.Fragment key={index}>{m}</React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <div style={{ padding: 10, textAlign: "center" }}>
                  <Chip label="Chat Not AVAILABLE" disabled color="secondary" />
                </div>
              </React.Fragment>
            );
        } else {
          msg = (
            <React.Fragment>
              <div style={{ padding: 10, textAlign: "center" }}>
                <Chip label="Chat Not AVAILABLE" disabled color="secondary" />
              </div>
            </React.Fragment>
          );
        }
        return (
          <div className="expandedContainer">
            <div className="questionContainer">{questions}</div>
            <div ref={chatContainer} className="messageContainer">
              {msg}
            </div>
          </div>
        );
      } else {
        return "No Questions & Chat Found!!";
      }
    },

    expanded,

    showExpandColumn: true,

    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setExpanded([...expanded, row.id]);
      } else {
        setExpanded(expanded.filter((x) => x !== row.id));
      }
    },

    onExpandAll: (isExpandAll, results, e) => {
      if (isExpandAll) {
        setExpanded(results.map((i) => i.id));
      } else {
        setExpanded([]);
      }
    },
  };

  // const events = {
  //   onClick: (e, column, columnIndex, row, rowIndex) => {
  //     const isRowExpanded = expanded.includes(row.id);
  //     if (isRowExpanded) {
  //       setExpanded(expanded.filter(x => x !== row.id));
  //     } else {
  //       setExpanded([...expanded, row.id]);
  //     }
  //   }
  // }
  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 13,
      padding: "10px 26px 10px 12px",
      width: "100%",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);
  const [columns, setColumns] = useState([
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      shouldHide: true,
      isSelected: true,
    },
    {
      dataField: "sid",
      text: "SID",
      sort: true,
    },
    {
      dataField: "added_date",
      text: "Added Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row, rowIndex) => {
        return moment
          .utc(cellContent)
          .utcOffset(offSet)
          .format("YYYY-MM-DD hh:mm:ss");
      },
    },
    {
      dataField: "firstname",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email_valid",
      text: "Email Valid",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.EmailValidColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "domain",
      text: "Domain",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lead_type",
      text: "Lead Type",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.LeadValidColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "lead_status",
      text: "Lead Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.LeadStatusColumnActionFormatter,
      formatExtraData: {
        openDeleteCustomerDialog: customersUIProps.openLeadStatusDialog,
      },
      headerSortingClasses,
    },
    {
      dataField: "livechat_status",
      text: "Livechat Status",
      formatter: columnFormatters.LivechatStatusColumnActionFormatter,
      formatExtraData: {
        openDeleteCustomerDialog: customersUIProps.openLeadStatusDialog,
      },
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "isduplicate",
      text: "Is AB Duplicate",
      sort: true,
      headerSortingClasses,
      formatter: columnFormatters.LivechatStatusColumnActionFormatter,
      formatExtraData: {
        openDeleteCustomerDialog: customersUIProps.openLeadStatusDialog,
      },
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "debt",
      text: "Debt",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["contacts"].includes("delete")
          ? false
          : true,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
      },
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      style: {
        minWidth: "100px",
      },
      shouldHide: true,
    },
  ]);
  useEffect(() => {
    let selectFilters
    const copy = [...columns];
    try {
      selectFilters  = localStorage.getItem("selected-filters");
      if(selectFilters){
        selectFilters = JSON.parse(selectFilters);
      } else {
        selectFilters = [];
      }
    }catch (e) {
      selectFilters = [];
    }
    if(selectFilters.length){
      copy.forEach(col => {
        if(selectFilters.find(sel => sel === col.dataField)){
          col.isSelected = true;
        } else {
          col.isSelected = false;
        }
      });
    } else {
      copy.forEach(col => {
        col.isSelected = true;
      });
    }
    setColumns(columns);
  }, [])
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.from,
  };
  return (
    <>
      <div className="filterpopout">
          <div className="filterpopovercom">
              <FilterPopover
                value={columns.filter(col => col.isSelected).map(col => col.dataField)}
                options={columns.filter(col => !col.shouldHide).map(col => col)}
                fieldName={"dataField"}
                labelField={"text"}
                onApply={(val) => {
                  let copy = [...columns];
                  if (!val.length) {
                    copy.forEach((value, index) => {
                      value.isSelected = index === 0;
                    });
                  } else {
                    copy.forEach(value => {
                      const isSelected = val.findIndex(item => item === value.dataField);
                      value.isSelected = isSelected !== -1;
                    });
                  }
                  setColumns(copy);
                  localStorage.setItem("selected-filters", JSON.stringify(copy.filter(col => col.isSelected).map(col => col.dataField)))
                }}
              />
          </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4  
                remote
                keyField="id"  
                // data={entities === null ? [] : entities}
                data={
                  entities && entities.length ? entities.map((item) => ({
                        ...item,
                        
                        phone: item.phone.replace(/[-\s]/g, ""),
                      }))
                    : []
                }
                columns={(columns.filter(col => col.isSelected) || [])}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                {...paginationTableProps}
                expandRow={expandRow}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
