import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";
import {
    RoleManagement
} from "../modules/StaffManagement/pages/RoleManagement";

export function RolesPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType,permissions } = useSelector(
    (state) => ({  userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions
     }),
    shallowEqual
  );
  if((userType && userType.toLowerCase()) === "admin" || permissions.includes("staff_manage")){
return <RoleManagement />;
  }
  else{
    return <UnauthorizedAccess />
  }
  
}
