import React from "react";
import { CpvTrackingLoadingDialog } from "./cpvtracking-loading-dialog/CpvTrackingLoadingDialog";
import { CpvTrackingUIProvider } from "./CpvTrackingUIContext";
import { CpvTrackingCard } from "./CpvTrackingCard";
export function CpvTrackingPage({ history }) {
  {/*const customersUIEvents = {
    openDeleteCustomerDialog: (id) => {
      history.push(`/contacts/${id}/delete`);
    },
    openDeleteDomainsDialog: () => {
      history.push(`/e-commerce/customers/deleteDomains`);
    },
  }*/}

  return (
    // customersUIEvents={customersUIEvents}
    <CpvTrackingUIProvider>
      <CpvTrackingLoadingDialog />
      <CpvTrackingCard />
    </CpvTrackingUIProvider>
  );
}
