import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal, Table, Button, Row, Col } from "react-bootstrap";
import { AddUpdateQuestionDialogHeader } from "./AddUpdateQuestionDialogHeader";
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import * as actions from "../../../_redux/questionManagementActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { FormControl, InputBase, MenuItem, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import * as Yup from "yup";
import Axios from "axios";
import { useFormik } from "formik";
import { useQuestionManagementUIContext } from "../QuestionManagementUIContext";
import { values } from "lodash";
const API_URL = process.env.REACT_APP_API_URL;

const BootstrapInput = withStyles((theme) => ({
  root: {},
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export function AddUpdateQuestionsDialog({ id, show, onHide, renderId }) {
  const defaultVal = {
    answer: "",
    showValue: "",
    qualified: true,
    newAnswer: true,
  };

  var initialValues = {
    questionName: "",
    categoryName: "",
    multiselect: false,
  };

  const QuestionsSchema = Yup.object().shape({
    questionName: Yup.string().required(),
    categoryName: Yup.string().required(),
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [answersOptions, setAnswersOptions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([defaultVal]);
  const [deleteInd, setdeleteInd] = useState(null);

  const { currentState, permissions, userType } = useSelector(
    (state) => ({
      currentState: state.question_management,
      userType: state.auth.user.userType,
      permissions: state.auth.user.permissions,
    }),
    shallowEqual
  );
  const { actionsLoading, entities } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getAllExistingAnswers()).then((ans) => {
      if (ans) {
        setAnswersOptions(ans.answers);
      }
    });
    Axios.get(`${API_URL}/faq/getAllQuestionCategories`).then((res) => {
      setCategoryOptions(res.data.data);
    });
    if (id) {
      // ------------check update permission when directly hit from url---------------
      if (
        !["Admin", "admin"].includes(userType) &&
        (permissions["question_manage"] &&
          !permissions["question_manage"].includes("update"))
      ) {
        console.log("decfcfrv");
        onHide();
      }
      let dataForEdit = entities.find((e) => e.q_id == id);
      if (dataForEdit) {
        initialValues.categoryName = dataForEdit.category;
        initialValues.questionName = dataForEdit.question;
        initialValues.multiselect = dataForEdit.multiselect;

        let answers = dataForEdit.QACollections.map((qa) => {
          return {
            answer: qa.Answer.a_id,
            showValue: qa.Answer.answer,
            qualified: qa.qualified,
            newAnswer: false,
          };
        });
        setSelectedAnswers(answers);
      } else {
        onHide();
      }
    } else {
      // ------------check Add permission when directly hit from url---------------
      if (
        !["Admin", "admin"].includes(userType) &&
        (permissions["question_manage"] &&
          !permissions["question_manage"].includes("add"))
      ) {
        onHide();
      }
    }
  }, []);

  useEffect(() => {}, [entities]);
  const addRowBelow = () => {
    let newItems = [...selectedAnswers];
    newItems.push(defaultVal);
    setSelectedAnswers(newItems);
  };

  const confirmation = (deleteInd) => {
    setdeleteInd(deleteInd);
  };

  const removeAccount = (deleteInd) => {
    let newItems = [...selectedAnswers];
    newItems.splice(deleteInd, 1);
    setSelectedAnswers(newItems);
  };

  const filterOptions = (e) => {
    let answersOptNew = answersOptions.filter(
      (op) => !selectedAnswers.find((s) => s.answer == op.a_id)
    );
    return answersOptNew;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: QuestionsSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (selectedAnswers.length < 2) {
        toast.error("*Please add Atleast two Answers", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (id) {
          dispatch(actions.updateQuestion({ ...values, selectedAnswers, id }))
            .then((success) => {
              console.log(success);
              if (success) {
                try {
                  dispatch(actions.fetchAllQuestions());
                } catch (error) {
                  console.log(error);
                }
                onHide();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          let answerArr = selectedAnswers.map((d) =>
            d.showValue.trim().toLowerCase()
          );
          let setArrLength = new Set(answerArr).size;
          if (answerArr.length === setArrLength) {
            dispatch(
              actions.addNewQuestion({ ...values, selectedAnswers })
            ).then((success) => {
              if (success) {
                onHide(renderId);
              }
            });
          } else {
            toast.error("*Answers contains duplicate Values!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      }
    },
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={(e) => onHide(renderId)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AddUpdateQuestionDialogHeader id={id} />
      <Modal.Body>
        <Row className="align-items-baseline">
          <Col md="4">
            <FormControl className="w-100 mb-2">
              <Form.Label>Question Category</Form.Label>
              <Select
                id="categoryName"
                name="categoryName"
                input={<BootstrapInput i />}
                {...formik.getFieldProps("categoryName")}
                disabled={id}
              >
                <MenuItem value="" disabled>
                  Select Question Category
                </MenuItem>
                {categoryOptions.map((cat, i) => {
                  return (
                    <MenuItem value={cat.id} key={i}>
                      {cat.id}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.categoryName && formik.errors.categoryName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">*required field</div>
                </div>
              ) : null}
            </FormControl>
          </Col>
          <Col md="8">
            <Form.Group>
              <Form.Label>Question Name</Form.Label>
              <Form.Control
                type="text"
                id="questionName"
                name="questionName"
                placeholder="Enter Question Name"
                {...formik.getFieldProps("questionName")}
              />
              {formik.touched.questionName && formik.errors.questionName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">*required field</div>
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <h3>Answers Options</h3>
        <hr />
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.multiselect}
              name={"multiselect"}
              onChange={(e) => {
                let val = e.target.checked;
                formik.setFieldValue("multiselect", val);
              }}
            />
          }
          label="Multiselect"
        />

        <Table className="mb-0" bordered>
          <thead>
            <tr>
              <th>
                Answer
                <br />
                <small className="text-danger">
                  *select values from dropdown for existing answers.
                </small>
              </th>
              {!formik.values.multiselect && (
                <th style={{ verticalAlign: "middle" }}>Qualified</th>
              )}

              <th style={{ verticalAlign: "middle" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {selectedAnswers.map((ans, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Combobox
                      value={ans.showValue}
                      placeholder="Add Answer"
                      data={filterOptions()}
                      textField="answer"
                      onBlur={(e) => {}}
                      onChange={(e) => {
                        let answer = e,
                          newAnswer = true,
                          showValue = e;

                        if (e.a_id) {
                          answer = e.a_id;
                          showValue = e.answer;
                          newAnswer = false;
                        }
                        let found = answersOptions.find(
                          (d) =>
                            d.answer.toLowerCase() ===
                            showValue.toLowerCase().trim()
                        );
                        if (found) {
                          answer = found.a_id;
                          showValue = found.answer;
                          newAnswer = false;
                        }
                        let newItems = [...selectedAnswers];
                        newItems[i].answer = answer;
                        newItems[i].newAnswer = newAnswer;
                        newItems[i].showValue = showValue;
                        setSelectedAnswers([...newItems]);
                      }}
                    />
                  </td>
                  {!formik.values.multiselect && (
                    <td className="text-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ans.qualified}
                            name={"qualified" + i}
                            onChange={(e) => {
                              let newItems = [...selectedAnswers];
                              newItems[i].qualified = e.target.checked;
                              setSelectedAnswers([...newItems]);
                            }}
                          />
                        }
                      />
                    </td>
                  )}

                  <td>
                    {deleteInd === i ? (
                      <>
                        <Button
                          variant="danger"
                          className="mr-3 p-2"
                          onClick={(e) => removeAccount(i)}
                        >
                          Confirm
                        </Button>
                        <Button
                          className="p-2"
                          onClick={(e) => setdeleteInd(null)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        title="Delete answer"
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={(e) => confirmation(i)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger fa fa-trash"></span>
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="text-right">
          <Button
            onClick={(e) => {
              if (
                selectedAnswers.length === 0 ||
                selectedAnswers[selectedAnswers.length - 1].answer
              ) {
                addRowBelow();
              } else {
                toast.error("Please fill existing answers First to Add new.", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            }}
          >
            Add New Answer +
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {actionsLoading ? (
          <button className="btn btn-primary btn-elevate" disabled>
            Saving....
          </button>
        ) : (
          <button
            type="submit"
            onClick={formik.handleSubmit}
            className="btn btn-primary btn-elevate"
          >
            Save
          </button>
        )}

        <button
          type="button"
          onClick={(e) => onHide(renderId)}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
