import * as requestFromServer from "./domainsCrud";
import {domainListSlice, callTypes} from "./domainsSlice";

const {actions} = domainListSlice;

export const fetchDomains = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDomains(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.domainsFetched({ totalCount: 0, entities: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.domainsFetched({ totalCount: response.data.data.count, entities: response.data.data.domains}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomain = domain => dispatch => {
  if (!domain) {
    return dispatch(actions.domainFetched({ domainForEdit: undefined }));
  }

  dispatch(actions.domainFetched({ domainForEdit: domain.domainForEdit }));
};

export const updateDomain = domain => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .updateDomain(domain)
    .then(() => {
      // streamline with app state
      domain.cpv_lab_url = domain.cpvLabUrl;
      domain.cpv_lab_api_key = domain.appKey;
      
      dispatch(actions.domainsUpdate({ domain }));
    })
    .catch(error => {
      error.clientMessage = "Can't update domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDomain = domainForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDomain(domainForCreation)
    .then(response => {
      const domain  = response.data.data;
      dispatch(actions.domainCreated({ domain }));
    })
    .catch(error => {
      error.clientMessage = "Can't create domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDomainCount = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDomainsTotal(queryParams)
    .then(response => {
      if( response && response.data && response.data.respType ) {
        dispatch(actions.domainsTotalFetched({ totalCount: response.data.data.total}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteDomain = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDomain(id)
    .then(response => {
      dispatch(actions.domainDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

/*export const deleteDomain = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDomain(id)
    .then(response => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDomains = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDomains(ids)
    .then(() => {
      dispatch(actions.domainsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete domains";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};*/
