import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function addNewLandingPage(data) {
  return axios.post(`${API_URL}/landing-pages`,data);
}

export function getAllLandingPages(params) {
  return axios.get(`${API_URL}/landing-pages`,{params:params});
}

export function getLandingPage(id) {
  return axios.get(`${API_URL}/landing-pages/${id}`);
}

export function updateLandingPage(data) {
  return axios.put(`${API_URL}/landing-pages/${data.id}`,data);
}

export function getQuestionsByCategory(category) {
  let params = category ? {categoryId:category}:{};
  return axios.get(`${API_URL}/landing-pages/questions`,{params:params});
}


export function findLeadSources() {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.get(`${API_URL}/ad-spent/fetch-lead-sources`, header);
}

