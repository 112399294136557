// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/staffManagementActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../RoleUIHelpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useRoleUIContext } from "../RoleUIContext";
import * as columnFormatters from "./column-formatters";
import * as moment from "moment";
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")

export function RolesTable() {
  // Role UI Context
  const rolesUIContext = useRoleUIContext();
  const rolesUIProps = useMemo(() => {
    return {
      ids: rolesUIContext.ids,
      setIds: rolesUIContext.setIds,
      queryParams: rolesUIContext.queryParams,
      setQueryParams: rolesUIContext.setQueryParams,
    };
  }, [rolesUIContext]);
  // Getting curret state of roles list from store (Redux)
  const { currentState, allState } = useSelector(
    (state) => ({ currentState: state.staffManagement, allState: state }),
    shallowEqual
  );

  const { rolesListing, listLoading, totalCount } = currentState;

  // Role Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchAllRole(rolesUIProps.queryParams));
  }, [rolesUIProps.queryParams, dispatch]);

  const [expanded, setExpanded] = React.useState([]);

  const events = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      const isRowExpanded = expanded.includes(row.id);
      if (isRowExpanded) {
        setExpanded(expanded.filter((x) => x !== row.id));
      } else {
        setExpanded([...expanded, row.id]);
      }
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Role Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "permissions",
      text: "Permissions",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return row.name.toLowerCase() === "admin" ? (
          <h6>Admin have all access</h6>
        ) : (
          Object.keys(cellContent).map(function(key, i) {
            return (
              <>
              <strong>{key + " => "}</strong>
                { cellContent[key].join(", ")} <br /> <br />
              </>
            );
          })
        );
        
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "createdAt",
      text: "Added On",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return moment
          .utc(cellContent)
          .utcOffset(offSet)
          .format("YYYY-MM-DD hh:mm:ss");
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "edit",
      text: "Edit",
      formatter: columnFormatters.EditColumnFormatter,
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center"
      bootstrap4
      remote
      keyField="id"
      data={rolesListing === null ? [] : rolesListing}
      columns={columns}
      defaultSorted={uiHelpers.defaultSorted}
      onTableChange={getHandlerTableChange(rolesUIProps.setQueryParams)}
    >
      <PleaseWaitMessage entities={rolesListing} />
      <NoRecordsFoundMessage entities={rolesListing} />
    </BootstrapTable>
  );
}
