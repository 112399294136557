import React from "react";
import { LeadAnalysisLoadingDialog } from "./lead-analysis-loading-dialog/LeadAnalysisLoadingDialog";
import { LeadAnalysisUIProvider } from "./LeadAnalysisUIContext";
import { LeadAnalysisCard } from "./LeadAnalysisCard";
export function LeadAnalysisPage({ history }) {
  const LeadAnalysisUIEvents = {
  }

  return (
    <LeadAnalysisUIProvider leadAnalysisUIEvents={LeadAnalysisUIEvents}>
      {/* <LeadAnalysisLoadingDialog /> */}
      <LeadAnalysisCard />
    </LeadAnalysisUIProvider>
  );
}
