import React from "react";
import { LeadStatusAnalysisLoadingDialog } from "./lead-status-analysis-loading-dialog/LeadStatusAnalysisLoadingDialog";
import { LeadStatusAnalysisUIProvider } from "./LeadStatusAnalysisUIContext";
import { LeadStatusAnalysisCard } from "./LeadStatusAnalysisCard";
export function LeadStatusAnalysisPage({ history }) {

  return (
    <LeadStatusAnalysisUIProvider>
      <LeadStatusAnalysisLoadingDialog />
      <LeadStatusAnalysisCard />
    </LeadStatusAnalysisUIProvider>
  );
}
