import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCpvTrackingUIContext } from "../CpvTrackingUIContext";
import * as actions from "../../../_redux/cpvTrackingActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectSearch from "react-select-search";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from "@material-ui/icons/Cached";
import "react-select-search/style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import momentTZ from "moment-timezone";
const API_URL = process.env.REACT_APP_API_URL;
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
export function CpvTrackingFilter({ listLoading }) {
  // CPV UI Context

  const cpvTrackingUIContext = useCpvTrackingUIContext();
  const cpvUIProps = useMemo(() => {
    return {
      queryParams: cpvTrackingUIContext.queryParams,
      setQueryParams: cpvTrackingUIContext.setQueryParams,
    };
  }, [cpvTrackingUIContext]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchCpvTracking(cpvUIProps.queryParams));
  }, [cpvUIProps.queryParams]);

  const changeReport = (e) => {
    let type = e.target.value;
    cpvUIProps.setQueryParams({ ...cpvUIProps.queryParams, type });
  };

  const refreshData = () => {
    dispatch(actions.fetchCpvTracking(cpvUIProps.queryParams.type));
  };

  return (
    <>
      <Formik
      // initialValues={{
      //   domain:domainsUIProps.queryParams.domain,
      //   startDate: domainsUIProps.queryParams.startDate,
      //   endDate: domainsUIProps.queryParams.endDate,
      // }}
      // onSubmit={(values) => {
      //   applyFilter(values);
      // }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-5 col-sm-12">
                <div className="input-group shadow-sm">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                      cpvUIProps.setQueryParams({
                        ...cpvUIProps.queryParams,
                        startDate,
                        endDate,
                      });
                    }}
                    initialSettings={{
                      startDate: moment(
                        cpvTrackingUIContext.queryParams.startDate
                      ),
                      endDate: moment(cpvTrackingUIContext.queryParams.endDate),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        Today: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days")
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek')
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                            moment.utc().utcOffset(offSet).endOf('isoweek')
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek')
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                            moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek')
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
      
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month")
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month")
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month")
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 59,
                            }),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      disabled={listLoading}
                    />
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
              <div className="col-lg-4 col-sm-8 col-9">
                <select
                  className="form-control shadow-sm"
                  name="status"
                  placeholder="Filter by Domain"
                  onChange={changeReport}
                  onBlur={handleBlur}
                  // value={values.domain}
                >
                  <option value="all">Campaign and country wise</option>
                  <option value="campaign">Campaign wise</option>
                  <option value="country">Country wise</option>
                  <option value="state">State wise</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-3 col-3">
                <Button
                  variant="outlined"
                  className="shadow-sm"
                  color="secondary"
                  onClick={refreshData}
                  title="Refresh"
                >
                  <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
