import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import moment from 'moment'
import { ImportLoadingDialog } from "../import-loading-dialog/ImportLoadingDialog"
import * as actions from "../../_redux/importActions"
import { nullChecker } from "../../../../../_metronic/_helpers/NullChecker"
import { ImportCardSpinner } from '../import-card-spinner/ImportCardSpinner'

export function ImportTable() {

  const columns = [{ 
    dataField: "id",
    text: "ID"
  }, {
    dataField: "added_date",
    text: "Added Date",
    formatter: ( cellContent, row, rowIndex) => {
      return moment(cellContent).format('YYYY-MM-DD hh:mm:ss');
    }
  }, {
    dataField: "original_file_name",
    text: "Original File Name",
    formatter: nullChecker
  }, {
    dataField: "cloud_file_name",
    text: "Cloud File Name",
    formatter: nullChecker
  }, {
    dataField: "status",
    text: "Status"
  }, {
    dataField: "total_records",
    text: "Total Records"
  }, {
    dataField: "processed_records",
    text: "Processed Records"
  }];

  const dispatch = useDispatch();

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.import }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.getImports());
  }, [dispatch]);

  const { entities, listLoading } = currentState;

  return (
    <>
      {listLoading ? <ImportCardSpinner /> :
        <>
          <ImportLoadingDialog />
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={true}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            remote
            keyField="id"
            data={entities === null ? [] : entities}
            columns={columns}
            noDataIndication={() => (<div>No records found</div>)}
          >
          </BootstrapTable>
        </>
      }
    </>
  )
}
