import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import axios from 'axios';
import moment from 'moment';
import momentTZ from 'moment-timezone';
const API_URL = process.env.REACT_APP_API_URL;
const offSet = momentTZ().tz("America/New_York").format("Z")

function getLogsForLead(id){
  return axios.get(API_URL+'/contacts/leadChangeLogs?id='+id);
}
function diffYMDHMS(date1, date2) {
  let years = date1.diff(date2, 'year');

  let months = date1.diff(date2, 'months');

  let days = date1.diff(date2, 'days');

  let hours = date1.diff(date2, 'hours');

  let minutes = date1.diff(date2, 'minutes');

  let seconds = date1.diff(date2, 'seconds');

  return { years, months, days, hours, minutes, seconds};
}
export function CustomersLeadStatusDialog({ show, onHide,id,date }) {
  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.actionsLoading }),
    shallowEqual
  );
  const [logDetails, setLogDetails] = React.useState([]);
  useEffect(()=>{
    getLogsForLead(id).then(res=>{
      if( res.data && res.data.respType === "Success" ) {
        let result = [];
        if(res.data && date){
          result = res.data.data;
          result.unshift({
            // addedTime:date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss')
            addedTime:date ? moment.utc(date).utcOffset(offSet).format('YYYY-MM-DD HH:mm:ss') : moment.utc().utcOffset(offSet).format('YYYY-MM-DD HH:mm:ss')
          })
        }
        setLogDetails(result);
      }
    });
  }, [id]);
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Lead Status Logs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          logDetails.map((log, index) => {
          // const duration = diffYMDHMS(moment(moment.utc().local().format('YYYY-MM-DD HH:mm:ss'),'YYYY-MM-DD HH:mm:ss'), moment(moment.utc(log.addedTime).local().format('YYYY-MM-DD HH:mm:ss'),'YYYY-MM-DD HH:mm:ss'));
          const duration = diffYMDHMS(moment(moment.utc().utcOffset(offSet).format('YYYY-MM-DD HH:mm:ss'),'YYYY-MM-DD HH:mm:ss'), log.addedTime);

          let durationText = '';
          let isAnyChange = false;
          if( duration.years > 0 ) {
            durationText += duration.years + ' years ';
            isAnyChange = true;
          }
          if( duration.months > 0 ) {
            durationText += (duration.months % 12) + ' months ';
            isAnyChange = true;
          }
          if( duration.days > 0 ) {
            durationText += (duration.days) + ' days ';
            isAnyChange = true;
          }
          if( duration.hours > 0 ) {
            durationText += (duration.hours % 24) + ' hours ';
            isAnyChange = true;
          }
          if( duration.minutes > 0 ) {
            durationText += (duration.minutes % 60) + ' minutes ';
            isAnyChange = true;
          }
          if( duration.seconds > 0 ) {
            durationText += (duration.seconds % 60) + ' seconds ';
            isAnyChange = true;
          }
          if(isAnyChange)
            durationText += ' ago!!';
          let sentence = '';
          if(index === 0 ) {
            sentence = 'Lead Created';
          } else {
            sentence = `${log.updatedBy} updated status from ${log.from ? log.from : 'New'} to ${log.to ? log.to : '-'}`;  
          }
          return <React.Fragment key={index}>
            {index > 0 ? <><br/><br/></> : ''}
            <div>
            <span>{durationText}</span><br/>
            <span>{sentence}</span>
            </div>
          </React.Fragment>
          })
        )}
        {isLoading && <span>Customer are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
