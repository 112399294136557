import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getQuestionAnalysis(queryParams) {
  let params = "";
  Object.keys(queryParams).forEach((key) => {
    if (params.length > 0) params += "&";
    params += `${key}=${
      typeof queryParams[key] === "object"
        ? JSON.stringify(queryParams[key])
        : queryParams[key]
    }`;
  });
  return axios.get(`${API_URL}/faq/getStats?` + params);
}

export function getDomainList(startDate, endDate) {
  return axios.get(`${API_URL}/faq/getAllDomains`, {
    params: { startDate, endDate },
  });
}

export function getQuestionsList(domain, category,startDate, endDate) {
  return axios.get(`${API_URL}/faq/getAllQuestions`, {
    params: { domain,category, startDate, endDate },
  });
}

export function getChartData(firstDomain,secondDomain,firstQuestion,secondQuestion,category,startDate, endDate,timezoneFilter) {
  return axios.get(`${API_URL}/faq/getChartData`, {
    params: { firstDomain,secondDomain,firstQuestion,secondQuestion,category,startDate, endDate,timezoneFilter },
  });
}