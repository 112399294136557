import React, { useEffect, useMemo, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/staffManagementActions";
import { UpdateUserDialogHeader } from "./UpdateUserDialogHeader";
import { UpdateProfileForm } from "./tabs/UpdateProfileForm";
import { UpdatePermissionForm } from "./tabs/UpdatePermissionForm";
import { useStaffManagementUIContext } from "../StaffManagementUIContext";

export function UpdateUserDialog({ id, show, onHide }) {
  // Staff UI Context
  const staffUIContext = useStaffManagementUIContext();
  const staffUIProps = useMemo(() => {
    return {
      initUser: staffUIContext.initUser,
    };
  }, [staffUIContext]);
  
  const [tabKey, setTabKey] = useState("profile");
  const [disableToggle, setDisableToggle] = useState(false);
  const [defaultProfileInfo, setDefaultProfileInfo] = useState(
    staffUIProps.initUser
  );
  const [updatedProfileInfo, setUpdatedProfileInfo] = useState(
    staffUIProps.initUser
  );

  // Staff Redux state
  const dispatch = useDispatch();
  let { actionsLoading, permissions, entities } = useSelector(
    (state) => ({
      actionsLoading: state.staffManagement.actionsLoading,
      permissions: state.staffManagement.rolePermissions,
      entities: state.staffManagement.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchRolePermissions());
  }, [dispatch]);

  useEffect(() => {
    if (entities.length > 0) {
      let userForEdit = entities.find((el) => el.id === parseInt(id));
      let data = userForEdit
        ? {
            id: userForEdit.id,
            firstName: userForEdit.firstName,
            lastName: userForEdit.lastName,
            email: userForEdit.email,
            phone: userForEdit.phone,
            profileName: "",
            passwordChange: false,
            permissions: {
              ...userForEdit.permissions,
              ...userForEdit.limits,
              roleName: userForEdit.role ? userForEdit.role.name :"",
              roleId: userForEdit.role,

            },
          }
        : {};
      setDefaultProfileInfo(data);
    } else {
      onHide(setTabKey);
    }
  }, [id, dispatch]);

  const saveUserInfoAndNext = (userInfo) => {
    let temp = { ...userInfo };
    delete temp.permissions;
    setUpdatedProfileInfo({ userInfo: temp, ...userInfo.permissions });
    setTabKey("permissions");
  };

  const updateStaffUser = (userInfo) => {
    dispatch(actions.updateUser(userInfo)).then((success) => {
      if(success){
        onHide(setTabKey);
      }else{

      }
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={(e) => onHide(setTabKey)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <UpdateUserDialogHeader id={id} />
      <Tabs
        id="controlled-tab-example"
        activeKey={tabKey}
        onSelect={(key) => {
          saveUserInfoAndNext(defaultProfileInfo)
          setTabKey(key)
        }
        }
      >
        <Tab eventKey="profile" title="Profile" disabled={disableToggle}>
          <UpdateProfileForm
            saveUserInfoAndNext={saveUserInfoAndNext}
            actionsLoading={actionsLoading}
            user={defaultProfileInfo}
            allPermissions={permissions}
            onHide={(e) => onHide(setTabKey)}
            disableToggle={setDisableToggle}
          />
        </Tab>
        <Tab eventKey="permissions" title="Permissions" disabled={disableToggle} >
          <UpdatePermissionForm
            saveStaffUser={updateStaffUser}
            actionsLoading={actionsLoading}
            userInfo={updatedProfileInfo}
            initialValues={{
              userInfo: updatedProfileInfo.userInfo,
              roleId: updatedProfileInfo.roleId,
              roleName: updatedProfileInfo.roleName,
            }}
            allPermissions={permissions}
            onHide={(e) => onHide(setTabKey)}
            setTabKey={setTabKey}
          />
        </Tab>
      </Tabs>
    </Modal>
  );
}
