import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { Form } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  applyContainer: {
    padding: "10px",
    borderTop: "1px solid #ddd",
    display: "flex",
    justifyContent: "space-between",
  },
  numberClass: {
    fontWeight: "600",
    fontSize: "13px",
  },
  domainDescription: {
    border: "1px solid #E4E6EF",
    borderRadius: "4px",
    minHeight: "40px",
    padding: "10px",
    cursor: "pointer",
  },
  showList: {
    display: "inline-block",
    backgroundColor: "#dee2e6",
    padding: "4px 7px",
    fontSize:"11px",
    borderRadius: "30px",
    boxShadow: "2px 2px #ccc"
  },
  searchFilter: {
    padding: "10px",
    fontSize: "12px",
  },
  menuItem: {
    padding:0
  },
  menuList: {
    overflow: "scroll",
    height: "240px",
  },
}));

export default function FilterPopoverDomainAnalysis({
  domainEntities,
  options,
  fieldName = " Domain ",
  onApply,
  value,
  limitSelection = null,
  placeholder = "Select Domain",
  maxListShow = 12,
  labelField = "dataField",
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allOptionNames, setAllOptionNames] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showList, setShowList] = React.useState([]);

  useEffect(() => {
    setAllOptionNames(options); 
    }, [options]);

  useEffect(() => {
      setSelected(value);
      setShowList(value);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onApply(selected);
    setShowList(selected);
  };

useEffect(()=>{
  if(domainEntities) {
    var ObjEntity = Object.keys(domainEntities);
    let allDomains = allOptionNames.map(a => a.dataField);
    let domainfilter = allDomains.filter(d => d);
    let filteredArray = domainfilter.filter(function(n) {
        return ObjEntity.indexOf(n) !== -1;
    });
    setSelected(ObjEntity);
    setShowList(filteredArray);
  }  
}, [domainEntities]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterDomainList = (e) => {
    let filteredData = options.filter((d) => {
      if (d[fieldName].includes(e.target.value)) {
        return true;
      } else {
        return false;
      }
    });
    setAllOptionNames(filteredData);
  };

  return (
    <div>
      <div   
        aria-describedby={id}
        className={classes.domainDescription + " shadow-sm multinewfilter "}
        onClick={handleClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>    
              {showList.length + (
                window.location.pathname === '/contacts/' ? 
                  (fieldName=="dataField"?" column ":fieldName) : (fieldName=="dataField"?" leads ": ( showList.length > 1 ? fieldName = ' Domains ' : ' Domain ' ) )
                )
              } Selected
            {/*</span>*/}
            {showList.length > 0 && maxListShow < showList.length && (
              <span className={classes.numberClass + " ml-1 mb-1"}>
                +{showList.length - maxListShow}
              </span>  
            )}
          </div>
          <div>
            {open ? (
              <i className="fas fa-chevron-up"></i>
            ) : (
              <i className="fas fa-chevron-down"></i>
            )}
          </div>
        </div>
      </div>
      <Popover
        id={id}
        className="popover_class"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "300px", maxHeight: "400px", overflow: "hidden" },
          className:"arrow-popup"
        }}
      >
        <div className="px-3">
          <h6 className="mb-0 mb-2 mt-3 font-weight-bold">Filter</h6>
          <Form.Control
            className={classes.searchFilter + " shadow-sm"}
            placeholder="Filter list"
            onChange={filterDomainList}
            autoFocus={true}
          />
        </div>

        {allOptionNames.length > 0 ? (
          <div className="multifilter">            
            <MenuList className={classes.menuList}>
              {allOptionNames.map((options) => {
                return (
                  <>
                     <MenuItem
                      className={classes.menuItem}
                      key={options.dataField}
                      value={options.dataField}
                      onClick={(e) => {
                        if (selected.includes(options.dataField)) {
                          let index = selected.indexOf(options.dataField);
                          let originalArr = [...selected];
                          originalArr.splice(index, 1);
                          setSelected(originalArr);
                        } else {
                          setSelected([...selected, options.dataField]);
                        }
                      }}
                      disabled={
                        limitSelection &&
                        selected.length >= limitSelection &&
                        !selected.includes(options.dataField)
                      }
                    >
                      <Checkbox key={options.dataField} checked={selected.includes(options.dataField)} />
                      <ListItemText primary={options[labelField ? labelField : fieldName]} />
                    </MenuItem>
                  </>
                );
              })}
            </MenuList>
            <div className={classes.applyContainer}>
              <div className="text-left">
                {!limitSelection && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={(e) => {
                      setSelected(allOptionNames.map((e) => e.dataField));
                    }}
                  >
                    Select All
                  </Button>  
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  className="mr-2"
                  onClick={(e) => {
                    setSelected([]);
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-5">Not Found!</div>
        )}
      </Popover>
    </div>
  );
}