import * as requestFromServer from "./templatesCrud";
import { landingPagesTemplatesSlice, callTypes } from "./templatesSlice";
import * as utils from "../../../../_metronic/_helpers/AWSFileUpload";
import { toast } from "react-toastify";

const { actions } = landingPagesTemplatesSlice;

export const addNewTemplates = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewTemplates(data)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Added", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.templateAdded({ data:  response.data.data }));
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find imports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return false;
    });
};

export const updateTemplate = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTemplate(data)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Updated", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.updateExist({ name: data.templateName, id: data.id }));
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find imports";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false;
    });
};

export const getAllTemplates = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllTemplates(params)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        dispatch(actions.templatesFetched({ entities: [], totalCount: 0 }));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.templatesFetched({
            entities: response.data.data.data,
            totalCount: response.data.data.totalCount,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find pages";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteTemplate = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTemplate(id)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        dispatch(actions.endCall({ callType: callTypes.action }));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.templateDeleted({
            id,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find page";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return null;
    });
};

export const getQuestionsByCategory = (category) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getQuestionsByCategory(category)
    .then((response) => {
      if (response && response.data && response.data.respType) {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return {
          entities: response.data.data,
        };
      } else {
        dispatch(actions.endCall({ callType: callTypes.list }));
        return null;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find pages";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return null;
    });
};
