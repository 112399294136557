import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function StatusOptionDialogHeader( ) {
  // Staff Redux state
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.liveChatConversation.actionsLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Add Status Option</Modal.Title>
      </Modal.Header>
    </>
  );
}
