import { createSlice } from "@reduxjs/toolkit";

const initialDomainsState = {
  listLoading: false,
  actionsLoading: false,
  accountsList: [],
  lastError: null,
  saleStatuses:[],
  chartData: {
    series: [],
    labels: [],
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const CPASlice = createSlice({
  name: "cpa",
  initialState: initialDomainsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    chartDetailsFetched: (state, action) => {
      const { chartData } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.chartData = chartData;
    },
    accountsFetched: (state, action) => {
      const { accountsList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.accountsList = [...accountsList];
    },
    saleStatusCreated:(state,action)=>{
      let { statusDetails } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.saleStatuses = state.saleStatuses.concat(statusDetails);
    },
    statusDeleted:(state,action)=>{
      let { id } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.saleStatuses = state.saleStatuses.filter(st => parseInt(st.id) !== parseInt(id));
    },
    statusFetched:(state,action)=>{
      let { statusDetails } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.saleStatuses = statusDetails;
    }
  },
});
