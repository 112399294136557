import * as requestFromServer from "./debtAnalysisCrud";
import { debtAnalysisSlice, callTypes } from "./debtAnalysisSlice";

const { actions } = debtAnalysisSlice;

export const fetchAllDomainNames = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDomainNames()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allDomainNamesFetched({ domains: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allDomainNamesFetched({ domains: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDebtAnalysisChartData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDebtAnalysisChartData(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        try {
          let originalData = { ...response.data.data },
            labels = originalData.labels,
            averageDebt = originalData.averageDebt;
          let series = [],
          averageDebtCharts = [];

          for (let i of Object.keys(averageDebt)) {
            averageDebtCharts.push({ name: i, data: averageDebt[i] });
          }
          dispatch(
            actions.chartDetailsFetched({
              labels: labels,
              averageDebt: averageDebtCharts,
            })
          );
        } catch (e) {
          console.log(e)
        }
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
