// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/dbBackupActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../DBBackupListingUIHelpers";
import { useDBBackupListingUIContext } from "../DBBackupListingUIContext";
import * as columnFormatters from "./column-formatters";

export function DBBackupListingTable() {
  // Admin Logs UI Context
  const dbBackupUIContext = useDBBackupListingUIContext();
  const dbBackupUIProps = useMemo(() => {
    return {
      ids: dbBackupUIContext.ids,
      setIds: dbBackupUIContext.setIds,
      queryParams: dbBackupUIContext.queryParams,
      setQueryParams: dbBackupUIContext.setQueryParams,
    };
  }, [dbBackupUIContext]);
  // Getting curret state of admin logs list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.dbLogs }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  // Admin Logs Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchDBListing(dbBackupUIProps.queryParams));
  }, [dbBackupUIProps.queryParams, dispatch]);

 
  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: columnFormatters.LogsColumnFormatter,
    },
    {
      dataField: "file",
      text: "Download",
      formatter: columnFormatters.DownloadColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPage: dbBackupUIProps.queryParams.pageSize,
    page: dbBackupUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
           
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  dbBackupUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
           
          );
        }}
      </PaginationProvider>
    </>
  );
}
