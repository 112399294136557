import React from "react";
import ZingChart from "zingchart-react";

export const MixedChartComponent = (props) => {
  return (
    <div>
      <ZingChart data={getOptions(props)} />
    </div>
  );
};

const getOptions = (props) => {
  let myConfig = {
    type: "mixed",
    backgroundColor: "none", // This is in the root
    plotarea: {
      backgroundColor: "transparent",
    },
    legend: {
      adjustLayout: true,
      align: "center",
      verticalAlign: "bottom",
    },
    "scale-x": {
      labels: props.labels,
      position: { x: 20, y: 100 },
      "item":{  
        "font-size":"9px"  
    }  
    },

    "scale-y": {
      format: "%v%",
      "item":{  
        "font-size":"9px"  
    }  
    },
    "scale-y-2": {
      step:5 ,  
      "item":{  
        "font-size":"9px"  
    }  
    },
    crosshairX: {
      "line-color": "#efefef",
      "plot-label": {
        "border-radius": "5px",
        "border-width": "1px",
        "border-color": "#f6f7f8",
        padding: "10px",
        "font-weight": "bold",
      },
      "scale-label": {
        "font-color": "#000",
        "background-color": "#f6f7f8",
        "border-radius": "5px",
      },
    },
    series: props.values,
  };
  return myConfig;
};
