import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AdminLogs } from "../modules/AdminLogs/pages/AdminLogs";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function AdminLogsPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("admin_logs")
  ) {
    return <AdminLogs />;
  } else {
    return <UnauthorizedAccess />;
  }
}
