import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { FunnelPerformance } from "../modules/FunnelPerformance/pages/FunnelPerformance";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function FunnelPerformancePage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions,permissionsObj } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
      permissionsObj: state.auth.user.permissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("funnel_performance")
  ) {
    return <FunnelPerformance userInfo={{userType,permissions:permissionsObj}}/>;
  } else {
    return <UnauthorizedAccess />;
  }
}
