import { createSlice } from "@reduxjs/toolkit";

const initialliveChatState = {
  listLoading: false,
  actionsLoading: false,
  averageDebt: [],
  labels:[],
  allDomainNames:[],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const debtAnalysisSlice = createSlice({
  name: "debt_analysis",
  initialState: initialliveChatState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    allDomainNamesFetched: (state, action) => {
      const { domains } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allDomainNames = domains;
    },
    chartDetailsFetched: (state, action) => {
      const { averageDebt,labels } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.averageDebt = averageDebt;
      state.labels = labels;
    },
  },
});
