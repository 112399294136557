import { createSlice } from "@reduxjs/toolkit";

const initialDomainsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  allDomainNames: [],
  entities: null,
  domainList: [],
  lastError: null,
  chartData: {
    series: [],
    labels: [],
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const domainsSlice = createSlice({
  name: "domain-analysis",
  initialState: initialDomainsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    domainsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    domainsList: (state, action) => {
      const { domainList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.domainList = domainList;
    },
    allDomainNamesFetched: (state, action) => {
      const { domains } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allDomainNames = state.allDomainNames.concat(domains);
    },
    chartDetailsFetched: (state, action) => {
      const { chartData } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.chartData = chartData;
    },
  },
});
