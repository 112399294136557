import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function findAdSpent(queryParams) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params:queryParams };
  return axios.get(`${API_URL}/ad-spent`, header);
}

export function addAccount(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/ad-spent/accounts`,details, header);
}

export function findAccounts(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }, params:details};
  return axios.get(`${API_URL}/ad-spent/accounts`, header);
}

export function deleteAccount(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/ad-spent/delete-accounts`,details, header);
}

export function addOrUpdateSpent(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/ad-spent/add-or-update`,details, header);
}
export function findLeadSources() {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.get(`${API_URL}/ad-spent/fetch-lead-sources`, header);
}

