import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useDomainsUIContext } from "../DomainsUIContext";
import * as actions from "../../../_redux/domainsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import SelectSearch from 'react-select-search';
import Switch from "@material-ui/core/Switch";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";

// START: Used for multi select domain list
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
// END: Used for multi select domain list

import { makeStyles } from "@material-ui/core/styles";
// import 'react-select-search/style.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useHistory } from "react-router";
import { FormControlLabel } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import FilterPopoverDomainAnalysis from "../../../../../../_metronic/layout/components/filterDropdown/FilterPopoverDomainAnalysis";
import { updateDomainFilter } from "../../../../../../redux/timezone/timezoneActions";
import momentTZ from 'moment-timezone';
import { useLocation } from 'react-router-dom';
const offSet = momentTZ().tz("America/New_York").format("Z")

const prepareFilter = (queryParams, values) => {
  const { domain, startDate, endDate } = values;
  const newQueryParams = { ...queryParams };
  // Filter by domain
  if (domain && domain !== "")
    newQueryParams.domain = domain !== "" ? domain : undefined;
  else delete newQueryParams.domain;

  // Filter by
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  return newQueryParams;
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
export function DomainsFilter({ listLoading }) {
  // Domains UI Context
  // const classes = useStyles();
  const history = useHistory();
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      queryParams: domainsUIContext.queryParams,
      setQueryParams: domainsUIContext.setQueryParams,
    };
  }, [domainsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(domainsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, domainsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      domainsUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchDomainsList());
  }, [null, dispatch]);

  const { currentState,defaultDomains } = useSelector(
    (state) => ({ currentState: state.domainAnalysis,
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  // START: Used for multi select domain list
  const { domainList, entities } = currentState;
  let domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    domainsState = [];
  }, [location])

  const [barChart, setBarChart] = React.useState(false);

  const refreshData = () => {
    dispatch(actions.fetchDomainsAnalysis(domainsUIProps.queryParams));
  };

  // END: Used for multi select domain list
  return (
    <>
      <Formik
        initialValues={{
          domain: domainsUIProps.queryParams.domain,
          startDate: domainsUIProps.queryParams.startDate,
          endDate: domainsUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <Row className="align-items-center w-100 mb-3">
              <Col lg="12" md="12" sm="12">
              <FilterPopoverDomainAnalysis
                  domainEntities={entities}   
                  value={domainsState}
                  fieldName={" Domain "}
                  options={domainList}
                  onApply={(val) => { 
                    // dispatch(updateDomainFilter(val));  
                    setFieldValue("domain", val.join(","));
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Domain
                </small>
              </Col>
            </Row>
            <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
              <FormControlLabel
                  control={
                    <Switch
                      checked={barChart}
                      onChange={(e) => {
                        setBarChart(true);
                        history.push("/domain-analysis/bar-charts");
                      }}
                    />
                  }
                  label="BarCharts Per Minute"
                /></div>
             <div className="col-xl-4 col-lg-5 col-md-5 col-sm-10 col-9">
                <div className="input-group">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");

                      setFieldValue("startDate", startDate);
                      setFieldValue("endDate", endDate);

                      handleSubmit();
                    }}
                    initialSettings={{
                      startDate: moment(domainsUIProps.queryParams.startDate),
                      endDate: moment(domainsUIProps.queryParams.endDate),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years"),
                          moment.utc().utcOffset(offSet),
                        ],
                        Today: [
                          moment.utc().utcOffset(offSet),
                          moment.utc().utcOffset(offSet),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek'),
                          moment.utc().utcOffset(offSet).endOf('isoweek'),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                          moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month"),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={refreshData}
                  title="Refresh"
                >
                  <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
