import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./BarChartUIHelpers";

const BarChartsUIContext = createContext();

export function useBarChartsUIContext() {
  return useContext(BarChartsUIContext);
}

export const BarChartUIConsumer = BarChartsUIContext.Consumer;

export function BarChartUIProvider({children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initDomain = {
    // id: 0,
    duration:"",
    domain_name: "",
    // leadsource: 0,
    // cpv_lab_api_key:"",
    // cpv_lab_url: ""
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initDomain,
  };

  return <BarChartsUIContext.Provider value={value}>{children}</BarChartsUIContext.Provider>;
}