import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Contacts} from "../modules/Contacts/pages/Contacts";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function ContactsPage() {
    // Getting curret state of Users list from store (Redux)
    const {userType, permissions, permissionsObj} = useSelector(
        (state) => ({
            userType: state.auth.user.userType,
            permissions: state.auth.user.sectionPermissions,
            permissionsObj: state.auth.user.permissions,
        }),
        shallowEqual
    );
    if (
        (userType && userType.toLowerCase()) === "admin" ||
        permissions.includes("contacts")
    ) {
        return <Contacts userInfo={{userType, permissions: permissionsObj}}/>;
    } else {
        return <UnauthorizedAccess/>;
    }
}
