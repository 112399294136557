// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { useLeadStatusAnalysisUIContext } from "../LeadStatusAnalysisUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/leadStatusAnalysisActions";
import { ChartComponent } from "./ChartComponent";
export function LeadStatusAnalysisTable() {
  const leadStatusAnalysisUiContext = useLeadStatusAnalysisUIContext();
  const leadStatusAnalysisUiProps = useMemo(() => {
    return {
      queryParams: leadStatusAnalysisUiContext.queryParams,
      setQueryParams: leadStatusAnalysisUiContext.setQueryParams,
    };
  }, [leadStatusAnalysisUiContext]);
  // Getting curret state of customers list from store (Redux)
  const { currentState, timezoneBy } = useSelector(
    (state) => ({
      currentState: state.leadStatusAnalysis,
      timezoneBy: state.timezoneBy.timezone,
    }),
    shallowEqual
  );

  useEffect(() => {
    leadStatusAnalysisUiProps.setQueryParams((prev) => ({
      ...prev,
      filterByDomainTZ: timezoneBy,
    }));
  }, [timezoneBy]);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.fetchLeadStatusAnalysis(leadStatusAnalysisUiProps.queryParams)
    );
  }, [leadStatusAnalysisUiProps.queryParams, dispatch]);

  useEffect(() => {
    dispatch(actions.fetchDomainsList());
    dispatch(actions.fetchColumns());
  }, [null, dispatch]);

  const generateList = () => {
    if (!entities) return <div>Loading</div>;

    const keys = Object.keys(entities);
    let ind = -1;
    return (
      <div className="row">
        {keys.map((domain, domainIndex) => {
          const valueKeys = Object.keys(entities[domain]); //.sort();
          const labels = [];
          const values = [];
          const colorsArr = [];

          let colorBoundry = [];
          if (columns) {
            const colorRange = [
              "#8517b3",
              "#187de4",
              "#1bc5bd",
              "#F64E60",
              "#FFA800",
              "#8950FC",
              "#1bc5bd",
              "#8ba646",
              "#121dca",
              "#338004",
              "#90283c",
              "#37f2a4",
              "#6a5f1b",
              "#752eaf",
              "#d07118",
              "#e5af0f",
              "#fb19d4",
              "#82afc2",
              "#2fa58a",
              "#0d2b81",
              "#32a8bd",
              "#44f366",
              "#c9c596",
              "#13287f",
              "#d45ef9",
              "#4e9da4",
              "#2e3785",
              "#e7edfc",
              "#2d2035",
              "#37a345",
              "#80434a",
              "#6a9425",
              "#f1eea5",
              "#f8e6d3",
              "#88a2d8",
              "#48e8ea",
              "#18b26f",
              "#842db9",
              "#9d3419",
              "#2691ba",
              "#a9b6b3",
              "#1de23b",
              "#a5b9a1",
              "#a49cb3",
              "#3754ae",
              "#c2469f",
              "#e7e275",
              "#7bbca0",
              "#a503ec",
              "#c6a884",
              "#7dfa09",
              "#ac0ff9",
              "#d5adab",
              "#8517b3",
              "#e17c0e",
            ];
            let index = 0;
            columns.forEach((element) => {
              colorBoundry.push({
                label: element.text,
                color: colorRange[index++],
              });
            });
          }

          let total = 0;
          let index = 0;
          valueKeys.forEach((valueKey) => {
            if (valueKey !== "total") {
              labels.push(valueKey);
              values.push(entities[domain][valueKey]);

              // let isFound = false
              // colorBoundry.forEach(val => {
              //   if(!isFound){
              //     if(val.label === valueKey){
              //       colorsArr.push(val.color)
              //       isFound = true
              //     }
              //   }
              // })

              let color_ = colorBoundry.map((val) =>
                val.label.toLowerCase() === valueKey.toLowerCase()
                  ? val.color
                  : ""
              );
              color_ = color_.filter(function(el) {
                return el != "";
              });
              colorsArr.push(color_[0]);

              // count[valueKey] = entities[domain][valueKey];
            } else {
              total = entities[domain][valueKey];
            }
          });
          if (domain === "") {
            return <></>;
          }

          ind++;
          return (
            // <div className={"col bg-light-primary px-6 py-8 rounded-xl mb-7 col-6 "+(domainIndex%2 ? '':'mr-6')} key={domainIndex} style={{'maxWidth':'49%'}}>
            <div
              className={
                "bg-light-primary px-6 py-8 rounded-xl mb-7 col-lg-6 col-md-6 col-sm-12 domain-analysis-chart " +
                ind +
                " " +
                (ind % 2 === 0 ? " domain-analysis-mr-6 " : "")
              }
              key={(ind + makeid(5)).toString()}
            >
              <div
                className="pb-4 text-center"
                style={{ borderBottom: "1px solid #e2e2e2" }}
              >
                <a
                  href="#"
                  className="text-primary font-weight-bold font-size-h4"
                >
                  {domain}
                </a>
              </div>
              <div className="mt-3">
                <ChartComponent
                  type="pie"
                  id={"kt_mixed_widget_1_chart" + makeid(5)}
                  labels={labels}
                  values={values}
                  colorRange={colorsArr}
                />
              </div>
              {/* <a
                  href="#"
                  className="text-primary font-weight-bold font-size-h5"
                  style={{position:'absolute', right: '45px', bottom: '15px',marginLeft:'25px'}}
                >
                  {'Total Leads: ' + total}
                  {
                    Object.keys(count).map(key => {
                      return <div key={key}>{`Total ${key}: ` + count[key]}</div>  
                    })
                  }
                </a> */}
            </div>
          );
        })}
      </div>
    );
  };
  const { entities, listLoading, columns } = currentState;
  return (
    <>
      {listLoading ? (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      ) : entities && Object.keys(entities).length > 0 ? (
        generateList()
      ) : (
        <div>No records found</div>
      )}
    </>
  );
}
