// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import {ChartComponent} from './ChartComponent';
import axios from 'axios';

export function OtherStateChart(props) {
  
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
  return (
    <>
        <div className={"bg-light-primary px-6 py-8 rounded-xl mb-7 col-lg-12 col-md-12 col-sm-12"} key={1}>
          <div className="pb-4 text-center" style={{'borderBottom': '1px solid #e2e2e2'}}>
          <a
            href="#"
            className="text-primary font-weight-bold font-size-h4"
          >
           Other State Report
          </a>
          </div>
          <div className="mt-3">
            <ChartComponent type='pie' id={"kt_mixed_widget_1_chart"+makeid(5) } labels={props.otherStateLabel} values={props.otherStateValue} filterBy='otherState' />
          </div>
        </div>
    </>
);
}