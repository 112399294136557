import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LeadAnalysisFilter } from "./lead-analysis-filter/LeadAnalysisFilter";
import { LeadAnalysisTable } from './lead-analysis-table/LeadAnalysisTable';
export function  LeadAnalysisCard() {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const getSelectedColumns = (col) => {
    setSelectedColumns(col);
  }

  return (
    <Card>
      {/* <CardHeader title="Lead Analysis">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <LeadAnalysisFilter getColumns={getSelectedColumns} />
        <LeadAnalysisTable selectedColumnsData={selectedColumns} />
      </CardBody>
    </Card>
  );
}
