import React, { useEffect, useMemo, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/staffManagementActions";
import { AddUserDialogHeader } from "./AddUserDialogHeader";
import { AddProfileForm } from "./tabs/AddProfileForm";
import { AddPermissionForm } from "./tabs/AddPermissionForm";
import { useStaffManagementUIContext } from "../StaffManagementUIContext";

export function AddUserDialog({ id, show, onHide }) {
  const [tabKey, setTabKey] = useState("profile");
  const [profileInfo, setProfileInfo] = useState({});

  // Staff UI Context
  const staffUIContext = useStaffManagementUIContext();
  const staffUIProps = useMemo(() => {
    return {
      initUser: staffUIContext.initUser,
    };
  }, [staffUIContext]);

  // Staff Redux state
  const dispatch = useDispatch();
  let { actionsLoading, permissions, error } = useSelector(
    (state) => ({
      actionsLoading: state.staffManagement.actionsLoading,
      permissions: state.staffManagement.rolePermissions,
      error: state.staffManagement.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchRolePermissions());
  }, [dispatch]);

  const saveUserInfoAndNext = (userInfo) => {
    setProfileInfo(userInfo);
    setTabKey("permissions");
  };

  const saveStaffUser = (userInfo) => {
    dispatch(actions.saveStaffUser(userInfo)).then((success) => {
      if (success) {
        onHide(setTabKey);
      }
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={(e) => onHide(setTabKey)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AddUserDialogHeader id={id} />
      <Modal.Body>
      <Tabs
        id="controlled-tab-example"
        activeKey={tabKey}
        onSelect={(key) => setTabKey(key)}
      >
        <Tab eventKey="profile" title="Profile">
          <AddProfileForm
            saveUserInfoAndNext={saveUserInfoAndNext}
            actionsLoading={actionsLoading}
            user={staffUIProps.initUser}
            allPermissions={permissions}
            onHide={(e) => onHide(setTabKey)}
          />
        </Tab>
        <Tab eventKey="permissions" title="Permissions" disabled>
          {/* <Sonnet /> */}
          <AddPermissionForm
            saveStaffUser={saveStaffUser}
            actionsLoading={actionsLoading}
            profileInfo={{ profileInfo, roleId: "" }}
            allPermissions={permissions}
            onHide={(e) => onHide(setTabKey)}
            setTabKey={setTabKey}
            error={error}
          />
        </Tab>
      </Tabs>
      </Modal.Body>
    </Modal>
  );
}
