import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./QuestionManagementUIHelpers";

const QuestionManagementUIContext = createContext();

export function useQuestionManagementUIContext() {
  return useContext(QuestionManagementUIContext);
}

export const QuestionManagementUIConsumer = QuestionManagementUIContext.Consumer;

export function QuestionManagementUIProvider({ customersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
  });
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return (
    <QuestionManagementUIContext.Provider value={value}>
      {children}
    </QuestionManagementUIContext.Provider>
  );
}
