import axios from "axios";
import userTableMock from "../__mocks__/userTableMock";
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

const API_URL = process.env.REACT_APP_API_URL;

export function login(email, password) {

  // return new Promise((resolve,reject)=>{
  //   if (email && password) {
  //     const user = userTableMock.find(
  //       x =>
  //         x.email.toLowerCase() === email.toLowerCase() &&
  //         x.password === password
  //     );
  //     if (user) {
  //       resolve([200, { ...user, password: undefined }]);
  //     }else {
  //       reject([400]);
  //     }
  //   }
  // })
  return axios.post(API_URL + '/users/login', { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(Authorization) {
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);
  return new Promise((resolve,reject)=>{
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (accessToken) {
      const user = userTableMock.find(x => x.accessToken === accessToken);

      if (user) {
        resolve([200, { ...user, password: undefined }])
      }
    }
    reject([401]);
  })
}




