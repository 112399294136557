import * as requestFromServer from "./liveChatCrud";
import { liveChatSlice, callTypes } from "./liveChatSlice";

const { actions } = liveChatSlice;

export const fetchAllDomainNames = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDomainNames()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allDomainNamesFetched({ domains: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allDomainNamesFetched({ domains: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getLiveChatChartData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLiveChatChartData(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        let originalData = { ...response.data.data },
          labels = originalData.labels,
          totalValids = originalData.totalValid,
          totalChats = originalData.totalChats;
        delete originalData.labels;
        delete originalData.totalValid;
        delete originalData.totalChats;

        let series = [],
          domainCharts = [],
          tableData = [];
        console.log(queryParams);

        Object.keys(totalValids).map((i) => {
          return {
            domain_name: i,
            series: [
              {
                name: "Percentage",
                data: originalData[i],
              },
              {
                name: "Total Valid Leads",
                data: totalValids[i],
              },
            ],
          };
        });
        if (queryParams.domains) {
          tableData = Object.keys(totalChats).map((i) => {
            let arr = [...originalData[i]];
            let totalOfValids = totalValids[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalOfChats = totalChats[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalPercentage = totalOfValids
              ? Math.abs(((totalOfChats / totalOfValids) * 100).toFixed(2))
              : 0;
            return {
              domain_name: i,
              percentage: [totalPercentage].concat(arr),
              totalValids: [totalOfValids].concat(totalValids[i]),
              totalChats: [totalOfChats].concat(totalChats[i]),
            };
          });

          domainCharts = Object.keys(totalValids).map((i) => {
            return {
              domain_name: i,
              series: [
                {
                  name: "Percentage",
                  data: originalData[i],
                },
                {
                  name: "Total Valid Leads",
                  data: totalValids[i],
                },
              ],
            };
          });

          series = Object.keys(originalData).map((i) => {
            return { name: i, data: originalData[i] };
          });
        } else {
          for (let i of Object.keys(totalChats)) {
            let arr = [...originalData[i]];
            let totalOfValids = totalValids[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalOfChats = totalChats[i].reduce((prev, curr) => {
              return prev + curr;
            }, 0);
            let totalPercentage = totalOfValids
              ? Math.abs(((totalOfChats / totalOfValids) * 100).toFixed(2))
              : 0;
            if (totalOfValids > 0 && totalOfChats > 0) {
              domainCharts.push({
                domain_name: i,
                series: [
                  {
                    name: "Percentage",
                    data: originalData[i],
                  },
                  {
                    name: "Total Valid Leads",
                    data: totalValids[i],
                  },
                ],
              });
              series.push({ name: i, data: originalData[i] });
              tableData.push({
                domain_name: i,
                percentage: [totalPercentage].concat(arr),
                totalValids: [totalOfValids].concat(totalValids[i]),
                totalChats: [totalOfChats].concat(totalChats[i]),
              });
            }
          }
        }
        // let tableData = Object.keys(totalChats).map((i) => {

        // });

        dispatch(
          actions.chartDetailsFetched({
            allConversions: series,
            domainCharts: domainCharts,
            labels: labels,
            tableData: tableData,
          })
        );
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
