
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "25", value: 25 }
];
export const initialFilter = {
  sortOrder: "asc", // asc||desc
  sortBy: "id",
  search:""
};
