// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment-timezone";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { InputLabel, MenuItem } from "@material-ui/core";
import {
  Input,
  TextArea
} from "../../../../../../_metronic/_partials/controls";
import { Select } from "formik-material-ui";

// Validation schema
const validation = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  text: Yup.mixed().required("Text is required"),
});

export function ConversationAddEditForm({
  saveDetails,
  id,
  initials,
  actionsLoading,
  onHide,
  options
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initials}
        validationSchema={validation}
        onSubmit={(values) => {
          saveDetails(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="col-12 mt-3">
                  <InputLabel id="demo-simple-select-helper-label">
                    Please Select Status
                  </InputLabel>
                  <Field
                    component={Select}
                    name="status"
                    inputProps={{
                      id: "statusId",
                    }}
                    className="form-control"
                    placeholder="Select Status"
                  >
                    {options.map((list, i) => {
                      return (
                        <MenuItem value={list.name} key={i}>
                          {list.name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </div>
                <div className="col-12 mt-3">
                  <Field
                    name="text"
                    rows={4}
                    component={TextArea}
                    placeholder="Text Description"
                    label="Text Description"
                    withFeedbackLabel={false}
                    showEnterInLabel={false}
                  />
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
