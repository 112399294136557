import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ConversationUIHelpers";

const ConversationUIContext = createContext();

export function useConversationUIContext() {
  return useContext(ConversationUIContext);
}

export const ConversationUIConsumer = ConversationUIContext.Consumer;

export function ConversationUIProvider({children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initDomain = {
    status: "",
    text: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initDomain,
  };

  return <ConversationUIContext.Provider value={value}>{children}</ConversationUIContext.Provider>;
}