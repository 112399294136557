import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";
import { QuestionManagement } from "../modules/QuestionManagement/pages/QuestionManagement";

export function QuestionManagementPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions,permissionsObj } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
      permissionsObj: state.auth.user.permissions,
    }),
    shallowEqual
  );
  if ((userType && userType.toLowerCase())=== "admin" || permissions.includes("question_manage")) {
    return <QuestionManagement userInfo={{userType,permissions:permissionsObj}}/>;
  } else {
    return <UnauthorizedAccess />;
  }
}
