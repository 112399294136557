// import * as moment from 'moment';
import * as moment from "moment";
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")

export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const months = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};
export const year = new Date().getFullYear();
export const years = Array.from(new Array(80), (val, index) => year - index);

export const sizePerPageList = [{ text: "25", value: 25 }];
export const initialFilter = {
  year: year,
  month: Object.keys(months)[
    moment
      .utc()
      .utcOffset(offSet)
      .month()
  ],
  days: 31,
};
