import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/domainsActions";
import { DomainEditDialogHeader } from "./DomainEditDialogHeader";
import { DomainEditForm } from "./DomainEditForm";
import { useDomainsUIContext } from "../DomainsUIContext";

export function DomainEditDialog({ id, show, onHide }) {
  // Domains UI Context
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      initDomain: domainsUIContext.initDomain,
    };
  }, [domainsUIContext]);

  // Domains Redux state
  const dispatch = useDispatch();
  let { actionsLoading, domainForEdit, domainList, allState } = useSelector(
    (state) => ({
      actionsLoading: state.domains.actionsLoading,
      domainForEdit: state.domains.domainForEdit,
      domainList: state.domains.entities,
      allState: state.domains
    }),
    shallowEqual
  );

  // domainForEdit = {id: 1, domain_name: 'test', leadsource: 10};
  
  useEffect(() => {
    let domainEdit = domainList.filter(el => el.id === parseInt(id))
    dispatch(actions.fetchDomain({ domainForEdit: domainEdit[0] }));
    // dispatch(actions.fetchDomain(id));
  }, [id, dispatch]);

  

  // server request for saving domain
  const saveDomain = (domain) => {
    
    if (id == 0) {
      // server request for creating domain
      dispatch(actions.createDomain(domain)).then(() => onHide());
    } else {
      domain.id = id;
      // server request for updating domain
      dispatch(actions.updateDomain(domain)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <DomainEditDialogHeader id={id} />
      <DomainEditForm
        saveDomain={saveDomain}
        id={id}
        actionsLoading={actionsLoading}
        domain={domainForEdit || domainsUIProps.initDomain}
        onHide={onHide}
      />
    </Modal>
  );
}
