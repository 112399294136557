/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import routes from "./routeObj";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  let { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );

  const renderSubmenu = (prop,ind) => {
    const RootIcon = prop.icon;

    return (
      <li
      key={ind}
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          prop.to,
          true
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className="menu-link menu-toggle" to={prop.to}>
          <span className="svg-icon menu-icon">
            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/> */}
            <RootIcon />
          </span>
          <span className="menu-text">{prop.name}</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">{prop.name}</span>
              </span>
            </li>
            {prop.subComponents.map((prop2, key) => {
              if (
                (userType && userType.toLowerCase() === "admin") ||
                permissions.includes(prop2.accessCode)
              ) {
                const Icon = prop2.icon;
                return (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      prop2.to,
                      false
                    )}`}
                    aria-haspopup="true"
                    key={key}
                  >
                    <NavLink className="menu-link" to={prop2.to}>
                      <span className="svg-icon menu-icon">
                        {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/> */}
                        <Icon />
                      </span>
                      <span className="menu-text">{prop2.name}</span>
                    </NavLink>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      </li>
    );
  };

  const renderListItems = (e) => {
    return routes.map((prop, key) => {
      if (
        (userType && userType.toLowerCase() === "admin") ||
        permissions.includes(prop.accessCode) ||
        (Array.isArray(prop.accessCode) &&
          prop.accessCode.some((r) => permissions.includes(r)))
      ) {
        const Icon = prop.icon;
        return prop.submenu ? (
          renderSubmenu(prop,key)
        ) : (
          <li
            className={`menu-item ${getMenuItemActive(prop.to, false)}`}
            aria-haspopup="true"
            key={key}
          >
            <NavLink className="menu-link" to={prop.to}>
              <span className="svg-icon menu-icon">
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/> */}
                <Icon />
              </span>
              <span className="menu-text">{prop.name}</span>
            </NavLink>
          </li>
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {renderListItems()}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
