import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { DBBackupListingTable } from "./db-backup-listing-table/DBBackupListingTable";

export function DBBackupListingCards() {
  return (
    <Card>
      {/* <CardHeader title="DB Backup Details"></CardHeader> */}
      <CardBody>
        <DBBackupListingTable />
      </CardBody>
    </Card>
  );
}
