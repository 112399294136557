import React from "react";
import { DBBackupListingLoadingDialog } from "./db-backup-listing-loading-dialog/DBBackupListingLoadingDialog";
import { DBBackupListingUIProvider } from "./DBBackupListingUIContext";
import { DBBackupListingCards } from "./DBBackupListingCards";

export function DBBackupListingPage({ history }) {
  const adminLogsUIEvents = {
    
  }

  return (
    <DBBackupListingUIProvider adminLogsUIEvents={adminLogsUIEvents}>
      <DBBackupListingLoadingDialog />
      <DBBackupListingCards />
    </DBBackupListingUIProvider>
  );
}
