import {createSlice} from "@reduxjs/toolkit";

const initialDomainsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities:[],
  options:[],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const conversationSlice = createSlice({
  name: "domains",
  initialState: initialDomainsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Options Fetched
    optionFetched: (state, action) => {
      const { options } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.options = options;
    },
    //option created and push new one to listing
    optionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.options = state.options.concat(action.payload.optionDetails);
    },
    // delete option
    optionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.options = state.options.filter(
        (el) => el.id != action.payload.details.id
      );
    },

    // Texts Fetched
    textsFetched: (state, action) => {
      const { data,count } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = count;
    },
    //text created and push new one to listing
    textCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.concat(action.payload.details);
      state.totalCount = state.totalCount +1;
    },
    // delete text
    textDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.totalCount = state.totalCount -1;
      state.entities = state.entities.filter(
        (el) => el.id != action.payload.details.id
      );
    },
    // update text
    textUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
    },
  }
});
