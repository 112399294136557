import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./StaffManagementUIHelpers";

const StaffManagementUIContext = createContext();

export function useStaffManagementUIContext() {
  return useContext(StaffManagementUIContext);
}

export const StaffManagementUIConsumer = StaffManagementUIContext.Consumer;

export function StaffManagementUIProvider({adminLogsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);
  const initUser = {
    firstName:"",
    lastName:"",
    password: "",
    email: "",
    phone:"",
    profileImage:"",
    profileName:""
  };
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUser
  };

  return <StaffManagementUIContext.Provider value={value}>{children}</StaffManagementUIContext.Provider>;
}