import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { QuestionsTable } from "./questions-table/QuestionsTable";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText, InputBase, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useQuestionManagementUIContext } from "./QuestionManagementUIContext";
const API_URL = process.env.REACT_APP_API_URL;


const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export function QuestionManagementCard({userInfo}) {
  const { permissions, userType } = userInfo;
  const history = useHistory();
  const [defaultLimit, setDefaultLimit] = useState("");
  const [categoeryFilter, setCategoeryFilter] = useState("All");
  const quesManageUIContext = useQuestionManagementUIContext();
  const quesManageUIProps = useMemo(() => {
    return {
      queryParams: quesManageUIContext.queryParams,
      setQueryParams: quesManageUIContext.setQueryParams,
    };
  }, [quesManageUIContext]);


  const {filterBy} = quesManageUIProps.queryParams;

  useEffect(() => {
    Axios.get(`${API_URL}/question-management/category-limit`).then(
      (success) => {
        if (success && success.data && success.data.respType) {
          setDefaultLimit(success.data.data.count);
        } else {
          setDefaultLimit(0);
        }
      }
    );
  }, []);
  const saveLimit = (val) => {
    Axios.put(`${API_URL}/question-management/update-limit`, {
      limit: val,
    }).then((success) => {
      if (success && success.data && success.data.respType) {
        setDefaultLimit(val)
        toast.success("Successfully Updated", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <Card>
      {/* Questions Management */}
      <CardHeader title="">
        <CardHeaderToolbar>
        {(userType.toLowerCase() === "admin" ||
            permissions["question_manage"].includes("add")) && (
          <Button
            id={"add-questions"}
            className="add-btn"
            variant="primary"
            onClick={(e) => {
              history.push("/question-management/add-new");
            }}
          >
            Add New Question
          </Button>
            )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between align-items-end mb-2">
        {(userType.toLowerCase() === "admin" ||
            permissions["question_manage"].includes("limit")) && (
          <Form.Group className="mb-0">
            {defaultLimit !== "" && (
              <>
                <Form.Label>
                  <strong>Question Limit Per LP:</strong>
                </Form.Label>
                <Form.Control
                  type="number"
                  className="shadow-sm"
                  defaultValue={defaultLimit}
                  id="questionLimit"
                  name="questionLimit"
                  placeholder="Enter Limit"
                  onBlur={(e) => {
                    if (e.target.value <= 0) {
                      toast.error("*Limit can not be less than 1!", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    } else {
                      saveLimit(e.target.value);
                    }
                  }}
                />
              </>
            )}
          </Form.Group>
            )}
       

       <FormControl style={{width:"120px"}}>
            <InputLabel htmlFor="categoryId"></InputLabel>
            <FormHelperText><b>Filter</b> by Category</FormHelperText>
            <Select
              id="categoryId"
              className="w-100 shadow-sm"
              
              value={filterBy}
              onChange={(e) => {
                quesManageUIContext.setQueryParams({...quesManageUIContext.queryParams,filterBy:e.target.value})
              }}
              renderValue={(selected) => {
                if (selected === "All") {
                  return selected;
                }
                else{
                  return "Category "+selected; 
                }
                
              }}
              input={<BootstrapInput className="w-100" />}
            >

              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              <MenuItem value="All" >All</MenuItem>
              {Array.from(Array(defaultLimit).keys()).map(ind=>{
                return <MenuItem value={ind+1} key={ind}>Category {ind+1}</MenuItem>
              })}
            </Select>
          </FormControl>


        </div>
        {/* <DomainsFilter /> */}
        <QuestionsTable userInfo={userInfo}/>
      </CardBody>
    </Card>
  );
}
