// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }
        
        const options = props.type && props.type === 'pie' ? getPieChartOptions(props) : {};

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px" }}
            ></div>
        </>
    );
}
function getPieChartOptions(props) {
    const colors = {
        2:['#187de4', '#1bc5bd'],
        3:['#187de4', '#F64E60', '#1bc5bd'],
        5:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
    };
    var options = {
        series: props.values,
        chart: {
            width: props.filterBy == 'otherState' ? 700 : 450,
            type: 'pie',
        },
        labels: props.labels,
        legend: {
            position: 'right',
            markers: {
                width: 5,
                radius: 0
            },
            width: props.filterBy == 'otherState' ? 240 : 180,
            tooltipHoverFormatter: function(seriesName, opts) {
                return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            },
            // inverseOrder: true
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 350,
                },
                legend: {
                    position: 'bottom',
                    height: 80,
                    width: 310,
                }
            }
        },{
            breakpoint: 769,
            options: {
                chart: {
                    width: 350,
                    type: 'pie',
                },
                legend: {
                    position: 'bottom',
                    markers: {
                        width: 5,
                        radius: 0
                    },
                    width: 310,
                    height: 60,
                }
            }
        },{
            breakpoint: 1025,
            options: {
                chart: {
                    width: 350,
                    type: 'pie',
                },
                legend: {
                    position: 'right',
                    markers: {
                        width: 5,
                        radius: 0
                    },
                    width: 180,
                }
            }
        }],
        colors: colors[ props.labels.length]?colors[ props.labels.length]:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC', '#1bc5bd'],
        plotOptions: {
            pie: {
              dataLabels: {
                  offset: -35,
                  minAngleToShowLabel: 0
              }, 
            }
        },
        tooltip: {
            fillSeriesColor: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let sum = 0, value = series[seriesIndex],color = w.config.colors[seriesIndex] ? w.config.colors[seriesIndex] : '#187de4';
                let label = w.config.labels[seriesIndex] ? w.config.labels[seriesIndex] : 'NA';
                let dataArr = w.config.series ? w.config.series : [];
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(1)+"%";
                return '<div class="arrow_box" style="background:'+color+';padding:10px;">' +
                  '<span>' + label + '</span></br>' +
                  '<span> Count = ' + value + '</span></br>' +
                  '<span>' + percentage +  '</span>' +
                  '</div>'
              }
          }
    };
    return options;
}