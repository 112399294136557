import React from "react";
import { Route } from "react-router";
import { StaffManagementLoadingDialog } from "./staff-management-loading-dialog/StaffManagementLoadingDialog";
import { StaffManagementUIProvider } from "./StaffManagementUIContext";
import { StaffMnagementCards } from "./StaffMnagementCards";
import { AddUserDialog } from "./add-user-dialog/AddUserDialog";
import { UpdateUserDialog } from "./update-user-dialog/UpdateUserDialog";
import { StaffUserDeleteDialog } from "./staff-user-delete-dialog/StaffUserDeleteDialog";


export function StaffManagementPage(props) {
  const staffManageUIEvents = {
    
  }

  return (
    <StaffManagementUIProvider staffManageUIEvents={staffManageUIEvents}>
      <StaffManagementLoadingDialog />
      <StaffMnagementCards {...props}/>
      <Route path="/staff-management/add-new">
        {({ history, match }) => (
          <AddUserDialog
            show={match != null}
            id={match && match.params.id}
            onHide={(setTab) => {
              history.push("/staff-management");
              setTab("profile")
            }}
          />
        )}
      </Route>
      <Route path="/staff-management/edit/:id">
        {({ history, match }) => (
          <UpdateUserDialog
            show={match != null}
            id={match && match.params.id}
            onHide={(setTab) => {
              history.push("/staff-management");
              setTab("profile")
            }}
          />
        )}
      </Route>
      <Route path="/staff-management/delete/:id">
        {({ history, match }) => (
          <StaffUserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/staff-management");
            }}
          />
        )}
      </Route>
    </StaffManagementUIProvider>
  );
}