import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DBBackupListing } from "../modules/DB-BackupListing/pages/DBBackupListing";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function DBBackupListingPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin") {
    return <DBBackupListing />;
  } else {
    return <UnauthorizedAccess />;
  }
}
