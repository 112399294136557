import React from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";

import { RolesTable } from "./roles-table/RolesTable";

export function RoleCards() {
  const history = useHistory();

  return (
    <Card>
      <CardHeader title="Staff Roles">
        <CardHeaderToolbar>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push("/roles/add");
            }}
          >
            Add New Role
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <RolesTable />
      </CardBody>
    </Card>
  );
}
