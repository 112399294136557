import React, { useEffect, useMemo, useState } from "react";
import * as actions from "../../../_redux/landingPageActions"
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {useLandingPageUIContext} from "../LandingPagesUIContext";
import * as uiHelpers from "../LandingPagesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export function LandingPagesTable({ userInfo }) {
  const { permissions, userType } = userInfo;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId,setPopoverId] = useState(null);
  
  const history = useHistory();

// Pages UI Context
const PagesUIContext = useLandingPageUIContext();
const PagesUIProps = useMemo(() => {
  return {
    ids: PagesUIContext.ids,
    setIds: PagesUIContext.setIds,
    queryParams: PagesUIContext.queryParams,
    setQueryParams: PagesUIContext.setQueryParams,
  };
}, [PagesUIContext]);

const { currentState } = useSelector(
    (state) => ({ currentState: state.landingPages }),
    shallowEqual
  );

  const { entities, listLoading,totalCount,tenant } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getAllLandingPages(PagesUIProps.queryParams));
  }, [PagesUIProps.queryParams]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "ques_action",
      text: "Questions",
      formatter: columnFormatters.QuestionColumnFormatter,
    },
    {
      dataField: "action",
      text: "Scripts",
      formatter: columnFormatters.ScriptsCopyColumnFormatter,
    //   hidden:
    //     userType.toLowerCase() === "admin" ||
    //     permissions["domain_manage"].includes("edit")
    //       ? false
    //       : true,

      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      formatExtraData: {
        anchorEl, setAnchorEl,classes,popoverId,setPopoverId,
        tenant
      },
    },
    {
      dataField: "action2",
      text: "Action",
      formatter: columnFormatters.ActionsColumnFormatter,
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["question_manage"].includes("update")
          ? false
          : true,

      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      formatExtraData: {
        tenant
      },
    },
  ];
 
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: PagesUIProps.queryParams.pageSize,
    page: PagesUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                    PagesUIProps.setQueryParams
                  )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}



