import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

export function ImportCardSpinner() {

  // Customers Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.import.fetching }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);

  return (
    <div className="d-flex align-items-center">
      <div className="mr-2 text-muted">Loading...</div>
      <div className="spinner spinner-primary mr-10"></div>
    </div>
  );
}
