import {createSlice} from "@reduxjs/toolkit";

const initialImportState = {
  listLoading: false,
  actionsLoading: false,
  entities:[],
  percentCompletion: 0
};

const IMPORT_STATUS = "INQUEUE";

export const callTypes = {
  list: "list",
  action: "action",
  fetching: "fetching"
};

export const importSlice = createSlice({
  name: "import",
  initialState: initialImportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.fetching = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true; // When click on something
      } else {
        state.fetching = true; // During get API call
      }
    },
    uploadFileStart:(state, action)=>{
      state.isImportingInProgress = true;
    },
    uploadFileEnd:(state, action)=>{
      state.isImportingInProgress = false;
      state.fetching = false;
      state.actionsLoading = false;
      state.error = null;
      state.importStatus = IMPORT_STATUS;
    },
    importFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    importStarted: (state) => {
      state.importStatus = IMPORT_STATUS;
      state.fetching = false;
      state.actionsLoading = false;
      state.error = null;
      if(state.isImportingInProgress == true)
        state.isImportingInProgress = false;
        state.importStatus = IMPORT_STATUS;
    },
    importStatusFetched: (state, action) => {
      const { processed_records, total_records, status, id } = action.payload.data;
      if(status && state.entities && action.payload.data && Object.keys(action.payload.data).length > 0){
        state.entities.forEach(row => {
          if(row.id === id){
            row.total_records = total_records;
            row.processed_records = processed_records;
            row.status = status;
          }
        })
        state.percentCompletion = processed_records > 0 && total_records > 0 ? processed_records/total_records*100: 0;
        state.fetching = false;
      }else if(state.importStatus !== 'NO_ACTIVE_QUEUE'){
        if(state.entities){
          state.entities.forEach(row => {
            if(row.status !== 'COMPLETED'){
              row.processed_records = row.total_records;
              row.status = 'COMPLETED';
            }
          })
        }
        state.importStatus = 'NO_ACTIVE_QUEUE';
        state.fetching = false;
      }
    }
  }
});
