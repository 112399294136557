import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { DomainsFilter } from "./domains-filter/DomainsFilter";
import { ConversationDataTable } from "./conversation-data-table/ConversationDataTable";
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

export function ConversationCard({ userInfo }) {
  const { permissions, userType } = userInfo;
  const [textManage, setTextManage] = React.useState(true);

  const history = useHistory();

  return (
    <Card>
      <CardHeader className="bg-danger text-white">
        <div class="card-title ">
          {/* <h3 className="font-weight-bolder text-white">LiveChat Conversation Management</h3> */}
        </div>
        <CardHeaderToolbar>
          <FormControlLabel
            className="switch-text"
            control={
              <Switch
                checked={textManage}
                onChange={(e) => {
                  setTextManage(false);
                  history.push("/live-chat/conversation");
                }}
              />
            }
            label="Text Management"
          />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="text-right mb-3">
            <Button
              id="add-domain"
              variant="contained"
              color="secondary"
              className="mr-2"
              onClick={() => {
                history.push(
                  "/live-chat/conversation/text-management/add-new"
                );
              }}
            >
              Add New Text
            </Button>
            <Button
              id="add-domain"
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(
                  "/live-chat/conversation/text-management/options"
                );
              }}
            >
              Add Status Option
            </Button>
        </div>
        <ConversationDataTable userInfo={userInfo} />
      </CardBody>
    </Card>
  );
}
