/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import {useLocation} from "react-router-dom";
// import {QuickActions} from "./components/QuickActions";
import {BreadCrumbs} from "./components/BreadCrumbs";
import {getBreadcrumbsAndTitle, useSubheader} from "../../_core/MetronicSubheader";
import {useHtmlClassService} from "../../_core/MetronicLayout"
import * as moment from 'moment';
import * as actions from "../../../../redux/timezone/timezoneActions";
import momentTZ from 'moment-timezone';
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/styles";
import { FormHelperText, InputBase, MenuItem, Select } from "@material-ui/core";
import { Topbar } from "../header/Topbar";

const offSet = momentTZ().tz("America/New_York").format("Z");


const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "110px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);



export function SubHeader() {

  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);

  const { timezoneBy } = useSelector(
    (state) => ({
      timezoneBy:state.timezoneBy.timezone
    }),
    shallowEqual
  );
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  // change timezone type
  const dispatch = useDispatch();
  
  const changeTimezone = (e) => {
    let tz = e.target.value
    dispatch(actions.updateTimezoneBy({ timezone: tz}));
  }

  const isTimezoneFilterEnabled = ['/questions-analysis', '/domain-analysis/bar-charts','/domain-analysis', '/leads/analysis', '/leads/status-analysis', '/leads/compare-analysis'].includes(location.pathname)

  return (
      <div
          id="kt_subheader"
          className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
      >
        <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
                <button
                    className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                    id="kt_subheader_mobile_toggle"
                >
                  <span/>
                </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <>
                  {subheader.title}
                </>
                {/* <small></small> */}
              </h5>

            </div>


            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>

          {/* Toolbar */}
          <div className="d-flex align-items-center">
            {isTimezoneFilterEnabled?
              <FormControl>
              <Select
                className="shadow-sm mr-2"
                
                onChange={changeTimezone}
                defaultValue={timezoneBy}
                input={<BootstrapInput className="w-100" />}
              >
                <MenuItem value="" disabled>

              Select timezone
                </MenuItem>
                <MenuItem value="master" >Master Timezone</MenuItem>
                <MenuItem value="domain" >Domain Timezone</MenuItem>
              </Select>
            </FormControl>
            :
              ''
            }

            <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">{offSet === "-04:00" ?"EDT":"EST"} Time</span>
            <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{moment().utc().utcOffset(offSet).format('DD,MMM YYYY hh:mm:ss')}</span>
            </a>
            {/*begin::Topbar*/}
            <div className="kt_topbar_ontop">
              <Topbar />
            </div>
            {/*end::Topbar*/}
            {/* <QuickActions/>   moment().format('DD,MMM YYYY hh:mm:ss')  */}
          </div>
        </div>
      </div>
  );
}
