import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LandingPages } from "../modules/LandingPagesManagement/pages/LandingPages";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";

export function LandingPagesManagementPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions,permissionsObj } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
      permissionsObj: state.auth.user.permissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("landing_pages")
  ) {
    return <LandingPages userInfo={{userType,permissions:permissionsObj}} />;
  } else {
    return <UnauthorizedAccess />;
  }
}
