import { Table } from "react-bootstrap";
import React, { useMemo } from "react";
import { useAdsSpentUIContext } from "../AdsSpentUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import * as actions from "../../../_redux/AdsSpentActions";

const useStyles = makeStyles((theme) => ({
  greyBack: {
    backgroundColor: "lightgrey",
  },
}));

export const AdsSpentTable = ({ userInfo }) => {
  const { permissions, userType } = userInfo;

  const classes = useStyles();
  const adSpentUIContext = useAdsSpentUIContext();
  const adSpentUIProps = useMemo(() => {
    return {
      queryParams: adSpentUIContext.queryParams,
    };
  }, [adSpentUIContext]);

  // Getting curret state of Ad spent list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.adSpent }),
    shallowEqual
  );

  const { month, year, days } = adSpentUIProps.queryParams;
  const { entities, listLoading } = currentState;
  const dispatch = useDispatch();

  const changeValues = (value, day, leadSource) => {
    let dataToSend = { day, month, year, spent: value, leadSource };
    dispatch(actions.addOrUpdateSpentValue(dataToSend));
  };
  const renderSpentList = () => {
    return (
      <div className="ad-spent">
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="headcol">Account</th>
              <th className={classes.greyBack}>Monthly</th>
              {Array.from(Array(days).keys()).map((el, i) => {
                return (
                  <th className="long" key={i}>
                    {i + 1} {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(entities).map((account, k) => {
              return (
                <tr key={k}>
                  <th className="headcol">{account}</th>
                  {entities[account].map((val, j) => {
                    return j === 0 || account === "Total" ? (
                      <th key={j} className={classes.greyBack}>
                        ${val.toFixed(2)}
                      </th>
                    ) : (
                      <td className="long" key={j}>
                        {userType.toLowerCase() === "admin" ||
                        permissions["ad_spent"].includes("update") ? (
                          <>
                            ${"   "}
                            <input
                              id={"spent-value" + k + j}
                              type="number"
                              step="0.01"
                              defaultValue={val.toFixed(2)}
                              onFocus={(e) => {
                                if (parseInt(e.target.value) === 0) {
                                  document.getElementById(
                                    "spent-value" + k + j
                                  ).value = "";
                                }
                              }}
                              onBlur={(vl) => {
                                if (vl.target.value === "") {
                                  document.getElementById(
                                    "spent-value" + k + j
                                  ).value = "0.00";
                                } else {
                                  document.getElementById(
                                    "spent-value" + k + j
                                  ).value = parseFloat(vl.target.value).toFixed(
                                    2
                                  );
                                }
                                changeValues(vl.target.value, j, account);
                              }}
                            />
                          </>
                        ) : (
                          `$${val.toFixed(2)}`
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <>
      {listLoading ? (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      ) : (
        renderSpentList()
      )}
    </>
  );
};
