/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import momentTZ from "moment-timezone";
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
const useStyles1 = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
const API_URL = process.env.REACT_APP_API_URL;
function getWeeklyStat() {
  return axios.get(`${API_URL}/dashboard/getWeeklyLeadCounts`);
}
function getHourlyLeadsState() {
  return axios.get(`${API_URL}/dashboard/getHourlyLeads`);
}
function getComparisonStatesAPI(startDate) {
  return axios.get(`${API_URL}/dashboard/getComparisonState`, {
    params: { date: startDate },
  });
}
function getAllStat(startDate, endDate) {
  return axios.get(
    `${API_URL}/dashboard/getStats?startDate=${startDate}&endDate=${endDate}`
  );
}
export function LeadStat({ className, userInfo }) {
  //-----Get user info userType and permissions
  const { permissions, userType } = userInfo;

  const classes = useStyles();
  const classes1 = useStyles1();
  const [startDate, setStartDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59"
  );
  const [weekLeads, setWeekLeads] = React.useState(0);
  const [hourlyLeads, setHourlyLeads] = React.useState(0);
  const [currentDate, setCurrentDate] = React.useState(0);
  const [yesterDayComparison, setYesterDayComparison] = React.useState({
    total: 0,
    BK: 0,
    VALID: 0,
  });
  const [
    lastWeekSameDayComparison,
    setLastWeekSameDayComparison,
  ] = React.useState({
    total: 0,
    BK: 0,
    VALID: 0,
  });
  const [totalLeads, setTotalLeads] = React.useState(0);
  const [goodLeads, setGoodLeads] = React.useState(0);
  const [badLeads, setBadLeads] = React.useState(0);
  const [livechatLeads, setLivechatLeads] = React.useState(0);
  const [goodLeadDebtAvg, setGoodLeadDebtAvg] = React.useState(0);

  const updateWeeklyStat = () => {
    getWeeklyStat().then((res) => {
      if (res.data && res.data.respType === "Success") {
        setWeekLeads(res.data.data.totalLeads);
      }
    });
  };
  const updateAllStat = (startDate, endDate) => {
    getAllStat(startDate, endDate)
      .then((res) => {
        if (res.data && res.data.respType === "Success") {
          setTotalLeads(res.data.data.total? res.data.data.total:0);
          setBadLeads(res.data.data.BK ? res.data.data.BK : 0);
          setGoodLeads(res.data.data.VALID ? res.data.data.VALID : 0);
          setLivechatLeads(res.data.data.livechat ? res.data.data.livechat : 0);
          setGoodLeadDebtAvg(res.data.data.avg_debt ? res.data.data.avg_debt : 0);
        }
      })
  };

  const refreshData = (startDate, endDate) => {
    axios.get(`${API_URL}/live-chat/updateLatestData`).then(
      (response) => {
        updateAllStat(startDate, endDate);
        updateWeeklyStat();
        getHourlyLeads();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getHourlyLeads = () => {
    getHourlyLeadsState().then((res) => {
      if (res.data && res.data.respType === "Success") {
        setHourlyLeads(res.data.data.avg_leads);
      }
    });
  };

  const getComparisonStates = (date) => {
    getComparisonStatesAPI(date).then((res) => {
      if (res.data && res.data.respType === "Success") {
        setYesterDayComparison(res.data.data.yesterday);
        setLastWeekSameDayComparison(res.data.data.last_same_day);
      }
    });
  };

  const checkDateAndLoadData = () => {
    let updatedCurrentDate = moment
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD");
    if (currentDate != updatedCurrentDate) {
      setCurrentDate(updatedCurrentDate);
    }
  };
  const loadData = () => {
    updateWeeklyStat();
    updateAllStat(startDate, endDate);
    getHourlyLeads();
    getComparisonStates(startDate);
  };
  useEffect(() => {
    loadData();
  }, [currentDate]);

  useEffect(() => {
    let updatedCurrentDate = moment
      .utc()
      .utcOffset("-05:00")
      .format("YYYY-MM-DD");
    setCurrentDate(updatedCurrentDate);
    window.addEventListener("focus", checkDateAndLoadData);
    return () => {
      window.removeEventListener("focus", checkDateAndLoadData);
    };
  }, []);

  const renderLeadsThisWeek = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0">This Week</p>
          <h3 className="box-title">Total Leads</h3>
          <ul className="list-inline two-part d-flex justify-content-between">
            <li>
              <i className="far fa-chart-bar"></i>
            </li>
            <li className="ms-auto">
              <span className="counter">{weekLeads}</span>
            </li>
          </ul>
        </div>
      </div>

      // <div className="col bg-light-primary px-6 py-4 rounded-xl mr-7 mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a href="#" className="text-primary font-weight-bold font-size-h6">
      //         Total Leads This Week
      //       </a>
      //       <a
      //         href="#"
      //         className="text-primary font-weight-bold font-size-h2"
      //         style={{ marginLeft: "25px" }}
      //       >
      //
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderTotalLeads = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0">
            From {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
            {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
          <h3 className="box-title">Total Leads</h3>
          <ul className="list-inline two-part d-flex justify-content-between">
            <li>
              <i className="far fa-chart-bar" style={{ color: "#7460ee" }}></i>
            </li>
            <li className="ms-auto">
              <span className="counter" style={{ color: "#7460ee" }}>
                {totalLeads}
              </span>
            </li>
          </ul>
        </div>
      </div>
      // <div className="col bg-light-primary px-6 py-4 rounded-xl mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a
      //         href="#"
      //         className="text-primary font-weight-bold font-size-h6 mt-2"
      //       >
      //         Total Leads From{" "}
      //         {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
      //         {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
      //       </a>
      //       <a
      //         href="#"
      //         className="text-primary font-weight-bold font-size-h2 mt-2"
      //         style={{ marginLeft: "25px" }}
      //       >
      //         {totalLeads}
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderTotalGoodLeads = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0 text-primary">
            From {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
            {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
          <h3 className="box-title text-primary">Total Good Leads</h3>
          <ul className="list-inline two-part d-flex justify-content-between mb-0">
            <li>
              <i className="far fa-chart-bar" style={{ color: "#11a1f8" }}></i>
            </li>
            <li className="ms-auto">
              <span className="counter" style={{ color: "#11a1f8" }}>
                {goodLeads}
              </span>
            </li>
          </ul>
          <p className="mt-1 mb-0 text-primary font-weight-bold">
            {goodLeads > 0
              ? "(" +
                ((goodLeads / (goodLeads + badLeads)) * 100).toFixed(2) +
                "% of Total Leads)"
              : "(0.00% of Total Leads)"}
          </p>
        </div>
      </div>
      // <div className="col bg-light-success px-6 py-4 rounded-xl mr-7 mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a
      //         href="#"
      //         className="text-success font-weight-bold font-size-h6 mt-2"
      //       >
      //         Total Good Leads From{" "}
      //         {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
      //         {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
      //       </a>
      //       <br />
      //       <a
      //         href="#"
      //         className="text-success font-weight-bold font-size-h3 mt-2"
      //       >
      //         {goodLeads} Leads{" "}
      //         {goodLeads > 0
      //           ? "(" +
      //             ((goodLeads / (goodLeads + badLeads)) * 100).toFixed(2) +
      //             "% of total leads)"
      //           : ""}
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderTotalBadLeads = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0 text-danger">
            From {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
            {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
          <h3 className="box-title text-danger">Total Bad Leads</h3>
          <ul className="list-inline two-part d-flex justify-content-between mb-0">
            <li>
              <i className="far fa-chart-bar text-danger"></i>
            </li>
            <li className="ms-auto">
              <span className="counter text-danger">{badLeads}</span>
            </li>
          </ul>
          <p className="mt-1 mb-0 text-danger font-weight-bold">
            {badLeads > 0
              ? "(" +
                ((badLeads / (goodLeads + badLeads)) * 100).toFixed(2) +
                "% of total leads)"
              : "(0.00% of Total Leads)"}
          </p>
        </div>
      </div>
      // <div className="col bg-light-primary px-6 py-4 rounded-xl mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a
      //         href="#"
      //         className="text-danger font-weight-bold font-size-h6 mt-2"
      //       >
      //         Total Bad Leads From{" "}
      //         {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
      //         {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
      //       </a>
      //       <br />
      //       <a
      //         href="#"
      //         className="text-danger font-weight-bold font-size-h3 mt-2"
      //       >
      //         {badLeads} Leads{" "}
      //         {badLeads > 0
      //           ? "(" +
      //             ((badLeads / (goodLeads + badLeads)) * 100).toFixed(2) +
      //             "% of total leads)"
      //           : ""}
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderTodayLeadsComparedToYesterday = (e) => {
    return (
      <div className="col-lg-6 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <h3 className="box-title text-primary mb-0">
            {startDate ===
            moment
              .utc()
              .utcOffset(offSet)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
              ? "Today's Lead"
              : moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY") + " Leads"}
          </h3>
          <p className="mt-0 text-primary">
            {startDate ===
            moment
              .utc()
              .utcOffset(offSet)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
              ? "Compared to yesterday"
              : "Compared to " +
                moment(startDate, "YYYY-MM-DD")
                  .subtract(1, "days")
                  .format("DD-MM-YYYY") +
                " Leads"}
          </p>

          <div className="text-center mb-2">
            <a
              href="#"
              className="text-primary font-weight-bold mt-2"
              style={{ fontSize: "18px" }}
            >
              <MergeTypeIcon /> Total Leads{" "}
              <span className="ml-5">{yesterDayComparison.total}%</span>
            </a>
          </div>
          <ul className="list-inline two-part d-flex justify-content-between">
            <li>
              <a
                href="#"
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                <TrendingUpIcon /> Good Leads{" "}
                <span className="ml-4">{yesterDayComparison.VALID}%</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                <TrendingDownIcon /> Bad Leads{" "}
                <span className="ml-8">{yesterDayComparison.BK}%</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTodayLeadsComparedToLastWeek = (e) => {
    return (
      <div className="col-lg-6 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <h3 className="box-title text-primary mb-0">
            {startDate ===
            moment
              .utc()
              .utcOffset(offSet)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
              ? "Today's Lead"
              : moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY") + " Leads"}
          </h3>
          <p className="mt-0 text-primary">
            {startDate ===
            moment
              .utc()
              .utcOffset(offSet)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
              ? "Compared to Last week on same day"
              : "Compared to " +
                moment(startDate, "YYYY-MM-DD")
                  .subtract(7, "days")
                  .format("DD-MM-YYYY") +
                " Leads"}
          </p>

          <div className="text-center mb-2">
            <a
              href="#"
              className="text-primary font-weight-bold mt-2"
              style={{ fontSize: "18px" }}
            >
              <span>
                <MergeTypeIcon />
              </span>{" "}
              Total Leads{" "}
              <span className="ml-5">{lastWeekSameDayComparison.total}%</span>
            </a>
          </div>
          <ul className="list-inline two-part d-flex justify-content-between">
            <li>
              <a
                href="#"
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                <TrendingUpIcon /> Good Leads{" "}
                <span className="ml-4">{lastWeekSameDayComparison.VALID}%</span>
              </a>
            </li>
            <li className="ms-auto">
              <a
                href="#"
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                <TrendingDownIcon /> Bad Leads{" "}
                <span className="ml-8">{lastWeekSameDayComparison.BK}%</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderHourlyLeads = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0">Average based on last 6 hours</p>
          <h3 className="box-title">Hourly Leads</h3>
          <ul className="list-inline two-part d-flex justify-content-between">
            <li>
              <i className="far fa-chart-bar" style={{ color: "#11a1f8" }}></i>
            </li>
            <li className="ms-auto">
              <span className="counter" style={{ color: "#11a1f8" }}>
                {hourlyLeads}
              </span>
            </li>
          </ul>
        </div>
      </div>
      // <div className="col bg-light-primary px-6 py-4 rounded-xl mr-7 mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a
      //         href="#"
      //         className="text-primary font-weight-bold font-size-h6 mt-2"
      //       >
      //         Hourly Leads (on average leads based on last 6 hours)
      //       </a>
      //       <a
      //         href="#"
      //         className="text-primary font-weight-bold font-size-h2 mt-2"
      //         style={{ marginLeft: "25px" }}
      //       >
      //         {hourlyLeads}
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderGoodLeadDebtAvg = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-primary rounded-xl text-center">
          <p className="mb-0 text-primary">
            From {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
            {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
          <h3 className="box-title text-primary">Good Leads Debt Average</h3>
          <ul className="list-inline two-part d-flex justify-content-between mb-0">
            <li>
              <i className="far fa-chart-bar" style={{ color: "#11a1f8" }}></i>
            </li>
            <li className="ms-auto">
              <span className="counter" style={{ color: "#11a1f8" }}>
                {goodLeadDebtAvg}
              </span>
            </li>
          </ul>
          <p className="mt-1 mb-0 text-primary font-weight-bold invisible">
            {goodLeads > 0
              ? "(" +
                ((goodLeads / (goodLeads + badLeads)) * 100).toFixed(2) +
                "% of Total Leads)"
              : "(0.00% of Total Leads)"}
          </p>
        </div>
      </div>
    );
  };


  const renderTotalLivechatLeads = (e) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="white-box bg-light-danger rounded-xl text-center">
          <p className="mb-0 text-primary">
            From {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
            {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
          <h3 className="box-title text-primary">Total Livechats</h3>
          <ul className="list-inline two-part d-flex justify-content-between mb-0">
            <li>
              <i className="far fa-chart-bar text-primary"></i>
            </li>
            <li className="ms-auto">
              <span className="counter text-primary">{livechatLeads}</span>
            </li>
          </ul>
          <p className="mt-1 mb-0 text-primary font-weight-bold">
            {livechatLeads > 0
              ? "(" +
                ((livechatLeads * 100) / goodLeads).toFixed(2) +
                "% of total leads)"
              : "(0.00% of Total Leads)"}
          </p>
        </div>
      </div>
      // <div className="col-lg-4 col-md-6 col-sm-12 ">
      //   <div className="white-box bg-light-primary rounded-xl text-center">
      //     <p className="mb-0">

      //     </p>
      //     <h3 className="box-title">Total Livechats</h3>
      //     <ul className="list-inline two-part d-flex justify-content-between">
      //       <li>
      //         <i className="far fa-chart-bar" style={{ color: "#11a1f8" }}></i>
      //       </li>
      //       <li className="ms-auto">
      //         <span className="counter" style={{ color: "#11a1f8" }}>
      //           {livechatLeads}
      //         </span>
      //       </li>
      //     </ul>
      //   </div>
      // </div>
      // <div className="col bg-light-info px-6 py-4 rounded-xl mb-7">
      //   <div className="row">
      //     <div className="col-2">
      //       <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
      //         <SVG
      //           src={toAbsoluteUrl(
      //             "/media/svg/icons/Communication/Add-user.svg"
      //           )}
      //         ></SVG>
      //       </span>
      //     </div>
      //     <div className="col-10">
      //       <a
      //         href="#"
      //         className="text-info font-weight-bold font-size-h6 mt-2"
      //       >
      //         Total Livechats From{" "}
      //         {moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} To{" "}
      //         {moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
      //       </a>
      //       <br />
      //       <a
      //         href="#"
      //         className="text-info font-weight-bold font-size-h3 mt-2"
      //       >
      //         {livechatLeads} LiveChats{" "}
      //         {livechatLeads > 0
      //           ? "(" +
      //             ((livechatLeads * 100) / goodLeads).toFixed(2) +
      //             "% of total leads)"
      //           : ""}
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        {/* <div className="card-header align-items-center border-0 py-5 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              Lead Stats
            </span>
          </h3>
        </div> */}
        {/* Body */}
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0" style={{ height: "150px" }}>
          <form className={classes.container} noValidate>
            <div className="col-lg-5">
              <div className="input-group shadow-sm">
                {/* <DateRangePicker onCallback={(start, end, label) => {
                      let startDate = moment(start).format('YYYY-MM-DD');
                      let endDate = moment(end).format('YYYY-MM-DD');

                      setStartDate(startDate + ' 00:00:00');
                      setEndDate(endDate + ' 23:59:59');
                    }}
                    initialSettings={{ startDate: moment(), endDate: moment(),
                    ranges: {
                      'Life Time': [moment().subtract(10, 'years'), moment()],
                      'Today': [moment(), moment()],
                      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                      'This week': [moment().subtract(6, 'days'), moment()],
                      'Last week': [moment().subtract(7, 'days'), moment().subtract(13, 'days')],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    },
                    locale: {
                      format: 'MMMM DD, YYYY'
                    },
                    alwaysShowCalendars: true
                  }}
                > */}
                <DateRangePicker
                  onCallback={(start, end, label) => {
                    let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                    let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                    setStartDate(startDate);
                    setEndDate(endDate);
                    updateAllStat(startDate, endDate);
                    getComparisonStates(startDate);
                  }}
                  initialSettings={{
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                    ranges: {
                      "Life Time": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(10, "years"),
                        moment.utc().utcOffset(offSet),
                      ],
                      Today: [
                        moment.utc().utcOffset(offSet),
                        moment.utc().utcOffset(offSet),
                      ],
                      Yesterday: [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "days"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "days"),
                      ],
                      "Last 7 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(6, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "Last 14 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(13, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "Last 30 Days": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(29, "days"),
                        moment.utc().utcOffset(offSet),
                      ],
                      "This week": [
                        moment.utc().utcOffset(offSet).startOf('isoweek'),
                        moment.utc().utcOffset(offSet).endOf('isoweek'),
                      ],
                      "Last week": [
                        moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                        moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                      ],
                      "This Month": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .startOf("month"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .endOf("month"),
                      ],
                      "Last Month": [
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "month")
                          .startOf("month"),
                        moment
                          .utc()
                          .utcOffset(offSet)
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                    },
                    locale: {
                      format: "MMMM DD, YYYY",
                    },
                    alwaysShowCalendars: true,
                  }}
                >
                  <input type="text" className="form-control" />
                  {/* <div className='input-group' >
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-calendar-alt"></i>
                        </span>
                    </div>
                  </div> */}
                </DateRangePicker>

                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-calendar-check"></i>
                  </span>
                </div>
              </div>
              <small className="form-text text-muted">
                <b>Filter</b> by Date
              </small>
            </div>
            {/* <div>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event)=>{
                  setStartDate(event.target.value + ' 00:00:00');
                }}
              />
              <TextField
                id="date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event)=>{
                  setEndDate(event.target.value + ' 23:59:59');
                }}
              />
            </div> */}
            <Button
              variant="contained"
              color="secondary"
              className={classes1.button}
              onClick={() => {
                refreshData(startDate, endDate);
              }}
            >
              Get Lead Stats
            </Button>
            {/*<div>*/}
            {/*  Current EST Time : {moment().utc().utcOffset('-04:00').format('YYYY-MM-DD HH:mm:ss')}*/}
            {/*</div>*/}
          </form>
        </div>
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("totalLeadsWeek")) &&
              renderLeadsThisWeek()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("totalLeadBetweenTimeFrame")) &&
              renderTotalLeads()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("hourlyLeads")) &&
              renderHourlyLeads()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("totalGoodLeads")) &&
              renderTotalGoodLeads()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("totalBadLeads")) &&
              renderTotalBadLeads()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes("totalGoodLeads")) &&
              renderGoodLeadDebtAvg()}  
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes(
                "todayLeadCompareToYesterday"
              )) &&
              renderTodayLeadsComparedToYesterday()}
            {(userType.toLowerCase() === "admin" ||
              permissions["dashboard"].includes(
                "todayLeadCompareToLastWeek"
              )) &&
              renderTodayLeadsComparedToLastWeek()}
          </div>
        </div>
      </div>
    </>
  );
}
