import React from "react";
import { Registry } from "react-form-builder2";

export const MyInput = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled, placeholder, type } = props;
  return (
    <input
      ref={ref}
      type={type}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
});
export const registerInputs = () => {
  Registry.register("First_Name", MyInput);
  Registry.register("Last_Name", MyInput);
  Registry.register("Email", MyInput);
  Registry.register("Phone", MyInput);
};
