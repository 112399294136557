import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as actions from "../_redux/debtAnalysisActions";
import {updateDomainFilter} from "../../../../redux/timezone/timezoneActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import CachedIcon from "@material-ui/icons/Cached";
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import RestoreIcon from "@material-ui/icons/Restore";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import FilterPopoverDebt from "../../../../_metronic/layout/components/filterDropdown/FilterPopoverDebt";
import momentTZ from 'moment-timezone';
import {Tooltip, OverlayTrigger} from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;
const offSet = momentTZ().tz("America/New_York").format("Z")

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingTop: 0,
    paddingleft: 5,
  },
  searchFilter: {
    padding: "10px",
    height: "30px",
    fontSize: "10px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));

export function DebtAnalysisFilters(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59"
  );
  const [selectedDomains, setSelectedDomains] = React.useState([]);
  const [isLatestDataUpdating, setStatusOfData] = React.useState(false);

  const { currentState,defaultDomains } = useSelector(
    (state) => ({ currentState: state.debtAnalysis,defaultDomains:state.timezoneBy }),
    shallowEqual
  );
  const { allDomainNames, listLoading } = currentState;
  const domainsState= defaultDomains.selectedDomain;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.getDebtAnalysisChartData({
        startDate,
        endDate,
        domains: domainsState.join(","),
      })
    );
  }, [domainsState]);


  const callApiOnDateFilter = (start,end)=>{
    dispatch(
      actions.getDebtAnalysisChartData({
        startDate:start,
        endDate:end,
        domains: domainsState.join(","),
      })
    );
  }

  const [isValidFilter, setIsValildFilter] = React.useState(false)
  const callApiOnValidFilter = (start,end,onlyValid)=>{
    setIsValildFilter(onlyValid)
    dispatch(
        actions.getDebtAnalysisChartData({
          startDate:start,
          endDate:end,
          domains: domainsState.join(","),
          onlyValid: onlyValid,
          onlyAB:isAbFilter,
        })
    );
  }
    const [isAbFilter, setIsAbFilter] = React.useState(false)
    const callApiOnlyABUniqueFilter = (start,end,onlyAB)=>{
        setIsAbFilter(onlyAB)
        dispatch(
            actions.getDebtAnalysisChartData({
                startDate:start,
                endDate:end,
                domains: domainsState.join(","),
                onlyAB: onlyAB,
                onlyValid:isValidFilter
            })
        );
    }

  const refreshData = (e) => {
    dispatch(
      actions.getDebtAnalysisChartData({
        startDate,
        endDate,
        domains: selectedDomains.join(","),
      })
    );
  };

  const updateLatestData = (e) => {
    setStatusOfData(true);
    axios.get(`${API_URL}/live-chat/updateLatestData`).then(
      (response) => {
        console.log(response.status);
        setStatusOfData(false);
        setTimeout(function() {
          refreshData();
        }, 1000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    dispatch(actions.fetchAllDomainNames());
  }, []);

  const applyDomainFilter = val=>{
    dispatch(updateDomainFilter(val));
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="kt_tooltip_msg_9" {...props}>
      <span>ON : Show AVG of Duplicate</span>
      <br />
      <span>OFF : Show AVG of Unique</span>
    </Tooltip>
  );

  return (
    <form className={classes.container + " w-100"} noValidate >
      <Row className="w-100 mb-3">
      <Col  xl="4" lg="12" md="12" sm="12" >
          <FilterPopoverDebt value={domainsState} debtdomainsval={props.valuesdebt} options={allDomainNames} onApply={applyDomainFilter}/>
          <small className="form-text text-muted">
            <b>Filter</b> by Domain
          </small>
        </Col>
        <Col xl="5" lg="5" md="6" sm="8" xs="9" className="mb-2">
          <div className="input-group shadow-sm">
            <DateRangePicker
              onCallback={(start, end, label) => {
                let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                setStartDate(startDate);
                setEndDate(endDate);
                callApiOnDateFilter(startDate,endDate)
              }}
              initialSettings={{
                startDate: moment(startDate),
                endDate: moment(endDate),
                ranges: {
                  "Life Time": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(10, "years"),
                    moment.utc().utcOffset(offSet),
                  ],
                  Today: [
                    moment.utc().utcOffset(offSet),
                    moment.utc().utcOffset(offSet),
                  ],
                  Yesterday: [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days"),
                  ],
                  "Last 7 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(6, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "Last 14 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(13, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "Last 30 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(29, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "This week": [
                    moment.utc().utcOffset(offSet).startOf('isoweek'),
                    moment.utc().utcOffset(offSet).endOf('isoweek'),
                  ],
                  "Last week": [
                    moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                    moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                  ],
                  "This Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .startOf("month"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .endOf("month"),
                  ],
                  "Last Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .startOf("month"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                },
                locale: {
                  format: "MMMM DD, YYYY",
                },
                alwaysShowCalendars: true,
              }}
            >
              <input
                type="text"
                className="form-control"
                disabled={listLoading}
              />
            </DateRangePicker>
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                <i className="fa fa-calendar-check"></i>
              </span>
            </div>
          </div>
          <small className="form-text text-muted">
            <b>Filter</b> by Date
          </small>
        </Col>
        <Col xl="3" lg="6" md="4" sm="5">
          <div className="kt_form_controls_filters">
          <FormControlLabel
              control={
                <Switch
                    // checked={barChart}
                    onChange={(e) => {
                        callApiOnlyABUniqueFilter(startDate,endDate,e.target.checked)
                    }}
                />
              }
              label={
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span className="kt_tooltip_mark">?</span>
                </OverlayTrigger>
              }
          />
          </div>
        </Col>
      </Row>
      <Row className="w-100">


          {/*commented as per jim request*/}
        {/*<Col xl="3" lg="4" md="4" sm="5">*/}
        {/*  <FormControlLabel*/}
        {/*      control={*/}
        {/*        <Switch*/}
        {/*            // checked={barChart}*/}
        {/*            onChange={(e) => {*/}
        {/*                callApiOnValidFilter(startDate,endDate,e.target.checked)*/}
        {/*            }}*/}
        {/*        />*/}
        {/*      }*/}
        {/*      label="Show Only Valid Leads"*/}
        {/*  />*/}
        {/*</Col>*/}

          {/*comments end*/}
        {/* <Col xl="1" lg="2" md="2" sm="2" xs="3" className="mb-2">
          {!isLatestDataUpdating ? (
            <Button
              variant="outlined"
              color="secondary"
              className="shadow-sm"
              onClick={updateLatestData}
              title="Update Latest Data"
            >
              <RestoreIcon style={{ fontSize: 25 }}></RestoreIcon>
            </Button>
          ) : (
            <CircularProgress style={{ fontSize: 25 }}></CircularProgress>
          )}
        </Col> */}
      </Row>
    </form>
  );
}
  