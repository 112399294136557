import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";
import { LiveChatAnalysisMain } from "../modules/LiveChatAnalysis/pages/LiveChatAnalysis";

export function LiveChatAnalysisPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );
  if ((userType && userType.toLowerCase())=== "admin" || permissions.includes("trends")) {
    return <LiveChatAnalysisMain />;
  } else {
    return <UnauthorizedAccess />;
  }
}
