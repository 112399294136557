import React from "react";
import { Route } from "react-router";
import { AddUpdateTemplateDialog } from "./AddUpdateTemplate/AddUpdateTemplateDialog";
import { LandingPagesTemplateLoadingDialog } from "./landing-pages-templates-loading-dialog/LandingPagesTemplateLoadingDialog";
import { TemplatessUIProvider } from "./TemplatesUIContext";
import { TemplatesCard } from "./TemplatesCard";
import { TemplateDeleteDialog } from "./temlate-delete-dialog/TemplateDeleteDialog";

export function TemplatesPage(props) {
  return (
    <TemplatessUIProvider>
      <Route path="/landing-pages/templates/add-new">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateTemplateDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/landing-pages/templates");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      <Route path="/landing-pages/templates/edit/:id">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateTemplateDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/landing-pages/templates");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      
      <Route path="/landing-pages/templates/delete/:id">
        {({ history, match }) => {
          if (match) {
            return (
              <TemplateDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/landing-pages/templates");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      <LandingPagesTemplateLoadingDialog />
      <TemplatesCard {...props} />
    </TemplatessUIProvider>
  );
}
