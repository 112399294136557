import {createSlice} from "@reduxjs/toolkit";

const initialImportState = {
  listLoading: false,
  actionsLoading: false,
  entities:[],
  totalCount: 0
};


export const callTypes = {
  list: "list",
  action: "action",
};

export const landingPagesTemplatesSlice = createSlice({
  name: "landing_pages_templates",
  initialState: initialImportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } 
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true; // When click on something
      } else {
        state.fetching = true; // During get API call
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false; // When click on something
      } else {
        state.fetching = false; // During get API call
      }
    },
    templateAdded:(state, action) => {
      const {data} = action.payload;
      state.entities = state.entities.concat([data]);
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = state.totalCount+1;;
    },
    templatesFetched:(state, action) => {
      const {entities,totalCount} = action.payload;
      state.entities = entities;
      state.listLoading = false;
      state.totalCount = totalCount;
    },
    templateDeleted:(state, action) => {
      const {id} = action.payload;
      state.entities = state.entities.filter(e=>e.id != id);
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = state.totalCount-1;
    },
    updateExist:(state, action) => {
      const {name,id} = action.payload;
      state.entities = state.entities.map(e=>{
        if(e.id ==id ){
          return {...e,name:name}
        }
        else{
          return e;
        }
      });
      state.listLoading = false;
      state.actionsLoading = false;
    }
  }
});
