// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, PasswordWithRandomButton,Checkbox } from "../../../../../../../_metronic/_partials/controls";

// Validation schema
const userValidation = Yup.object().shape({
  firstName: Yup.string().required("Role name is required"),
  lastName: Yup.string().required("Role name is required"),
  email: Yup.string().required("Role name is required"),
  password: Yup.string().required("Role name is required"),
});

export function AddProfileForm({
  saveUserInfoAndNext,
  user,
  actionsLoading,
  onHide,
}) {
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={user}
        validationSchema={userValidation}
        onSubmit={(values) => {
          saveUserInfoAndNext(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-12 mt-3">
                    <Field
                      type="file"
                      component={Input}
                      name="profileName"
                        onChange={ (e) => {
                          console.log(e.target.files[0]);
                          setFieldValue("profileImage",e.target.files[0])
                          setFieldValue("profileName",e.target.value)
                        }}
                      
                      placeholder="Profile Image"
                      label="Profile Image"
                      withFeedbackLabel={false}
                      
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name="firstName"
                      component={Input}
                      placeholder="First Name"
                      label="First Name"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name="lastName"
                      component={Input}
                      placeholder="last Name"
                      label="last Name"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      type="number"
                      name="phone"
                      component={Input}
                      placeholder="Phone"
                      label="Phone"
                      withFeedbackLabel={false}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name="password"
                      component={PasswordWithRandomButton}
                      placeholder="Password"
                      label="Password"
                      withFeedbackLabel={false}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {/* <div className="col-12 mt-3">
                  <hr className="mt-5"/>

                    <Field
                      component={Checkbox}
                      withFeedbackLabel={false}
                      setFieldValue={setFieldValue}
                      onChange={e =>{
                        setFieldValue("sendEmail",e.target.checked);
                      }}
                    >
                      <strong>Send Welcom Email&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                      </Field>
                  </div> */}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Next <i className="fas fa-angle-double-right"/>
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
