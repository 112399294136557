import React from 'react';
import {ImportPage} from './ImportPage';

export function Import({props}) {
  return (
    <div className="import-page">
      <ImportPage />
    </div>
  );
}
