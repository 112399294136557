import React from "react";
import { AdsSpentLoadingDialog } from "./ads-spent-loading-dialog/AdsSpentLoadingDialog";
import { AdsSpentUIProvider } from "./AdsSpentUIContext";
import { AdsSpentCards } from "./AdsSpentCards";
import { AccountsDialog } from "./Accounts-dialog/AccountsDialog";
import { Route } from "react-router";
import { useState } from "react";

export function AdsSpentPage(props) {
  const [updateState,setUpdateState] = useState(true)
  const AdsSpentUIEvents = {
    
  }

  return (
    <AdsSpentUIProvider adsSpentUIEvents={AdsSpentUIEvents}>
      <AdsSpentLoadingDialog />
      <Route path="/ads-spent/accounts">
        {({ history, match }) => (
          <AccountsDialog
            show={match != null}
            match={match}
            id={match && match.params.id}
            onHide={() => {
              setUpdateState(!updateState)
              history.push("/ads-spent");
            }}
          />
        )}
      </Route>
      <AdsSpentCards {...props} updateState={updateState}/>
    </AdsSpentUIProvider>
  );
}
