import React, { useEffect } from "react";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TextField from "@material-ui/core/TextField";
import { FormHelperText, InputLabel, makeStyles } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/cpaActions";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import FilterPopover from "../../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import { updateDomainFilter } from "../../../../../redux/timezone/timezoneActions";
import momentTZ from 'moment-timezone';
import { useLocation } from 'react-router-dom';
const offSet = momentTZ().tz("America/New_York").format("Z")

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: 120,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export function CPAFilters() {
  const classes = useStyles();
  const [durationFilter, setDurationFilter] = React.useState("date");
  const [selectedAccountsStatus, setSelectedAccountsStatus] = React.useState(
    []
  );

  const [startDate, setStartDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .startOf("month")
      .format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment
      .utc()
      .endOf("month")
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59"
  );

  const { currentState, defaultDomains } = useSelector(
    (state) => ({ currentState: state.cpa, defaultDomains: state.timezoneBy }),
    shallowEqual
  );
  const { accountsList, saleStatuses } = currentState;
  let domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    domainsState = [];
  }, [location]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchAccountsList());
  }, []);
  useEffect(() => {
    let selectedDomain = selectedAccountsStatus.map((d) => d.text);
    dispatch(
      actions.getChartsData({
        domainFilter: selectedDomain.join(","),
        durationFilter,
        startDate,
        endDate,
      })
    );
  }, [
    selectedAccountsStatus,
    durationFilter,
    saleStatuses,
  ]);
  const callApiOnDateFilter = (start, end) => {
    let selectedDomain = selectedAccountsStatus.map((d) => d.text);
    dispatch(
      actions.getChartsData({
        domainFilter: selectedDomain.join(","),
        durationFilter,
        start,
        end,
      })
    );
  };

  useEffect(() => {
    if (domainsState.length) {
      let leadSources = accountsList.filter((acc) =>
        domainsState.includes(acc.dataField)
      );
      setSelectedAccountsStatus(leadSources);
    }
  }, [accountsList]);

  useEffect(() => {
    let leadSources = accountsList.filter((acc) =>
      domainsState.includes(acc.dataField)
    );
    setSelectedAccountsStatus(leadSources);
  }, [domainsState]);

  const refresh = (e) => {
    let selectedDomain = selectedAccountsStatus.map((d) => d.text);
    dispatch(
      actions.getChartsData({
        domainFilter: selectedDomain.join(","),
        durationFilter,
        startDate,
        endDate,
      })
    );
  };
  return (
    <form className="mb-9" noValidate>
      <Row className="align-items-center w-100 mb-3">
        <Col lg="12" md="12" sm="12">
          <FilterPopover
            fieldName={"dataField"}
            value={domainsState}
            options={accountsList}
            onApply={(val) => {
              dispatch(updateDomainFilter(val));
            }}
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Domain
          </small>
        </Col>
      </Row>
      <Row className="w-100">
        <Col lg="5" md="12">
          <div className="input-group shadow-sm">
            <DateRangePicker
              onCallback={(start, end, label) => {
                let startDateChange = start.format("YYYY-MM-DD HH:mm:ss");
                let endDateChange = end.format("YYYY-MM-DD HH:mm:ss");
                setStartDate(startDateChange);
                setEndDate(endDateChange);
                callApiOnDateFilter(startDateChange,endDateChange);
              }}
              initialSettings={{
                startDate: moment(startDate),
                endDate: moment(endDate),
                ranges: {
                  "Life Time": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(10, "years"),
                    moment.utc().utcOffset(offSet),
                  ],
                  Today: [
                    moment.utc().utcOffset(offSet),
                    moment.utc().utcOffset(offSet),
                  ],
                  Yesterday: [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days"),
                  ],
                  "Last 7 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(6, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "Last 14 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(13, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "Last 30 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(29, "days"),
                    moment.utc().utcOffset(offSet),
                  ],
                  "This week": [
                    moment.utc().utcOffset(offSet).startOf('isoweek'),
                    moment.utc().utcOffset(offSet).endOf('isoweek'),
                  ],
                  "Last week": [
                    moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                    moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                  ],
                  "This Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .startOf("month"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .endOf("month"),
                  ],
                  "Last Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .startOf("month"),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                },
                locale: {
                  format: "MMMM DD, YYYY",
                },
                alwaysShowCalendars: true,
              }}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                <i className="fa fa-calendar-check"></i>
              </span>
            </div>
          </div>
          <small className="form-text text-muted">
            <b>Filter</b> by Date
          </small>
        </Col>
        <Col lg="4" md="4" sm="12">
          <FormControl className={classes.margin}>
            <InputLabel htmlFor="lead/account"></InputLabel>
            <Select
              id="lead/account"
              className="shadow-sm"
              value={durationFilter}
              onChange={(e) => {
                setDurationFilter(e.target.value);
              }}
              input={<BootstrapInput i />}
            >
              <MenuItem value="" disabled>
                Select Lead Source
              </MenuItem>
              <MenuItem value="date">By Day</MenuItem>
              <MenuItem value="day_name">By Week</MenuItem>
              <MenuItem value="month">By Month</MenuItem>
            </Select>
            <FormHelperText>Select Duration Filter</FormHelperText>
          </FormControl>
        </Col>
      </Row>
    </form>
  );
}
