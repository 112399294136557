import * as requestFromServer from "./leadStatusAnalysisCrud";
import {leadStatusAnalysisSlice, callTypes} from "./leadStatusAnalysisSlice";

const {actions} = leadStatusAnalysisSlice;

export const fetchLeadStatusAnalysis = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLeadStatusAnalysis(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.leadStatusAnalysisFetched({ entities: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.leadStatusAnalysisFetched({entities: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomainsList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainList()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.domainsList({ domainList: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.domainsList({domainList: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchColumns = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getColumns()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.columnsReceived({ columns: null, defaultColumns: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.columnsReceived({columns: response.data.data.columns, defaultColumns: response.data.data.columns}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLeadStatusAnalysisList = (startDate, endDate) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLeadStatusAnalysisList(startDate, endDate)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.domainsList({ domainList: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.domainsList({domainList: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateColumns = (selectedColumns) => dispatch => {
  dispatch(actions.updateColumns(selectedColumns));
}