import * as moment from 'moment';

export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const sizePerPageList = [
  { text: "25", value: 25 }
];
export const initialFilter = {
  // startDate:moment.utc().utcOffset("-04:00").format('YYYY-MM-DD') + ' 00:00:00',
  // endDate:moment.utc().utcOffset("-04:00").format('YYYY-MM-DD') + ' 23:59:59',
  // filter: {
  //   search: "",
  // },
  sortOrder: "asc",
  sortBy: "id",
  // from: 1,
  // rows: 25
};
