import { createSlice } from "@reduxjs/toolkit";

const initialliveChatState = {
  listLoading: false,
  actionsLoading: false,
  allDomainNames: [],
  allConversions: [],
  domainCharts:[],
  labels:[],
  tableData:[],
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const liveChatSlice = createSlice({
  name: "live_chat",
  initialState: initialliveChatState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    allDomainNamesFetched: (state, action) => {
      const { domains } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allDomainNames = domains;
    },
    chartDetailsFetched: (state, action) => {
      const { allConversions,domainCharts,labels,tableData } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allConversions = allConversions;
      state.labels = labels;
      state.domainCharts = domainCharts;
      state.tableData = tableData;
    },
  },
});
