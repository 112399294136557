import React from "react";
import {CustomersLoadingDialog} from "./customers-loading-dialog/CustomersLoadingDialog";
import {CustomersUIProvider} from "./CustomersUIContext";
import {CustomersCard} from "./CustomersCard";
import {Route} from "react-router-dom";
import {useHistory} from 'react-router-dom'
import {CustomerDeleteDialog} from "./customer-delete-dialog/CustomerDeleteDialog";
import {CustomersDeleteDialog} from "./customers-delete-dialog/CustomersDeleteDialog";
import {CustomersLeadStatusDialog} from "./customers-lead-status-dialog/CustomersLeadStatusDialog";

export function CustomersPage(props) {
    let history = useHistory();
    const customersUIEvents = {
        openDeleteCustomerDialog: (id) => {
            history.push(`/contacts/${id}/delete`);
        },
        openDeleteCustomersDialog: () => {
            history.push(`/contacts/deleteCustomers`);
        },
    }

    return (
        <CustomersUIProvider customersUIEvents={customersUIEvents}>
            <CustomersLoadingDialog/>
            <Route path="/contacts/:id/delete">
                {({history, match}) => (
                    <CustomerDeleteDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/contacts/");
                        }}
                    />
                )}
            </Route>
            <Route path="/contacts/:id/:date/showlogs">
                {({history, match}) => (
                    <CustomersLeadStatusDialog
                        show={match != null}
                        id={match && match.params.id}
                        date={match && match.params.date}
                        onHide={() => {
                            history.push("/contacts/");
                        }}
                    />
                )}
            </Route>
            <Route path="/contacts/deleteCustomers">
                {({history, match}) => (
                    <CustomersDeleteDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/contacts/");
                        }}
                    />
                )}
            </Route>
            <CustomersCard {...props}/>
        </CustomersUIProvider>
    );
}
