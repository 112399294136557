import { toast } from "react-toastify";
import * as requestFromServer from "./questionManagementCrud";
import { questionsManageSlice, callTypes } from "./questionManagementSlice";

const { actions } = questionsManageSlice;

export const fetchAllQuestions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllQuestions(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.questionsFetched({ entities: [], totalCount: 0 }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.questionsFetched({
            entities: response.data.data.data,
            totalCount: response.data.data.totalCount,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find questions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getAllExistingAnswers = () => (dispatch) => {
  return requestFromServer
    .getAllAnswers()
    .then((response) => {
      if (response && response.data && response.data.respType) {
        return {
          answers: response.data.data,
        };
      } else {
        return null;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find answers";
      return null;
    });
};

export const addNewQuestion = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewQuestion(data)
    .then((response) => {
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Added", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(actions.newQuestionAdded({ data: response.data.data }));
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't add new question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false;
    });
};

export const updateQuestion = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateQuestion(data)
    .then((response) => {
      dispatch(actions.endCall({ callType: callTypes.action }));
      if (
        response &&
        (response.status === 204 || response.data.respType === "Failure")
      ) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        return false;
      } else if (response && response.data && response.data.respType) {
        toast.success("Successfully Added", {
          position: toast.POSITION.TOP_CENTER,
        });
        return true;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't add new question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false;
    });
};

export const deleteQuestions = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteQuestions(id)
    .then(() => {
      dispatch(actions.questionDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
