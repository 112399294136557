import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function getAllDomainNames() {
  return axios.get(`${API_URL}/cpa/account`);
}



export function getDebtAnalysisChartData(details) {
  let header = {  params:details};
  return axios.get(`${API_URL}/dept-report/get-debt-report`, header);
}