import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function getLeadStatusAnalysis(queryParams) {
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? (key === 'domain' && Number(sessionStorage.getItem("urlChange")) === 1 ? '' : JSON.stringify(queryParams[key])) : key === 'domain' && Number(sessionStorage.getItem("urlChange")) === 1 ? '' : queryParams[key] }`;
  });
  return axios.get(`${API_URL}/cpv-tracking/getLeadStatusAnalysis?`+params);
}

// get all Domain Listing (Can get from cpa route)
export function getDomainList(){
  return axios.get(`${API_URL}/cpa/account`);
}

export function getCampaignList(domainList){
  let endPoint = `${API_URL}/cpv-tracking/getCampaignList`;
  if(domainList != null){
    endPoint += `?domainList=${domainList}`
  }
  return axios.get(endPoint);
}

export function getColumns(){
  return axios.get(`${API_URL}/cpv-tracking//getColumns`);
}

export function getLeadStatusAnalysisList(startDate, endDate) {
  // This is temporary, it will be updated soon
  return axios.get(`${API_URL}/domain-report/getStats?startDate=${startDate}&endDate=${endDate}`);
}