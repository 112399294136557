import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./QuestionsUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const QuestionsUIContext = createContext();

export function useQuestionsUIContext() {
  return useContext(QuestionsUIContext);
}

export const QuestionsUIConsumer = QuestionsUIContext.Consumer;

export function QuestionsUIProvider({customersUIEvents, children}) {
  const { defaultDomains } = useSelector(
    (state) => ({
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    filterByDomainTZ: defaultDomains.timezone,
    domain: defaultDomains.selectedDomain.join(","),
  });
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <QuestionsUIContext.Provider value={value}>{children}</QuestionsUIContext.Provider>;
}