import React, { useEffect, useState } from "react";
import { ChartComponent2 } from "./ChartComponent2";
import { BarChartComponent } from "./BarChartComponent";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Col, Row } from "react-bootstrap";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { makeStyles } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  colMargin: {
    borderRight: "5px solid grey",
    borderRadius: "4px",
  },
  compareContainer: {
    backgroundColor: "skyblue",
  },
  chartDivRight: {
    backgroundColor: "#ffe2e5!important",
    borderRadius: "12px",
  },
  chartDivLeft: {
    backgroundColor: "#e1f0ff!important",
    borderRadius: "12px",
  },
}));

export function QuestionsContent({
  category,
  firstQuestion,
  secondQuestion,
  domain,
  chartsData,
  valid,
}) {
  const classes = useStyles();
  const [firstTotalCountPer, setFirstTotalCountPer] = useState("");
  const [secondTotalCountPer, setSecondTotalCountPer] = useState("");
  const [firstGoodCountPer, setFirstGoodCountPer] = useState("");
  const [secondGoodCountPer, setSecondGoodCountPer] = useState("");
  const [firstBadCountPer, setFirstBadCountPer] = useState("");
  const [secondBadCountPer, setSecondBadCountPer] = useState("");

//   const { chartsData } = useSelector(
//     (state) => ({
//       chartsData: state.questionAnalysis["chartsData_" + category.id],
//     }),
//     shallowEqual
//   );

  useEffect(() => {
    if (chartsData) {
      let totalLeadsQ1 = chartsData.q1.goodLeads + chartsData.q1.badLeads;
      let totalLeadsQ2 = chartsData.q2.goodLeads + chartsData.q2.badLeads;
      if (totalLeadsQ1 > totalLeadsQ2) {
        let increase = totalLeadsQ1 - totalLeadsQ2,
          increasePer = ((increase / totalLeadsQ1) * 100).toFixed(2);
        setFirstTotalCountPer(increasePer);
        setSecondTotalCountPer("");
      } else if (totalLeadsQ1 < totalLeadsQ2) {
        let increase = totalLeadsQ2 - totalLeadsQ1,
          increasePer = ((increase / totalLeadsQ2) * 100).toFixed(2);
        setSecondTotalCountPer(increasePer);
        setFirstTotalCountPer("");
      }
      if (chartsData.q1.goodLeads > chartsData.q2.goodLeads) {
        let increase = chartsData.q1.goodLeads - chartsData.q2.goodLeads,
          increasePer = ((increase / chartsData.q1.goodLeads) * 100).toFixed(2);
        setSecondGoodCountPer("");
        setFirstGoodCountPer(increasePer);
      } else if (chartsData.q1.goodLeads < chartsData.q2.goodLeads) {
        let increase = chartsData.q2.goodLeads - chartsData.q1.goodLeads,
          increasePer = ((increase / chartsData.q2.goodLeads) * 100).toFixed(2);
        setSecondGoodCountPer(increasePer);
        setFirstGoodCountPer("");
      }

      if (chartsData.q1.badLeads > chartsData.q2.badLeads) {
        let increase = chartsData.q1.badLeads - chartsData.q2.badLeads,
          increasePer = ((increase / chartsData.q1.badLeads) * 100).toFixed(2);
        setFirstBadCountPer(increasePer);
        setSecondBadCountPer("");
      } else if (chartsData.q1.badLeads < chartsData.q2.badLeads) {
        let increase = chartsData.q2.badLeads - chartsData.q1.badLeads,
          increasePer = ((increase / chartsData.q2.badLeads) * 100).toFixed(2);
        setSecondBadCountPer(increasePer);
        setFirstBadCountPer("");
      }
    }
  }, [chartsData]);

  return chartsData ? (
    <Row className={classes.darkGrey + " mt-10 p-2"}>
      <Col className={classes.chartDivLeft}>
        <div className="w-100">
          <div
            className="py-4 text-center my-2"
            style={{ borderBottom: "1px solid #e2e2e2" }}
          >
            <a href="#" className="text-primary font-weight-bold font-size-h5">
              <small>({domain.firstDomain})</small>
              <br />
              1.{firstQuestion.question}
            </a>
          </div>
          <div className="text-center">
            <a
              href="#"
              className="text-primary font-weight-bold mt-3"
              style={{ fontSize: "18px" }}
            >
              <MergeTypeIcon /> Total Count{" "}
              <span className="ml-5">
                {chartsData.q1.goodLeads + chartsData.q1.badLeads}
              </span>
            </a>
          </div>
          <BarChartComponent
            id={
              category.category_name
                .toLowerCase()
                .split(" ")
                .join("_") + "_bar_chart _q1"
            }
            data={[
              {
                name: "Good Leads",
                data: [chartsData.q1.goodLeads],
              },
              {
                name: "Bad Leads",
                data: [chartsData.q1.badLeads],
              },
            ]}
          />
          <ChartComponent2
            type="pie"
            id={
              category.category_name
                .toLowerCase()
                .split(" ")
                .join("_") + "_pie_chart_q1"
            }
            labels={chartsData.q1.labels}
            data={chartsData.q1.data}
            qualifiedArr={chartsData.q1.qualifiedArr}
          />
        </div>
        {firstTotalCountPer && (
          <div className="count-inc-left">
            <span>Count</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{firstTotalCountPer}%
            </span>
          </div>
        )}

        {firstGoodCountPer && (
          <div className="good-inc-left">
            <span>Good Leads</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{firstGoodCountPer}%
            </span>
          </div>
        )}
        {firstBadCountPer && (
          <div className="bad-inc-left">
            <span>Bad Leads</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{firstBadCountPer}%
            </span>
          </div>
        )}
      </Col>
      <Col
        lg="2"
        md="2"
        xl="2"
        className={classes.chartDiv}
        style={{ minHeight: "100px" }}
      >
        <div className="text-center mt-5 comparision-div">
          <span>Comparison</span>
        </div>
      </Col>
      <Col className={classes.chartDivRight}>
        <div className="w-100">
          <div
            className="py-4 text-center my-2"
            style={{ borderBottom: "1px solid #e2e2e2" }}
          >
            <a href="#" className="text-primary font-weight-bold font-size-h5">
              <small>({domain.secondDomain})</small>
              <br />
              2.{secondQuestion.question}
            </a>
          </div>

          <div className="text-center">
            <a
              href="#"
              className="text-primary font-weight-bold mt-3"
              style={{ fontSize: "18px" }}
            >
              <MergeTypeIcon /> Total Count{" "}
              <span className="ml-5">
                {chartsData.q2.goodLeads + chartsData.q2.badLeads}
              </span>
            </a>
          </div>
          <BarChartComponent
            id={
              category.category_name
                .toLowerCase()
                .split(" ")
                .join("_") + "_bar_chart _q2"
            }
            data={[
              {
                name: "Good Leads",
                data: [chartsData.q2.goodLeads],
              },
              {
                name: "Bad Leads",
                data: [chartsData.q2.badLeads],
              },
            ]}
          />
          <ChartComponent2
            type="pie"
            id={
              category.category_name
                .toLowerCase()
                .split(" ")
                .join("_") + "_pie_chart_q2"
            }
            labels={chartsData.q2.labels}
            data={chartsData.q2.data}
            qualifiedArr={chartsData.q2.qualifiedArr}
          />
        </div>

        {secondTotalCountPer && (
          <div className="count-inc-right">
            <span>Count</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{secondTotalCountPer}%
            </span>
          </div>
        )}

        {secondGoodCountPer && (
          <div className="good-inc-right">
            <span>Good Leads</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{secondGoodCountPer}%
            </span>
          </div>
        )}

        {secondBadCountPer && (
          <div className="bad-inc-right">
            <span>Bad Leads</span>
            <span>
              {" "}
              <TrendingUpIcon /> +{secondBadCountPer}%
            </span>
          </div>
        )}
      </Col>
    </Row>
  ) : null;
}
