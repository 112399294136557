import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function findDomains(queryParams) {
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : queryParams[key]}`;
  });
  return axios.get(`${API_URL}/domains?`+params);
}

export function createDomain(domain) {
  domain.domainName = domain.domain_name
  domain.cpvLabUrl = domain.cpv_lab_url
  domain.appKey = domain.cpv_lab_api_key
  
  delete domain.id;
  delete domain.domain_name;
  delete domain.cpv_lab_api_key;
  delete domain.cpv_lab_url;

  return axios.post(`${API_URL}/domains`, {...domain});
}

export function updateDomain(domain) {
  domain.cpvLabUrl = domain.cpv_lab_url
  domain.appKey = domain.cpv_lab_api_key
  
  delete domain.cpv_lab_api_key;
  delete domain.cpv_lab_url;
  delete domain.last_synced_date;

  return axios.put(`${API_URL}/domains/`+domain.id, { ...domain });
}

export function getDomainById(domainId) {
  return axios.get(`${API_URL}/domains/${domainId}`);
}

export function findDomainsTotal(queryParams) {
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : queryParams[key]}`;
  });
  return axios.get(`${API_URL}/contacts/list/totalRecords?`+params);
}

// DELETE => delete the customer from the server
export function deleteDomain(domainId) {
  return axios.delete(`${API_URL}/domains/${domainId}`);
}
// DELETE Domains by ids
/*export function deleteDomains(ids) {
  return axios.post(`${DOMAINS_URL}/deleteDomains`, { ids });
} */
