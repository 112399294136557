export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    const res = error.response;
    if(res?.status === 440){
      window.location = '/logout';
    }
    return Promise.reject(error);
  });
}

