import React, { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function PasswordWithRandomButton({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "password",
  setFieldValue,
  ...props
}) {
  const [showPass, setShowPass] = useState(false);

  const randomPassword = (e) => {
    let randomstring = Math.random()
      .toString(36)
      .slice(-8);
    document.getElementById("staff-pass").value = randomstring;
    setFieldValue("password",randomstring)
  };
  return (
    <>
      {label && <label>Enter {label}</label>}
      <InputGroup>
        <FormControl
          id="staff-pass"
          type={showPass ? "text" : "password"}
          className={getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}
          {...field}
          {...props}
        />
        <InputGroup.Append>
          <Button
            variant="outline-secondary"
            onClick={(e) => setShowPass(!showPass)}
          >
            <i className="fas fa-eye" />
          </Button>
          <Button variant="outline-secondary" onClick={randomPassword}>
            <i className="fas fa-sync-alt" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
