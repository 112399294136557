import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { QuestionsFilter } from "./questions-filter/QuestionsFilter";
import { QuestionsMain } from "./questions-main/QuestionsMain";
const API_URL = process.env.REACT_APP_API_URL;

export function QuestionsCard() {
  const [quesCategories, setQuesCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reloadCate, setReloadCate] = useState(false);
  useEffect(() => {
    if(quesCategories .length !==0){
      setQuesCategories([])
    }
    Axios.get(`${API_URL}/faq/getAllQuestionCategories`).then((res) => {
      setQuesCategories(res.data.data);
    });
  },[reloadCate]);
  return (
    <Card>
      {/* <CardHeader title="Question Analysis">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <QuestionsFilter setRefresh={setRefresh} refresh={refresh} reloadCate={reloadCate} setReloadCate={setReloadCate}/>
        {quesCategories.map((cate, i) => {
          return <><QuestionsMain refresh={refresh} category={cate} key={i} ind={i}/>
          <hr className="my-8 w-50" style={{borderTop:"6px solid rgba(0, 0, 0, 0.1)"}}/></>;
        })}
        {/* <QuestionsTable/> */}
      </CardBody>
    </Card>
  );
}
