import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LeadCompareAnalysisFilter } from "./lead-compare-analysis-filter/LeadCompareAnalysisFilter";
import { LeadCompareAnalysisTable } from './lead-compare-analysis-table/LeadCompareAnalysisTable';
export function LeadCompareAnalysisCard() {

  return (
    <Card>
      {/* <CardHeader title="Lead Compare Analysis">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <LeadCompareAnalysisFilter />
        <LeadCompareAnalysisTable/>
      </CardBody>
    </Card>
  );
}
