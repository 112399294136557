import {createSlice} from "@reduxjs/toolkit";

const initialCpvTrackingState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities:null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const cpvTrackingSlice = createSlice({
  name: "cpv tracking",
  initialState: initialCpvTrackingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    cpvTrackingFetched: (state, action) => {
      const { entities, type } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.type = type;
    },
    cpvFilterState: (state, action) => {
      const { type } = action.payload;
      state.type = type;
    },
  }
});
