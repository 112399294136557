import * as requestFromServer from "./questionsCrud";
import { questionsSlice, callTypes } from "./questionsSlice";

const { actions } = questionsSlice;

export const fetchQuestionsAnalysis = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getQuestionAnalysis(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.questionsFetched({ entities: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(actions.questionsFetched({ entities: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find questions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomainsList = (startDate, endDate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainList(startDate, endDate)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.domainsList({ domainList: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(actions.domainsList({ domainList: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchQuestionsList = (
  domain,
  name,
  category,
  startDate,
  endDate
) => {
  return requestFromServer
    .getQuestionsList(domain, category, startDate, endDate)
    .then((response) => {
      if (response && response.status === 204) {
        return {
          questionsList: [],
        };
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        return {
          questionsList: response.data.data,
          
        };
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find questions";
    });
};


export const fetchChartData = (
  firstDomain,
  secondDomain,
  firstQuestion,
  secondQuestion,
  category,
  startDate,
  endDate,
  timezoneFilter
)  => {
  return requestFromServer
    .getChartData(
      firstDomain,
      secondDomain,
      firstQuestion,
      secondQuestion,
      category,
      startDate,
      endDate,
      timezoneFilter
    )
    .then((response) => {
      if (response && response.status === 204) {
        return {
          chartsData: [], category: category
        }
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        return {
          chartsData: response.data.data,
          category: category,
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find chartsDat";
    });
};



//redux Api
// export const fetchQuestionsList = (
//   domain,
//   name,
//   category,
//   startDate,
//   endDate
// ) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return requestFromServer
//     .getQuestionsList(domain, category, startDate, endDate)
//     .then((response) => {
//       if (response && response.status === 204) {
//         dispatch(
//           actions.questionsList({
//             questionsList: [],
//             name: name,
//             category: category,
//           })
//         );
//       } else if (
//         response &&
//         response.data &&
//         response.data.respType === "Success"
//       ) {
//         dispatch(
//           actions.questionsList({
//             questionsList: response.data.data,
//             name: name,
//             category: category,
//           })
//         );
//       }
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find domains";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const fetchChartData = (
//   firstDomain,
//   secondDomain,
//   firstQuestion,
//   secondQuestion,
//   category,
//   startDate,
//   endDate
// ) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return requestFromServer
//     .getChartData(
//       firstDomain,
//       secondDomain,
//       firstQuestion,
//       secondQuestion,
//       category,
//       startDate,
//       endDate
//     )
//     .then((response) => {
//       if (response && response.status === 204) {
//         dispatch(actions.chartData({ chartsData: [], category: category }));
//       } else if (
//         response &&
//         response.data &&
//         response.data.respType === "Success"
//       ) {
//         dispatch(
//           actions.chartData({
//             chartsData: response.data.data,
//             category: category,
//           })
//         );
//       }
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find domains";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };
