import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CpvTrackingFilter } from "./cpvtracking-filter/CpvTrackingFilter";
import { CpvTrackingTable } from './cpvtracking-table/CpvTrackingTable';
export function CpvTrackingCard() {

  return (
    <Card>
      {/* <CardHeader title="CPV Tracking">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <CpvTrackingFilter />
        <CpvTrackingTable/>
      </CardBody>
    </Card>
  );
}
