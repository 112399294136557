import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/staffManagementActions";
import { AddOrEditRoleDialogHeader } from "./AddOrEditRoleDialogHeader";
import { AddOrEditRoleForm } from "./AddOrEditRoleForm";
import { useRoleUIContext } from "../RoleUIContext";

export function AddOrEditRoleDialog({ id, show, onHide }) {
  // Roles UI Context
  const roleUIContext = useRoleUIContext();
  const roleUIProps = useMemo(() => {
    return {
      initRole: roleUIContext.initRole,
    };
  }, [roleUIContext]);
  // Roles Redux state
  const dispatch = useDispatch();
  let {
    actionsLoading,
    permissions,
    roleForEdit,
    rolesListing,
    error,
  } = useSelector(
    (state) => ({
      actionsLoading: state.staffManagement.actionsLoading,
      permissions: state.staffManagement.rolePermissions,
      roleForEdit: state.staffManagement.roleForEdit,
      rolesListing: state.staffManagement.rolesListing,
      error: state.staffManagement.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchRolePermissions());
  }, []);

  useEffect(() => {
    if (rolesListing.length > 0) {
      let roleForEdit = rolesListing.find((el) => el.id === parseInt(id));
      dispatch(
        actions.fetchRole({
          roleForEdit: roleForEdit
            ? {
                id: roleForEdit.id,
                role_name: roleForEdit.name,
                ...roleForEdit.limits,
                ...roleForEdit.permissions,
              }
            : roleForEdit,
        })
      );
    } else {
      onHide();
    }
  }, [id, dispatch]);

  //   server request for saving role
  const saveRole = (role) => {
    if (id) {
      let data = { ...role };
      delete data.permissions.id;
      dispatch(actions.updateRole({ id, ...data })).then((success) => {
        if (success) {
          onHide();
        }
        else{
          window.scrollTo(0, 0)
        }
      });
    } else {
      dispatch(actions.addNewRole(role)).then((success) => {
        if (success) {
          onHide();
        }
        else{
          window.scrollTo(0, 0)
        }
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AddOrEditRoleDialogHeader id={id} />
      <AddOrEditRoleForm
        saveRole={saveRole}
        id={id}
        actionsLoading={actionsLoading}
        role={roleForEdit || roleUIProps.initRole}
        allPermissions={permissions}
        onHide={onHide}
        error={error}
      />
    </Modal>
  );
}
