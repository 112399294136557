// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function EmailValidColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${row.email_valid === 'VALID' ? 'success' : 'danger'} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row.email_valid}
    </span>
  );
}
export function LeadValidColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${row.lead_type === 'VALID' ? 'success' : 'danger'} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row.lead_type === 'VALID' ? 'Good' : 'Bad'}
    </span>
  );
}
export function LeadStatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${row.lead_status === 'Contacted' ? 'success' : 'danger'} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row.lead_status ? row.lead_status : '-'}
    </span>
  );
}
