import React from "react";
import {
    LiveChatStat
} from "./LiveChatStat";
export function LiveChatMain() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <LiveChatStat className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
