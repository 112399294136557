// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent2(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }

    const options = getOptions(props);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom centered-graph"
        style={{ height: "300px" }}
      ></div>
    </>
  );
}

const getOptions = (props) => {
  let colors = ["#8FBC8F", "#3CB371", "#2E8B57", "#32CD32", "#20B2AA"],
    redColor = ["#AA4A44", "#EE4B2B", "#A52A2A", "#880808"];
  var options = {
    colors: props.qualifiedArr.map((e) => {
      if (e) {
        let clr = colors[0];
        colors.shift();
        return clr;
      } else {
        let clr = redColor[0];
        redColor.shift();
        return clr;
      }
    }),
    series: props.data,
    legend: {
        position: 'bottom',
        // inverseOrder: true,
        fontSize: '10px',
        formatter: function (val, opt) { 
            let total = 0;
            for(let i=0;i<opt.w.config.series.length;i++){
                total += opt.w.config.series[i];
            }
            let percent = ((opt.w.config.series[opt.seriesIndex] * 100) / total).toFixed(2);
            let qualified = props.qualifiedArr[opt.seriesIndex];
            return "&nbsp;" + val + "&nbsp;&nbsp;(" + percent +"%) "  + (qualified ?"Qualified":"Disqualified")
        }
    },
    chart: {
      type: "pie",
      height:"400px",
      stackType: "100%",
      dropShadow: {
        enabled: true,
        enabledOnSeries: props.qualifiedArr.map((e, i) => {
          if (e) {
            return i;
          } else {
            return null;
          }
        }),
        top: 0,
        left: 0,
        blur: 3,
        color: 'red',
        opacity: 0.35
      },
    },

    plotOptions: {
      pie: {
        customScale: 0.8,
      },
    },
    stroke: {
      show: true,
      width: 2,
    },
    fill: {
      type: "pattern",
      opacity: 1,
      pattern: {
        style: props.qualifiedArr.map((e) => {
          if (e) {
            return "squares";
          } else {
            return "horizontalLines";
          }
        }),
        width: 6,
        height: 6,
        strokeWidth: 5,
      },
    },

    dataLabels: {
      formatter: function(val, { seriesIndex, dataPointIndex, w }) {
        let value = w.config.series[seriesIndex];
        if (props.qualifiedArr[seriesIndex]) {
          value = value;
        }
        // return [value, "(" + val.toFixed(1) + "%)"];
        return value;
      },
    },
    labels: props.labels,
   
  };
  return options;
};
