// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }

        let customColors = ["#008FFB"];
        
        props.values.forEach(label =>{
            if(label['name']=="Livechat Status"){
                customColors.pop();
                customColors.push("#003399")
                customColors.push("#003300")
            }
            if(label['name']=="LIVE"){
                customColors.push("#00E396")
            }
            if(label['name']=="CONTACTED"){
                customColors.push("#FEB019")
            }
            if(label['name']=="DFF"){
                customColors.push("#FF4560")
            }
            if(label['name']=="TTT"){
                customColors.push("#775DD0")
            }
        })
        const options = getPieChartOptions(props,customColors);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props.values]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px",width:"100%" }}
            ></div>
        </>
    );
}
function getPieChartOptions(props,customColors) {
    const copy = [...props.values];
    let maxIndex = -1, maxTotal = 0, totalLeadCount = 0;
    copy.forEach((obj, index) => {
            const maxNumber = Math.max(...obj.data);
            if (maxTotal < maxNumber) {
                maxTotal = maxNumber;
                maxIndex = index;
            }
    });
    const finalData = [];
    copy.forEach((obj, index) => {
        let show = false;
        if (obj.name === 'Total Leads') {
            show = true;
        }
        if (maxIndex > -1 && copy[maxIndex].name === obj.name) {
            show = true;
        }
        const defaultOptions = {
            seriesName: obj.name === 'Total Leads' ? 'Total Leads' : maxIndex > -1 ? copy[maxIndex].name : 'Total Leads',
            show,
            opposite: false,
            max: maxTotal + 3,
            labels: {
                formatter: function (val) {
                    return  Math.round(val);
                }
            },
            tickAmount: 4,  
        };
        finalData.push(defaultOptions);
    });

    var porpsValChart = props.values;
    var seriesDataChart = [];
    for (const valsChart of porpsValChart){
      const dataChart = valsChart.data;
      if (dataChart.some(el => el > 0)) {
        seriesDataChart.push(valsChart)
      }
    }

    var options = {
        series: seriesDataChart,
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            stackType: "100%",
            height:"500px"
        },
        colors: [
            "#008FFB",
            "#06b109",
            "#3f11b7",
            "#b9c8a2",
            "#626d13",
            "#bddc14",
            "#003399",
            "#945da1",
            "#7e68da",
            "#4f2c47",
            "#2a1e69",
            "#ae0167",
            "#b3b8fa",
            "#3d2d27",
            "#00FF00",
            "#CD5C5C",
            "#F08080",
            "#FA8072",
            "#E9967A",
            "#FFA07A",
            "#FFBF00",
            "#CCCCFF",
            "#40E0D0",
            "#9FE2BF",
            "#FF69B4",
            "#C71585",
            "#FFA07A",
            "#FF6347",
            "#FF8C00",
            "#BDB76B",
            "#DA70D6",
            "#9370DB",
            "#8B008B",
            "#4B0082",
            "#00FF00",
            "#3CB371",
            "#808000",
            "#66CDAA",
            "#008B8B",
            "#4682B4",
            "#ADD8E6",
            "#6495ED",
            "#FFDEAD",
            "#DEB887",
            "#BC8F8F",
            "#8B4513"
        ],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: 'Trends',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: props.labels,
            labels: {
                style: {
                  cssClass: "live-chat-x-axis",
                  fontSize: "8px",
                },
              },
        },
        yaxis: finalData
    };

    return options;
}