import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function getDomainAnalysis(queryParams) {
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : queryParams[key]}`;
  });
  return axios.get(`${API_URL}/domain-report/getStats?`+params);
}

export function getDomainList(startDate, endDate) {
  // This is temporary, it will be updated soon
  return axios.get(`${API_URL}/cpa/account`);
}

export function getAllDomainNames() {
  return axios.get(`${API_URL}/cpa/account`);
}

export function getBarChartData(details) {
  let token = localStorage.getItem("token");
  let header = { params:details};
  return axios.get(`${API_URL}/domain-report/get-bar-chart-data`, header);
}
