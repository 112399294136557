import React from "react";
import {
    LeadStat
} from "./LeadStat";
export function MainDashboard(props) {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <LeadStat className="card-stretch gutter-b" {...props}/>
                </div>
            </div>
    </>);
}
