import * as requestFromServer from "./fpCrud";
import { fpSlice, callTypes } from "./fpSlice";

const { actions } = fpSlice;

export const fetchAllDomainNames = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDomainNames()
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allDomainNamesFetched({ domains: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allDomainNamesFetched({ domains: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllLandingPagesList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchAllLandingPagesList(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.allLPNamesFetched({ pagesList: [] }));
      } else if (
        response &&
        response.data &&
        response.data.respType === "Success"
      ) {
        dispatch(
          actions.allLPNamesFetched({ pagesList: response.data.data })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const getStats = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStats(queryParams)
    .then((response) => {
      if (response.data && response.data.respType === "Success") {
        let data = response.data.data;
        let labels = data.labels
        let series=[
          {
            name: "Percentage",
            data: data.totalLiveChats,
          },
          {
            name: "Total Valid Leads",
            data: data.totalValid,
          },
        ];
        dispatch(
          actions.chartDetailsFetched({
            labels,
            series
          })
        );
        
      } else {
        dispatch(
          actions.catchError({
            error: response.data.data,
            callType: callTypes.list,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
