// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import * as moment from 'moment';
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")

export function LogsColumnFormatter(cellContent, row, rowIndex) {
  return (
    <>
      <span>{moment.utc(cellContent).utcOffset(offSet).format('YYYY-MM-DD hh:mm:ss')}</span>
    </>
  );
}
