import * as moment from 'moment';
import momentTZ from 'moment-timezone';
const offSet = momentTZ().tz("America/New_York").format("Z")
export const LeadAnalysisStatusCssClasses = ["danger", "success", "info", ""];
export const LeadAnalysisStatusTitles = ["Suspended", "Active", "Pending", ""];
export const LeadAnalysisTypeCssClasses = ["success", "primary", ""];
export const LeadAnalysisTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 200 },
];
export const initialFilter = {
  startDate:moment.utc().utcOffset(offSet).format('YYYY-MM-DD') + ' 00:00:00',
  endDate:moment.utc().utcOffset(offSet).format('YYYY-MM-DD') + ' 23:59:59',
  filter: {
    domain: "",
    campaign: "",
  },
  sortOrder: "asc", // asc||desc
  sortBy: "extra1",
  from: 1,
  rows: 25,pageNumber:1 
};
