// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/domainsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../DomainsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useDomainsUIContext } from "../DomainsUIContext";

export function DomainsTable({ userInfo }) {
  const { permissions, userType } = userInfo;

  // Domains UI Context
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      ids: domainsUIContext.ids,
      setIds: domainsUIContext.setIds,
      queryParams: domainsUIContext.queryParams,
      setQueryParams: domainsUIContext.setQueryParams,
      openEditDomainDialog: domainsUIContext.openEditDomainDialog,
      openDeleteDomainDialog: domainsUIContext.openDeleteDomainDialog,
    };
  }, [domainsUIContext]);
  // Getting curret state of domains list from store (Redux)
  const { currentState, allState } = useSelector(
    (state) => ({ currentState: state.domains, allState: state }),
    shallowEqual
  );

  const { entities, listLoading, totalCount } = currentState;

  // Domains Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    domainsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchDomains(domainsUIProps.queryParams));
    // dispatch(actions.fetchDomainCount(domainsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainsUIProps.queryParams, dispatch]);
  // Table columns

  // useEffect(() => {
  //   dispatch(actions.fetchTimezone());
  // });

  const [expanded, setExpanded] = React.useState([]);

  const events = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      const isRowExpanded = expanded.includes(row.id);
      if (isRowExpanded) {
        setExpanded(expanded.filter((x) => x !== row.id));
      } else {
        setExpanded([...expanded, row.id]);
      }
    },
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "domain_name",
      text: "Domain Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events,
    },
    {
      dataField: "leadsource",
      text: "Lead-source",
      sort: true,
      sortCaret: sortCaret,
      // classes: "react-bootstrap-table-editing-cell",
      headerSortingClasses,
      events,
    },
    {
      dataField: "timezone",
      text: "Timezone",
      sort: true,
      sortCaret: sortCaret,
      // classes: "react-bootstrap-table-editing-cell",
      headerSortingClasses,
      events,
    },
    {
      dataField: "action",
      text: "Edit",
      formatter: columnFormatters.ActionsColumnFormatter,
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["domain_manage"].includes("edit")
          ? false
          : true,

      formatExtraData: {
        openEditDomainDialog: domainsUIProps.openEditDomainDialog,
      },
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: columnFormatters.DeleteColumnFormatter,
      hidden:
        userType.toLowerCase() === "admin" ||
        permissions["domain_manage"].includes("delete")
          ? false
          : true,
      formatExtraData: {
        openDeleteDomainDialog: domainsUIProps.openDeleteDomainDialog,
      },
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: domainsUIProps.queryParams.pageSize,
    page: domainsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  domainsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
