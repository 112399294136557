// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
// import { useDomainsUIContext } from "../DomainsUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cpvTrackingActions";
import {ChartComponent} from './ChartComponent';
import {CpvTrackingStateComponent} from './CpvTrackingStateComponent';
import axios from 'axios';
import { useCpvTrackingUIContext } from "../CpvTrackingUIContext";

export function CpvTrackingTable() {
  
  const API_URL = process.env.REACT_APP_API_URL;
  // const [stateChartData, setStateChartData] = useState(null);

  function getStateWiseReportChartAPI(){
    return axios.get(`${API_URL}/cpv-tracking/getStateWiseReportChart`);
  }

  // const getStateWiseReportChart = () => {
  //   getStateWiseReportChartAPI().then(res=>{
  //     if( res.data && res.data.respType === "Success" ) {
  //       setStateChartData(res.data.data);
  //     }
  //   });
  // }
  
  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.cpvTracking }),
    shallowEqual
  );
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  const dispatch = useDispatch();
  const { listLoading, type } = currentState;
  let {entities} = currentState;
  
  useEffect(() => {
    // dispatch(actions.fetchCpvTracking(domainUiProps.queryParams));
    // dispatch(actions.fetchCpvTracking(cpvUIProps.queryParams));
  }, [null, dispatch]);

  const generateList = () => {
    if(!entities)
      return <div>Loading</div>

    const keys = Object.keys(entities);
    return (<div className="row">
      {
        keys.map((tracking, trackingIndex) => {
          const valueKeys = Object.keys(entities[tracking]) //.sort();
          const labels = [];
          const values = [];
          valueKeys.forEach(valueKey => {
            if(valueKey !== 'total') {
              // labels.push(valueKey.length > 15 ? valueKey.substr(0, 15) : valueKey);
              labels.push(valueKey);
              values.push(entities[tracking][valueKey]);
            }
          })
          if(tracking === '') {
            return <></>
          }
          return (
            // <div className={"col bg-light-primary px-6 py-8 rounded-xl mb-7 col-6 "+(trackingIndex%2 ? '':'mr-6')} key={trackingIndex} style={{'maxWidth':'49%'}}>
            <div className={"bg-light-primary px-6 py-8 rounded-xl mb-7 col-lg-6 col-md-6 col-sm-12 domain-analysis-chart "+(trackingIndex%2 === 0 ? ' domain-analysis-mr-6 ':' ')} key={trackingIndex}>
              <div className="pb-4 text-center" style={{'borderBottom': '1px solid #e2e2e2'}}>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h4"
              >
                {tracking}
              </a>
              </div>
              <div className="mt-3">
                <ChartComponent type='pie' id={"kt_mixed_widget_1_chart"+makeid(5) } labels={labels} values={values}/>
              </div>
            </div>
          )

        })
      }
      </div>
    )
  }

  const generateList2 = () => {
    if(!entities)
      return <div>Loading</div>

    if(type == undefined || type == 'undefined'){
      return <div>Loading</div>
    }

    const labels = Object.keys(entities);
    const values = Object.values(entities);
  
    return (<div className="">
        {type == 'state' ?
          <CpvTrackingStateComponent type={type} entities={entities} stateChartData={entities} />
        :
          <div className="row">
            <div className={"bg-light-primary px-6 py-8 rounded-xl mb-7 " + (type == 'state' ? ' col-lg-12 col-md-12 col-sm-12 ':' col-lg-6 col-md-6 col-sm-12 domain-analysis-chart')} key={1}>
              <div className="pb-4 text-center" style={{'borderBottom': '1px solid #e2e2e2'}}>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h4"
              >
                {(type == 'campaign' ? 'Campaign wise' : `${type.charAt(0).toUpperCase() + type.slice(1)} wise`)}
              </a>
              </div>
              <div className="mt-3">
                <ChartComponent type='pie' id={"kt_mixed_widget_1_chart"+makeid(5) } labels={labels} values={values} filterBy={type} />
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <>
      { listLoading ? <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div> : (entities && Object.keys(entities).length > 0) ? type == 'all' ? generateList() : generateList2() : <div>No records found</div>}
    </>
  );
}