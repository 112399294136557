import React from 'react';
import { ConversationLoadingDialog } from "./conversation-loading-dialog/ConversationLoadingDialog";
import { ConversationUIProvider } from "./ConversationUIContext";
import { ConversationCard } from "./ConversationCard";
import { Route } from 'react-router';
import { ConversationAddEditDialog } from './conversation-add-edit-dialog/ConversationAddEditDialog';
import { StatusOptionDialog } from './status-options-dialog/StatusOptionDialog';
import { TextDeleteDialog } from './text-delete-dialog/TextDeleteDialog';

export function LiveChatConversationPage(props) {
  return (
    <ConversationUIProvider >
      <ConversationLoadingDialog />
      <Route path="/live-chat/conversation/text-management/add-new">
        {({ history, match }) => (
          <ConversationAddEditDialog
            show={match != null}
            match={match}
            id={match && match.params.id}
            onHide={() => {
              history.push("/live-chat/conversation/text-management");
            }}
          />
        )}
      </Route>
      <Route path="/live-chat/conversation/text-management/edit/:id">
        {({ history, match }) => (
          <ConversationAddEditDialog
            show={match != null}
            match={match}
            id={match && match.params.id}
            onHide={() => {
              history.push("/live-chat/conversation/text-management");
            }}
          />
        )}
      </Route>
      <Route path="/live-chat/conversation/text-management/options">
        {({ history, match }) => (
          <StatusOptionDialog
            show={match != null}
            match={match}
            onHide={() => {
              history.push("/live-chat/conversation/text-management");
            }}
          />
        )}
      </Route>
      <Route path="/live-chat/conversation/text-management/delete/:id">
        {({ history, match }) => (
          <TextDeleteDialog
            show={match != null}
            id={match && match.params.id}
            match={match}
            onHide={() => {
              history.push("/live-chat/conversation/text-management");
            }}
          />
        )}
      </Route>
      <ConversationCard {...props}/>
    </ConversationUIProvider>
  );
}
