import React from "react";
import {
    TrnendStat
} from "./TrendStat";
export function MainTrend() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <TrnendStat className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
