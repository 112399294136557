import React, { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/AdsSpentActions";

export function AcountsListTable({ month, year, accounts, listLoading }) {
  const [leadSource, setLeadSource] = useState("");
  const dispatch = useDispatch();

  const confirmation = (lead) => {
    setLeadSource(lead);
  };
  const removeAccount = (lead) => {
    dispatch(actions.deleteAccount({ month, year, leadSource: lead }));
    setLeadSource("")
  };
  const renderAccountLise = (e) => {
    return (
      <>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="w-50">Lead Sources</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((acc, i) => {
              return (
                <tr key={i}>
                  <td className="w-50">{acc}</td>
                  <td>
                    {leadSource === acc ? (
                      <>
                        <Button
                          variant="danger"
                          className="mr-3 p-2"
                          onClick={(e) => removeAccount(acc)}
                        >
                          Confirm
                        </Button>
                        <Button className="p-2" onClick={(e) => setLeadSource("")}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        title="Delete Account"
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={(e) => confirmation(acc)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger fa fa-trash"></span>
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <small className="text-danger mt-0">
          {" "}
          *note when you delete a account, all spents associate with account
          will also removed.
        </small>
      </>
    );
  };

  return (
    <>
      {listLoading ? (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      ) : (
        renderAccountLise()
      )}
    </>
  );
}
