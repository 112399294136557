import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function DomainEditDialogHeader({ id }) {
  // Domains Redux state
  const { domainForEdit, actionsLoading } = useSelector(
    (state) => ({
      domainForEdit: state.domains.domainForEdit,
      actionsLoading: state.domains.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id != 0 ? "" : "New Domain";
    if (domainForEdit && id) {
      _title = `Edit domain ${domainForEdit.domain_name}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [domainForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
