/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { LiveChatFilters } from "./LiveChatFilters";
import { ChartComponent } from "./ChartComponent";
import { Col, Row } from "react-bootstrap";
import { LiveChatNumbersView } from "./LiveChatNumbersView";

export function LiveChatStat({ className }) {
  const [numberView, setNumberView] = React.useState(false);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.liveChat }),
    shallowEqual
  );
  const {
    labels,
    allConversions,
    domainCharts,
    listLoading,
    tableData,
  } = currentState;

  return (
    <>
      <div className={`card card-custom live-chat ${className}`}>
        {/* Header */}
        {/* <div className="card-header align-items-center border-0 py-5 bg-danger">
          <h3 className="card-title align-items-start flex-column text-white">
            <span className="card-title font-weight-bolder text-white">
              Live Chat Analysis
            </span>
          </h3>
        </div> */}
        {/* Body */}
        <div className="separator separator-dashed mb-7"></div>
        <div className="card-body pt-0 row">
          <LiveChatFilters
            setShowTableView={setNumberView}
            showTableView={numberView}
          />
        </div>
        {listLoading ? (
          <div className="d-flex align-items-center mx-5 my-8">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        ) : numberView ? (
          <LiveChatNumbersView data={tableData} labels={labels}/>
        ) : (
          <>
            <div className="graph-container mt-2">
              <div className="text-center">
                <ChartComponent
                  id={"coversion_chart"}
                  type={"total"}
                  labels={labels}
                  values={allConversions}
                />
              </div>
            </div>
            <div>
              <Row className="mx-2 mt-8">
                {domainCharts.map((chartData, i) => {
                  return (
                    <Col md="12" lg="12" xl="6" key={i}>
                      <div className=" bg-light-primary py-3 px-1 rounded-xl graph-container mb-6">
                        <div
                          className="pb-4 text-center"
                          style={{ borderBottom: "1px solid #e2e2e2" }}
                        >
                          <a
                            href="#"
                            className="text-primary font-weight-bold font-size-h4"
                          >
                            {chartData.domain_name}
                          </a>
                        </div>
                        <div className="text-center">
                          <ChartComponent
                            id={chartData.domain_name}
                            type={"domain"}
                            labels={labels}
                            values={chartData.series}
                          />
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
}
