import React, { useMemo } from "react";
import { useDomainsUIContext } from "../DomainsUIContext";
export function DomainsGrouping() {
  // Domains UI Context
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      ids: domainsUIContext.ids,
      setIds: domainsUIContext.setIds,
      openDeleteDomainsDialog: domainsUIContext.openDeleteDomainsDialog,
      openFetchDomainsDialog: domainsUIContext.openFetchDomainsDialog,
      openUpdateDomainsStatusDialog:
        domainsUIContext.openUpdateDomainsStatusDialog,
    };
  }, [domainsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{domainsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={()=>{
                  alert('work in progress');
                }}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              {/* <Link
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                to={'/contacts/deleteDomains'}
              >
                <i className="fa fa-trash"></i> Delete All
              </Link> */}
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
