import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./DomainsUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const DomainsUIContext = createContext();

export function useDomainsUIContext() {
  return useContext(DomainsUIContext);
}

export const DomainsUIConsumer = DomainsUIContext.Consumer;

export function DomainsUIProvider({ customersUIEvents, children }) {
  const { defaultDomains } = useSelector(
    (state) => ({
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    filterByDomainTZ: defaultDomains.timezone,
    domain: defaultDomains.selectedDomain.join(","),
  });
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return (
    <DomainsUIContext.Provider value={value}>
      {children}
    </DomainsUIContext.Provider>
  );
}
