import * as requestFromServer from "./conversationCrud";
import {conversationSlice, callTypes} from "./conversationSlice";

const {actions} = conversationSlice;

export const addNewOption = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addOption(details)
    .then((response) => {
      const optionDetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.optionCreated({ optionDetails }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create option";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};


export const deleteOption = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOption(details)
    .then((response) => {
      dispatch(actions.optionDeleted({ details }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete option";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOptionsListing = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOptions(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.optionFetched({ options: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.optionFetched({  options: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Options";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const addNewConversationsText = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addText(details)
    .then((response) => {
      const resData = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.textCreated({ details:resData }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create text";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};

export const updateConversationsText = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateText(details)
    .then((response) => {
      const resData = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.textUpdated(resData));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create text";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};


export const deleteConversationsText = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteText(details)
    .then((response) => {
      dispatch(actions.textDeleted({ details }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete text";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchConversationTextListing = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTexts(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.textsFetched({ data: [],count:0}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.textsFetched({  data: response.data.data.alldata,count:response.data.data.count}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find texts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
