import * as requestFromServer from "./adminLogsCrud";
import {adminLogsListSlice, callTypes} from "./adminLogsSlice";

const {actions} = adminLogsListSlice;

export const fetchAdminLogs = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAdminLogs(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.AdminLogsFetched({ totalCount: 0, entities: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.AdminLogsFetched({ totalCount: response.data.data.count, entities: response.data.data.allLogs}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Admin Logs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
