import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { AdminLogsTable } from "./admin-logs-table/AdminLogsTable";
import { AdminLogsFilter } from "./admin-logs-filter/AdminLogsFilter";

export function AdminLogsCards() {
  return (
    <Card>
      {/* <CardHeader title="Admin Logs Details"></CardHeader> */}
      <CardBody>
        <AdminLogsFilter />
        <AdminLogsTable />
      </CardBody>
    </Card>
  );
}
