import React from "react";
import { AdminLogsLoadingDialog } from "./admin-logs-loading-dialog/AdminLogsLoadingDialog";
import { AdminLogsUIProvider } from "./AdminLogsUIContext";
import { AdminLogsCards } from "./AdminLogsCards";

export function AdminLogsPage({ history }) {
  const adminLogsUIEvents = {
    
  }

  return (
    <AdminLogsUIProvider adminLogsUIEvents={adminLogsUIEvents}>
      <AdminLogsLoadingDialog />
      <AdminLogsCards />
    </AdminLogsUIProvider>
  );
}
