import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/conversationActions";
import { ConversationAddEditDialogHeader } from "./ConversationAddEditDialogHeader";
import { ConversationAddEditForm } from "./ConversationAddEditForm";
import { useConversationUIContext } from "../ConversationUIContext";

export function ConversationAddEditDialog({ id, show, onHide, match }) {
  const [editText, setEditText] = useState(null);

  // Coversation UI Context
  const conversationUIContext = useConversationUIContext();
  const conversationUIProps = useMemo(() => {
    return {
      initDomain: conversationUIContext.initDomain,
    };
  }, [conversationUIContext]);

  // Coversation Redux state
  const dispatch = useDispatch();
  // Getting curret state of live Chat Conversation list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.liveChatConversation }),
    shallowEqual
  );
  const { options, entities } = currentState;

  useEffect(() => {
    if (id) {
      let textEdit = entities.find((el) => el.id === parseInt(id));
      setEditText(textEdit);
    }
    // dispatch(actions.fetchDomain(id));
    if (match) {
      dispatch(actions.fetchOptionsListing());
    }
  }, [match]);

  // server request for saving Data
  const saveDetails = (textData) => {
    if (id) {
      dispatch(
        actions.updateConversationsText({
          data: { status: textData.status, text: textData.text },
          id: id,
        })
      ).then(() => onHide());
    } else {
      // server request for creating textData
      dispatch(actions.addNewConversationsText(textData)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ConversationAddEditDialogHeader id={id} />
      <ConversationAddEditForm
        saveDetails={saveDetails}
        id={id}
        options={options}
        // actionsLoading={actionsLoading}
        initials={editText || conversationUIProps.initDomain}
        onHide={onHide}
      />
    </Modal>
  );
}
