import React from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalCSS: {
    width: "90vw",
    maxWidth: "90vw",
  },
}));

export function RenderPreviewInModal({ id, show, onHide, url }) {
  const classes = useStyles();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={(e) => onHide()}
      dialogClassName={classes.modalCSS}
      //   aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="preview-header" closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <iframe
          src={url}
          title="preview"
          width="100%"
          style={{ height: "90vh" }}
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}
