import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./AdsSpentUIHelpers";

const AdsSpentUIContext = createContext();

export function useAdsSpentUIContext() {
  return useContext(AdsSpentUIContext);
}

export const AdsSpentUIConsumer = AdsSpentUIContext.Consumer;

export function AdsSpentUIProvider({AdsSpentUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams
  };

  return <AdsSpentUIContext.Provider value={value}>{children}</AdsSpentUIContext.Provider>;
}