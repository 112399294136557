// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }
        
        const options = props.type && props.type === 'pie' ? getPieChartOptions(props) : {};

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px" }}
            ></div>
        </>
    );
}
function getPieChartOptions(props) {
    const colors = {
        1:['#187de4'],
        2:['#187de4', '#1bc5bd'],
        3:['#187de4', '#F64E60', '#1bc5bd'],
        5:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
    };

    // console.log('props.labels', props.labels);
    // console.log('props.values', props.values);

    /* const colorBoundry = ["#f9af72", "#8ba646", "#b6b0da", "#121dca", "#338004", "#90283c", "#37f2a4", "#6a5f1b", "#752eaf", "#d07118", "#e5af0f", "#fb19d4", "#82afc2", "#2fa58a", "#0d2b81", "#32a8bd", "#44f366", "#c9c596", "#13287f", "#d45ef9", "#4e9da4", "#2e3785", "#e7edfc", "#2d2035", "#37a345", "#80434a", "#6a9425", "#f1eea5", "#f8e6d3", "#88a2d8", "#48e8ea", "#18b26f", "#842db9", "#9d3419", "#2691ba", "#a9b6b3", "#1de23b", "#a5b9a1", "#a49cb3", "#3754ae", "#c2469f", "#e7e275", "#7bbca0", "#a503ec", "#c6a884", "#7dfa09", "#ac0ff9", "#d5adab", "#8517b3", "#e17c0e"];
    // '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
    const colorArr = []
    for(let i=0; i<props.labels.length; i++){
        colorArr.push(colorBoundry[i]);
    }

    console.log('colorArr', colorArr) */

    var options = {
        series: props.values,
        chart: {
            width: 420,
            type: 'pie',
        },
        labels: props.labels,
        legend: {
            position: 'right',
            markers: {
                width: 25,
                radius: 0
            },
            width: 200,
            // inverseOrder: true,
            formatter: function (val, opt) { 
                let total = 0;
                for(let i=0;i<opt.w.config.series.length;i++){
                    total += opt.w.config.series[i];
                }
                let percent = ((opt.w.config.series[opt.seriesIndex] * 100) / total).toFixed(2);
                return "&nbsp;" + val + "&nbsp;&nbsp;" + percent +"%"
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300,
                    height: 300,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        },{
            breakpoint: 769,
            options: {
                chart: {
                    width: 350,
                    height: 220,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        },{
            breakpoint: 1025,
            options: {
                chart: {
                    width: 300,
                    height: 300,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    markers: {
                        width: 25,
                        radius: 0
                    },
                }
            }
        }],
        // colors: colors[props.labels.length]?colors[props.labels.length]:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
        colors: props.colorRange,
        plotOptions: {
            pie: {
              dataLabels: {
                  offset: -20,
                  minAngleToShowLabel: 0
              }, 
            }
        },
        tooltip: {
            fillSeriesColor: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let sum = 0, value = series[seriesIndex],color = w.config.colors[seriesIndex] ? w.config.colors[seriesIndex] : '#187de4';
                let label = w.config.labels[seriesIndex] ? w.config.labels[seriesIndex] : 'NA';
                let dataArr = w.config.series ? w.config.series : [];
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(1)+"%";
                return '<div class="arrow_box" style="background:'+color+';padding:10px;">' +
                    '<span>' + label + '</span></br>' +
                    '<span> Count = ' + value + '</span></br>' +
                    '<span>' + percentage +  '</span>' +
                    '</div>'
              }
          }
    };
    return options;
}