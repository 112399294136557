import { createSlice } from "@reduxjs/toolkit";

const initialliveChatState = {
  listLoading: false,
  actionsLoading: false,
  labels: [],
  pagesList:[],
  allDomainNames:[],
  series: [],
  lastError: null,
  
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const fpSlice = createSlice({
  name: "funnel_stats",
  initialState: initialliveChatState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    allDomainNamesFetched: (state, action) => {
      const { domains } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allDomainNames = domains;
    },
    chartDetailsFetched: (state, action) => {
      const { labels,series } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.labels = labels;
      state.series = series;
    },
    allLPNamesFetched: (state, action) => {
      const { pagesList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.pagesList = pagesList.filter(l => l.page_name);
    },
  },
});
