import { createSlice } from "@reduxjs/toolkit";

const initialQuestionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  domainList: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState: initialQuestionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    questionsFetched: (state, action) => {
      const { entities, name } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    domainsList: (state, action) => {
      const { domainList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.domainList = domainList;
    },
    questionsList: (state, action) => {
      const { questionsList, name, category } = action.payload;
      let secondName =
        name === "firstQuestionList"
          ? "secondQuestionList"
          : "firstQuestionList";
      state.listLoading = false;
      state.error = null;
      state["questionsList_" + category] = {
          [secondName]:
            state["questionsList_" + category]
              ? state["questionsList_" + category][secondName]
                ? state["questionsList_" + category][secondName]
                : []
              : [],
          [name]: questionsList,
      };
    },

    chartData: (state, action) => {
      const { chartsData, category } = action.payload;

      state.listLoading = false;
      state.error = null;
      state["chartsData_" + category] = chartsData;
    },
  },
});
