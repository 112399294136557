import React from "react";
import { FunnelPerformanceStat } from "./FunnelPerformanceStat";
export function FunnelPerformanceMain() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <FunnelPerformanceStat className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
