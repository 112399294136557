import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function getAllQuestions(queryParams) {
  return axios.get(`${API_URL}/question-management`,{params:queryParams});
}

export function getAllAnswers() {
  return axios.get(`${API_URL}/question-management/all-answers`);
}

export function addNewQuestion(data) {
  return axios.post(`${API_URL}/question-management`,data);
}

export function updateQuestion(data) {
  return axios.put(`${API_URL}/question-management/${data.id}`,data);
}

export function deleteQuestions(id) {
  return axios.delete(`${API_URL}/question-management/${id}`);
}
