import React, { useEffect, useState } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { AddUpdateTemplateDialogHeader } from "./AddUpdateTemplateDialogHeader";
import "react-widgets/styles.css";
import * as actions from "../../../_redux/templatesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormControl, InputBase, MenuItem, Select } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
const API_URL = process.env.REACT_APP_API_URL;

export function AddUpdateTemplateDialog({ id, show, onHide, renderId }) {
  const [initialValues, setInitialValues] = useState({
    templateName: "",
    cssFile: "",
  });

  const TemplatesSchema = Yup.object().shape({
    templateName: Yup.string().required(),
    cssFile: Yup.string().required(),
  });

  const UpdateTemplatesSchema = Yup.object().shape({
    templateName: Yup.string().required(),
  });

  const { currentState,userType,permissions } = useSelector(
    (state) => ({ currentState: state.cssTemplates,
      userType: state.auth.user.userType,
      permissions: state.auth.user.permissions, }),
    shallowEqual
  );
  const { actionsLoading, entities } = currentState;


  
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      // ------------check Add permission when directly hit from url---------------
      if (
        !["Admin", "admin"].includes(userType) &&
        (permissions["templates"] && !permissions["templates"].includes("update"))
      ) {
        onHide();
      }
      let dataToUpdate = entities.find((e) => e.id == id);
      formik.setFieldValue(
        "templateName",
        dataToUpdate ? dataToUpdate.name : ""
      );
    }
    else{
       // ------------check Add permission when directly hit from url---------------
       if (
        !["Admin", "admin"].includes(userType) &&
        (permissions["templates"] &&!permissions["templates"].includes("add"))
      ) {
        onHide();
      }
    }
  }, [entities]);

  const formik = useFormik({
    initialValues,
    validationSchema: id ? UpdateTemplatesSchema : TemplatesSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (id) {
        dispatch(actions.updateTemplate({ ...values, id }))
          .then((success) => {
            if (success) {
              onHide();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(actions.addNewTemplates({ ...values })).then((success) => {
          if (success) {
            onHide(renderId);
          }
        });
      }
    },
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={(e) => onHide(renderId)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AddUpdateTemplateDialogHeader id={id} />

      <Modal.Body>
        {id && (
          <div className="mb-5 text-center">
            <a
              href={API_URL + "/landing-pages/preview/" + id}
              title="Preview template"
              target="_blank"
            >
              Preview of current(In new tab)
            </a>
          </div>
        )}
        <Row className="align-items-baseline">
          <Col md="12" className="mb-5">
            <FormControl className="w-100">
              <Form.Label>Template Name</Form.Label>
              <Form.Control
                type="text"
                id="templateName"
                name="templateName"
                placeholder="Enter Template Name"
                {...formik.getFieldProps("templateName")}
              />
              {formik.touched.templateName && formik.errors.templateName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">*required field</div>
                </div>
              ) : null}
            </FormControl>
          </Col>
          <Col md="12">
            <FormControl className="w-100 mb-2">
              <Form.Label>{id ? "Upload New File" : "Upload File"}</Form.Label>
              <Form.Control
                type="file"
                id="cssFile"
                accept="text/css"
                placeholder="Enter Template Name"
                onChange={(e) => {
                  formik.setFieldValue(
                    "cssFile",
                    e.target.files ? e.target.files[0] : null
                  );
                }}
              />
              {formik.touched.cssFile && formik.errors.cssFile ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">*required field</div>
                </div>
              ) : null}
            </FormControl>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {actionsLoading ? (
          <button className="btn btn-primary btn-elevate" disabled>
            Saving....
          </button>
        ) : (
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="btn btn-primary btn-elevate"
          >
            Save
          </button>
        )}

        <button
          type="button"
          onClick={(e) => onHide(renderId)}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
