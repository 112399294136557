// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { number } from "prop-types";

const colors = ["#000075","#800000","#f58231","#808000"]
export function ChartComponent(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }
    let y_axis_opt;
    if (props.type === "domain") {
      y_axis_opt = props.values.reduce((prev, curr,i) => {
        if (curr.name !== "Percentage") {
          let opt = {
            seriesName: curr.name,
            opposite: true,
            // axisTicks: {
            //   show: true,
            // },
            tickAmount:5,
      min: 0,
            axisBorder: {
              show: true,
            },
            labels: {
              style: {
                colors: colors[i+1],
              },
            },
            title: {
              text: curr.name,
              style: {
                color: colors[i+1],
              }
            },
          };
          return prev.concat(opt);
        } else {
          return prev;
        }
      }, []);
    }
    const options =
      props.type === "total"
        ? getAllCRChartOptions(props)
        : getParticularDomainChart(props,y_axis_opt);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "200px" }}
      ></div>
    </>
  );
}
function getAllCRChartOptions(props) {
  let options = {
    series: props.values,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      stackType: "100%",
      height: "400",
    },
    colors: [
      "#2cc5db",
      "#06b109",
      "#3f11b7",
      "#b9c8a2",
      "#626d13",
      "#bddc14",
      "#003399",
      "#945da1",
      "#7e68da",
      "#4f2c47",
      "#2a1e69",
      "#ae0167",
      "#b3b8fa",
      "#3d2d27",
      "#00FF00",
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "LC Conversation Analysis (Total CR)",
      align: "left",
    },
    xaxis: {
      categories: props.labels,
      hideOverlappingLabels: true,
      labels: {
        style: {
          cssClass: "live-chat-x-axis",
        },
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      forceNiceScale: false,
      tickAmount: 5,
      labels: {
        formatter: function(value) {
          return value + "%";
        },
      },
    },
  };
  return options;
}

function getParticularDomainChart(props,y_axis_opt) {
  const series = [
    {
      data: [4,5,6],
      name: "Complete",
      type: "column"
    },
    {
      data: [1,2,3],
      name: "Pending",
      type: "column"
    },
    {
      data: [10, 50, 90],
      name: "Percentage",
      type: "line"
    }
  ];
  const options = {
    series:props.values,
    chart: {
      // background: "#222",
      height: 400,
      // redrawOnParentResize: true,
      stacked: true,
      // toolbar: {
      //   show: true
      // },
      // zoom: {
      //   enabled: true
      // },
      type: "line"
    },
    colors: ["red", "blue", "green"],
    // dataLabels: {
    //   enabled: true,
    //   enabledOnSeries: [2]
    // },
    // noData: {
    //   align: "center",
    //   text: "No data available at the moment",
    //   verticalAlign: "middle"
    // },
    // plotOptions: {
    //   bar: {
    //     borderRadius: 8,
    //     horizontal: false
    //   }
    // },
    // stroke: {
    //   width: [0, 0, 4]
    // },
    title: {
      text: "Completion Count and Percentage"
    },
    // theme: {
    //   mode: "dark"
    // },
    xaxis: {
      categories: props.labels,
      type: "category"
    },
    yaxis: [
      {
        title: {
          text: "Counts"
        }
      },
      // {
      //   max: 100,
      //   min: 0,
      //   opposite: true,
      //   title: {
      //     text: "(%) Complete"
      //   }
      // }
    ]
  };
  // let options = {
  //   series: props.values,
  //   colors:['#1BC5BD', '#F64E60'],
  //   chart: {
  //     type: "line",
  //     height: 450,
  //     stacked: true,
  //     stackType: "100%",
  //   },
  //   // dataLabels: {
  //   //   formatter: function(val, { seriesIndex, dataPointIndex, w }) {
  //   //     let total = parseInt(w.config.series[0]["data"][dataPointIndex])+ parseInt(w.config.series[1]["data"][dataPointIndex]);
  //   //     return parseInt(parseInt(val)/total *100)  + "%";
  //   //   },
  //   //   style: {
  //   //     fontSize: "10.5px",
  //   //     colors: ["#000"],
  //   //   },
  //   //   orientation: "horizontal",
  //   //   enabled: true,
  //   //   enabledOnSeries: undefined,
  //   //   textAnchor: "middle",
  //   //   distributed: false,
  //   //   background: {
  //   //     enabled: true,
  //   //     foreColor: "#fff",
  //   //     padding: 2,
  //   //     borderRadius: 2,
  //   //     borderWidth: 1,
  //   //     borderColor: "#fff",
  //   //     opacity: 0.6,
  //   //     dropShadow: {
  //   //       enabled: false,
  //   //       top: 1,
  //   //       left: 1,
  //   //       blur: 1,
  //   //       color: "#000",
  //   //       opacity: 0.45,
  //   //     },
  //   //   },
  //   //   dropShadow: {
  //   //     enabled: false,
  //   //     top: 1,
  //   //     left: 1,
  //   //     blur: 1,
  //   //     color: "#000",
  //   //     opacity: 0.45,
  //   //   },
  //   // },
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           position: "bottom",
  //           offsetX: -10,
  //           offsetY: 0,
  //         },
  //       },
  //     },
  //   ],
  //   // plotOptions: {
  //   //     bar: {
  //   //       horizontal: true,
  //   //     },
  //   //   },
  //   xaxis: {
  //     categories: props.labels,
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   legend: {
  //     position: "right",
  //     offsetX: 0,
  //     offsetY: 50,
  //   },
  // };
  return options;
}
