import { Table } from "react-bootstrap";
import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import {AdsSpentFilter} from "./ads-spent-filters/AdsSpentFilter"
import {AdsSpentTable} from "./ads-spent-table/AdsSpentTable"


export function AdsSpentCards(props) {
 
  return (
    <Card>
      {/* <CardHeader title="Ad Spent"></CardHeader> */}
      <CardBody >
        <AdsSpentFilter {...props}/>
        <AdsSpentTable {...props}/>
      </CardBody>
    </Card>
  );
}
