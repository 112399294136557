import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import { useLandingPageUIContext } from "../LandingPagesUIContext";
const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  delete newQueryParams.operator;
  if (searchText && searchText.length > 0) {
    filter.search = searchText;
  }
  newQueryParams.from = 1;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function LandingPageListingFilter({ listLoading }) {
  const landingPageUIContext = useLandingPageUIContext();
  const landingPageListUIProps = useMemo(() => {
    return {
      queryParams: landingPageUIContext.queryParams,
      setQueryParams: landingPageUIContext.setQueryParams,
    };
  }, [landingPageUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
      landingPageListUIProps.setQueryParams({...landingPageListUIProps.queryParams,search:values});
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          console.log(values);
          applyFilter(values.searchText);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3 col-md-12 col-sm-12 contact-filter-input">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
