import React from "react";
import { QuestionManagementLoadingDialog } from "./question-loading-dialog/QuestionManagementLoadingDialog";
import { QuestionManagementUIProvider } from "./QuestionManagementUIContext";
import { QuestionManagementCard } from "./QuestionManagementCard";
import { AddUpdateQuestionsDialog } from "./add-update-questions-dialog/AddUpdateQuestionsDialog";
import { Route } from "react-router";
import { QuestionDeleteDialog } from "./question-delete-dialog/QuestionDeleteDialog";
export function QuestionManagementPage({ history,userInfo }) {
  return (
    <QuestionManagementUIProvider>
      <QuestionManagementLoadingDialog />
      <Route path="/question-management/add-new">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateQuestionsDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/question-management");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      <Route path="/question-management/edit/:id">
        {({ history, match }) => {
          if (match) {
            return (
              <AddUpdateQuestionsDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/question-management");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      <Route path="/question-management/delete/:id">
        {({ history, match }) => {
          if (match) {
            return (
              <QuestionDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/question-management");
                }}
              />
            );
          }
          else{
            return null
          }
        }}
      </Route>
      <QuestionManagementCard userInfo={userInfo}/>
    </QuestionManagementUIProvider>
  );
}
