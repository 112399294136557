// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { useDomainsUIContext } from "../DomainsUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/domainsActions";
import {ChartComponent} from './ChartComponent';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function TriggerTooltip() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Good Leads Debt Average
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div style={{marginRight : '5px'}}>GLDA* : </div>
    </OverlayTrigger>
  );
}


export function DomainsTable() {
  const domainsUiContext = useDomainsUIContext();
  
  // Getting curret state of customers list from store (Redux)
  const { currentState,timezoneBy } = useSelector(
    (state) => ({ currentState: state.domainAnalysis,timezoneBy:state.timezoneBy.timezone }),
    shallowEqual
  );

  const domainUiProps = useMemo(() => {
    return {
      queryParams: {...domainsUiContext.queryParams},
      setQueryParams: domainsUiContext.setQueryParams,
    };
  }, [domainsUiContext]);

  useEffect(()=>{
    domainUiProps.setQueryParams((prev) => ({ ...prev,filterByDomainTZ:timezoneBy}));
  },[timezoneBy]);
  
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchDomainsAnalysis(domainUiProps.queryParams));
  }, [domainUiProps.queryParams, dispatch]);
 
  const generateList = () => {
    if(!entities)
      return <div>Loading</div>
  
    const keys = Object.keys(entities);
    let ind = -1;
    return (<div className="row">
      {
        keys.map((domain, domainIndex) => {
          const valueKeys = Object.keys(entities[domain]).sort();
          const labels = [];
          const values = [];
          let total = 0;
          let count = {
            INVALID: 0,
            VALID: 0,
            ab_duplicate: 0,
            avg_debt: 0,
          };
          valueKeys.forEach(valueKey => {
            if(valueKey !== 'total') {
              if ( valueKey !== 'avg_debt' && valueKey !== "ab_duplicate" ) {
                labels.push(valueKey);
                values.push(entities[domain][valueKey]);
              }
              count[valueKey] = entities[domain][valueKey];
            } else {
              total = entities[domain][valueKey];
            }
          })
          
          let percDupCalc =  ((count['ab_duplicate']/count['VALID'])*100).toFixed(2);
          if(isNaN(percDupCalc)) percDupCalc = '0.00';
          console.log("count p 2 = ", count)

          if(domain === '') {
            return <></>
          }
          ind++;
          return (
            // <div className={"col bg-light-primary px-6 py-8 rounded-xl mb-7 col-6 "+(domainIndex%2 ? '':'mr-6')} key={domainIndex} style={{'maxWidth':'49%'}}>
            <div className={"bg-light-primary px-6 py-8 rounded-xl mb-7 col-lg-6 col-md-6 col-sm-12 domain_analysis_chart_avg domain-analysis-chart "+(ind)+" "+(ind%2 === 0 ? ' domain-analysis-mr-6 ':'')} key={(ind+makeid(5)).toString()}>
              <div className="pb-4 text-center" style={{'borderBottom': '1px solid #e2e2e2'}}>
              <a  
                href="#"
                className="text-primary font-weight-bold font-size-h4"
              >
                {domain.split("www.").length > 1 
                ? domain.split("www.")[1] 
                : domain.split("www.")[0]}
              </a>
              </div>
              <div className="mt-3">
                <ChartComponent type='pie' id={"kt_mixed_widget_1_chart"+makeid(5) } labels={labels} values={values}/>
              </div>
              <a
                  href="#"
                  className="text-primary font-weight-bold font-size-h5"
                  style={{position:'absolute', right: '45px', bottom: '15px',marginLeft:'25px'}}
                >
                  {'Total Leads: ' + total}
                  {
                    Object.keys(count).map(key => {
                      return (
                        key !== 'ab_duplicate' ? (
                          <div key={key} className={key === `avg_debt` ? "d-flex" : ''}>
                          {key === `VALID` ? 
                          ( count[key] === 1 ? "Good Lead : " : `Good Leads : `) :
                          (key === `INVALID` ?  
                          ( count[key] === 1 ? "Bad Lead : " : `Bad Leads : `) :
                          (key === `avg_debt` ? <TriggerTooltip /> : ''))
                          } {count[key]}</div>) : ''
                        )
                    })  
                  }
                  <div class="">Client Dup % : {percDupCalc}%</div> 
                </a>
            </div>
          )  
        })
      }
      </div>
    )
  }
  const { entities,listLoading } = currentState;
  return (
    <>
      { listLoading ? <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div> : (entities && Object.keys(entities).length > 0) ? generateList() : <div>No records found</div>}
    </>
  );
}