import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useLeadStatusAnalysisUIContext } from "../LeadStatusAnalysisUIContext";
import * as actions from "../../../_redux/leadStatusAnalysisActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import SelectSearch from 'react-select-search';

import DateRangePicker from "react-bootstrap-daterangepicker";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";

import Autocomplete from "@material-ui/lab/Autocomplete";

// START: Used for multi select domain list
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
// END: Used for multi select domain list

import { makeStyles } from "@material-ui/core/styles";
// import 'react-select-search/style.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Col, Row } from "react-bootstrap";
import FilterPopover from "../../../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import { updateDomainFilter } from "../../../../../../redux/timezone/timezoneActions";
import momentTZ from "moment-timezone";
import { useLocation } from 'react-router-dom';
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const prepareFilter = (queryParams, values) => {
  const { domain, startDate, endDate, columns } = values;
  const newQueryParams = { ...queryParams };
  // Filter by domain
  if (domain && domain !== "")
    newQueryParams.domain = domain !== "" ? domain : undefined;
  else delete newQueryParams.domain;

  if (columns && columns !== "")
    newQueryParams.columns = columns !== "" ? columns : undefined;
  else delete newQueryParams.columns;

  // Filter by
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  return newQueryParams;
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
export function LeadStatusAnalysisFilter({ listLoading }) {
  // LeadStatusAnalysis UI Context
  const classes = useStyles();
  const leadStatusAnalysisUIContext = useLeadStatusAnalysisUIContext();
  const leadStatusAnalysisUIProps = useMemo(() => {
    return {
      queryParams: leadStatusAnalysisUIContext.queryParams,
      setQueryParams: leadStatusAnalysisUIContext.setQueryParams,
    };
  }, [leadStatusAnalysisUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      leadStatusAnalysisUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, leadStatusAnalysisUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      leadStatusAnalysisUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  /* useEffect(() => {
    leadStatusAnalysisUIProps.startDate = moment.utc(leadStatusAnalysisUIProps.queryParams.startDate).utcOffset(offSet).subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss')
    leadStatusAnalysisUIProps.endDate = moment.utc(leadStatusAnalysisUIProps.queryParams.endDate).utcOffset(offSet).format('YYYY-MM-DD HH:mm:ss')

    // dispatch(actions.fetchLeadStatusAnalysisList(startDate, endDate));
    dispatch(actions.fetchLeadStatusAnalysis(leadStatusAnalysisUIProps.queryParams));
  }, [null, dispatch]); */

  const { currentState, defaultDomains } = useSelector(
    (state) => ({
      currentState: state.leadStatusAnalysis,
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  // START: Used for multi select domain list
  const { domainList, columns } = currentState;
  const domainsState = defaultDomains.selectedDomain;

  let columnMenuItems = [];
  if (columns != null) {
    columns.forEach((element) => {
      columnMenuItems.push(element.text);
    });
  }

  const [disabledCols, setDisabledCols] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [openColumnFilter, setOpenColumnFilter] = React.useState(false);

  const location = useLocation();
  useEffect(() => {
    sessionStorage.setItem("urlChange", 1);
    setInterval(() => {
      sessionStorage.setItem("urlChange", 0);
    }, 100);
  }, [location]);

  const defaultCol = [];
  useEffect(() => {
    let defaultCols = [
      "On Call",
      "Contacted",
      "Working",
      "Appointment",
      "Missed APP",
    ];
    columns.forEach((element) => {
      if (defaultCols.includes(element.text)) {
        defaultCol.push(element.text);
      }
    });

    setSelectedColumns(defaultCol);
  }, [columns]);

  const refreshData = () => {
    dispatch(
      actions.fetchLeadStatusAnalysis(leadStatusAnalysisUIProps.queryParams)
    );
  };

  const handleOpenColumnFilter = () => {
    setOpenColumnFilter(true);
  };

  const handleCloseColumnFilter = () => {
    setOpenColumnFilter(false);
  };
  // END: Used for multi select domain list

  return (
    <>
      <Formik
        initialValues={{
          domain: leadStatusAnalysisUIProps.queryParams.domain,
          startDate: leadStatusAnalysisUIProps.queryParams.startDate,
          endDate: leadStatusAnalysisUIProps.queryParams.endDate,
          columns: leadStatusAnalysisUIProps.queryParams.columns,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <Row className="align-items-center w-100 mb-3">
              <Col lg="8" md="8" sm="12">
                <FilterPopover
                  fieldName={"dataField"}
                  value={domainsState}
                  options={domainList}
                  onApply={(val) => {
                    setFieldValue("domain", val.join(","));
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Domain
                </small>
              </Col>
              <Col
                lg="4"
                md="4"
                sm="12"
                className="contact-filter-input  analysis-page lead-analysis"
              >
                <FormControl>
                  {/* <InputLabel id="demo-mutiple-checkbox-label" shrink={true}>Select Domain</InputLabel> */}
                  {/* <Autocomplete
                    multiple
                    InputLabelProps={{ shrink: false }}
                    limitTags={3}
                    id="tags-filled"
                    variant="outlined"
                    disableCloseOnSelect
                    options={columns}
                    open={openColumnFilter}
                    onOpen={handleOpenColumnFilter}
                    onClose={handleCloseColumnFilter}
                    value={selectedColumns}
                    onBlur={(e, val) => {
                      setOpenColumnFilter(false);
                      handleSubmit();
                    }}
                    onChange={(e, val) => {
                      // let val = event.target.value;
                      // let isAllSelected = false;

                      // val.forEach((innerVal) => {
                      //   if(innerVal == '*'){
                      //     isAllSelected = true
                      //   }
                      // });

                      let selectedColumnList = [];

                      // selectedColumnList = val;
                      selectedColumnList = val.map((val) => val.text);
                      setSelectedColumns(val);
                      // dispatch(actions.updateColumns(val));
                      // setCheckedAllColumns(false);
                      setOpenColumnFilter(true);

                      console.log("selectedColumnList", selectedColumnList);
                      console.log("columnMenuItems", columnMenuItems);

                      if (selectedColumnList.length >= 6) {
                        let tmpDisabledCols = [];
                        columnMenuItems.forEach((opt) => {
                          if (!selectedColumnList.includes(opt)) {
                            tmpDisabledCols.push(opt);
                          }
                        });
                        console.log("tmpDisabledCols", tmpDisabledCols);
                        setDisabledCols(tmpDisabledCols);
                      } else {
                        setDisabledCols([]);
                      }

                      setFieldValue("columns", selectedColumnList.join());
                      // handleSubmit();
                    }}
                    getOptionLabel={(option) => option.text}
                    getOptionDisabled={(option) => {
                      if (disabledCols.includes(option.text)) {
                        return true;
                      }
                      return false;
                    }}
                    renderOption={(option, state) => {
                      const selectIndex = selectedColumns.findIndex(
                        (col) => col === option.text
                      );

                      state.selected = false;
                      if (selectIndex > -1) {
                        state.selected = true;
                      }

                      let selectedColumnArr = selectedColumns.map(
                        (selectedCol) => selectedCol.text
                      );

                      return (
                        <React.Fragment>
                          <Checkbox
                            checked={
                              selectedColumnArr.indexOf(option.text) > -1
                            }
                          />
                          <ListItemText primary={option.text} />
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Select Columns"
                      />
                    )}
                  ></Autocomplete> */}

                  <FilterPopover
                    value={selectedColumns}
                    options={columns}
                    placeholder="Select Columns"
                    maxListShow={3}
                    limitSelection={6}
                    fieldName={"text"}
                    onApply={(e) => {
                      setSelectedColumns(e);

                      setFieldValue("columns", e.join());
                      handleSubmit();
                    }}
                  />

                  {/* <Select
                    labelId="column-checkbox-input"
                    id="column-checkbox"
                    variant="outlined"
                    multiple
                    open={openColumnFilter}
                    onOpen={handleOpenColumnFilter}
                    onClose={handleCloseColumnFilter}
                    value={selectedColumns}
                    className="form-control"
                    onChange={(event) => {
                      let val = event.target.value;
                      let isAllSelected = false;

                      val.forEach((innerVal) => {
                        if(innerVal == '*'){
                          isAllSelected = true
                        }
                      });

                      let selectedColumnList = [];
                      if(isAllSelected){
                        if(selectedColumns.length == columns.length){
                          setCheckedAllColumns(false);
                          setSelectedColumns([]);
                          selectedColumnList = ['-'];
                          // dispatch(actions.updateColumns(['-']));
                        } else {
                          setCheckedAllColumns(true);
                          selectedColumnList = columns.map(column => column.dataField);
                          setSelectedColumns(selectedColumnList);
                          // dispatch(actions.updateColumns(columns.map(column => column.dataField)));
                        }
                        setOpenColumnFilter(false);
                      } else {
                        selectedColumnList = val;
                        setSelectedColumns(val)
                        // dispatch(actions.updateColumns(val));
                        setCheckedAllColumns(false);
                        setOpenColumnFilter(true);
                      }

                      setFieldValue('columns', selectedColumnList.join());
                      handleSubmit();
                    }}
                    input={<Input />}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'Select Columns';
                      }

                      return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      Select Columns
                    </MenuItem>
                    <MenuItem
                      key='*'
                      value="*"
                    >
                      <Checkbox checked={checkedAllcolumns} />
                      <ListItemText primary={checkedAllcolumns?"Unselect All":"Select All"}  />
                    </MenuItem>
                    {columnMenuItems.map((column) => (
                      <MenuItem key={column} value={column}>
                        <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                        <ListItemText primary={column} />
                      </MenuItem>
                    ))}
                  </Select> */}
                  <small className="form-text text-muted">
                    <b>Select</b> Columns
                  </small>
                </FormControl>
              </Col>
            </Row>
            <div className="form-group row lead-compare-analysis">
              <div className="col-lg-5 col-md-5 col-sm-10 col-9">
                <div className="input-group shadow-sm">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");

                      setFieldValue("startDate", startDate);
                      setFieldValue("endDate", endDate);

                      handleSubmit();
                    }}
                    initialSettings={{
                      startDate: moment(
                        leadStatusAnalysisUIProps.queryParams.startDate
                      ),
                      endDate: moment(
                        leadStatusAnalysisUIProps.queryParams.endDate
                      ),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years"),
                          moment.utc().utcOffset(offSet),
                        ],
                        Today: [
                          moment.utc().utcOffset(offSet),
                          moment.utc().utcOffset(offSet),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek'),
                          moment.utc().utcOffset(offSet).endOf('isoweek'),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                          moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month"),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input type="text" className="form-control" />
                    {/* <div className='input-group' >
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-calendar-alt"></i>
                        </span>
                    </div>
                  </div> */}
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-2 col-3">
                <Button
                  variant="outlined"
                  color="secondary"
                  className="shadow-sm"
                  onClick={refreshData}
                  title="Refresh"
                >
                  <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
                </Button>
              </div>
              {/* <div className="col-lg-3">
                <TextField
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    className={classes.textField}
                    value={values.startDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(event)=>{
                      setFieldValue('startDate',moment(event.target.value).format('YYYY-MM-DD'));
                    }}
                  />
              </div>
              <div className="col-lg-3">
                  <TextField
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    type="date"
                    className={classes.textField}
                    value={values.endDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(event)=>{
                      setFieldValue('endDate', moment(event.target.value).format('YYYY-MM-DD'));
                    }}
                  />
                </div> */}
              {/* <div className="col-lg-3">
                  <Button
                      variant="contained"
                      color="secondary"
                      onClick={()=>{
                        handleSubmit();
                      }}
                    >
                      Update List
                    </Button>
              </div> */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
