import axios from "axios";

export const CUSTOMERS_URL = "api/customers";
let ajaxRequestForCustomers = null;
let ajaxRequestForTotal = null; 
const API_URL = process.env.REACT_APP_API_URL;
export function findCustomers(queryParams) {
  // cancel  previous ajax if exists
  if (ajaxRequestForCustomers) {
    ajaxRequestForCustomers.cancel();
  }
  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequestForCustomers = axios.CancelToken.source();
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : key === 'from' ? (queryParams[key]-1) * queryParams['rows']: queryParams[key]}`;
  });
  return axios.get(`${API_URL}/contacts/list?`+params, { cancelToken: ajaxRequestForCustomers.token });
}

export function findCustomersTotal(queryParams) {
   // cancel  previous ajax if exists
   if (ajaxRequestForTotal) {
    ajaxRequestForTotal.cancel();
  }
  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequestForTotal = axios.CancelToken.source();
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : key === 'from' ? (queryParams[key]-1) * queryParams['rows']: queryParams[key]}`;
  });
  return axios.get(`${API_URL}/contacts/list/totalRecords?`+params, { cancelToken: ajaxRequestForTotal.token });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${API_URL}/contacts/${customerId}`);
}
// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${API_URL}/contacts/deleteCustomers`, { ids });
}

// Export contact list
export function startExport(queryParams) {
  return axios.post(`${API_URL}/exports`, queryParams);
}

export function getExport(id) {
  // return axios.get(`http://localhost:8000/v1/exports/getExportTemp?id=${id}`);
  return axios.get(`${API_URL}/exports/active`);
}

export function getStatusOfExport(ids) {
  let queryString = '';
  if( ids && ids.length > 0 ) {
    queryString = '?ids='+ids.join(',');
  }
  // return axios.get(`http://localhost:8000/v1/exports/getExportTemp`);
  return axios.get(`${API_URL}/exports/active${queryString}`);
}