// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import { CpvTrackingStateTableComponent } from "./CpvTrackingStateTableComponent";
import { Top5StateChart } from "./Top5StateChart";
import { OtherStateChart } from "./OtherStateChart";

export function CpvTrackingStateComponent(props) {
  let top5Label = [];
  let top5Value = [];
  let otherStateLabel = [];
  let otherStateValue = [];

  if (props.stateChartData != null) {
    let stateChartData = props.stateChartData;
    top5Label = Object.keys(stateChartData.top5Data);
    top5Value = Object.values(stateChartData.top5Data);
    otherStateLabel = Object.keys(stateChartData.otherStateData);
    otherStateValue = Object.values(stateChartData.otherStateData);
  }

  return (
    top5Label.length > 0 ? (

    <>
      <div className="row form-group">
        {top5Label.length > 0 && (
          <>
            <Top5StateChart
              type={props.type}
              top5Label={top5Label}
              top5Value={top5Value}
            />
            <CpvTrackingStateTableComponent
              type={props.type}
              entities={props.entities}
            />
          </>
        )}
      </div>

      <div className="row">
      {otherStateValue.length > 0 && (

        <OtherStateChart
          type={props.type}
          otherStateLabel={otherStateLabel}
          otherStateValue={otherStateValue}
        />)}
      </div>
    </>):(
      "No record found"
    )
  );
}
