import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useAdminLogUIContext } from "../AdminLogsUIContext";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import momentTZ from "moment-timezone";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText, InputBase, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const prepareFilter = (queryParams, values) => {
  const { searchText, startDate, endDate,type } = values;
  const newQueryParams = { ...queryParams };
  const filter = {type};

  delete newQueryParams.operator;
  if (searchText && searchText.length > 0) {
    filter.search = searchText;
  }
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  newQueryParams.from = 1;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function AdminLogsFilter({ listLoading }) {
  const adminLogsUIContext = useAdminLogUIContext();
  const adminLogsUIProps = useMemo(() => {
    return {
      queryParams: adminLogsUIContext.queryParams,
      setQueryParams: adminLogsUIContext.setQueryParams,
    };
  }, [adminLogsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(adminLogsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, adminLogsUIProps.queryParams)) {
      // update list by queryParams
      adminLogsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
          type: "all",
          startDate: adminLogsUIProps.queryParams.startDate,
          endDate: adminLogsUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3 col-md-12 col-sm-12 contact-filter-input">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                <div className="input-group shadow-sm">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                      setFieldValue("startDate", startDate);
                      setFieldValue("endDate", endDate);

                      handleSubmit();
                    }}
                    initialSettings={{
                      startDate: moment(adminLogsUIProps.queryParams.startDate),
                      endDate: moment(adminLogsUIProps.queryParams.endDate),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years"),
                          moment.utc().utcOffset(offSet),
                        ],
                        Today: [
                          moment.utc().utcOffset(offSet),
                          moment.utc().utcOffset(offSet),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek'),
                          moment.utc().utcOffset(offSet).endOf('isoweek'),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                          moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month"),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <FormControl className="w-100">
                  <InputLabel htmlFor="categoryId"></InputLabel>
                  <Select
                    id="categoryId"
                    className="w-100 shadow-sm"
                    value={values.type}
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                      handleSubmit();
                    }}
                    input={<BootstrapInput className="w-100" />}
                  >
                    <MenuItem value="" disabled>
                      Select Log Type
                    </MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="login">Login</MenuItem>
                    <MenuItem value="backup_download">DB Backup </MenuItem>
                  </Select>
                  <FormHelperText>
                    <b>Filter</b> by Type
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
