import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {TemplatesTable} from "./landing-pages-templates-table/TemplatesTable"
export function TemplatesCard({ userInfo }) {
  const { permissions, userType } = userInfo;
  const history = useHistory();

  return (
    <Card>
      {/* Landing Page Templates */}
      <CardHeader title="">
        <CardHeaderToolbar>
          {(userType.toLowerCase() === "admin" ||
            permissions["templates"].includes("add")) && (
          <Button
            id="add-template"
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push("/landing-pages/templates/add-new");
            }}
          >
            Add New Template
          </Button>
           )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <TemplatesTable userInfo={userInfo} />
      </CardBody>
    </Card>
  );
}
