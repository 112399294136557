import {createSlice} from "@reduxjs/toolkit";

const initialLeadCompareAnalysisState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities:null,
  domainList:[],
  columns:[],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadCompareAnalysisSlice = createSlice({
  name: "lead-compare-analysis",
  initialState: initialLeadCompareAnalysisState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    leadCompareAnalysisFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    // get domain list
    domainsList: (state, action) => {
      const { domainList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.domainList = domainList;
    },
    // get campaign List
    campaignList: (state, action) => {
      const { campaignList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignList = campaignList;
    },
    columnsReceived: (state, action) => {
      const { columns, defaultColumns } = action.payload;
      state.listLoading = false;
      state.error = null;
      const columnList = [];
      const newColArr = []
      columns.forEach(column => {
        if(!['totalLeads', 'domain'].includes(column.dataField)){
          columnList.push(column.dataField);
          column.selected = true;
          newColArr.push(column)
        }
      })
      state.columns = newColArr;
      state.defaultColumns = columnList
    },
    updateColumns: (state, action) => {
      const values = action.payload;
      const copy = [...state.columns];
      copy.forEach(col => {
        const findFromValues = values.find(value => value === col.dataField);
        if(findFromValues){
          col.selected = true;
        } else{
          col.selected = false;
        } 
      });
      state.columns = copy;
    }
  }
});
