import axios from "axios";

export const CUSTOMERS_URL = "api/customers";
let ajaxRequestForCustomers = null;
const API_URL = process.env.REACT_APP_API_URL;
export function findLeadAnalysis(queryParams) {
  // cancel  previous ajax if exists
  if (ajaxRequestForCustomers) {
    ajaxRequestForCustomers.cancel();
  }
  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequestForCustomers = axios.CancelToken.source();
  let params = '';
  Object.keys(queryParams).forEach(key=>{
    if( params.length > 0 ) params += '&';
    if (queryParams[key].domain && Number(sessionStorage.getItem("urlChange")) === 1) queryParams[key].domain = '';
    params += `${key}=${typeof queryParams[key] === 'object' ? JSON.stringify(queryParams[key]) : key === 'from' ? (queryParams[key]-1) * queryParams['rows']: queryParams[key]}`;
  });
  return axios.get(`${API_URL}/cpv-tracking/getLeadAnalysis?`+params, { cancelToken: ajaxRequestForCustomers.token });
}

// get all Domain Listing (Can get from cpa route)
export function getDomainList(){
  return axios.get(`${API_URL}/cpa/account`);
}

export function getCampaignList(domainList){
  let endPoint = `${API_URL}/cpv-tracking/getCampaignList`;
  if(domainList != null){
    endPoint += `?domainList=${domainList}`
  }
  return axios.get(endPoint);
}
export function getColumns(){
  return axios.get(`${API_URL}/cpv-tracking//getColumns`);
}