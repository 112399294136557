// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
) {

  return (
    <>
      <Link
        id={"edit" + row.id}
        title="Edit Page"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        to={"/landing-pages/templates/edit/" + row.id}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary fa fa-pencil-alt"></span>

      </Link>
      <Link
        title="Delete Page"
        className="btn btn-icon btn-light btn-hover-danger btn-sm ml-3"
        to={"/landing-pages/templates/delete/" + row.id}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger fa fa-trash"></span>
      </Link>
    </>
  );
}
