import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./DBBackupListingUIHelpers";

const DBBackupListingUIContext = createContext();

export function useDBBackupListingUIContext() {
  return useContext(DBBackupListingUIContext);
}

export const DBBackupListingUIConsumer = DBBackupListingUIContext.Consumer;

export function DBBackupListingUIProvider({DBBackupListingUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams
  };

  return <DBBackupListingUIContext.Provider value={value}>{children}</DBBackupListingUIContext.Provider>;
}