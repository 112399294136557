import {createSlice} from "@reduxjs/toolkit";

const initialImportState = {
  listLoading: false,
  actionsLoading: false,
  entities:[],
  totalCount: 0,
  allLeadSources:[],
  tenant:""
};


export const callTypes = {
  list: "list",
  action: "action",
};

export const landingPagesSlice = createSlice({
  name: "landing_pages",
  initialState: initialImportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } 
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true; // When click on something
      } else {
        state.fetching = true; // During get API call
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false; // When to load list
      } 
      else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false; // When click on something
      } else {
        state.fetching = false; // During get API call
      }
    },
    pagesList:(state, action) => {
      const {entities,tenant,totalCount} = action.payload;
      state.entities = entities;
      state.tenant = tenant;
      state.listLoading = false;
      state.totalCount = totalCount;
    },
    leadSourcesFetched: (state, action) => {
      const { leadSources } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allLeadSources = leadSources;
    },
  }
});
