import React from "react";
import { DomainsLoadingDialog } from "./domains-loading-dialog/DomainsLoadingDialog";
import { BarChartUIProvider } from "./BarChartsUIContext";
import { BarChartsPerMinuteCard } from "./BarChartsPerMinuteCard";

export function BarChartsPerMinutePage({ history }) {
  return (
    <BarChartUIProvider >
      <DomainsLoadingDialog />
      <BarChartsPerMinuteCard />
    </BarChartUIProvider>
  );
}
