import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./LeadStatusAnalysisUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const LeadStatusAnalysisUIContext = createContext();

export function useLeadStatusAnalysisUIContext() {
  return useContext(LeadStatusAnalysisUIContext);
}

export const LeadStatusAnalysisUIConsumer = LeadStatusAnalysisUIContext.Consumer;

export function LeadStatusAnalysisUIProvider({customersUIEvents, children}) {
  const { defaultDomains } = useSelector(
    (state) => ({
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    filterByDomainTZ: defaultDomains.timezone,
    domain: defaultDomains.selectedDomain.join(","),
  });
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <LeadStatusAnalysisUIContext.Provider value={value}>{children}</LeadStatusAnalysisUIContext.Provider>;
}