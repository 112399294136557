/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Password } from "../../../../_partials/controls";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import UserImage from "../../../../../images/user.jpeg";
import { ModalProgressBar } from "../../../../_partials/controls";
import axios from "axios";
import avatar from "../../../../../images/img_avatar.png";

// Validation schema
const ChangePasswordSchema = Yup.object().shape({
  old_pwd: Yup.string()
    .min(3)
    .max(8)
    .required("Please enter old password"),
  new_pwd: Yup.string()
    .min(5)
    .max(8)
    .required("Please enter new password"),
  confirm_pwd: Yup.string()
    .min(5)
    .max(8)
    .oneOf(
      [Yup.ref("new_pwd"), null],
      "New and confirm pasword should be equal"
    )
    .required("Please enter confirm password"),

});

const API_URL = process.env.REACT_APP_API_URL;
function changePwdAPI(values) {
  // values.email = 'Jim@gmail.com';
  return axios.post(`${API_URL}/users/changePwd`, { ...values });
}

export function QuickUser() {
  const history = useHistory();

  const maintanance_password = "7513bf17e44abd0ce4a972b7f837801e7e69ef25d5052ed9d7b0d0a0a061d5e0";//s2
  const userPwd = {
    old_pwd: "",
    new_pwd: "",
    confirm_pwd: "",
  };
  useEffect(() => {
    axios.get(`${API_URL}/project/maintanance`).then((res) => {
      setStatus(res["data"]["maintanace_mode"][0][0]["VALUE"]);
    });
  }, [])

  //CryptoJS.SHA256("Ashok@Surat").toString();
  const [show, setShow] = React.useState(false);
  const [showMaintanance, setShowMaintanace] = React.useState(false);
  const [status, setStatus] = React.useState(0)
  const [changePwdStatus, setChangePwdStatus] = React.useState({
    status: "",
    msg: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [passwordNotMatch, SetPasswordNotMatch] = React.useState(false);
  const [passwordToCheck, setPasswordToCheck] = React.useState(true);
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const changePwd = (values) => {
    changePwdAPI(values).then((res) => {
      if (res.data && res.data.respType === "Success") {
        setChangePwdStatus({
          status: res.data.respType,
          msg: res.data.data.message,
        });
        setTimeout(() => {
          setShow(false);
          setChangePwdStatus({
            status: "",
            msg: "",
          });
        }, 1000);
      } else if (res.data && res.data.respType === "Failure") {
        setChangePwdStatus({
          status: res.data.respType,
          msg: res.data.message,
        });
      }
      setLoading(false);
    });
  };

  const setMaintainanceMode = () => {
    if (passwordNotMatch == false && passwordToCheck == false) {
      axios.post(`${API_URL}/project/setMaintanance`, { "status": !status }).then(
        res => {
          setShowMaintanace(false);
          setStatus(!status);
          window.location.reload();
        }
      );
    }else{
      window.location.reload();
    }
  }


  const { user } = useSelector((state) => state.auth);
  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            {/* <div className="symbol-label" style={{
                backgroundImage: `url(${UserImage})`
              }}/> */}
            <img
              src={
                user.pic
                  ? process.env.REACT_APP_ASSETS_URL + "/" + user.pic
                  : avatar
              }
              alt="Avatar"
              className="avatar"
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.fullname}
            </a>
            <div className="text-muted mt-1">
              {user.occupation ? user.occupation : ""}
            </div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button> */}
          </div>
        </div>

        <div className="d-flex align-items-center mt-5">
          <div className="d-flex flex-column col-5">
            <button className="btn btn-twitter btn-bold" onClick={logoutClick}>
              Sign out
            </button>
          </div>

          <div className="d-flex flex-column col-7">
            <button
              className="btn btn-twitter btn-bold"
              onClick={() => {
                setShow(true);
              }}
            >
              Change Password
            </button>
          </div>

          <Modal
            size="lg"
            show={show}
            onHide={() => {
              setShow(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            className="change-pwd-model"
          >
            <Formik
              enableReinitialize={true}
              initialValues={userPwd}
              validationSchema={ChangePasswordSchema}
              onSubmit={(values) => {
                setLoading(true);
                values.email = user.email;
                changePwd(values);
              }}
            >
              {({ handleSubmit, setFieldValue }) => (
                <>
                  {loading && <ModalProgressBar />}
                  <ModalProgressBar />
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Change Password
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="overlay overlay-block">
                    {loading && (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    )}
                    <Form className="form form-label-right">
                      {changePwdStatus.status != "" ? (
                        <div className="form-group row">
                          <div className="col-lg-1"></div>
                          <div className="col-lg-10">
                            <div
                              className={
                                "alert alert-custom alert-light-" +
                                (changePwdStatus.status == "Failure"
                                  ? "danger"
                                  : "success") +
                                " alert-dismissible"
                              }
                            >
                              <div className="alert-text font-weight-bold">
                                {changePwdStatus.msg}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group row">
                        {/* Old Password */}
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <Field
                            name="old_pwd"
                            component={Password}
                            placeholder="Old Password"
                            label="Old Password"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        {/* New Password */}
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <Field
                            name="new_pwd"
                            component={Password}
                            placeholder="New Password"
                            label="New Password"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        {/* Confirm Password */}
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <Field
                            name="confirm_pwd"
                            component={Password}
                            placeholder="Confirm Password"
                            label="Confirm Password"
                          />
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Modal>
        </div>

        <div style={{ padding: "13px" }}>
          <label><strong>Maintenance Mode:</strong></label><br />
          <label className="switch_m">
            <input type="checkbox" checked={status} onChange={(v) => {
              setShowMaintanace(true);
            }} />
            <span className="slider_m round_m"></span>
          </label><br />

          <Modal
            size="lg"
            show={showMaintanance}
            onHide={() => {
              setShowMaintanace(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            className="change-pwd-model"
          >
            <Formik
              enableReinitialize={true}
              onSubmit={() => {
                setLoading(true);
                setMaintainanceMode();
              }}
            >
              {({ handleSubmit, setFieldValue }) => (
                <>
                  {loading && <ModalProgressBar />}
                  <ModalProgressBar />
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Change Maintanance Mode
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="overlay overlay-block">
                    <Form className="form form-label-right">

                      <div className="form-group row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <Field
                            name="maintanace_password_field"
                            component={Password}
                            placeholder="Enter Password"
                            label="Password"
                            onChange={e => {
                              if (CryptoJS.SHA256(e.target.value).toString() === maintanance_password) {
                                SetPasswordNotMatch(false);
                                setPasswordToCheck(false);
                              } else {
                                SetPasswordNotMatch(true);
                                setPasswordToCheck(true);
                              }

                            }}
                          />
                          {passwordNotMatch && <label style={{ color: "red" }}>Paasword Does Not Match</label>}
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMaintanace(false);
                      }}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                      disabled={passwordToCheck}
                    >
                      Save
                    </button>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Modal>
        </div>
      </div>
    </div>
  );
}
