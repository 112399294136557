// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/leadAnalysisActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  DragDropProvider,
  Table,
  TableHeaderRow,
  TableColumnReordering,
} from "@devexpress/dx-react-grid-bootstrap4";

import * as uiHelpers from "../LeadAnalysisUIHelpers";
// import * as columnFormatters from "./column-formatters";
// Pagination commented on 29-01-2021
import { Pagination as PagHelper } from "../../../../../../_metronic/_partials/controls";
import Pagination from "react-js-pagination";
import { useLeadAnalysisUIContext } from "../LeadAnalysisUIContext";
import * as moment from "moment";

export function LeadAnalysisTable({ selectedColumnsData }) {
  // LeadAnalysis UI Context
  const leadAnalysisUIContext = useLeadAnalysisUIContext();
  const leadAnalysisUIProps = useMemo(() => {
    return {
      ids: leadAnalysisUIContext.ids,
      setIds: leadAnalysisUIContext.setIds,
      queryParams: leadAnalysisUIContext.queryParams,
      setQueryParams: leadAnalysisUIContext.setQueryParams,
      openEditCustomerDialog: leadAnalysisUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: leadAnalysisUIContext.openDeleteCustomerDialog,
      openLeadStatusDialog: leadAnalysisUIContext.openLeadStatusDialog,
    };
  }, [leadAnalysisUIContext]);
  // Getting curret state of leadAnalysis list from store (Redux)
  const { currentState, timezoneBy } = useSelector(
    (state) => ({
      currentState: state.leadAnalysis,
      timezoneBy: state.timezoneBy,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading, columns } = currentState;
  let { defaultColumns } = currentState;
  const perPageLoading = leadAnalysisUIContext.queryParams.rows;
  const pageNumber = leadAnalysisUIContext.queryParams.pageNumber;
  const { timezone } = timezoneBy;

  // let columnArr = ["live", "extra1", "extra2", "extra3", "totalLeads", "domain", "dnq-", "on-call", "new", "s-sonoma-sold", "contacted", "working", "missed-app", "quoted", "appointment", "not-sold", "not-interested", "dead", "turbo", "bad-number", "chat-app", "s-enrolled"];
  let columnArr = defaultColumns;

  let columnList = [];
  if (columns.length > 0) {
    columnArr = [];
    let selectedColumns = columns;
    if (selectedColumnsData.length)  selectedColumns = columns.filter(column => selectedColumnsData.find(x => x === column.text ));

    selectedColumns.forEach((element) => {
      columnArr.push(element.dataField);
      columnList.push({
        name: element.dataField,
        title: element.text,
        dataField: element.dataField,
        text: element.dataField,
      });
    });
  }

  const [columnOrder, setColumnOrder] = useState(columnArr);

  // leadAnalysis Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    leadAnalysisUIProps.setIds([]);
    // server call by queryParams
    leadAnalysisUIProps.queryParams.timezoneBy = timezone;
    dispatch(actions.fetchLeadAnalysis(leadAnalysisUIProps.queryParams));
    // dispatch(actions.fetchCustomerCount(leadAnalysisUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // return () => {
    //   dispatch(actions.removeLeadAnalysisState());
    // };
  }, [leadAnalysisUIProps.queryParams, dispatch]);
  // Table pagination properties

  // get effective when timezone type is changed
  useEffect(() => {
    leadAnalysisUIProps.queryParams.timezoneBy = timezone;
    dispatch(actions.fetchLeadAnalysis(leadAnalysisUIProps.queryParams));
  }, [timezone]);

  useEffect(() => {
    setColumnOrder(columnArr);
  }, [defaultColumns]);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: leadAnalysisUIProps.queryParams.pageSize,
    page: leadAnalysisUIProps.queryParams.from,
  };

  const [tableColumnExtensions] = useState([
    { columnName: "extra1", width: 100 },
  ]);

  const changeOrder = (order) => {
    setColumnOrder(order);
    // defaultColumns = order;
  };

  const handlePageChange = (pageNumber) => {
    leadAnalysisUIProps.setQueryParams((prev) => {
      return { ...prev, pageNumber: pageNumber };
    });
    // leadAnalysisUIProps.setQueryParams.pageNumber = pageNumber;
    // dispatch(actions.fetchLeadAnalysis(leadAnalysisUIProps.queryParams));
  };

  return (
    <>
      {columns.length === 0 ? (
        <></>
      ) : entities != null ? (
        <>
          {/* <PagHelper
            isLoading={listLoading}
            paginationProps={paginationOptions}
          > */}
          <Grid remote rows={entities} columns={columnList}>
            <DragDropProvider />
            <SortingState />
            <IntegratedSorting />
            <Table columnExtensions={tableColumnExtensions} />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={changeOrder}
            />
            <TableHeaderRow showSortingControls />
          </Grid>
          {/* </PagHelper> */}
          {entities.length > 0 ? (
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={perPageLoading}
                totalItemsCount={totalCount}
                pageRangeDisplayed={perPageLoading}
                onChange={handlePageChange}
                itemClass={"btn btn-icon btn-sm border-0 btn-light  mr-2 my-1"}
                activeClass={"btn-hover-primary active"}
                activeLinkClass={"btn-hover-primary text-white"}
                linkClass={"custom-pagination-a"}
                prevPageText={<i className="ki ki-bold-arrow-back icon-xs"></i>}
                nextPageText={<i className="ki ki-bold-arrow-next icon-xs"></i>}
                firstPageText={
                  <i className="ki ki-bold-double-arrow-back icon-xs"></i>
                }
                lastPageText={
                  <i className="ki ki-bold-double-arrow-next icon-xs"></i>
                }
              />

              {listLoading ? (
                <div className="d-flex align-items-center">
                  <div className="mr-2 text-muted">Loading...</div>
                  <div className="spinner spinner-primary mr-10"></div>
                </div>
              ) : (
                <div className="d-flex align-items-center py-3">
                  <select
                    className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
                    style={{ width: "75px" }}
                    value={perPageLoading}
                    onChange={(e) => {
                      leadAnalysisUIProps.setQueryParams((prev) => {
                        return { ...prev, rows: e.target.value };
                      });
                    }}
                  >
                    {uiHelpers.sizePerPageList.map((per) => {
                      return (
                        <option className="btn " value={per.value}>
                          {per.text}
                        </option>
                      );
                    })}
                  </select>
                  <span className="react-bootstrap-table-pagination-total">
                    &nbsp;Showing rows{" "}
                    {(pageNumber - 1) * perPageLoading <= 0
                      ? 1
                      : (pageNumber - 1) * perPageLoading}{" "}
                    to&nbsp;
                    {perPageLoading * pageNumber < totalCount
                      ? perPageLoading * pageNumber
                      : perPageLoading * (pageNumber - 1) +
                        totalCount -
                        perPageLoading * (pageNumber - 1)}{" "}
                    of&nbsp;{totalCount}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          {/* <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={50}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => {
              setActivePage(pageNumber);
            }}
            itemClass={"li-item"}
          /> */}

          {/* OLD */}
          {/*<PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center"
                    bootstrap4
                    remote
                    keyField="id"
                    data={entities || []}
                    columns={columns.filter(col => col.selected) || []}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                      leadAnalysisUIProps.setQueryParams
                    )}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={entities} />
                    <NoRecordsFoundMessage entities={entities} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
          */}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
