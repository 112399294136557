import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useQuestionsUIContext } from "../QuestionsUIContext";
import * as actions from "../../../_redux/questionsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import SelectSearch from 'react-select-search';
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
// import 'react-select-search/style.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import momentTZ from 'moment-timezone';
import CachedIcon from "@material-ui/icons/Cached";
import Button from "@material-ui/core/Button";
import { useLocation } from 'react-router-dom';
const offSet = momentTZ().tz("America/New_York").format("Z")

const prepareFilter = (queryParams, values) => {
  const { domain, startDate, endDate } = values;
  const newQueryParams = { ...queryParams };
  // Filter by domain
  if (domain && domain !== "")
    newQueryParams.domain = domain !== "" ? domain : undefined;
  else delete newQueryParams.domain;
  // Filter by
  newQueryParams.startDate = startDate;
  newQueryParams.endDate = endDate;
  return newQueryParams;
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));
export function QuestionsFilter({ refresh,setRefresh,setReloadCate,reloadCate }) {
  // Questions UI Context
  const classes = useStyles();
  const questionsUIContext = useQuestionsUIContext();
  const questionsUIProps = useMemo(() => {
    return {
      queryParams: questionsUIContext.queryParams,
      setQueryParams: questionsUIContext.setQueryParams,
    };
  }, [questionsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(questionsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, questionsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      questionsUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchDomainsList(questionsUIProps.queryParams.startDate,questionsUIProps.queryParams.endDate));
  }, [questionsUIProps.queryParams]);

  useEffect(() => {
    setReloadCate(!reloadCate);
  }, [questionsUIProps.queryParams.startDate,questionsUIProps.queryParams.endDate]);

  const { defaultDomains } = useSelector(
    (state) => ({
      currentState: state.questionAnalysis,
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  let domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    domainsState = [];
  }, [location]);
  
  const refreshData = () => {
    setRefresh(!refresh);
    // dispatch(actions.fetchQuestionsAnalysis(questionsUIProps.queryParams));
  };
  // END: Used for multi select domain list

  return (
    <>
      <Formik
        initialValues={{
          domain: domainsState.join(","),
          startDate: questionsUIProps.queryParams.startDate,
          endDate: questionsUIProps.queryParams.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-5 col-md-5 col-sm-9 col-8">
                <div className="input-group">
                  <DateRangePicker
                    onCallback={(start, end, label) => {
                      let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                      let endDate = end.format("YYYY-MM-DD HH:mm:ss");

                      setFieldValue("startDate", startDate);
                      setFieldValue("endDate", endDate);

                      handleSubmit();
                    }}
                    initialSettings={{
                      startDate: moment(questionsUIProps.queryParams.startDate),
                      endDate: moment(questionsUIProps.queryParams.endDate),
                      ranges: {
                        "Life Time": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(10, "years"),
                          moment.utc().utcOffset(offSet),
                        ],
                        Today: [
                          moment.utc().utcOffset(offSet),
                          moment.utc().utcOffset(offSet),
                        ],
                        Yesterday: [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "days"),
                        ],
                        "Last 7 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(6, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 14 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(13, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "Last 30 Days": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(29, "days"),
                          moment.utc().utcOffset(offSet),
                        ],
                        "This week": [
                          moment.utc().utcOffset(offSet).startOf('isoweek'),
                          moment.utc().utcOffset(offSet).endOf('isoweek'),
                        ],
                        "Last week": [
                          moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek'),
                          moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek'),
                        ],
                        "This Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .endOf("month"),
                        ],
                        "Last Month": [
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .startOf("month"),
                          moment
                            .utc()
                            .utcOffset(offSet)
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                      },
                      locale: {
                        format: "MMMM DD, YYYY",
                      },
                      alwaysShowCalendars: true,
                    }}
                  >
                    <input type="text" className="form-control" />
                    {/* <div className='input-group' >
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-calendar-alt"></i>
                        </span>
                    </div>
                  </div> */}
                  </DateRangePicker>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="fa fa-calendar-check"></i>
                    </span>
                  </div>
                </div>
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-3 col-4">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={refreshData}
                  title="Refresh"
                >
                  <CachedIcon style={{ fontSize: 25 }}></CachedIcon>
                </Button>
              </div>
              {/* <div className="col-lg-3">
                <TextField
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    className={classes.textField}
                    value={values.startDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(event)=>{
                      setFieldValue('startDate',moment(event.target.value).format('YYYY-MM-DD'));
                    }}
                  />
              </div> */}
              {/* <div className="col-lg-3">
                  <TextField
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    type="date"
                    className={classes.textField}
                    value={values.endDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(event)=>{
                      setFieldValue('endDate', moment(event.target.value).format('YYYY-MM-DD'));
                    }}
                  />
                </div>
              <div className="col-lg-3">
                  <Button
                      variant="contained"
                      color="secondary"
                      onClick={()=>{
                        handleSubmit();
                      }}
                    >
                      Update List
                    </Button>
              </div> */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
