import momentTZ from "moment-timezone";
import * as moment from "moment";
export const CustomerStatusCssClasses = ["danger", "success", "info", ""];
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [{ text: "25", value: 25 }];
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");
export const initialFilter = {
  startDate:
    moment()
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00",
  endDate:
    moment()
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59",
  type: "all",
};
