import * as requestFromServer from "./customersCrud";
import { customersSlice, callTypes } from "./customersSlice";
import { toast } from "react-toastify";
import React from "react";
const { actions } = customersSlice;
export const fetchCustomers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then((response) => {
      if (response && response.status === 204) {
        dispatch(actions.customersFetched({ totalCount: 0, entities: [] }));
      } else if (response && response.data && response.data.respType) {
        dispatch(
          actions.customersFetched({
            totalCount: 0,
            entities: response.data.data,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCustomerCount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomersTotal(queryParams)
    .then((response) => {
      if (response && response.data && response.data.respType) {
        dispatch(
          actions.customersTotalFetched({
            totalCount: response.data.data.total,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then((response) => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export contact list
export const startExport = (queryParams) => (dispatch) => {
  return requestFromServer
    .startExport(queryParams)
    .then((response) => {
      if (response && response.data.respType === "Success") {
        dispatch(actions.startExportSuccess({ data: response.data.data }));
      } else {
        toast.error(
          <strong>{response.data.message}</strong>,
          { position: toast.POSITION.TOP_CENTER }
        );
        dispatch(actions.catchError({ callType: callTypes.list }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Something is wrong!!";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export contact list
export const getExport = (id) => (dispatch) => {
  return requestFromServer
    .getStatusOfExport(id)
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(actions.exportStatusFetched({ data: response.data.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Something is wrong!!";
      dispatch(actions.catchError({ error, callType: callTypes.fetching }));
    });
};
export const donwloadDone = (id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(actions.exportDownloadDone({ id }));
    resolve();
  });
};
