import S3 from "aws-sdk/clients/s3";
import { v4 as uuidv4 } from "uuid";

const BUCKET = "our-data-matrix";
const _S3 = new S3({
  accessKeyId: "AKIAWT5IIH3HIBB3C26J",
  secretAccessKey: "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",
  region: "us-east-2",
});
let currentFileUpload = null;
export function AWSFileUpload(base64Data, fileName, uploadProgresshandler) {
  const clouldFileName = uuidv4();

  // Setting up S3 upload parameters
  const params = {
    Bucket: BUCKET,
    Key: "import/" + clouldFileName,
    Body: base64Data,
  };

  return new Promise((resolve, reject) => {
    currentFileUpload = _S3
      .upload(params, (err, data) => {
        currentFileUpload = null;
        if (err) {
          reject(err);
        } else {
          resolve(clouldFileName);
        }
      })
      .on("httpUploadProgress", uploadProgresshandler);
  });
}
export function cancelUpload() {
  if (currentFileUpload) {
    currentFileUpload.abort();
    currentFileUpload = null;
  }
  return Promise.resolve();
}
export function AWSFileDownload(exportReport) {
  // Setting up S3 upload parameters
  const params = {
    Bucket: BUCKET,
    Key: exportReport.downloadUrl,
  };

  return new Promise((resolve, reject) => {
    _S3.getObject(params, (error, data) => {
      if (error != null) {
        alert("File download failed!!");
        reject(false);
      } else {
        let blob = new Blob([data.Body], { type: data.ContentType });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        try {
          const nameCondition = JSON.parse(exportReport.condition);
          const name =
            "From:" + nameCondition.startDate + " To: " + nameCondition.endDate;
          link.download = name + ".csv";
        } catch (e) {
          console.warn(e);
        }
        link.click();
        resolve(true);
      }
    });
  });
}
export async function AWSFileDownloadRequest(exportReport) {
  // Setting up S3 upload parameters
  const params = {
    Bucket: BUCKET,
    Key: exportReport.downloadUrl,
    Expires: 3600 * 5
  };
  try {
    let url = _S3.getSignedUrl("getObject", params);
    return url;
  } catch (error) {
    console.log(error);
    
  }
}
