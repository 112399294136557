import * as requestFromServer from "./AdsSpentCrud";
import {adSpentListSlice, callTypes} from "./AdsSpentSlice";
import {toast} from "react-toastify"
const {actions} = adSpentListSlice;

export const fetchAdSpentListing = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAdSpent(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.AdSpentFetched({ entities: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.AdSpentFetched({  entities: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Ad Spent";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const addNewAccount = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addAccount(details)
    .then((response) => {
      const accountDetails = response.data.data;
      if (response.data.respType === "Success") {
        dispatch(actions.accountCreated({ accountDetails }));
        return true;
      } else {
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create account";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const deleteAccount = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAccount(details)
    .then((response) => {
      dispatch(actions.accountDeleted({ details }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete account";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAccountsListing = queryParams => dispatch => {
  return requestFromServer
    .findAccounts(queryParams)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.accountFetched({ accounts: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.accountFetched({  accounts: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Ad Spent";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const addOrUpdateSpentValue = (details) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addOrUpdateSpent(details)
    .then((response) => {
      if (response.data.respType === "Success") {
        dispatch(actions.spentUpdated({ ...details,difference:response.data.data.difference }));
        return true;
      } else {
        toast.error("There are some errors during update",{position:toast.POSITION.TOP_CENTER})
        dispatch(
          actions.catchError({
            error: response.data.message,
            callType: callTypes.action,
          })
        );
        return false;
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update values";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAllLeadSources = () => (dispatch) => {
  return requestFromServer
    .findLeadSources()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.leadSourcesFetched({ leadSources: []}));
      }else if( response && response.data && response.data.respType ) {
        dispatch(actions.leadSourcesFetched({  leadSources: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Lead Sources";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
  
}
