import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { LandingPagesTable } from "./landing-pages-table/LandingPagesTable";
import { LandingPageListingFilter } from "./landing-page-listing-filter/LandingPageListingFilter";
export function LandingPagesCard({ userInfo }) {
  const { permissions, userType } = userInfo;
  const history = useHistory();

  return (
    <Card>
      {/* LandingPage Forms */}
      <CardHeader title="">
        <CardHeaderToolbar>
          {(userType.toLowerCase() === "admin" ||
            permissions["landing_pages"].includes("add")) && (
          <Button
            id="add-domain"
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push("/landing-pages/forms/add-new");
            }}
          >
            Add New Form
          </Button>
           )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <LandingPageListingFilter />
        <LandingPagesTable userInfo={userInfo}/>
      </CardBody>
    </Card>
  );
}
