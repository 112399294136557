import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getAllAccountList() {
  return axios.get(`${API_URL}/cpa/account`);
}

export function getChartData(details) {
  let header = { params:details};
  return axios.get(`${API_URL}/cpa`, header);
}


export function addSaleStatus(details) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.post(`${API_URL}/cpa/sale-status`,details, header);
}


export function deleteStatus(id) {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.delete(`${API_URL}/cpa/sale-status/${id}`, header);
}

export function getSaleStatus() {
  let token = localStorage.getItem("token");
  let header = { headers: { Authorization: `Bearer ${token}` }};
  return axios.get(`${API_URL}/cpa/sale-status`, header);
}


