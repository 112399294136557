import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { Form } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  applyContainer: {
    padding: "10px",
    borderTop: "1px solid #ddd",
    display: "flex",
    justifyContent: "space-between",
  },
  numberClass: {
    fontWeight: "600",
    fontSize: "13px",
  },
  domainDescription: {
    border: "1px solid #E4E6EF",
    borderRadius: "4px",
    minHeight: "40px",
    padding: "10px",
    cursor: "pointer",
  },
  showList: {
    display: "inline-block",
    backgroundColor: "#dee2e6",
    padding: "4px 7px",
    fontSize:"11px",
    borderRadius: "30px",
    boxShadow: "2px 2px #ccc"
  },
  searchFilter: {
    padding: "10px",
    fontSize: "12px",
  },
  menuItem: {
    padding:0
  },
  menuList: {
    overflow: "scroll",
    height: "240px",
  },
  selected_text_status : {
    fontSize: "15px",
    borderBottom: "1px solid #ccc",
    fontWeight: "500",
    paddingBottom: "5px",
    margin: "9px 9px 0px 9px",
  }
}));

export default function FilterPopover({
  showAllDomainCheck,
  querySelectedDomains,
  selectedDomains,
  disableBtn,
  options,
  fieldName = " Domain ",
  onApply,
  activeLeadStatusData,
  value,
  limitSelection = null,
  placeholder = "Select Domain",
  maxListShow = 12,
  labelField = "dataField",
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allOptionNames, setAllOptionNames] = React.useState([]);
  const [sortedActiveLeadStatus, setSortedActiveLeadStatus] = React.useState([]);
  const [sortedUnactiveLeadStatus, setSortedUnactiveLeadStatus] = React.useState([]);
  const [showsearchLeadLabel, setShowsearchLeadLabel] = React.useState(false);
  const [sortedLeadStatus, setSortedLeadStatus] = React.useState([]);
  const [sortedLeadStatusEmpty, setSortedLeadStatusEmpty] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showList, setShowList] = React.useState([]);

  useEffect(() => {
    setAllOptionNames(options);
  }, [options]);

  useEffect(() => {
    let activestatus = [];
       if(value.length < 1) {
        if (showAllDomainCheck === false && disableBtn === false) {
          setSelected([]);
          setShowList([]);
          activestatus = [];
        }
        else {
          setSelected([activeLeadStatusData]);
          setShowList([activeLeadStatusData]);
          activestatus = [activeLeadStatusData];
        }
      }
      else {
        setSelected(value);
        setShowList(value);
        activestatus = value;
        if (value.length !== 0) {
          activestatus = value; 
        }
        else {
          activestatus = [activeLeadStatusData];
        }
      }

      let activeStatusesData = options.filter(function(n) { return activestatus.indexOf(n.dataField) !== -1; })
      let nonactiveStatusesData = options.filter(function(n) { return activestatus.indexOf(n.dataField) === -1; })
      let sortedStatusData = activeStatusesData.concat(nonactiveStatusesData);
      setSortedActiveLeadStatus(activeStatusesData);
      setSortedUnactiveLeadStatus(nonactiveStatusesData);
      setSortedLeadStatus(sortedStatusData);
      setSortedLeadStatusEmpty(sortedStatusData);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onApply(selected);
    setShowList(selected);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filterDomainList = (e) => {
    let filteredData = options.filter((d) => {
      if (d[fieldName].includes(e.target.value)) {
        return true;
      } else {
        return false;
      }
    });
    e.target.value != '' ? setSortedLeadStatus(filteredData) : setSortedLeadStatus(sortedLeadStatusEmpty);
    e.target.value != '' ? setShowsearchLeadLabel(true) : setShowsearchLeadLabel(false);
    setAllOptionNames(filteredData);
  };

  return (
    <div  className={disableBtn ? "disabledfieldStatus" : ""}>
      <div
        aria-describedby={id}
        className={classes.domainDescription + " shadow-sm multinewfilter " + (window.location.href.indexOf("trends") > -1 ? " disableDomainBtn" : "")}
        onClick={handleClick}
      >
        <div className="d-flex justify-content-between align-items-center">
        <div>
          {
            disableBtn ? 'Select Leads' : 
            showList.length + (window.location.pathname === '/contacts/' ? (fieldName=="dataField" ? " column ": fieldName) : (fieldName=="dataField" ? " leads ": fieldName) ) + " Selected"
          } 
          {
            disableBtn ? '' : (showList.length > 0 && maxListShow < showList.length && (
              <span className={classes.numberClass + " ml-1 mb-1"}>
                +{showList.length - maxListShow}
              </span>  
            ))
          }
          </div>
          <div>
            {open ? (
              <i className="fas fa-chevron-up"></i>
            ) : (
              <i className="fas fa-chevron-down"></i>
            )}
          </div>
        </div>
      </div>
      <Popover
        id={id}
        className="popover_class"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "300px", maxHeight: "400px", overflow: "hidden" },
          className:"arrow-popup"
        }}
      >
        <div className="px-3">
          <h6 className="mb-0 mb-2 mt-3 font-weight-bold">Filter</h6>
          <Form.Control
            className={classes.searchFilter + " shadow-sm"}
            placeholder="Filter list"
            onChange={filterDomainList}
            autoFocus={true}
          />
        </div>

        {allOptionNames.length > 0 ? (
          <div className="multifilter">
            <MenuList className={classes.menuList}>
            {sortedLeadStatus.map((options, indexNum) => {
                return (
                  <div className="MenuItem_mui_options">
                    {
                      showsearchLeadLabel === false && indexNum === 0 && sortedActiveLeadStatus.length > 0 &&
                      <p className={classes.selected_text_status}>Selected</p> 
                    }
                    {
                      showsearchLeadLabel === false && sortedActiveLeadStatus.length === indexNum &&
                      <p className={classes.selected_text_status}>Unselected</p>
                    }
                    <MenuItem
                      className={classes.menuItem}
                      key={options[fieldName]}
                      value={options[fieldName]}
                      onClick={(e) => {
                        if (selected.includes(options[fieldName])) {
                          let index = selected.indexOf(options[fieldName]);
                          let originalArr = [...selected];
                          originalArr.splice(index, 1);
                          selectedDomains > 1 && !showAllDomainCheck ?
                          (querySelectedDomains.length === 0 ? setSelected(originalArr) : 
                          setSelected([])) :
                          setSelected(originalArr);
                        } else {
                          selectedDomains > 1 && !showAllDomainCheck ?
                          (
                            querySelectedDomains.length === 0 ? setSelected([...selected, options[fieldName]]) : 
                            setSelected([options[fieldName]])
                          ) :
                          setSelected([...selected, options[fieldName]]);
                        }
                      }}
                      disabled={
                        limitSelection &&
                        selected.length >= limitSelection &&
                        !selected.includes(options[fieldName])
                      }
                    >
                      <Checkbox checked={selected.includes(options[fieldName])} />
                      <ListItemText primary={options[fieldName]} />
                    </MenuItem>  
                  </div>
                );
              })}
            </MenuList>
            <div className={classes.applyContainer}>
              <div className="text-left">
                {!limitSelection && ( 
                  <div  className={ selectedDomains > 1 && showAllDomainCheck === false && disableBtn === false ? "disabledfieldStatus" : ""}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={"text-white" + (window.location.href.indexOf("trends") > -1 ? " disableDomainBtn" : "")}
                      onClick={(e) => {
                        setSelected(allOptionNames.map((e) => e[fieldName]));
                      }}
                    >
                      Select All
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  className="mr-2"
                  onClick={(e) => {
                    setSelected([]);
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-5">Not Found!</div>
        )}
      </Popover>
    </div>
  );  
}
  