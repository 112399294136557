// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponentDemo(props) {
  useEffect(() => {
    const element = document.getElementById(props.id);
    if (!element) {
      return;
    }
    const options = getAllCRChartOptions(props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props]);
  return (
    <>
      <div
        id={props.id}
        className="card-rounded-bottom"
        style={{ height: "200px" }}
      ></div>
    </>
  );
}
function getAllCRChartOptions(props) {
  var porps_dt = props.values;
  var super_array = [];
  for (const kt_data_0 of porps_dt){

    const kt_a = kt_data_0.data;
    if (kt_a.some(el => el > 0)) {
      super_array.push(kt_data_0)
    }
  }

  let options = {
    series: super_array,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      stackType: "100%",
      height: "400",
    },
    colors: [
      "#2cc5db",
      "#06b109",
      "#3f11b7",
      "#b9c8a2",
      "#626d13",
      "#bddc14",
      "#003399",
      "#945da1",
      "#7e68da",
      "#4f2c47",
      "#2a1e69",
      "#ae0167",
      "#b3b8fa",
      "#3d2d27",
      "#00FF00",
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {  
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true
    },
    stroke: {
      curve: "straight",
      width: [3, 3],
    },
    title: {
      text: "Debt Analysis",
      align: "left",
    },
    xaxis: {
      categories: props.labels,
      hideOverlappingLabels: true,
      labels: {
        style: {
          cssClass: "live-chat-x-axis",
          fontSize: "8px",
        },
      },
    },
    yaxis: {
      forceNiceScale: false,
      tickAmount: 5,
      labels: {
        formatter: function(value) {
          return value.toLocaleString() + " $";
        },
      },
    },
  };
  
  return options;
}
