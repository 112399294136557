import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./LeadAnalysisUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const LeadAnalysisUIContext = createContext();

export function useLeadAnalysisUIContext() {
  return useContext(LeadAnalysisUIContext);
}

export const LeadAnalysisUIConsumer = LeadAnalysisUIContext.Consumer;

export function LeadAnalysisUIProvider({ leadAnalysisUIEvents, children }) {
  const { defaultDomains } = useSelector(
    (state) => ({
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );

  const [queryParams, setQueryParamsBase] = useState({
    ...initialFilter,
    timezoneBy: defaultDomains.timezone,
    filter: {
      ...initialFilter.filter,
      domain: defaultDomains.selectedDomain.join(","),
    },
  });
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
  };

  return (
    <LeadAnalysisUIContext.Provider value={value}>
      {children}
    </LeadAnalysisUIContext.Provider>
  );
}
