import React from "react";
import { QuestionsLoadingDialog } from "./questions-loading-dialog/QuestionsLoadingDialog";
import { QuestionsUIProvider } from "./QuestionsUIContext";
import { QuestionsCard } from "./QuestionsCard";
export function QuestionsPage({ history }) {
  const customersUIEvents = {
    openDeleteCustomerDialog: (id) => {
      history.push(`/contacts/${id}/delete`);
    },
    openDeleteQuestionsDialog: () => {
      history.push(`/e-commerce/customers/deleteQuestions`);
    },
  }

  return (
    <QuestionsUIProvider customersUIEvents={customersUIEvents}>
      <QuestionsLoadingDialog />
      <QuestionsCard />
    </QuestionsUIProvider>
  );
}
