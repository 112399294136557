import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./TemplatesUIHelpers";

const TemplatesUIContext = createContext();

export function useTemplatesUIContext() {
  return useContext(TemplatesUIContext);
}

export const TemplatesUIConsumer = TemplatesUIContext.Consumer;

export function TemplatessUIProvider({domainsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initTemplatess = {
   
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initTemplatess,
  };

  return <TemplatesUIContext.Provider value={value}>{children}</TemplatesUIContext.Provider>;
}