// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
export function ChartComponent(props) {
    useEffect(() => {
        const element = document.getElementById(props.id);
        if (!element) {
            return;
        }
        
        const options = getPieChartOptions(props);

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [props]);
    return (
        <>
            <div
                id={props.id}
                className="card-rounded-bottom"
                style={{ height: "200px" }}
            ></div>
        </>
    );
}
function getMaximumValue( series ){
    let values = [];
    series.map(ele=>{
        values = [...values, ...ele.data];
    })
    values = values.map(value=>parseFloat(value));
    return roundToNextMultipleOf10(Math.max(...values));
}
function roundToNextMultipleOf10(i)
{
    let roundToNextValue = 0;
    if(i%10==0)
        roundToNextValue = parseInt(i+10);
    else 
        roundToNextValue = ((parseInt(i/10)+1)*10);
    return roundToNextValue > 100 ? 100 : roundToNextValue;
}
function getPieChartOptions(props) {
    const colors = {
        1:['#187de4'],
        2:['#187de4', '#1bc5bd'],
        3:['#187de4', '#F64E60', '#1bc5bd'],
        5:['#187de4', '#1bc5bd','#F64E60', '#FFA800','#8950FC'],
    };

    // console.log('props.labels', props.labels);
    // console.log('props.values', props.values);

    /* const colorBoundry = ["#f9af72", "#8ba646", "#b6b0da", "#121dca", "#338004", "#90283c", "#37f2a4", "#6a5f1b", "#752eaf", "#d07118", "#e5af0f", "#fb19d4", "#82afc2", "#2fa58a", "#0d2b81", "#32a8bd", "#44f366", "#c9c596", "#13287f", "#d45ef9", "#4e9da4", "#2e3785", "#e7edfc", "#2d2035", "#37a345", "#80434a", "#6a9425", "#f1eea5", "#f8e6d3", "#88a2d8", "#48e8ea", "#18b26f", "#842db9", "#9d3419", "#2691ba", "#a9b6b3", "#1de23b", "#a5b9a1", "#a49cb3", "#3754ae", "#c2469f", "#e7e275", "#7bbca0", "#a503ec", "#c6a884", "#7dfa09", "#ac0ff9", "#d5adab", "#8517b3", "#e17c0e"];
    // '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
    const colorArr = []
    for(let i=0; i<props.labels.length; i++){
        colorArr.push(colorBoundry[i]);
    }

    console.log('colorArr', colorArr) */
    
    var options = {
        series: props.series,
        chart: {
            // width: 420,
            type: 'bar',
            height: 550,
            // stacked: true,
            stackType: '100%'
        },
        yaxis: {
            min: 0,
            max: getMaximumValue(props.series),
        },
        xaxis: {
            categories: props.domainList,
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                trim: false,
                minHeight: 140,
                offsetY: 10,
                style: {
                    colors: ['#444'],
                    fontSize: '13px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            }
        },
        fill: {
            opacity: 1
        },
        plotOptions: {
            bar: {
                columnWidth: '90%',
                distributed: false,
                rangeBarOverlap: false,
                rangeBarGroupRows: false,
            }
        },
        dataLabels: {
            formatter: function(val, opt) {
              return val == '0.55' ? '0 %' : val + ' %'
            //   return val + ' %'
            },
            style: {
                fontSize: '10.5px',
                colors: ['#000']
            },
            orientation: 'horizontal',
            enabled: true,
            enabledOnSeries: undefined,
            textAnchor: 'middle',
            distributed: false,
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 2,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.6,
                dropShadow: {
                  enabled: false,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: '#000',
                  opacity: 0.45
                }
              },
              dropShadow: {
                  enabled: false,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: '#000',
                  opacity: 0.45
              }
        },
        colors: props.colorRange,
        labels: props.domainList,
        tooltip: {
            /* y: {
              formatter: function (val) {
                return (val == '0.55' ? 0 : val) + " %"
                // return val + " %"
              }
            } */
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let label = w.config.labels[dataPointIndex ] ? w.config.labels[dataPointIndex ] : 'NA';
                let col = w.config.series[seriesIndex].name ? w.config.series[seriesIndex].name : 'N/A'
                let cnt = 0, percentage = 0;
                let percent = series[seriesIndex][dataPointIndex]
                percent = percent == '0.55' ? 0 : percent
                percent += ' %' 
                
                cnt = w.config.series[seriesIndex].cnt[dataPointIndex] ? w.config.series[seriesIndex].cnt[dataPointIndex] : 0

                // return '<div class="arrow_box" style="padding:10px;">' +
                //     '<div style="border-bottom:1px solid #cfcfd3;">' + label + '</div>' +
                //     '<span style="margin-top:2px;"><span style="font-size:12px;">' + col + '</span></br>' +
                //     '<span style="font-size:12px;> Percentage: '+ percent +'</br> Leads: ' + cnt +  '</span></span>' +
                //     '</div>'

                return '<div class="arrow_box" style="padding:10px;">' +
                    '<span style="font-weight:700;margin-top:5px;border-bottom:1px solid #cfcfd3;">' + label + '</span></br>' +
                    '<span style="font-weight:700;font-size:14px;">' + col + '</span></br>' +
                    '<span style="font-size:12px;"> Leads = ' + cnt + '</span></br>' +
                    '<span style="font-size:12px;">' + percent +  '</span>' +
                    '</div>'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
        }],
        legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50
        }
    };
    return options;
}