// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input,Checkbox } from "../../../../../../_metronic/_partials/controls";
import subPermissions from "../../permissionsObj";
// Validation schema
const validation = Yup.object().shape({
  role_name: Yup.string().required("Role name is required"),
});

export function AddOrEditRoleForm({
  saveRole,
  role,
  actionsLoading,
  onHide,
  allPermissions,
  error,
}) {
  const renderSubCategoryPermissions = (slug) => {
    return Object.keys(subPermissions[slug]).map((subPerm, i) => {
      return (
        <Col lg="6" key={i}>
          <label>
            <Field type="checkbox" name={slug} value={subPerm} />
            {"  "}
            {subPermissions[slug][subPerm].name}
          </label>
        </Col>
      );
    });
  };

  const renderPermissions = (values,setFieldValue) => {
    return allPermissions.map((perm, i) => {
      return (
        <tr key={i}>
          <td style={{ width: "220px" }}>
            <div className="d-flex align-items-center">
              <Field
                component={Checkbox}
                withFeedbackLabel={false}
                isSelected={
                  values[perm.slug] &&
                  values[perm.slug].length > 0 &&
                  values[perm.slug].length ===
                    Object.keys(subPermissions[perm.slug]).length
                }
                onChange={(e) => {
                  // console.log(perm.slug,subPermissions[]);
                  if (e.target.checked) {
                    // setFieldValue
                    setFieldValue(
                      perm.slug,
                      Object.keys(subPermissions[perm.slug])
                    );
                  } else {
                    setFieldValue(perm.slug, []);
                  }
                }}
              ></Field>
              <strong className="ml-1">
                &nbsp;&nbsp;&nbsp;&nbsp;{perm.name}
              </strong>
            </div></td>
          <td>
            <Row>{renderSubCategoryPermissions(perm.slug)}</Row>
            {values[perm.slug] && values[perm.slug].includes("export") && (
              <div>
                <h5>Exports Limits</h5>
                <p className="text-danger">
                  (*if limit not added it will count as infinite).
                </p>
                <Row>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_day"
                      component={Input}
                      placeholder="Export Limit(Day)"
                      label="Day Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_week"
                      component={Input}
                      placeholder="Export Limit(Week)"
                      label="Week Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                  <Col sm="6">
                    <Field
                      type="number"
                      name="export_limit_month"
                      component={Input}
                      placeholder="Export Limit(Month)"
                      label="Month Limit"
                      validate="void"
                      withFeedbackLabel={false}
                      showEnterInLabel={false}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={role}
        validationSchema={validation}
        onSubmit={(values) => {
          let data = { ...values },
            limits = {
              export_limit_day: values.export_limit_day
                ? values.export_limit_day
                : null,
              export_limit_week: values.export_limit_week
                ? values.export_limit_week
                : null,
              export_limit_month: values.export_limit_month
                ? values.export_limit_month
                : null,
            };
          delete data.role_name;
          delete data.export_limit_month;
          delete data.export_limit_week;
          delete data.export_limit_day;
          saveRole({
            name: values.role_name,
            permissions: data,
            limits:
              data["contacts"] && data["contacts"].includes("export")
                ? limits
                : {},
          });
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Role Name */}
                  <div className="col-12">
                    <Field
                      name="role_name"
                      component={Input}
                      placeholder="Role Name"
                      label="Role Name"
                      validate="void"
                    />
                  </div>
                </div>
                <div>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="w-25">Feature</th>
                        <th>Capabilities</th>
                      </tr>
                    </thead>
                    <tbody>{renderPermissions(values,setFieldValue)}</tbody>
                  </Table>
                </div>
                {error && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{error}</div>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              {actionsLoading ? (
                <button
                  type="button"
                  className="btn btn-primary btn-elevate"
                  disabled
                >
                  Saving <i className="fas fa-spinner fa-spin" />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
