import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import UnauthorizedAccess from "../modules/Errors/UnauthorizedAccess";
import { LeadCompareAnalysis } from "../modules/LeadCompareAnalysis/pages/LeadCompareAnalysis";

export function LeadCompareAnalysisPage() {
  // Getting curret state of Users list from store (Redux)
  const { userType, permissions } = useSelector(
    (state) => ({
      userType: state.auth.user.userType,
      permissions: state.auth.user.sectionPermissions,
    }),
    shallowEqual
  );
  if (
    (userType && userType.toLowerCase()) === "admin" ||
    permissions.includes("lead_compare_analysis")
  ) {
    return <LeadCompareAnalysis />;
  } else {
    return <UnauthorizedAccess />;
  }
}
