import * as requestFromServer from "./cpvTrackingCrud";
import {cpvTrackingSlice, callTypes} from "./cpvTrackingSlice";

const {actions} = cpvTrackingSlice;

export const setFilterState = type => dispatch => {
  dispatch(actions.cpvFilterState({type: type}));
}

export const fetchCpvTracking = data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCpvTracking(data)
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.cpvTrackingFetched({ entities: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.cpvTrackingFetched({entities: response.data.data, type: data.type}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomainsList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDomainList()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.domainsList({ domainList: []}));
      }else if( response && response.data && response.data.respType === 'Success') {
        dispatch(actions.domainsList({domainList: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
