import * as requestFromServer from "./importCrud";
import {importSlice, callTypes} from "./importSlice";
import * as utils from "../../../../_metronic/_helpers/AWSFileUpload"

const {actions} = importSlice;

export const getImports = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getImportList()
    .then(response => {
      if( response && response.status === 204 ) {
        dispatch(actions.importFetched({ totalCount: 0, entities: []}));
      } else if( response && response.data && response.data.respType ) {
        dispatch(actions.importFetched({ totalCount: 0, entities: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find imports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const cancelImportFileProcess = () => dispatch =>{
  return utils.cancelUpload().then(()=>{
    dispatch(actions.uploadFileEnd());
  }).catch(error=>{
    dispatch(actions.uploadFileEnd());
  });
}
export const startImport = (base64Data, originalFileName, headerMapObject, fileUploadProgressHandler) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.uploadFileStart());
  return utils.AWSFileUpload(base64Data, originalFileName, fileUploadProgressHandler).then((cloudFileName) => {
    requestFromServer
      .startImport(originalFileName, cloudFileName, headerMapObject)
      .then(response => {
        if( response && response.status === 200 ) {
          dispatch(getImports());
          dispatch(actions.importStarted());
        }
      })
      .catch(error => {
        error.clientMessage = "Something is wrong!!";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }).catch((err) => {
    console.log("Error: ", err);
  });
};

export const getImportStatus = () => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.fetching }));
  return requestFromServer
    .getStatusOfImport()
    .then(response => {
      if( response && response.status === 200 ) {
        dispatch(actions.importStatusFetched({ data: response.data.data}));
      }
    })
    .catch(error => {
      error.clientMessage = "Something is wrong!!";
      dispatch(actions.catchError({ error, callType: callTypes.fetching }));
    });
};
