import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function SaleLeadStatusDialogHeader({ month,year }) {
  // Staff Redux state
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.adSpent.actionsLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Add Sale Lead Status </Modal.Title>
      </Modal.Header>
    </>
  );
}
