import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as actions from "../_redux/fpActions";
import { updateDomainFilter } from "../../../../redux/timezone/timezoneActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import CachedIcon from "@material-ui/icons/Cached";
import RestoreIcon from "@material-ui/icons/Restore";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import FilterPopover from "../../../../_metronic/layout/components/filterDropdown/FilterPopover";
import momentTZ from "moment-timezone";
import { useLocation } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
const offSet = momentTZ()
  .tz("America/New_York")
  .format("Z");

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingTop: 0,
    paddingleft: 5,
  },
  searchFilter: {
    padding: "10px",
    height: "30px",
    fontSize: "10px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
}));

export function FunnelPerformanceFilters(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment
      .utc()
      .utcOffset(offSet)
      .format("YYYY-MM-DD") + " 23:59:59"
  );
  const [selectedDomains, setSelectedDomains] = React.useState([]);
  const [isLatestDataUpdating, setStatusOfData] = React.useState(false);
  const [selectedPages, setSelectedPages] = React.useState([]);

  const { currentState, defaultDomains } = useSelector(
    (state) => ({
      currentState: state.funnelPerformance,
      defaultDomains: state.timezoneBy,
    }),
    shallowEqual
  );
  const { allDomainNames, listLoading, pagesList } = currentState;
  const domainsState = defaultDomains.selectedDomain;

  const location = useLocation()
  useEffect(() => {
    sessionStorage.setItem("urlChange", 1);
    setInterval(() => {
      sessionStorage.setItem("urlChange", 0);
    }, 100);
  }, [location]);
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.fetchAllLandingPagesList({
        startDate,
        endDate,
        domains: domainsState.join(","),
      })
    );
  }, [ domainsState]);
  useEffect(() => {
    if (pagesList.length > 0) {
      if (pagesList.length <= 6) {
        setSelectedPages(pagesList.map((e) => e.page_name));
      } else {
        setSelectedPages([0, 1, 2, 3, 4, 5].map((e) => pagesList[e].page_name));
      }
    } else {
      setSelectedPages([]);
    }
  }, [pagesList]);

  useEffect(() => {
    dispatch(
      actions.getStats({
        startDate,
        endDate,
        domains: domainsState.join(","),
        selectedPages: selectedPages.join(","),
      })
    );
  }, [selectedPages]);

  const callApiOnDateFilter = (start, end) => {
    dispatch(
      actions.fetchAllLandingPagesList({
        startDate:start,
        endDate:end,
        domains: domainsState.join(","),
      })
    );
  };


  const refreshData = (e) => {
    dispatch(
      actions.getStats({
        startDate,
        endDate,
        domains: domainsState.join(","),
        selectedPages: selectedPages.join(","),
      })
    );
  };

  const updateLatestData = (e) => {
    setStatusOfData(true);
    axios.get(`${API_URL}/live-chat/updateLatestData`).then(
      (response) => {
        setStatusOfData(false);
        setTimeout(function() {
          refreshData();
        }, 1000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    dispatch(actions.fetchAllDomainNames());
  }, []);

  const applyDomainFilter = (val) => {
    dispatch(updateDomainFilter(val));
  };
  const applyLPFilter = (val) => {
    setSelectedPages(val);
  };
  return (
    <form className={classes.container + " w-100"} noValidate>
      <Row className="w-100 mb-3">
        <Col lg="12" md="12" sm="12">
          <FilterPopover
            fieldName={"dataField"}
            value={domainsState}
            options={allDomainNames}
            onApply={applyDomainFilter}
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Domain
          </small>
        </Col>
        <Col lg="5" md="5" sm="9" xs="9">
          <div className="input-group shadow-sm">
            <DateRangePicker
              onCallback={(start, end, label) => {
                let startDate = start.format("YYYY-MM-DD HH:mm:ss");
                let endDate = end.format("YYYY-MM-DD HH:mm:ss");
                setStartDate(startDate);
                setEndDate(endDate);
                callApiOnDateFilter(startDate,endDate);
              }}
              initialSettings={{
                timePicker: true,
                startDate: moment(startDate),
                endDate: moment(endDate),
                ranges: {
                  "Life Time": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(10, "years")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  Today: [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  Yesterday: [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "days")
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "Last 7 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(6, "days")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "Last 14 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(13, "days")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "Last 30 Days": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(29, "days")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "This week": [
                    moment.utc().utcOffset(offSet).startOf('isoweek')
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                      moment.utc().utcOffset(offSet).endOf('isoweek')
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "Last week": [
                    moment().utc().utcOffset(offSet).subtract(1, 'weeks').startOf('isoweek')
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                      moment().utc().utcOffset(offSet).subtract(7, "days").endOf('isoweek')
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),

                  ],
                  "This Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .startOf("month")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .endOf("month")
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                  "Last Month": [
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .startOf("month")
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      }),
                    moment
                      .utc()
                      .utcOffset(offSet)
                      .subtract(1, "month")
                      .endOf("month")
                      .set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 59,
                      }),
                  ],
                },
                locale: {
                  format: "MMMM DD, YYYY",
                },
                alwaysShowCalendars: true,
              }}
            >
              <input
                type="text"
                className="form-control"
                disabled={listLoading}
              />
            </DateRangePicker>
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                <i className="fa fa-calendar-check"></i>
              </span>
            </div>
          </div>
          <small className="form-text text-muted">
            <b>Filter</b> by Date
          </small>
        </Col>
        <Col lg="2" md="2" sm="3" xs="3">
          {!isLatestDataUpdating ? (
            <Button
              variant="outlined"
              color="secondary"
              className="shadow-sm"
              onClick={updateLatestData}
              title="Update Latest Data"
            >
              <RestoreIcon style={{ fontSize: 25 }}></RestoreIcon>
            </Button>
          ) : (
            <CircularProgress style={{ fontSize: 25 }}></CircularProgress>
          )}
        </Col>
        <Col lg="5" md="5" sm="12">
          <FilterPopover
            placeholder="Select Landing Pages"
            fieldName={"page_name"}
            value={selectedPages}
            options={pagesList}
            onApply={applyLPFilter}
            maxListShow={2}
            limitSelection={6}
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Landing Page
          </small>
        </Col>

      </Row>
    </form>
  );
}
